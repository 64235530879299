import { privatePostApi, privateGetApi } from "../../utils/apiHelper";
import { ExportState } from "../../utils/types/export";

/**
 * Bulk Operations
 */
export const listExportRecords = (payload: any) => () => {
  return new Promise<ExportState>((resolve, reject) => {
    privateGetApi("/exportrecords", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const createExportRecord = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/exportrecords", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
