import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import { Card, Col, Row, Tag, Tooltip } from "antd";
import "antd/dist/reset.css";
import {
  CodeSandboxOutlined,
  ExperimentOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "./substanceListing.css";
import { getRegulationsFromLocalStorage } from "../../../../utils/localStore";
interface CustomNodeProps {
  data: any;
}

const CustomNode: React.FC<CustomNodeProps> = ({ data }) => {
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);
  const regulations = getRegulationsFromLocalStorage() ?? [];
  return (
    <>
      {data && data.id === "manufacturer" ? (
        <Card
          bordered={true}
          style={{
            backgroundColor: "#003152",
            color: "white",
            height: "100px",
            width: "300px",
          }}
        >
          <Row>{data?.internalItemNumber}</Row>
          <Row>
            <Col span={18}>
              <b>{data?.manufacturer?.itemNumber}</b>
            </Col>
            <Col span={6}>
              <b>
                {parseFloat(data?.partWeight).toString()}
                {data?.partUnitOfMeasure}
              </b>
            </Col>
          </Row>
          <Row>{data?.manufacturer?.itemName}</Row>
        </Card>
      ) : data.id?.includes("material-") ? (
        <>
          <Card
            bordered={true}
            style={{
              backgroundColor: "#007FFF",
              color: "white",
              height: "100px",
              width: "300px",
              paddingTop: "17px",
            }}
          >
            <Row gutter={24}>
              <Col span={4}>
                <CodeSandboxOutlined
                  style={{
                    fontSize: "25px",
                  }}
                />
              </Col>
              <Col span={12}>
                <Tooltip title={data?.name}>
                  <b>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.name}
                    </p>
                  </b>
                </Tooltip>
              </Col>
              <Col span={8}>
                <b>
                  {parseFloat(data?.weight).toString()}
                  {data?.unitOfMeasure}
                </b>
              </Col>
            </Row>
          </Card>
        </>
      ) : (
        <Card
          key={data.substanceIndex + 1}
          className={`card-wrapper ${hoveredCard ? "hovered" : ""}`}
          onMouseEnter={() => setHoveredCard(data.substanceIndex + 1)}
          onMouseLeave={() => setHoveredCard(null)}
          style={{
            height: "100px",
            width: "300px",
            padding: 0,
            margin: 0,
            backgroundColor:
              data?.compliantalert?.toLowerCase() === "yes" ? "#FF4D4F" : "",
            color:
              data?.compliantalert?.toLowerCase() === "yes" ? "white" : "black",
          }}
          hoverable={true}
        >
          <Row gutter={24}>
            <Col span={4}>
              <ExperimentOutlined
                style={{
                  fontSize: "20px",
                }}
              />
            </Col>
            <Col span={17}>
              <Tooltip
                title={
                  typeof data?.name === "object"
                    ? data.name?.substance
                    : data.name
                }
              >
                <b>
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {typeof data?.name === "object"
                      ? data.name?.substance
                      : data.name}
                  </p>
                </b>
              </Tooltip>
            </Col>
            {data?.compliantalertRegulation.length > 0 && (
              <Col span={3}>
                <Tooltip
                  color="rgba(0, 0, 0, 0.5)"
                  title={regulations
                    .filter((regulation: { regulationNumber: any }) =>
                      data?.compliantalertRegulation.includes(
                        regulation.regulationNumber
                      )
                    )
                    .map((item: any) => (
                      <Tag
                        key={item?.regulationNumber}
                        style={{
                          color: "white",
                          backgroundColor: "#FF4D4F",
                          border: 0,
                        }}
                      >
                        {item.name}
                      </Tag>
                    ))}
                >
                  <InfoCircleOutlined
                    style={{ fontSize: "20px", color: "black" }}
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
          <Row>
            <Col
              span={16}
              style={{
                color:
                  data?.compliantalert?.toLowerCase() === "yes"
                    ? "#222222"
                    : "grey",
              }}
            >
              {"CAS # "}
              <b>{data?.casNumber}</b>
            </Col>
            <Col
              span={8}
              style={{
                backgroundColor: "#222222",
                borderRadius: "8px",
                color: "lightgrey",
                textAlign: "center",
              }}
            >
              {parseFloat(data?.weight).toString()}
              {data?.unitOfMeasure}
            </Col>
          </Row>
        </Card>
      )}

      <Handle type="source" position={Position.Left} id="left-source" />
      <Handle type="source" position={Position.Right} id="right-source" />
      <Handle type="target" position={Position.Left} id="left-target" />
      <Handle type="target" position={Position.Right} id="right-target" />
    </>
  );
};

export default CustomNode;
