import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Typography, Table } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { useAppDispatch } from "../../../../redux/hooks";
import { regulationExemptions } from "../../../../redux/actions/regulationsAction";
import ExemptionForm from "../../../../components/forms/ExemptionForm";
import { getLocalStorage } from "../../../../utils/localStore";
import ErrorBoundary from "../../../../utils/errorBoundary";

const { Text } = Typography;

interface ExemptionProps {
  regulationNumber: string;
  search?: string;
}
export interface ExemptionHandle {
  focusInput: () => void;
}

const RegulationExemptions = forwardRef<ExemptionHandle, ExemptionProps>(
  (props, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focusInput: () => {
        if (inputRef.current) {
          inputRef.current.focus();
          fetchData();
        }
      },
    }));

    const dispatch = useAppDispatch();
    const [exemptions, setExemptions] = useState<any[]>([]);
    const [exemptionCount, setExemptionCount] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [tablePagination, setTablePagination] =
      useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
      });
    const [tableSorter, setTableSorter] = useState<SorterResult<any>>({});
    const [tableFilters, setTableFilters] =
      useState<Record<string, FilterValue | null>>();
    const permissions = getLocalStorage("role")
      ? getLocalStorage("role").permissions
      : null;
    const { tenantNumber } = getLocalStorage("user");

    const columns: ColumnsType<any> = [
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 150,
        hidden: tenantNumber !== "acquiscompliance" ? true : false,
        render: (_: string, record: any) => (
          <>
            {permissions?.update.includes("exemptions") && (
              <ExemptionForm
                type="edit"
                formData={record}
                fetchData={fetchData}
                id={props.regulationNumber}
              />
            )}
          </>
        ),
      },
      {
        title: "#",
        dataIndex: "exemption",
        key: "exemption",
      },
      {
        title: "Clause",
        dataIndex: "clause",
        key: "clause",
      },
      {
        title: "Categories",
        dataIndex: "categories",
        key: "categories",
        render: (text: any) => <Text>{text?.join(", ")}</Text>,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text: string) => (
          <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
            {text}
          </Text>
        ),
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
      },
      {
        title: "Created",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text: string, record: any) => (
          <Text>
            {new Date(text).toLocaleString()}
            <br />
            {record.createdBy}
          </Text>
        ),
      },
    ];

    const fetchData = (payload?: any) => {
      setLoading(true);
      dispatch(
        regulationExemptions({
          pageSize:
            props.search && props.search?.length > 0
              ? 10
              : tablePagination.pageSize,
          pageNum:
            props.search && props.search?.length > 0
              ? 1
              : tablePagination.current,
          sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
          sortDesc: tableSorter.column
            ? tableSorter.order === "descend"
              ? false
              : true
            : false,
          filters: payload
            ? JSON.stringify({
                ...payload,
                regulationNumber: props.regulationNumber,
              })
            : JSON.stringify({
                regulationNumber: props.regulationNumber,
              }),
        })
      )
        .then((response) => {
          setExemptions(response.exemptions);
          setExemptionCount(response.exemptionCount);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    };

    const handleTableChange: TableProps<any>["onChange"] = (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[]
    ) => {
      setTablePagination(pagination);
      setTableFilters(filters);
      setTableSorter(sorter as SorterResult<any>);

      if (pagination.pageSize !== tablePagination.pageSize) {
        setExemptions([]);
      }
    };

    useEffect(() => {
      fetchData();
    }, [
      JSON.stringify(tablePagination),
      JSON.stringify(tableFilters),
      JSON.stringify(tableSorter),
    ]);

    useEffect(() => {
      if (props.search || props.search === "") {
        fetchData({
          softDelete: false,
          $or: [
            // Exact match without special characters
            {
              exemption: {
                $regex: escapeRegex(props.search),
                $options: "i",
              },
            },
            // Match with parentheses treated as optional
            {
              exemption: {
                $regex: escapeRegexWithOptionalParentheses(props.search),
                $options: "i",
              },
            },
          ],
        });
      }
    }, [props.search]);

    const escapeRegex = (str: string): string => {
      return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    const escapeRegexWithOptionalParentheses = (str: string): string => {
      return str
        .split("")
        .map((char) => escapeRegex(char) + "[()]*")
        .join("");
    };

    return (
      <div ref={inputRef}>
        <ErrorBoundary>
          <Table
            loading={loading}
            dataSource={exemptions}
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            columns={columns}
            rowKey="_id"
            pagination={{
              ...tablePagination,
              total: exemptionCount,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
          />
        </ErrorBoundary>
      </div>
    );
  }
);

RegulationExemptions.displayName = "RegulationExemptions";

export default RegulationExemptions;
