import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConflictMineralsState, conflictMinerals } from "../../utils/types/ConflictMinerals";


const initialState: ConflictMineralsState = {
    conflictMinerals: [],
    conflictMineralsLength: 0,
};

export const ConflictMineralsSlice = createSlice({
  name: "conflictMinerals",
  initialState,
  reducers: {
    setConflictMinerals: (
      state,
      action: PayloadAction<{ conflictMinerals: conflictMinerals[]; count: number }>
    ) => {
      state.conflictMinerals = action.payload.conflictMinerals;
      state.conflictMineralsLength = action.payload.count;
    },
  },
});

export const { setConflictMinerals } = ConflictMineralsSlice.actions;

export default ConflictMineralsSlice.reducer;
