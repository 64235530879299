import React, { useEffect, useState, useCallback } from "react";
import { Layout, Menu, Space, Button, Flex } from "antd";
import {
  HomeOutlined,
  CustomerServiceOutlined,
  MenuFoldOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../../assets/logo-text.png";
import logoIcon from "../../../assets/logo-icon.png";
import { getLocalStorage, setLocalStorage } from "../../../utils/localStore";
import styles from "./sider.module.css";

const { Sider } = Layout;

const SupplierSiderLayout: React.FC<{
  campaignAnalytics: any[];
}> = ({ campaignAnalytics }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onMenuClick = useCallback((key: string) => navigate(key), [navigate]);

  const onCollapse = useCallback(() => {
    setCollapsed((prev) => !prev);
    setLocalStorage("collapsed", !collapsed);
  }, []);

  useEffect(() => {
    const collapsed = getLocalStorage("collapsed");
    setCollapsed(collapsed ? collapsed : false);
  }, []);

  return (
    <Sider
      className={styles.sider}
      width={300}
      theme="dark"
      collapsed={collapsed}
    >
      <div className={styles.logo}>
        {collapsed ? (
          <img src={logoIcon} alt="Logo" height={30} />
        ) : (
          <img src={logo} alt="Logo" height={30} />
        )}
      </div>
      <Space
        direction="vertical"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "93.25%",
        }}
      >
        <Menu
          mode="inline"
          className={styles.menu}
          selectedKeys={[
            (location.pathname.includes("campaign") ? "campaign/" : "") +
              location.pathname.split("/").pop() ?? "",
          ]}
          defaultOpenKeys={["campaign"]}
          items={[
            {
              key: "introduction",
              label: "Introduction",
              icon: <HomeOutlined />,
            },
            {
              key: "campaign",
              label: "Campaign",
              icon: <MailOutlined />,
              children: campaignAnalytics.length
                ? campaignAnalytics.map((e: any) => ({
                    label: (
                      <Flex justify="space-between">
                        <span>{e.displayName}</span>{" "}
                        <span>
                          {e.responses}/{e.totalComponents}
                        </span>
                      </Flex>
                    ),
                    key: `campaign/${e.name}`,
                  }))
                : [],
            },
            {
              key: "support",
              label: "Support",
              icon: <CustomerServiceOutlined />,
            },
          ]}
          theme="dark"
          onClick={(e: any) => onMenuClick(e.key)}
        />
        <Button
          type="primary"
          block
          icon={<MenuFoldOutlined />}
          className={styles.siderButton}
          onClick={onCollapse}
        />
      </Space>
    </Sider>
  );
};

export default SupplierSiderLayout;
