import React, { useEffect, useState } from "react";
import {
  CheckOutlined,
  ExclamationCircleTwoTone,
  ClockCircleTwoTone,
  ExportOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Space, Typography, List, Row, Col, Card, Collapse, Table } from "antd";

import { PaginationConfig } from "antd/es/pagination";
import type { CollapseProps } from "antd";

import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { useAppDispatch } from "../../../../../redux/hooks";
import { readCampaignComplianceResponse } from "../../../../../redux/actions/CampaignManager/campaignManagerAction";
import { useParams } from "react-router-dom";
import { PendingResponse } from "../../../../../utils/types/CampaignManager/campaignManager";
import AddComplianceApprove from "../../../../../components/modals/addApproveComplianceModal";
import AddMaterialApprove from "../../../../../components/modals/addApproveMaterialsModal";
import ReadSupportingDocument from "../../../../../components/modals/ReadSupportingDocuments";
import ErrorBoundary from "../../../../../utils/errorBoundary";

const { Text, Title } = Typography;

const CampaignResponse: React.FC = () => {
  const { id, name } = useParams<{ id: any; name: string }>();
  const dispatch = useAppDispatch();
  const [campaignResponse, setCampaignResponse] = useState<any[]>([]);
  const [campaignResponseCount, setCampaignResponseCount] = useState<number>(0);
  const [items, setItems] = useState<CollapseProps["items"]>([]);

  const [loading, setLoading] = useState(false);
  const [listPagination, setListPagination] = useState<PaginationConfig>({
    pageSize: 10,
    current: 1,
    position: "bottom",
    align: "end",
  });
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<PendingResponse>>(
    {}
  );
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [component, setComponent] = useState<PendingResponse>();

  const columns: ColumnsType<PendingResponse> = [
    {
      title: "Component",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      width: 150,
      render: (_, record: any) => (
        <List.Item
          key={record._id}
          onClick={() => setComponent(record)}
          style={{ cursor: "pointer" }}
        >
          <List.Item.Meta
            title={
              <>
                <Space
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Space>
                    <Text>
                      {record.manufacturer.name} <br></br>
                      {record.manufacturer.itemNumber}
                    </Text>
                  </Space>
                  {component?._id === record._id && <ArrowRightOutlined />}
                </Space>
              </>
            }
            description={
              <div>
                {record.supplierComplianceResponse.filter(
                  (e: any) => e.status !== "approved"
                ).length +
                  (record.supplierMaterialsResponse &&
                  record.supplierMaterialsResponse.status !== "approved"
                    ? 1
                    : 0) +
                  " Pending approvals and " +
                  (record.supplierComplianceResponse.filter(
                    (e: any) => e.status === "approved"
                  ).length +
                    (record.supplierMaterialsResponse &&
                    record.supplierMaterialsResponse.status === "approved"
                      ? 1
                      : 0)) +
                  " approved"}
              </div>
            }
          />
        </List.Item>
      ),
    },
  ];

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      readCampaignComplianceResponse({
        sendgridCampaignId: id,
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: ["createdAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        softDelete: false,
        search: search?.query ? search?.query : "",
      })
    )
      .then((response: any) => {
        setCampaignResponse(response?.campaignResponse);
        setCampaignResponseCount(response?.count);
        const finalValues: any[] = [];
        response?.campaignResponse[0]?.supplierComplianceResponse.forEach(
          (e: any) => {
            finalValues.push({
              componentId: response?.campaignResponse[0].componentId,
              ...e,
            });
          }
        );
        handleSelection(response?.campaignResponse[0]);
        setComponent(response?.campaignResponse[0]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<PendingResponse>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PendingResponse> | SorterResult<PendingResponse>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<PendingResponse>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const handleSelection = (value: any) => {
    const finalValues: any[] = [];
    value?.supplierComplianceResponse.forEach((e: any) => {
      finalValues.push({
        componentId: value.componentId,
        ...e,
      });
    });

    const materials =
      value?.supplierMaterialsResponse &&
      Object.assign({}, value?.supplierMaterialsResponse, {
        componentId: value.componentId,
      });
    const complianceData = finalValues.map((item: any) => ({
      key: item.regulationNumber,
      label: (
        <div>
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Space>
              {item.regulationNumber.replace(/_/g, " ").toUpperCase() +
                " Compliances"}
            </Space>
            {item?.status === "approved" && (
              <CheckOutlined style={{ fontSize: "18px", color: "#52c41a" }} />
            )}
            {item?.status === "pending" && (
              <ClockCircleTwoTone
                style={{ fontSize: "18px" }}
                twoToneColor="blue"
              />
            )}
            {item?.status === "initiated" && (
              <ExclamationCircleTwoTone
                style={{ fontSize: "18px" }}
                twoToneColor="grey"
              />
            )}
          </Space>
        </div>
      ),
      children: (
        <div>
          <Title level={5}>
            {" "}
            Status :{" "}
            <Text>
              {" "}
              {(item.complianceOutputs && item.complianceOutputs.compliant) ||
                "NA"}{" "}
            </Text>{" "}
          </Title>
          <Title level={5}>
            {" "}
            Substance :{" "}
            <Text>
              {" "}
              {(item.complianceOutputs &&
                item.complianceOutputs.substance &&
                item.complianceOutputs.substance.toString()) ||
                "NA"}{" "}
            </Text>{" "}
          </Title>
          {item.regulationNumber === "eu_rohs" && (
            <Title level={5}>
              {" "}
              Exemptions :{" "}
              <Text>
                {" "}
                {(item.complianceOutputs &&
                  item.complianceOutputs.exemption &&
                  item.complianceOutputs.exemption.toString()) ||
                  "NA"}{" "}
              </Text>{" "}
            </Title>
          )}
          <Title level={5}>
            {" "}
            Version :{" "}
            <Text>
              {" "}
              {(item.complianceOutputs &&
                item.complianceOutputs.version &&
                item.complianceOutputs.version.toString()) ||
                "NA"}{" "}
            </Text>{" "}
          </Title>
          {item.complianceOutputs &&
            item.complianceOutputs.concentrationOfPfas && (
              <Title level={5}>
                {" "}
                Concentration of PFAS :{" "}
                <Text>
                  {" "}
                  {(item.complianceOutputs &&
                    item.complianceOutputs.concentrationOfPfas) ||
                    "NA"}{" "}
                </Text>{" "}
              </Title>
            )}
          {item.complianceOutputs &&
            item.complianceOutputs.concentrationUnit && (
              <Title level={5}>
                {" "}
                Concentration Unit :{" "}
                <Text>
                  {" "}
                  {(item.complianceOutputs &&
                    item.complianceOutputs.concentrationUnit) ||
                    "NA"}{" "}
                </Text>{" "}
              </Title>
            )}
          <Title level={5}>
            {" "}
            Compliance Document :{" "}
            {item && item?.complianceDocument ? (
              item?.complianceDocument?.includes("http") ? (
                <span>
                  <ExportOutlined
                    onClick={() => onClickViewLink(item?.complianceDocument)}
                  />{" "}
                  Link
                </span>
              ) : (
                <ReadSupportingDocument
                  postUrl="campaignmanager/document/download"
                  filename={item?.complianceDocument}
                  foldername="campaign"
                  campaignId={id}
                />
              )
            ) : null}
          </Title>
          <Title level={5}>
            {" "}
            Action :{" "}
            <Text>
              {" "}
              {item && item.status === "initiated"
                ? "Yet to submit"
                : item.status}{" "}
            </Text>{" "}
          </Title>
          <Title level={5}>
            {" "}
            Submited :{" "}
            <Text>
              {" "}
              {item && new Date(item.updatedAt).toLocaleString()}{" "}
            </Text>{" "}
          </Title>
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {item && item?.status === "pending" && (
              <AddComplianceApprove
                formData={item}
                fetchData={fetchData}
                type="button"
                sendgridCampaignId={id}
              />
            )}
          </Space>
        </div>
      ),
    }));

    const materialData = {
      key: "material",
      label: (
        <div>
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Space>Full Material Disclosure</Space>
            {materials?.status === "approved" && (
              <CheckOutlined style={{ fontSize: "18px", color: "#52c41a" }} />
            )}
            {materials?.status === "pending" && (
              <ClockCircleTwoTone
                style={{ fontSize: "18px" }}
                twoToneColor="blue"
              />
            )}
            {materials?.status === "initiated" && (
              <ExclamationCircleTwoTone
                style={{ fontSize: "18px" }}
                twoToneColor="grey"
              />
            )}
          </Space>
        </div>
      ),
      children: (
        <div>
          <Title level={5}>
            {" "}
            Part Weight :{" "}
            <Text>
              {" "}
              {(materials && materials?.partWeight) +
                (materials && materials?.partWeightUnit) || "NA"}{" "}
            </Text>{" "}
          </Title>
          <Title level={5}>
            {" "}
            FMD Document :
            {materials && materials?.fmdDocument ? (
              <ReadSupportingDocument
                postUrl="campaignmanager/document/download"
                filename={materials?.fmdDocument}
                foldername="campaign"
                campaignId={id}
                subfolder="materials"
              />
            ) : null}
          </Title>
          <Title level={5}>
            {" "}
            Action :{" "}
            <Text>
              {" "}
              {materials && materials?.status === "initiated"
                ? "Yet to submit"
                : materials?.status}{" "}
            </Text>{" "}
          </Title>
          <Title level={5}>
            {" "}
            Submited :{" "}
            <Text>
              {" "}
              {(materials &&
                materials?.updatedAt &&
                new Date(materials?.updatedAt).toLocaleString()) ||
                ""}{" "}
            </Text>{" "}
          </Title>
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {materials && materials?.status === "pending" && (
              <AddMaterialApprove
                formData={materials}
                fetchData={fetchData}
                type="button"
                sendgridCampaignId={id}
              />
            )}
          </Space>
        </div>
      ),
    };

    const dataValues = materials
      ? [...complianceData, materialData]
      : complianceData;
    setItems(dataValues);
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Row style={{ background: "white" }}>
          <Col span={8}>
            <Table
              loading={loading}
              dataSource={campaignResponse}
              scroll={{
                // x: 1000,
                y: "calc(100vh - 285px)",
              }}
              columns={columns}
              rowKey="_id"
              pagination={{
                ...tablePagination,
                total: campaignResponseCount,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (totalCount) => `Total  ${totalCount}  items`,
              }}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: () => handleSelection(record),
              })}
            />
          </Col>
          <Col span={16}>
            <Collapse accordion items={items} />
          </Col>
        </Row>
      </ErrorBoundary>
    </div>
  );
};

export default CampaignResponse;
