import { privateGetApi } from "../../utils/apiHelper";

export const AnalyticsAdmin = (payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      privateGetApi("/analytics/admin", payload)
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            resolve(response.data.data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  