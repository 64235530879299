import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { SmeltersState } from "../../utils/types/smelter";
import { Dispatch } from "redux";

/**
 * Bulk Operations
 */
export const listSmelters = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<SmeltersState>((resolve, reject) => {
    privateGetApi("/smelters", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createSmelter = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/smelters", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readSmelter = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/smelters/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateSmelter = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/smelters/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSmelter = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/smelters/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
