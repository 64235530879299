import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Form, Input, Row, Col, Select, Space, Button, Drawer } from "antd";
import { useParams } from "react-router-dom";

import type { SelectProps } from "antd";

import { Contact } from "../../utils/types/supplier";
import { useAppDispatch } from "../../redux/hooks";
import { updateSupplier } from "../../redux/actions/suppliersAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../utils/localStore";

const { Option } = Select;

const SupplierContactForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Contact;
}> = (props) => {
  const { id } = useParams<{ id: any }>();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const regulations = getRegulationsFromLocalStorage() ?? [];
  const options: SelectProps["options"] = [];

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  regulations?.forEach((regulation: any) => {
    options.push({
      label: regulation.name,
      value: regulation.regulationNumber,
    });
  });

  if (permissions.read?.includes?.("conflictminerals")) {
    options.push({
      label: "Conflict Minerals",
      value: "conflict",
    });
  }
  if (permissions.read?.includes?.("extendedminerals")) {
    options.push({
      label: "Extended Minerals",
      value: "extended",
    });
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFormSubmit = (contacts: Contact) => {
    setLoading(true);
    const updates = {
      ...contacts,
      _id: props?.formData?._id,
    };
    dispatch(
      updateSupplier({ type: "contact", updates: { contacts: updates } }, id)
    )
      .then(() => {
        props.fetchData();
        form.resetFields();
        setLoading(false);
        setOpen(false);
      })
      .catch((e: any) => console.log(e));
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <div>
      <Button
        type={props.type === "create" ? "primary" : "text"}
        onClick={showDrawer}
        title={props.type === "create" ? "New Contact" : "Edit Contact"}
        data-testid={
          props.type === "create"
            ? "create-contact-button"
            : "edit-contact-button"
        }
        icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
      >
        {props.type === "create" ? "Add Contact" : null}
      </Button>
      <Drawer
        title={props.type === "create" ? "Add Contact" : "Edit Contact"}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-contact-button">
              Cancel
            </Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              data-testid="submit-contact-button"
            >
              {props.type === "create" ? "Submit" : "Save"}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={props.type === "create" ? {} : { ...props.formData }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please input your first Name!" },
                  {
                    pattern: new RegExp(/^[a-zA-Z]+$/),
                    message: "Please input alphabet characters only",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="lastName" label="Last Name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="designation" label="Job Title">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="emailAddress"
                label="Contact E-Mail"
                rules={[
                  {
                    required: true,
                    message: "Please enter email address",
                  },
                  {
                    pattern: new RegExp(
                      /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
                    ),
                    message: "Please input a valid email address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="primaryNumber"
                label="Primary. No."
                rules={[
                  {
                    required: false,
                  },
                  {
                    pattern: new RegExp(
                      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/
                    ),
                    message: "Please input a valid phone number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="secondaryNumber"
                label="Secondary. No."
                rules={[
                  {
                    required: false,
                  },
                  {
                    pattern: new RegExp(
                      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/
                    ),
                    message: "Please input a valid phone number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="contactStatus"
                label="Contact status"
                rules={[
                  { required: true, message: "Please select contact status" },
                ]}
              >
                <Select placeholder="Select Contact Status">
                  <Option value="Primary">Primary</Option>
                  <Option value="Active">Active</Option>
                  <Option value="Inactive">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="regulationTags" label="Regulation Tags">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select Regulations"
                  options={options}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="comments" label="Contact Remarks">
                <Input.TextArea rows={4} placeholder="Contact Remarks" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default SupplierContactForm;
