import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { extendedMineralsState, extendedMinerals } from "../../utils/types/extendedMinerals";


const initialState: extendedMineralsState = {
    extendedMinerals: [],
    extendedMineralsLength: 0,
};

export const extendedMineralsSlice = createSlice({
  name: "extendedMinerals",
  initialState,
  reducers: {
    setExtendedMinerals: (
      state,
      action: PayloadAction<{ emrt: extendedMinerals[]; count: number }>
    ) => {
      state.extendedMinerals = action.payload.emrt;
      state.extendedMineralsLength = action.payload.count;
    },
  },
});

export const { setExtendedMinerals } = extendedMineralsSlice.actions;

export default extendedMineralsSlice.reducer;
