import React from "react";
import { Button, Col, Card, Form, Input, Row } from "antd";
import { User } from "../../utils/types/user";
import { useAppDispatch } from "../../redux/hooks";
import { updateUserMFA } from "../../redux/actions/usersAction";
import { getLocalStorage, setLocalStorage } from "../../utils/localStore";

const SecurityForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const user = getLocalStorage("user");
  const [loading, setLoading] = React.useState(false);

  const onFormSubmit = (values: User) => {
    setLoading(true);
    dispatch(updateUserMFA({ values }))
      .then((response: any) => {
        setLocalStorage("user", {
          ...user,
          mfa: response.mfa,
        });
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Card
      title="2-Step Verification"
      extra={
        <Button type="primary" onClick={form.submit} loading={loading}>
          {user.mfa ? "Disable" : "Enable"}
        </Button>
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ ...user }}
        onFinish={onFormSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="emailAddress"
              label="Email Address"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter email address",
                },
                {
                  type: "email",
                  message: "Please input a valid email address",
                },
              ]}
            >
              <Input placeholder="Please enter email address" disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default SecurityForm;
