import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListsState, List } from "../../../utils/types/CampaignManager/list";

const initialState: ListsState = {
  lists: [],
  listsCount: 0,
  listSuppliers: [],
  listSupplierCount: 0,
  listComponents: [],
  listComponentCount: 0,
  list: {},
};

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    setLists: (
      state,
      action: PayloadAction<{ lists: List[]; count: number }>
    ) => {
      state.lists = action.payload.lists;
      state.listsCount = action.payload.count;
    },
    setSupplierLists: (state, action: PayloadAction<any>) => {
      state.listSuppliers = action.payload.listSuppliers;
      state.listSupplierCount = action.payload.listSupplierCount;
    },
    setComponentLists: (state, action: PayloadAction<any>) => {
      state.listComponents = action.payload.listComponents;
      state.listComponentCount = action.payload.listComponentCount;
    },
    setList: (state, action: PayloadAction<any>) => {
      state.list = action.payload.list;
    },
  },
});

export const { setLists, setSupplierLists, setComponentLists, setList } =
  listsSlice.actions;

export default listsSlice.reducer;
