// suppliersSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SuppliersState, Supplier, Contact } from "../../utils/types/supplier";

const initialState: SuppliersState = {
  suppliers: [],
  supplierCount: 0,
  supplier: {},
  supplierComponents: [],
  supplierComponentsLength: 0,
  supplierHistory: [],
  supplierCampaigns: {},
  supplierSmelters: [],
  supplierSmeltersLength: 0,
  supplierContacts: [],
  supplierContactsLength: 0
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setSuppliers: (
      state,
      action: PayloadAction<{ suppliers: Supplier[]; count: number }>
    ) => {
      state.suppliers = action.payload.suppliers;
      state.supplierCount = action.payload.count;
    },
    readSupplier: (
      state,
      action: PayloadAction<any>
    ) => {
      state.supplier = action.payload.supplier;
      state.supplierCampaigns = action.payload.documentCampaigns;
      state.supplierHistory = action.payload.documentHistory;
    },
    setSupplierComponents: (
      state,
      action: PayloadAction<any>
    ) => {
      state.supplierComponents = action.payload.documentComponents;
      state.supplierComponentsLength = action.payload.documentComponentsLength;
    },
    setSupplierSmelters: (
      state,
      action: PayloadAction<any>
    ) => {
      state.supplierSmelters = action.payload.documentSmelters;
      state.supplierSmeltersLength = action.payload.documentSmeltersLength;
    },
    setSupplierContacts: (
      state,
      action: PayloadAction<{ supplierContacts: Contact[]; supplierContactsLength: number }>
    ) => {
      state.supplierContacts = action.payload?.supplierContacts;
      state.supplierContactsLength = action.payload.supplierContactsLength;
    },
  },
});

export const { setSuppliers, setSupplierComponents, setSupplierSmelters, setSupplierContacts } = suppliersSlice.actions;

export default suppliersSlice.reducer;
