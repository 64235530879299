import React from "react";
import { Button, Col, Card, Form, Input, Row } from "antd";

import { User } from "../../utils/types/user";
import { useAppDispatch } from "../../redux/hooks";
import { updateUser } from "../../redux/actions/usersAction";
import { getLocalStorage, setLocalStorage } from "../../utils/localStore";

const PersonalInformationForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const user = getLocalStorage("user");
  const [loading, setLoading] = React.useState(false);

  const onFormSubmit = (values: User) => {
    setLoading(true);
    dispatch(
      updateUser(
        {
          updates: values,
        },
        user.id
      )
    )
      .then(() => {
        setLocalStorage("user", { ...user, ...values });
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Card
      title="Personal Information"
      extra={
        <Button type="primary" onClick={form.submit} loading={loading}>
          Save
        </Button>
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ ...user }}
        onFinish={onFormSubmit}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter first name",
                },
              ]}
            >
              <Input placeholder="Please enter first name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter last name",
                },
              ]}
            >
              <Input placeholder="Please enter last name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="designation" label="Designation">
              <Input placeholder="Please enter designation" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="emailAddress"
              label="Email Address"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter email address",
                },
                {
                  type: "email",
                  message: "Please input a valid email address",
                },
              ]}
            >
              <Input placeholder="Please enter email address" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter phone number",
                },
                {
                  pattern: new RegExp(/^[0-9]{10}$/),
                  message: "Please input a valid phone number",
                },
              ]}
            >
              <Input placeholder="Please enter phone number" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default PersonalInformationForm;
