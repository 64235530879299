import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Descriptions, Tabs, Flex, Card, Avatar, Input, Space } from "antd";
import type { DescriptionsProps, TabsProps } from "antd";

import RegulationOverview from "./Overview";
import RegulationSubstances, { SubstanceHandle } from "./Substances";

import { Regulation } from "../../../../utils/types/regulation";
import { useAppDispatch } from "../../../../redux/hooks";
import { readRegulation } from "../../../../redux/actions/regulationsAction";
import RegulationForm from "../../../../components/forms/RegulationForm";
import RegulationExemptions, { ExemptionHandle } from "./Exemptions";
import { getLocalStorage } from "../../../../utils/localStore";
import SubstanceForm from "../../../../components/forms/SubstanceForm";
import ExemptionForm from "../../../../components/forms/ExemptionForm";
import ErrorBoundary from "../../../../utils/errorBoundary";

const { Search } = Input;
const { Meta } = Card;

const RegulationDetails: React.FC = () => {
  const substanceRef = useRef<SubstanceHandle>(null);
  const exemptionRef = useRef<ExemptionHandle>(null);

  const { regulationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [regulation, setRegulation] = useState<Regulation>();
  const [tabItems, setTabItems] = useState<TabsProps["items"]>([]);
  const [descriptionItems, setDescriptionItems] = useState<
    DescriptionsProps["items"]
  >([]);
  const [loading, setLoading] = useState(false);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const fetchData = (regulationId: string, payload?: any) => {
    setLoading(true);
    dispatch(readRegulation(regulationId))
      .then((response: Regulation) => {
        setRegulation(response);
        setDescriptionItems([
          {
            key: "2",
            label: "Description",
            children: response.description,
            span: 3,
          },
          {
            key: "1",
            label: "Type",
            children: response.type,
          },
        ]);
        const items = [
          {
            key: "",
            label: "Overview",
            children: <RegulationOverview />,
          },
          {
            key: "substances",
            label: "Substances",
            children: (
              <RegulationSubstances
                regulationNumber={response.regulationNumber}
                ref={substanceRef}
              />
            ),
          },
        ];
        response.hasExemptions &&
          items.push({
            key: "exemptions",
            label: "Exemptions",
            children: (
              <RegulationExemptions
                regulationNumber={response.regulationNumber}
                ref={exemptionRef}
              />
            ),
          });
        setTabItems(items);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTabChange = (key: string) => {
    navigate(key);
  };

  const handleSubstanceChild = () => {
    if (substanceRef.current) {
      substanceRef.current.focusInput();
    }
  };

  const handleExemptionChild = () => {
    if (exemptionRef.current) {
      exemptionRef.current.focusInput();
    }
  };

  useEffect(() => {
    if (regulationId) {
      fetchData(regulationId);
    }
  }, [regulationId]);

  return (
    <div>
      <ErrorBoundary>
        <Card style={{ marginTop: "2rem" }}>
          <Flex gap="small" justify="space-between" align="start">
            <Meta
              avatar={
                <Avatar
                  shape="square"
                  size={{ xs: 40, sm: 40, md: 40, lg: 45, xl: 55, xxl: 55 }}
                >
                  {regulation?.regulationNumber.slice(0, 2).toUpperCase()}
                </Avatar>
              }
              title={
                <Flex gap="small" justify="space-between" align="center">
                  {regulation?.name.toUpperCase()}
                  <RegulationForm
                    type="update"
                    fetchData={fetchData}
                    formData={regulation}
                  />
                </Flex>
              }
              description={
                <Descriptions size="small" items={descriptionItems} />
              }
            />
            {/* <Button>Show Timeline</Button> */}
          </Flex>
        </Card>
        <Tabs
          activeKey={
            location.pathname.split("/").pop() === regulationId
              ? ""
              : location.pathname.split("/").pop()
          }
          items={tabItems}
          onChange={handleTabChange}
          tabBarExtraContent={
            location.pathname.split("/").pop() !== regulationId && (
              <Space
                align="end"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                <Search
                  placeholder="Search using number or name"
                  allowClear
                  onSearch={(text) =>
                    setTabItems((prev) => {
                      return prev?.map((item) => {
                        if (item.key === "substances") {
                          return {
                            ...item,
                            children: (
                              <RegulationSubstances
                                regulationNumber={
                                  regulation?.regulationNumber || ""
                                }
                                search={text}
                              />
                            ),
                          };
                        } else if (item.key === "exemptions") {
                          return {
                            ...item,
                            children: (
                              <RegulationExemptions
                                regulationNumber={
                                  regulation?.regulationNumber || ""
                                }
                                search={text}
                              />
                            ),
                          };
                        } else {
                          return item;
                        }
                      });
                    })
                  }
                  style={{ width: 350 }}
                />
                {location.pathname.split("/").pop() === "substances" &&
                  permissions?.create.includes("substances") && (
                    <SubstanceForm
                      type="create"
                      fetchData={handleSubstanceChild}
                      id={regulation?.regulationNumber || ""}
                    />
                  )}
                {location.pathname.split("/").pop() === "exemptions" &&
                  permissions?.create.includes("exemptions") && (
                    <ExemptionForm
                      type="create"
                      fetchData={handleExemptionChild}
                      id={regulation?.regulationNumber || ""}
                    />
                  )}
              </Space>
            )
          }
        />
      </ErrorBoundary>
    </div>
  );
};

export default RegulationDetails;
