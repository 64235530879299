import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Button,
  Typography,
  Avatar,
  Tooltip,
  Input,
  Pagination,
  Dropdown,
  Col,
  Row,
  MenuProps,
  Tag,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  UploadOutlined,
  EyeFilled,
  LinkOutlined,
  DownOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { conflictMinerals } from "../../../../utils/types/ConflictMinerals";
import {
  listConflictMinerals,
  readConflictMineralsDocument,
} from "../../../../redux/actions/conflictMineralsAction";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import ImportCMRT from "../../../../components/modals/import-cmrt-modal";
import ConflictEdit from "../../../../components/modals/edit-cmrt-modal";
import Gold from "../../../../../src/assets/conflict/gold.svg";
import Tungsten from "../../../../../src/assets/conflict/tungsten.svg";
import Tin from "../../../../../src/assets/conflict/tin.svg";
import Tantalum from "../../../../../src/assets/conflict/tantalum.svg";
import { useNavigate } from "react-router-dom";
import type { TableProps, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../../../utils/localStore";
import axios from "axios";
import { baseUrl } from "../../../../utils/apiHelper";
import ErrorBoundary from "../../../../utils/errorBoundary";

const { Text } = Typography;
const { Search } = Input;

const ConflictResponseTracker: React.FC = () => {
  const dispatch = useAppDispatch();
  const { conflictMinerals, conflictMineralsLength } = useAppSelector(
    (state) => state.ConflictMinerals
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedTableRows, setselectedTableRows] = useState([]);
  const viewFile = async (filename: string) => {
    if (filename.includes("xlsx")) {
      try {
        const response = await axios.post(
          baseUrl + "/conflictminerals/document/read/conflict-minerals",
          {
            filename: filename,
            foldername: "conflict-minerals",
          },
          {
            headers: {
              "x-service-call": "conflictminerals",
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
            responseType: "arraybuffer",
          }
        );

        if (response && response.status === 200 && response.data) {
          const blobPDF = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });

          const blobURL = window.URL.createObjectURL(blobPDF);
          const fileLink = document.createElement("a");
          fileLink.href = blobURL;
          fileLink.download = filename;
          fileLink.click();
        } else {
          console.error("No response data received.");
        }
      } catch (error) {
        console.error("Error fetching file data:", error);
      }
    }
  };
  const columns: ColumnsType<conflictMinerals> = [
    {
      title: "",
      key: "operation",
      width: 100,
      render: (text: any, record: conflictMinerals) => (
        <Space>
          <Button
            type="text"
            icon={<UploadOutlined />}
            onClick={() => CMRTUpload()}
            data-testid="import-cmrt-button"
            title="Import CMRT"
          />
          {record.conflictMineral && (
            <Button
              type="text"
              icon={<EyeOutlined />}
              onClick={() => handleButtonClick(record)}
              data-testid="preview-cmrt-button"
              title="Preview"
            />
          )}
        </Space>
      ),
    },
    {
      title: "Supplier No.",
      width: 150,
      key: "number",
      dataIndex: "number",
      sorter: true,
    },
    {
      title: "Supplier Name",
      width: 200,
      key: "name",
      dataIndex: "name",
      sorter: true,
      render: (text, record) => (
        <span>
          {text}
          <br></br>
          {Array.isArray(record.outOfScope) &&
          record.outOfScope.includes("conflict") ? (
            <Tag color="grey">Out Of Scope</Tag>
          ) : null}
        </span>
      ),
    },
    {
      title: "Declaration Level",
      width: 150,
      key: "filename",
      dataIndex: ["conflictMineral", "company", "declarationScope"],
      sorter: (a: conflictMinerals, b: conflictMinerals) =>
        a?.conflictMineral?.company?.declarationScope.localeCompare(
          b?.conflictMineral?.company?.declarationScope
        ),
      render: (text: string, record: conflictMinerals) => {
        const level =
          record.conflictMineral?.company?.declarationScope.split(" ")[1];
        const formattedDeclarationLevel = level
          ? level.charAt(0).toUpperCase() + level.slice(1).toLowerCase()
          : "";
        return <>{formattedDeclarationLevel}</>;
      },
    },
    {
      title: "Origin From Covered Countries",
      width: 200,
      dataIndex: ["conflictMineral", "declarationScope"],
      render: (
        text: { state: string }[] | null | undefined,
        record: conflictMinerals
      ) => {
        const passValues =
          record.conflictMineral?.declarationScope
            ?.filter(
              (item) =>
                item.question ===
                  "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)" &&
                item.state?.toLowerCase() === "yes"
            )
            ?.map((item) => item.name) || [];
        return (
          <div style={{ position: "relative", height: "30px" }}>
            {passValues.map((passValue, index) => (
              <div
                key={index}
                style={{
                  position: "absolute",
                  left: `${index * 29}px`,
                  zIndex: passValues.length - index,
                  boxShadow: `0 2px 4px rgba(0, 0, 0, 0.5)`,
                  marginRight: "-5px",
                }}
              >
                {passValue === "Tantalum" && (
                  <Tooltip title={passValue}>
                    <Avatar
                      src={<img src={Tantalum} alt="Tin" />}
                      shape="square"
                      size={34}
                      style={{ border: "none" }}
                    />
                  </Tooltip>
                )}
                {passValue === "Tin" && (
                  <Tooltip title={passValue}>
                    <Avatar
                      src={<img src={Tin} alt="Tin" />}
                      shape="square"
                      size={34}
                      style={{ border: "none" }}
                    />
                  </Tooltip>
                )}
                {passValue === "Gold" && (
                  <Tooltip title={passValue}>
                    <Avatar
                      src={<img src={Gold} alt="Gold" />}
                      shape="square"
                      size={34}
                      style={{ border: "none" }}
                    />
                  </Tooltip>
                )}
                {passValue === "Tungsten" && (
                  <Tooltip title={passValue}>
                    <Avatar
                      src={<img src={Tungsten} alt="Tungsten" />}
                      shape="square"
                      size={34}
                      style={{ border: "none" }}
                    />
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Origin From CAHRAs",
      width: 200,
      dataIndex: ["conflictMineral", "declarationScope"],
      render: (
        text: { state: string }[] | null | undefined,
        record: conflictMinerals
      ) => {
        const passValues =
          record.conflictMineral?.declarationScope
            ?.filter(
              (item) =>
                item.question ===
                  "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?" &&
                item.state?.toLowerCase() === "yes"
            )
            ?.map((item) => item.name) || [];
        return (
          <div style={{ position: "relative", height: "30px" }}>
            {passValues.map((passValue, index) => (
              <div
                key={index}
                style={{
                  position: "absolute",
                  left: `${index * 29}px`,
                  zIndex: passValues.length - index,
                  boxShadow: `0 2px 4px rgba(0, 0, 0, 0.5)`,
                  marginRight: "-5px",
                }}
              >
                {passValue === "Tantalum" && (
                  <Tooltip title={passValue}>
                    <Avatar
                      src={<img src={Tantalum} alt="Tin" />}
                      shape="square"
                      size={34}
                      style={{ border: "none" }}
                    />
                  </Tooltip>
                )}
                {passValue === "Tin" && (
                  <Tooltip title={passValue}>
                    <Avatar
                      src={<img src={Tin} alt="Tin" />}
                      shape="square"
                      size={34}
                      style={{ border: "none" }}
                    />
                  </Tooltip>
                )}
                {passValue === "Gold" && (
                  <Tooltip title={passValue}>
                    <Avatar
                      src={<img src={Gold} alt="Gold" />}
                      shape="square"
                      size={34}
                      style={{ border: "none" }}
                    />
                  </Tooltip>
                )}
                {passValue === "Tungsten" && (
                  <Tooltip title={passValue}>
                    <Avatar
                      src={<img src={Tungsten} alt="Tungsten" />}
                      shape="square"
                      size={34}
                      style={{ border: "none" }}
                    />
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Tantalum",
      key: "tantlum",
      width: 180,
      dataIndex: ["conflictMineral"],
      render: (
        text: { state: string }[] | null | undefined,
        record: conflictMinerals
      ) => {
        const passValue = (
          record.conflictMineral?.declaration
            .filter((item) => item.name === "Tantalum")
            .map((item) => ({ question: item.question, state: item.state })) ||
          []
        ).filter(Boolean);
        const passValue1 = (
          record.conflictMineral?.declarationScope
            .filter((item) => item.name === "Tantalum")
            .map((item) => ({ question: item.question, state: item.state })) ||
          []
        ).filter(Boolean);

        const combinedValues = [...passValue, ...passValue1];
        return (
          <>
            {combinedValues.map((item, index) => (
              <React.Fragment key={index}>
                <Tooltip title={item.question}>{getAvatar(item.state)}</Tooltip>
              </React.Fragment>
            ))}
          </>
        );
      },
    },
    {
      title: "Tin",
      width: 180,
      key: "tin",
      dataIndex: ["conflictMineral"],
      render: (
        text: { state: string }[] | null | undefined,
        record: conflictMinerals
      ) => {
        const passValue = (
          record.conflictMineral?.declaration
            .filter((item) => item.name === "Tin")
            .map((item) => ({ question: item.question, state: item.state })) ||
          []
        ).filter(Boolean);
        const passValue1 = (
          record.conflictMineral?.declarationScope
            .filter((item) => item.name === "Tin")
            .map((item) => ({ question: item.question, state: item.state })) ||
          []
        ).filter(Boolean);

        const combinedValues = [...passValue, ...passValue1];
        return (
          <>
            {combinedValues.map((item, index) => (
              <React.Fragment key={index}>
                <Tooltip title={item.question}>{getAvatar(item.state)}</Tooltip>
              </React.Fragment>
            ))}
          </>
        );
      },
    },
    {
      title: "Gold",
      width: 180,
      key: "gold",
      dataIndex: ["conflictMineral"],
      render: (
        text: { state: string }[] | null | undefined,
        record: conflictMinerals
      ) => {
        const passValue = (
          record.conflictMineral?.declaration
            .filter((item) => item.name === "Gold")
            .map((item) => ({ question: item.question, state: item.state })) ||
          []
        ).filter(Boolean);
        const passValue1 = (
          record.conflictMineral?.declarationScope
            .filter((item) => item.name === "Gold")
            .map((item) => ({ question: item.question, state: item.state })) ||
          []
        ).filter(Boolean);

        const combinedValues = [...passValue, ...passValue1];
        return (
          <>
            {combinedValues.map((item, index) => (
              <React.Fragment key={index}>
                <Tooltip title={item.question}>{getAvatar(item.state)}</Tooltip>
              </React.Fragment>
            ))}
          </>
        );
      },
    },
    {
      title: "Tungsten",
      width: 180,
      key: "tungsten",
      dataIndex: ["conflictMineral"],
      render: (
        text: { state: string }[] | null | undefined,
        record: conflictMinerals
      ) => {
        const passValue = (
          record.conflictMineral?.declaration
            .filter((item) => item.name === "Tungsten")
            .map((item) => ({ question: item.question, state: item.state })) ||
          []
        ).filter(Boolean);
        const passValue1 = (
          record.conflictMineral?.declarationScope
            .filter((item) => item.name === "Tungsten")
            .map((item) => ({ question: item.question, state: item.state })) ||
          []
        ).filter(Boolean);

        const combinedValues = [...passValue, ...passValue1];
        return (
          <>
            {combinedValues.map((item, index) => (
              <React.Fragment key={index}>
                <Tooltip title={item.question}>{getAvatar(item.state)}</Tooltip>
              </React.Fragment>
            ))}
          </>
        );
      },
    },
    {
      title: "Supporting Files",
      width: 150,
      key: "filename",
      dataIndex: ["conflictMineral", "filename"],
      sorter: (a: conflictMinerals, b: conflictMinerals) =>
        a?.conflictMineral?.filename.localeCompare(
          b?.conflictMineral?.filename
        ),
      render: (text: string, record: conflictMinerals) => {
        const fileversion =
          (record.conflictMineral &&
            record.conflictMineral.filename &&
            "v" +
              (record.conflictMineral?.filename?.match(/_(\d+_\d+)/)?.[1]
                ? record.conflictMineral?.filename
                    ?.match(/_(\d+_\d+)/)?.[1]
                    .replace(/_/g, ".")
                : "NA")) ||
          "";
        return (
          <>
            <Tooltip title={record.conflictMineral?.filename}>
              {fileversion && (
                <span
                  onClick={() => viewFile(record?.conflictMineral?.filename)}
                >
                  <LinkOutlined /> {fileversion}
                </span>
              )}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Submitted By",
      width: 150,
      key: "supplierName",
      dataIndex: ["conflictMineral", "supplierName"],
      sorter: (a: conflictMinerals, b: conflictMinerals) =>
        a?.conflictMineral?.supplierName.localeCompare(
          b?.conflictMineral?.supplierName
        ),
      render: (text: string, record: conflictMinerals) => {
        return <>{record?.conflictMineral?.supplierName}</>;
      },
    },
    {
      title: "Submitted At",
      width: 150,
      dataIndex: ["conflictMineral", "updatedAt"],
      key: "updatedAt",
      sorter: (a, b) =>
        a.conflictMineral?.updatedAt.localeCompare(
          b.conflictMineral?.updatedAt
        ),
      render: (text: string, record: conflictMinerals) => (
        <Text>{record.conflictMineral && new Date(text).toLocaleString()}</Text>
      ),
    },
  ];
  const getAvatar = (passValue: string) => {
    const lowerCasePassValue = passValue;
    if (lowerCasePassValue?.toLowerCase() === "yes") {
      return (
        <Avatar
          size={16}
          style={{
            backgroundColor: "green",
            color: "white",
            marginRight: "2px",
          }}
        >
          Y
        </Avatar>
      );
    } else if (lowerCasePassValue?.toLowerCase() === "no") {
      return (
        <Avatar
          size={16}
          style={{ backgroundColor: "red", color: "white", marginRight: "2px" }}
        >
          N
        </Avatar>
      );
    } else if (lowerCasePassValue?.toLowerCase() === "unknown") {
      return (
        <Avatar
          size={16}
          style={{
            backgroundColor: "orange",
            color: "white",
            marginRight: "2px",
          }}
        >
          ?
        </Avatar>
      );
    } else if (
      lowerCasePassValue?.toLowerCase() === "none" ||
      lowerCasePassValue === null
    ) {
      return (
        <Avatar
          size={16}
          style={{
            backgroundColor: "grey",
            color: "white",
            marginRight: "2px",
          }}
        >
          -
        </Avatar>
      );
    }
  };

  const [search, setSearch] = useState<any>();

  const onSearch = (value: any) => {
    setSearch({
      searchField: true,
      query:
        value?.key === "Enter" ? value?.target?.value.trim() : value.trim(),
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<
    SorterResult<conflictMinerals>
  >({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      listConflictMinerals({
        page: tablePagination.current,
        limit: tablePagination.pageSize,
        sortBy: tableSorter.column
          ? tableSorter.field
          : ["conflictMineral.updatedAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        search: search?.searchField ? search?.query : "",
        version: getLocalStorage("cmrtversion"),
      })
    )
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const handleTableChange: TableProps<conflictMinerals>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<conflictMinerals> | SorterResult<conflictMinerals>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<conflictMinerals>);
    if (sorter) {
      setTablePagination({
        current: tablePagination.current,
        pageSize: tablePagination.pageSize,
      });
    }
    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  useEffect(() => {
    fetchData(search);
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  const [modal2Open, setModal2Open] = useState(false);
  const CMRTUpload = () => {
    setModal2Open(true);
  };

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };
  const handleButtonClick = (record: any) => {
    setOpen(true);
    setSelectedRow(record);
  };

  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  const handleRowClick = (record: any) => {
    setSelectedRow(record);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setselectedTableRows(selectedRows);
    },
    getCheckboxProps: (record: { name: string }) => ({
      disabled: record.name === "Disabled User",
    }),
  };
  const navigate = useNavigate();

  const handleGetData = () => {
    const user = getLocalStorage("user");
    if (user) {
      const parsedData = user;
      const tenantNumber = parsedData.tenantNumber;

      removeLocalStorage("supplier");
      removeLocalStorage("conflictMinerals");
      setLocalStorage("supplier", tenantNumber);
      navigate("/app/responsibleminerals/conflictminerals/generator", {
        state: { selectedTableRows: selectedTableRows },
        replace: true,
      });
    }
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Tantalum",
      icon: (
        <Avatar
          src={<img src={Tantalum} alt="Tantalum" />}
          shape="square"
          size={34}
          style={{ border: "none" }}
        />
      ),
    },
    {
      key: "2",
      label: "Tin",
      icon: (
        <Avatar
          src={<img src={Tin} alt="Tin" />}
          shape="square"
          size={34}
          style={{ border: "none" }}
        />
      ),
    },
    {
      key: "3",
      label: "Gold",
      icon: (
        <Avatar
          src={<img src={Gold} alt="Gold" />}
          shape="square"
          size={34}
          style={{ border: "none" }}
        />
      ),
    },
    {
      key: "4",
      label: "Tungsten",
      icon: (
        <Avatar
          src={<img src={Tungsten} alt="Tungsten" />}
          shape="square"
          size={34}
          style={{ border: "none" }}
        />
      ),
    },
    {
      key: "5",
      label: "Yes",
      icon: (
        <Avatar
          size={16}
          style={{
            backgroundColor: "green",
            color: "white",
            marginRight: "2px",
          }}
        >
          Y
        </Avatar>
      ),
    },
    {
      key: "6",
      label: "No",
      icon: (
        <Avatar
          size={16}
          style={{ backgroundColor: "red", color: "white", marginRight: "2px" }}
        >
          N
        </Avatar>
      ),
    },
    {
      key: "7",
      label: "Unknown",
      icon: (
        <Avatar
          size={16}
          style={{
            backgroundColor: "orange",
            color: "white",
            marginRight: "2px",
          }}
        >
          ?
        </Avatar>
      ),
    },
    {
      key: "8",
      label: "None",
      icon: (
        <Avatar
          size={16}
          style={{
            backgroundColor: "grey",
            color: "white",
            marginRight: "2px",
          }}
        >
          -
        </Avatar>
      ),
    },
  ];
  return (
    <div>
      <ErrorBoundary>
        <div style={{ textAlign: "right" }}>
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
            }}
          >
            <Search
              placeholder="Search using supplier name or number"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
            {selectedTableRows.length > 0 && (
              <Button
                type="primary"
                onClick={handleGetData}
                data-testid="consolidate-cmrt-button"
                title="Consolidate CMRT"
              >
                Consolidated CMRT
              </Button>
            )}
          </Space>
        </div>
        <Table
          columns={columns}
          loading={loading}
          rowKey="_id"
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
          dataSource={conflictMinerals}
          pagination={false}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          rowSelection={rowSelection}
          onChange={handleTableChange}
          footer={() => (
            <>
              <Row justify="space-between" align="middle">
                <Col>
                  <Dropdown menu={{ items }}>
                    <Button type="primary">
                      Legends
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
                <Col>
                  <Pagination
                    {...tablePagination}
                    total={conflictMineralsLength}
                    showTotal={(total: number, range: [number, number]) =>
                      `${range[0]}-${range[1]} of ${total} items`
                    }
                    showSizeChanger={true}
                    showQuickJumper={true}
                    onChange={(page, pageSize) =>
                      setTablePagination({
                        ...tablePagination,
                        current: page,
                        pageSize,
                      })
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        />

        {selectedRow && selectedRow?.conflictMineral && (
          <ConflictEdit
            rowdata={selectedRow.conflictMineral}
            open={open}
            onClose={onClose}
            fetchData={fetchData}
          />
        )}

        <ImportCMRT
          open={modal2Open}
          onCancel={() => setModal2Open(false)}
          supplier={selectedRow?.name}
          show={false}
        />
      </ErrorBoundary>
    </div>
  );
};

export default ConflictResponseTracker;
