import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { Smelter } from "../../../src/utils/types/smelter";
import TextArea from "antd/es/input/TextArea";
import { useAppDispatch } from "../../redux/hooks";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  createExtendedMineralsSmelter,
  readPublicSmelter,
} from "../../redux/actions/extendedMineralsActions";
import { listSuppliers } from "../../redux/actions/suppliersAction";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { Supplier } from "../../utils/types/supplier";
import { createConflictMineralsSmelter } from "../../redux/actions/conflictMineralsAction";
import { getLocalStorage } from "../../utils/localStore";

const EditSmelter: React.FC<{
  open: boolean;
  onClose: () => void;
  rowdata?: Smelter;
  type: any;
  smelterListData: any;
  mineral: string;
  fetchData: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [supplierCount, setSupplierCount] = useState<number>(0);

  const [search, setSearch] = useState<any>();

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Supplier>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const onSearch = (value: any) => {
    setSearch({
      searchField: true,
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };
  const onFormSubmit = (values: any) => {
    setLoading(true);
    const user = getLocalStorage("user");
    const tenantNumber = user.tenantNumber;
    if (props.mineral === "ExtendedMinerals") {
      dispatch(
        createExtendedMineralsSmelter({
          number: values.number,
          address: values.address,
          lookUp: values.lookUp,
          metal: values.metal,
          name: values.lookUp,
          references: values.reportedSuppliers
            ? values.reportedSuppliers
            : [tenantNumber],
          risk: values.risk,
          smelterRegions: props?.rowdata?.smelterRegions,
          sourceOfIdentificationNumber: values.sourceOfIdentificationNumber,
          comments: values.comments,
          type: props.type,
          _id: props?.rowdata?._id ? props?.rowdata?._id : null,
        })
      )
        .then(() => {
          form.resetFields();
          setLoading(false);
          setOpen(false);
          props.fetchData();
        })
        .catch((e) => setLoading(false));
    } else {
      dispatch(
        createConflictMineralsSmelter({
          number: values.number,
          address: values.address,
          lookUp: values.lookUp,
          metal: values.metal,
          name: values.lookUp,
          references: values.reportedSuppliers
            ? values.reportedSuppliers
            : [tenantNumber],
          risk: values.risk,
          smelterRegions: props?.rowdata?.smelterRegions,
          sourceOfIdentificationNumber: values.sourceOfIdentificationNumber,
          comments: values.comments,
          type: props.type,
          _id: props?.rowdata?._id ? props?.rowdata?._id : null,
        })
      )
        .then(() => {
          form.resetFields();
          setLoading(false);
          setOpen(false);
          props.fetchData();
        })
        .catch((e) => setLoading(false));
    }
  };
  const uniqueCountries: string[] = Array.from(
    new Set(
      props.smelterListData.map(
        (option: { address: { country: any } }) => option?.address?.country
      )
    )
  );
  const fetchData = () => {
    const filters: { [x: string]: FilterValue | null }[] = [];
    Object.keys(tableFilters).forEach((key) => {
      if (tableFilters[key] && tableFilters[key]?.length) {
        filters.push({ [key]: tableFilters[key] });
      }
    });
    setLoading(true);
    dispatch(
      listSuppliers({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        searchField:
          !filters.length && search?.searchField ? search?.query : "",
        filtersUsed: filters.length ? "useFilters" : false,
        filters: filters.length
          ? JSON.stringify({
              elements: filters,
              path: [],
            })
          : [],
      })
    )
      .then((response: any) => {
        setSuppliers(response.suppliers);
        setSupplierCount(response.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (open) {
      fetchData();
      fetchSmelterData();
    }
  }, [open, search]);
  const [smelterCountryData, setSmelterCountryData] = useState<string[]>([]);
  const [smelterNameData, setsmelterNameData] = useState<string[]>([]);

  const fetchSmelterData = async () => {
    try {
      const response = await readPublicSmelter({
        sortBy: [],
        sortDesc: [],
        page: 1,
        limit: 10,
        search: search?.searchField ? search?.query : "",

        softDelete: false,
      });
      setSmelterCountryData(
        Array.from(
          new Set(
            response.data.smelters.map(
              (option: { address: { country: any } }) =>
                option?.address?.country
            )
          )
        )
      );
      setsmelterNameData(
        Array.from(
          new Set(
            response.data.smelters.map((option: { name: any }) => option?.name)
          )
        )
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div>
      <Button
        type={props.type === "create" ? "primary" : "text"}
        onClick={showDrawer}
        title={props.type === "create" ? "New Smelter" : "Edit Smelter"}
        data-testid={
          props.type === "create"
            ? "create-smelter-button"
            : "edit-smelter-button"
        }
        icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
      >
        {props.type === "create" ? "Create Smelter" : null}
      </Button>
      <Drawer
        title={props.type === "create" ? "Create Smelter" : "Edit Smelter"}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-smelter-button">
              Cancel
            </Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              data-testid="submit-smelter-button"
            >
              {props.type === "create" ? "Submit" : "Save"}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={props.type === "create" ? {} : { ...props.rowdata }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={"number"}
                label={"Smelter Id"}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter smelter number",
                  },
                ]}
              >
                <Input placeholder="Enter" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"sourceOfIdentificationNumber"}
                label={"Source of Smelter Identification Number"}
              >
                <Input placeholder="Enter" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={"metal"}
                label={"Metal"}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please select metal",
                  },
                ]}
              >
                {props.mineral === "ExtendedMinerals" ? (
                  <Select placeholder={"Select"}>
                    <Select.Option value={"Cobalt"}>Cobalt</Select.Option>
                    <Select.Option value={"Mica"}>Mica</Select.Option>
                  </Select>
                ) : (
                  <Select placeholder={"Select"}>
                    <Select.Option value={"Tantalum"}>Tantalum</Select.Option>
                    <Select.Option value={"Tin"}>Tin</Select.Option>
                    <Select.Option value={"Gold"}>Gold</Select.Option>
                    <Select.Option value={"Tungsten"}>Tungsten</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"lookUp" || "name"}
                label={"Smelter Name"}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please select smelter name",
                  },
                ]}
              >
                <AutoComplete
                  placeholder="Enter smelter name to search"
                  onSearch={onSearch}
                  showSearch={true}
                >
                  {smelterNameData.map((option: string, index: number) => (
                    <Select.Option key={index} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={["address", "country"]}
                label={"Smelter Country"}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please select country",
                  },
                ]}
              >
                <Select
                  placeholder="Enter country name to search"
                  onSearch={onSearch}
                  showSearch={true}
                >
                  {smelterCountryData.map((option: string, index: number) => (
                    <Select.Option key={index} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["address", "streetAddress"]}
                label={"Smelter Street"}
              >
                <Input placeholder="Enter" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={["address", "city"]} label={"Smelter City"}>
                <Input placeholder="Enter" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["address", "state"]}
                label={"Smelter Facility Location"}
              >
                <Input placeholder="State/Province" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={"risk"} label={"Risk Ranking"}>
                <Select placeholder="Select">
                  <Select.Option value="low">Low</Select.Option>
                  <Select.Option value="mid">Mid</Select.Option>
                  <Select.Option value="high">High</Select.Option>
                  <Select.Option value="unknown">Unknown</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="reportedSuppliers"
                label={"Reported Supplier"}
                initialValue={props.rowdata?.references?.map(
                  (item) => item.supplier
                )}
              >
                <Select
                  mode="multiple"
                  placeholder="Enter supplier name to search"
                  onSearch={onSearch}
                >
                  {suppliers?.map((item) => (
                    <Select.Option key={item.name} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name={"comments"} label={"Comments"}>
                <TextArea placeholder="Enter" rows={5}></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default EditSmelter;
