import React from "react";
import { Col, Row, Typography } from "antd";

import PersonalInformationForm from "../../../components/forms/PersonalInformationForm";
import UpdatePasswordForm from "../../../components/forms/UpdatePasswordForm";
import SecurityForm from "../../../components/forms/SecurityForm";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Title } = Typography;

const ProfileSettings: React.FC = () => {
  return (
    <>
      <ErrorBoundary>
        <Title level={5}>Profile Settings</Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <PersonalInformationForm />
          </Col>
          <Col span={12}>
            <UpdatePasswordForm />
          </Col>
        </Row>
        <Title level={5}>Security</Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <SecurityForm />
          </Col>
        </Row>
      </ErrorBoundary>
    </>
  );
};

export default ProfileSettings;
