import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  DrawerProps,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd";
import {
  CommentOutlined,
  DownOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Card } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  createConflictMinerals,
  uploadConflictMineralsDocument,
} from "../../redux/actions/conflictMineralsAction";
import dayjs, { Dayjs } from "dayjs";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../utils/localStore";
import axios from "axios";
import { baseUrl } from "../../utils/apiHelper";
import { ColumnsType } from "antd/es/table";

interface Declaration {
  question: string;
  name: string;
  state: string;
  comments: string;
}
interface SupportingDocuments {
  filename: string;
  type: string;
  _id: string;
}
interface Policy {
  question: string | null;
  name: string | null;
  state: string | null;
  comments: string | null;
}
interface Smelter {
  number: string;
  metal: string;
  lookUp: string;
  sourceOfIdentificationNumber: string;
  address: {
    country: string;
    city: string;
    state: string;
  };
}
interface Products {
  manufacturer: {
    itemNumber: string;
    name: string;
  };
  comments: string;
}
interface FormGenerate {
  cmrtSupplier: string | null;
  cmrtVersion: null | string;
  company: {
    name: string;
    declarationScope: string;
    declarationDescription: string;
    uniqueId: string;
    uniqueIdAuthority: string;
    address: string;
    contact: {
      name: string;
      emailAddress: string;
      phoneNumber: string;
    };
    authorizer: {
      name: string;
      title: string;
      emailAddress: string;
      phoneNumber: string;
      effectiveDate: Dayjs | null;
    };
  };
  declaration: Declaration[];
  declarationScope: Declaration[];
  policy: Policy[];
  smelter: Smelter[];
  products: Products[];
  rawFileName: string;
  supportingDocuments: SupportingDocuments[];
}

interface DrawerComponentProps {
  open: boolean;
  onClose: () => void;
  rowdata: FormGenerate;
  fetchData: any;
}

const ConflictEdit: React.FC<DrawerComponentProps> = ({
  rowdata,
  open,
  onClose,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(rowdata || {});
  const [disabledgold, setDisabledgold] = useState<boolean>(false || true);
  const [disabledtin, setDisabledtin] = useState<boolean>(false || true);
  const [disabledtungsten, setDisabledtungsten] = useState<boolean>(
    false || true
  );
  const [disabledtantalum, setDisabledtantalum] = useState<boolean>(
    false || true
  );
  const [isUpdated, setisUpdated] = useState<boolean>(false);

  useEffect(() => {
    setFormData(rowdata);
    if (
      formData?.declaration &&
      formData?.declaration?.length > 0 &&
      !isUpdated
    ) {
      setDisabledgold(
        formData.declaration
          .find(
            (val) =>
              val.name === "Gold" &&
              val.question ===
                "Is any 3TG intentionally added or used in the product(s) or in the production process?"
          )
          ?.state?.toLowerCase() === "no"
      );
      setDisabledtin(
        formData.declaration
          .find(
            (val) =>
              val.name === "Tin" &&
              val.question ===
                "Is any 3TG intentionally added or used in the product(s) or in the production process?"
          )
          ?.state?.toLowerCase() === "no"
      );
      setDisabledtantalum(
        formData.declaration
          .find(
            (val) =>
              val.name === "Tantalum" &&
              val.question ===
                "Is any 3TG intentionally added or used in the product(s) or in the production process?"
          )
          ?.state?.toLowerCase() === "no"
      );
      setDisabledtungsten(
        formData.declaration
          .find(
            (val) =>
              val.name === "Tungsten" &&
              val.question ===
                "Is any 3TG intentionally added or used in the product(s) or in the production process?"
          )
          ?.state?.toLowerCase() === "no"
      );
    }
    setLocalStorage("conflictMinerals", formData);
  }, [rowdata]);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const declaration = [];
      const declarationscope = [];
      for (const question of [
        "Is any 3TG intentionally added or used in the product(s) or in the production process?",
        "Does any 3TG remain in the product(s)?",
      ]) {
        for (const name of ["Tantalum", "Tin", "Gold", "Tungsten"]) {
          const key = `${question}-${name}-state`;
          const Commentskey = `${question}-${name}-comments`;
          declaration.push({
            question,
            name,
            state: values[key],
            comments: values[Commentskey],
          });
        }
      }
      for (const question of [
        "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)",
        "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?",
        "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?",
        "What percentage of relevant suppliers have provided a response to your supply chain survey?",
        "Have you identified all of the smelters supplying the 3TG to your supply chain?",
        "Has all applicable smelter information received by your company been reported in this declaration?",
      ]) {
        for (const name of ["Tantalum", "Tin", "Gold", "Tungsten"]) {
          const key = `${question}-${name}-state`;
          const Commentskey = `${question}-${name}-comments`;
          declarationscope.push({
            question,
            name,
            state: values[key],
            comments: values[Commentskey],
          });
        }
      }

      const policy = formData.policy.map((item) => {
        const key = `${item.question}-${item.name}-state`;
        const Commentskey = `${item.question}-${item.name}-comments`;
        return {
          question: item.question,
          name: item.name,
          state: values[key],
          comments: values[Commentskey],
        };
      });

      const companyDetails = {
        name: values.company.name,
        declarationScope: values.company.declarationScope,
        declarationDescription: values.company.declarationDescription,
        uniqueId: values.company.uniqueId,
        uniqueIdAuthority: values.company.uniqueIdAuthority,
        address: values.company.address,
        contact: {
          name: values.company.contact?.name,
          emailAddress: values.company.contact?.emailAddress,
          phoneNumber: values.company.contact?.phoneNumber,
        },
        authorizer: {
          name: values.company.authorizer?.name,
          title: values.company.authorizer?.title,
          emailAddress: values.company.authorizer?.emailAddress,
          phoneNumber: values.company.authorizer?.phoneNumber,
          effectiveDate: values.company.authorizer?.effectiveDate,
        },
      };
      const user = getLocalStorage("user");
      if (user) {
        const parsedData = user;
        const companyName = parsedData.companyName;

        await createConflictMinerals({
          declaration: declaration,
          declarationScope: declarationscope,
          policy: policy,
          company: companyDetails,
          smelters: [],
          products: formData.products,
          rawFileName: formData.rawFileName,
          supplierName: companyName,
          cmrtSupplier: formData.cmrtSupplier,
          sendgridCampaignId: null,
          type: "declaration",
          supportingDocuments:
            file && DocumentKey
              ? { filename: file.name, type: DocumentKey }
              : null,
        });
      }
    } catch (error) {
      console.error("Error sending data:", error);
    } finally {
      fetchData();
      setLoading(false);
      removeLocalStorage("conflictMinerals");
    }

    onClose();
  };

  const [editMode, setEditMode] = useState(false);

  const onEditClick = () => {
    setEditMode(true);
  };

  const onCancel = () => {
    setEditMode(false);
  };
  const handleFieldChange = (fieldName: string, name: any) => {
    const value = form.getFieldValue(fieldName);
    if (name === "Tantalum") {
      setDisabledtantalum(value?.toLowerCase() === "no");
    }
    if (name === "Tin") {
      setDisabledtin(value?.toLowerCase() === "no");
    }
    if (name === "Gold") {
      setDisabledgold(value?.toLowerCase() === "no");
    }
    if (name === "Tungsten") {
      setDisabledtungsten(value?.toLowerCase() === "no");
    }
    setisUpdated(true);
  };
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  );

  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
  };
  const viewFile = async (filename: string) => {
    try {
      const response = await axios.post(
        baseUrl + "/conflictminerals/document/read/conflict-minerals",
        {
          filename: filename,
          foldername: "conflict-minerals",
        },
        {
          headers: {
            "x-service-call": "conflictminerals",
            Authorization: `Bearer ${getLocalStorage("accessToken")}`,
          },
          responseType: "arraybuffer",
        }
      );

      if (response && response.status === 200 && response.data) {
        const fileType = (filename?.split(".")?.pop() ?? "").toLowerCase();
        const defaultType = "application/pdf";
        const fileFormatMap: { [key: string]: string } = {
          pdf: "application/pdf",
          docx: "application/octet-stream",
          doc: "application/octet-stream",
          xlsx: "application/vnd.ms-excel",
        };
        let blobPDF;
        if (fileType) {
          blobPDF = new Blob([response.data], {
            type: fileFormatMap[fileType],
          });
        } else {
          blobPDF = new Blob([response.data], {
            type: defaultType,
          });
        }
        const blobURL = window.URL.createObjectURL(blobPDF);
        const fileLink = document.createElement("a");
        fileLink.href = blobURL;
        fileLink.download = filename;
        fileLink.click();
      } else {
        console.error("No response data received.");
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
    }
  };
  const SuppotingDocumentsColumns: ColumnsType<SupportingDocuments> = [
    {
      title: "Actions",
      key: "operation",
      width: 100,
      render: (text: any, record: SupportingDocuments) => (
        <Space>
          <Button
            type="text"
            icon={<DownloadOutlined />}
            onClick={() => viewFile(record?.filename)}
            data-testid="download-cmrt-supporting-document-button"
            title="Download Supporting Document"
          />
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        if (text === "cmrtDocument") {
          return "CMRT";
        }
        if (text === "cmrtStatement") {
          return "Conflict Minerals Statement";
        } else if (text === "policyStatement") {
          return "Policy Statement";
        } else if (text === "others") {
          return "Others";
        } else {
          return text;
        }
      },
    },
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename",
    },
  ];
  const [placement] = useState<DrawerProps["placement"]>("right");
  const TabsItems: TabsProps["items"] = [
    {
      key: "declaration",
      label: "Declaration",
      forceRender: true,
      children: (
        <Card style={{ overflow: "auto", height: "75vh" }}>
          {" "}
          <b>
            1. Is any 3TG intentionally added or used in the product(s) or in
            the production process?
          </b>
          {formData?.declaration
            .filter(
              (item) =>
                item.question ===
                "Is any 3TG intentionally added or used in the product(s) or in the production process?"
            )
            .map((item) => (
              <Row
                key={`Is any 3TG intentionally added or used in the product(s) or in the production process?-${item.name}`}
                style={{ display: "flex", flex: "auto" }}
                gutter={[24, 24]}
              >
                <Col span={8}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  {editMode ? (
                    <Form.Item
                      name={`Is any 3TG intentionally added or used in the product(s) or in the production process?-${item.name}-state`}
                      initialValue={item.state}
                      dependencies={[
                        `Is any 3TG intentionally added or used in the product(s) or in the production process?-${item.name}-state`,
                      ]}
                    >
                      <Select
                        onChange={() =>
                          handleFieldChange(
                            `Is any 3TG intentionally added or used in the product(s) or in the production process?-${item.name}-state`,
                            item.name
                          )
                        }
                      >
                        <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                        <Select.Option value="Unknown">Unknown</Select.Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item>{item.state}</Form.Item>
                  )}
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  {editMode ? (
                    <Tooltip title={item.comments}>
                      <Form.Item
                        name={`${item.question}-${item.name}-comments`}
                        initialValue={item.comments}
                      >
                        <Input placeholder="Comments" />
                      </Form.Item>
                    </Tooltip>
                  ) : (
                    <Form.Item
                      name={`${item.question}-${item.name}-comments`}
                      initialValue={item.comments}
                    >
                      <Tooltip title={item.comments}>
                        <CommentOutlined
                          style={{
                            fontSize: "20px",
                            color: item.comments ? "blue" : "black",
                          }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            ))}
          <b>2. Does any 3TG remain in the product(s)?</b>
          {formData?.declaration
            .filter(
              (item) =>
                item.question === "Does any 3TG remain in the product(s)?"
            )
            .map((item, index) => (
              <Row
                key={`Does any 3TG remain in the product(s)?-${item.name}`}
                style={{ display: "flex", flex: "auto" }}
                gutter={[24, 24]}
              >
                <Col span={8}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  {editMode ? (
                    <Form.Item
                      name={`Does any 3TG remain in the product(s)?-${item.name}-state`}
                      initialValue={item.state}
                      rules={[
                        index === 0 && !disabledtantalum
                          ? { required: true, message: "Required" }
                          : index === 1 && !disabledtin
                          ? { required: true, message: "Required" }
                          : index === 2 && !disabledgold
                          ? { required: true, message: "Required" }
                          : index === 3 && !disabledtungsten
                          ? { required: true, message: "Required" }
                          : {},
                      ]}
                    >
                      <Select
                        disabled={
                          (index === 0 && disabledtantalum === true) ||
                          (index === 1 && disabledtin === true) ||
                          (index === 2 && disabledgold === true) ||
                          (index === 3 && disabledtungsten === true)
                        }
                      >
                        <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                        <Select.Option value="Unknown">Unknown</Select.Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item>{item.state}</Form.Item>
                  )}
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  {editMode ? (
                    <Tooltip title={item.comments}>
                      <Form.Item
                        name={`${item.question}-${item.name}-comments`}
                        initialValue={item.comments}
                      >
                        <Input placeholder="Comments" />
                      </Form.Item>
                    </Tooltip>
                  ) : (
                    <Form.Item
                      name={`${item.question}-${item.name}-comments`}
                      initialValue={item.comments}
                    >
                      <Tooltip title={item.comments}>
                        <CommentOutlined
                          style={{
                            fontSize: "20px",
                            color: item.comments ? "blue" : "black",
                          }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            ))}
          <b>
            3. Do any of the smelters in your supply chain source the 3TG from
            the covered countries? (SEC term, see definitions tab)
          </b>
          {formData?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)"
            )
            .map((item, index) => (
              <Row
                key={`Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)-${item.name}`}
                style={{ display: "flex", flex: "auto" }}
                gutter={[24, 24]}
              >
                <Col span={8}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  {editMode ? (
                    <Form.Item
                      name={`Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)-${item.name}-state`}
                      initialValue={item.state}
                      rules={[
                        index === 0 && !disabledtantalum
                          ? { required: true, message: "Required" }
                          : index === 1 && !disabledtin
                          ? { required: true, message: "Required" }
                          : index === 2 && !disabledgold
                          ? { required: true, message: "Required" }
                          : index === 3 && !disabledtungsten
                          ? { required: true, message: "Required" }
                          : {},
                      ]}
                    >
                      <Select
                        disabled={
                          (index === 0 && disabledtantalum === true) ||
                          (index === 1 && disabledtin === true) ||
                          (index === 2 && disabledgold === true) ||
                          (index === 3 && disabledtungsten === true)
                        }
                      >
                        <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                        <Select.Option value="Unknown">Unknown</Select.Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item>{item.state}</Form.Item>
                  )}
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  {editMode ? (
                    <Tooltip title={item.comments}>
                      <Form.Item
                        name={`${item.question}-${item.name}-comments`}
                        initialValue={item.comments}
                      >
                        <Input placeholder="Comments" />
                      </Form.Item>
                    </Tooltip>
                  ) : (
                    <Form.Item
                      name={`${item.question}-${item.name}-comments`}
                      initialValue={item.comments}
                    >
                      <Tooltip title={item.comments}>
                        <CommentOutlined
                          style={{
                            fontSize: "20px",
                            color: item.comments ? "blue" : "black",
                          }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            ))}
          <b>
            4. Do any of the smelters in your supply chain source the 3TG from
            conflict-affected and high-risk areas?
          </b>
          {formData?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?"
            )
            .map((item, index) => (
              <Row
                key={`Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
                gutter={[24, 24]}
              >
                <Col span={8}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  {editMode ? (
                    <Form.Item
                      name={`Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?-${item.name}-state`}
                      initialValue={item.state}
                      rules={[
                        index === 0 && !disabledtantalum
                          ? { required: true, message: "Required" }
                          : index === 1 && !disabledtin
                          ? { required: true, message: "Required" }
                          : index === 2 && !disabledgold
                          ? { required: true, message: "Required" }
                          : index === 3 && !disabledtungsten
                          ? { required: true, message: "Required" }
                          : {},
                      ]}
                    >
                      <Select
                        disabled={
                          (index === 0 && disabledtantalum === true) ||
                          (index === 1 && disabledtin === true) ||
                          (index === 2 && disabledgold === true) ||
                          (index === 3 && disabledtungsten === true)
                        }
                      >
                        <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                        <Select.Option value="Unknown">Unknown</Select.Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item>{item.state}</Form.Item>
                  )}
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  {editMode ? (
                    <Tooltip title={item.comments}>
                      <Form.Item
                        name={`${item.question}-${item.name}-comments`}
                        initialValue={item.comments}
                      >
                        <Input placeholder="Comments" />
                      </Form.Item>
                    </Tooltip>
                  ) : (
                    <Form.Item
                      name={`${item.question}-${item.name}-comments`}
                      initialValue={item.comments}
                    >
                      <Tooltip title={item.comments}>
                        <CommentOutlined
                          style={{
                            fontSize: "20px",
                            color: item.comments ? "blue" : "black",
                          }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            ))}
          <b>
            5. Does 100 percent of the 3TG (necessary to the functionality or
            production of your products) originate from recycled or scrap
            sources?
          </b>
          {formData?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?"
            )
            .map((item, index) => (
              <Row
                key={`Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
                gutter={[24, 24]}
              >
                <Col span={8}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  {editMode ? (
                    <Form.Item
                      name={`Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?-${item.name}-state`}
                      initialValue={item.state}
                      rules={[
                        index === 0 && !disabledtantalum
                          ? { required: true, message: "Required" }
                          : index === 1 && !disabledtin
                          ? { required: true, message: "Required" }
                          : index === 2 && !disabledgold
                          ? { required: true, message: "Required" }
                          : index === 3 && !disabledtungsten
                          ? { required: true, message: "Required" }
                          : {},
                      ]}
                    >
                      <Select
                        disabled={
                          (index === 0 && disabledtantalum === true) ||
                          (index === 1 && disabledtin === true) ||
                          (index === 2 && disabledgold === true) ||
                          (index === 3 && disabledtungsten === true)
                        }
                      >
                        <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                        <Select.Option value="Unknown">Unknown</Select.Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item>{item.state}</Form.Item>
                  )}
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  {editMode ? (
                    <Tooltip title={item.comments}>
                      <Form.Item
                        name={`${item.question}-${item.name}-comments`}
                        initialValue={item.comments}
                      >
                        <Input placeholder="Comments" />
                      </Form.Item>
                    </Tooltip>
                  ) : (
                    <Form.Item
                      name={`${item.question}-${item.name}-comments`}
                      initialValue={item.comments}
                    >
                      <Tooltip title={item.comments}>
                        <CommentOutlined
                          style={{
                            fontSize: "20px",
                            color: item.comments ? "blue" : "black",
                          }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            ))}
          <b>
            6. What percentage of relevant suppliers have provided a response to
            your supply chain survey?
          </b>
          {formData?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "What percentage of relevant suppliers have provided a response to your supply chain survey?"
            )
            .map((item, index) => (
              <Row
                key={`What percentage of relevant suppliers have provided a response to your supply chain survey?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
                gutter={[24, 24]}
              >
                <Col span={8}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  {editMode ? (
                    <Form.Item
                      name={`What percentage of relevant suppliers have provided a response to your supply chain survey?-${item.name}-state`}
                      initialValue={item.state}
                      rules={[
                        index === 0 && !disabledtantalum
                          ? { required: true, message: "Required" }
                          : index === 1 && !disabledtin
                          ? { required: true, message: "Required" }
                          : index === 2 && !disabledgold
                          ? { required: true, message: "Required" }
                          : index === 3 && !disabledtungsten
                          ? { required: true, message: "Required" }
                          : {},
                      ]}
                    >
                      <Select
                        disabled={
                          (index === 0 && disabledtantalum === true) ||
                          (index === 1 && disabledtin === true) ||
                          (index === 2 && disabledgold === true) ||
                          (index === 3 && disabledtungsten === true)
                        }
                        options={[
                          { label: "100%", value: "1" },
                          {
                            label: "Greater than 90%",
                            value: "Greater than 90%",
                          },
                          {
                            label: "Greater than 75%",
                            value: "Greater than 75%",
                          },
                          {
                            label: "Greater than 50%",
                            value: "Greater than 50%",
                          },
                          { label: "50% or less", value: "50% or less" },
                          { label: "None", value: "None" },
                        ]}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      {item.state === "1" ? "100%" : item.state}
                    </Form.Item>
                  )}
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  {editMode ? (
                    <Tooltip title={item.comments}>
                      <Form.Item
                        name={`${item.question}-${item.name}-comments`}
                        initialValue={item.comments}
                      >
                        <Input placeholder="Comments" />
                      </Form.Item>
                    </Tooltip>
                  ) : (
                    <Form.Item
                      name={`${item.question}-${item.name}-comments`}
                      initialValue={item.comments}
                    >
                      <Tooltip title={item.comments}>
                        <CommentOutlined
                          style={{
                            fontSize: "20px",
                            color: item.comments ? "blue" : "black",
                          }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            ))}
          <b>
            7. Have you identified all of the smelters supplying the 3TG to your
            supply chain?
          </b>
          {formData?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Have you identified all of the smelters supplying the 3TG to your supply chain?"
            )
            .map((item, index) => (
              <Row
                key={`Have you identified all of the smelters supplying the 3TG to your supply chain?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
                gutter={[24, 24]}
              >
                <Col span={8}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  {editMode ? (
                    <Form.Item
                      name={`Have you identified all of the smelters supplying the 3TG to your supply chain?-${item.name}-state`}
                      initialValue={item.state}
                      rules={[
                        index === 0 && !disabledtantalum
                          ? { required: true, message: "Required" }
                          : index === 1 && !disabledtin
                          ? { required: true, message: "Required" }
                          : index === 2 && !disabledgold
                          ? { required: true, message: "Required" }
                          : index === 3 && !disabledtungsten
                          ? { required: true, message: "Required" }
                          : {},
                      ]}
                    >
                      <Select
                        disabled={
                          (index === 0 && disabledtantalum === true) ||
                          (index === 1 && disabledtin === true) ||
                          (index === 2 && disabledgold === true) ||
                          (index === 3 && disabledtungsten === true)
                        }
                      >
                        <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                        <Select.Option value="Unknown">Unknown</Select.Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item>{item.state}</Form.Item>
                  )}
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  {editMode ? (
                    <Tooltip title={item.comments}>
                      <Form.Item
                        name={`${item.question}-${item.name}-comments`}
                        initialValue={item.comments}
                      >
                        <Input placeholder="Comments" />
                      </Form.Item>
                    </Tooltip>
                  ) : (
                    <Form.Item
                      name={`${item.question}-${item.name}-comments`}
                      initialValue={item.comments}
                    >
                      <Tooltip title={item.comments}>
                        <CommentOutlined
                          style={{
                            fontSize: "20px",
                            color: item.comments ? "blue" : "black",
                          }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            ))}
          <b>
            8. Has all applicable smelter information received by your company
            been reported in this declaration?
          </b>
          {formData?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Has all applicable smelter information received by your company been reported in this declaration?"
            )
            .map((item, index) => (
              <Row
                key={`Has all applicable smelter information received by your company been reported in this declaration?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
                gutter={[24, 24]}
              >
                <Col span={8}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  {editMode ? (
                    <Form.Item
                      name={`Has all applicable smelter information received by your company been reported in this declaration?-${item.name}-state`}
                      initialValue={item.state}
                      rules={[
                        index === 0 && !disabledtantalum
                          ? { required: true, message: "Required" }
                          : index === 1 && !disabledtin
                          ? { required: true, message: "Required" }
                          : index === 2 && !disabledgold
                          ? { required: true, message: "Required" }
                          : index === 3 && !disabledtungsten
                          ? { required: true, message: "Required" }
                          : {},
                      ]}
                    >
                      <Select
                        disabled={
                          (index === 0 && disabledtantalum === true) ||
                          (index === 1 && disabledtin === true) ||
                          (index === 2 && disabledgold === true) ||
                          (index === 3 && disabledtungsten === true)
                        }
                      >
                        <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                        <Select.Option value="Unknown">Unknown</Select.Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item>{item.state}</Form.Item>
                  )}
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  {editMode ? (
                    <Tooltip title={item.comments}>
                      <Form.Item
                        name={`${item.question}-${item.name}-comments`}
                        initialValue={item.comments}
                      >
                        <Input placeholder="Comments" />
                      </Form.Item>
                    </Tooltip>
                  ) : (
                    <Form.Item
                      name={`${item.question}-${item.name}-comments`}
                      initialValue={item.comments}
                    >
                      <Tooltip title={item.comments}>
                        <CommentOutlined
                          style={{
                            fontSize: "20px",
                            color: item.comments ? "blue" : "black",
                          }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            ))}
        </Card>
      ),
    },
    {
      key: "policy",
      label: "Policy",
      forceRender: true,
      children: (
        <Card style={{ overflow: "auto", height: "75vh" }}>
          {formData?.policy.map((item, index) => (
            <div key={`${item.question}-${item.name}`}>
              <b>{item.question}</b>
              <Row
                style={{ display: "flex", marginBottom: 8 }}
                gutter={[24, 24]}
              >
                <Col span={16}>
                  {editMode ? (
                    <Form.Item
                      name={`${item.question}-${item.name}-state`}
                      initialValue={item.state}
                    >
                      <Select>
                        {index === 4 && (
                          <Select.Option value="Yes, using other format (describe)">
                            Yes, using other format (describe)
                          </Select.Option>
                        )}
                        {index === 4 && (
                          <Select.Option value="Yes, in conformance with IPC1755 (e.g., CMRT)">
                            Yes, in conformance with IPC1755 (e.g., CMRT)
                          </Select.Option>
                        )}
                        {index === 7 && (
                          <Select.Option value="Yes, with the SEC and the EU">
                            Yes, with the SEC and the EU
                          </Select.Option>
                        )}
                        {index === 7 && (
                          <Select.Option value="Yes, with the EU">
                            Yes, with the EU
                          </Select.Option>
                        )}
                        {index === 7 && (
                          <Select.Option value="Yes, with the SEC">
                            Yes, with the SEC
                          </Select.Option>
                        )}
                        {index !== 7 && index !== 4 && (
                          <Select.Option value="Yes">Yes</Select.Option>
                        )}
                        <Select.Option value="No">No</Select.Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item>{item.state}</Form.Item>
                  )}
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  {editMode ? (
                    <Tooltip title={item.comments}>
                      <Form.Item
                        initialValue={item.comments}
                        name={`${item.question}-${item.name}-comments`}
                      >
                        <Input placeholder="Comments" />
                      </Form.Item>
                    </Tooltip>
                  ) : (
                    <Form.Item
                      initialValue={item.comments}
                      name={`${item.question}-${item.name}-comments`}
                    >
                      <Tooltip title={item.comments}>
                        <CommentOutlined
                          style={{
                            fontSize: "20px",
                            color: item.comments ? "blue" : "black",
                          }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Divider></Divider>
            </div>
          ))}
        </Card>
      ),
    },
    {
      key: "company",
      label: "Company Info",
      forceRender: true,
      children: (
        <Card style={{ overflow: "auto", height: "75vh" }}>
          <Row>
            <Col span={8}>
              <Form.Item>Company name *</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "name"]}
                  initialValue={formData?.company?.name}
                  rules={[
                    {
                      required: true,
                      message: "Company name is required",
                    },
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^(?!.*\s{2,})[a-zA-Z0-9()-/.,&_@*\u00C0-\u024F\u00A9|\u2122|\u00AEu4E00-\u9FFF\u{20000}-\u{2FA1F}\p{L}\p{Mn}\p{P}\p{N}\s]+$/u.test(
                            value
                          )
                        ) {
                          return Promise.reject("Not Allowed");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>{formData?.company?.name}</Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Declaration Scope or Class *</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "declarationScope"]}
                  initialValue={formData?.company.declarationScope}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select onChange={handleSelectChange}>
                    <Select.Option value="A. Company">A. Company</Select.Option>
                    <Select.Option value="B. Product (or List of Products)">
                      B. Product (or List of Products)
                    </Select.Option>
                    <Select.Option value="C. User defined [Specify in 'Description of scope']">
                      C. User defined [Specify in &#39;Description of
                      scope&#39;]
                    </Select.Option>
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item>{formData?.company?.declarationScope}</Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Description of Scope</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "declarationDescription"]}
                  initialValue={formData?.company.declarationDescription}
                  rules={[
                    {
                      required:
                        selectedOption ===
                        "C. User defined [Specify in 'Description of scope']",
                      message: "Required",
                    },
                  ]}
                >
                  <TextArea />
                </Form.Item>
              ) : (
                <Form.Item>
                  {formData?.company?.declarationDescription}
                </Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Company Unique ID</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "uniqueId"]}
                  initialValue={formData?.company.uniqueId}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>{formData?.company?.uniqueId}</Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Company Unique ID Authority</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "uniqueIdAuthority"]}
                  initialValue={formData?.company.uniqueIdAuthority}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>{formData?.company?.uniqueIdAuthority}</Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Address</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "address"]}
                  initialValue={formData?.company.address}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>{formData?.company?.address}</Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Contact Name *</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "contact", "name"]}
                  initialValue={formData?.company.contact.name}
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^([a-zA-Z0-9().-/&]+\s?)*$/i.test(value)
                        ) {
                          return Promise.reject("Not Allowed");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>{formData?.company?.contact?.name}</Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Contact Email *</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "contact", "emailAddress"]}
                  initialValue={formData?.company.contact.emailAddress}
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            value
                          )
                        ) {
                          return Promise.reject("E-mail must be valid");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>
                  {formData?.company?.contact?.emailAddress}
                </Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Contact Number *</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "contact", "phoneNumber"]}
                  initialValue={formData?.company.contact.phoneNumber}
                  rules={[
                    {
                      required: true,
                      message: "Number is required",
                    },
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(
                            value
                          )
                        ) {
                          return Promise.reject("Phone Number must be valid");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>{formData?.company?.contact?.phoneNumber}</Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Authorizer *</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "authorizer", "name"]}
                  initialValue={formData?.company.authorizer.name}
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^([a-zA-Z0-9().-/&]+\s?)*$/i.test(value)
                        ) {
                          return Promise.reject("Not Allowed");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>{formData?.company?.authorizer?.name}</Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Email-Authorizer *</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "authorizer", "emailAddress"]}
                  initialValue={formData?.company.authorizer.emailAddress}
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            value
                          )
                        ) {
                          return Promise.reject("E-mail must be valid");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>
                  {formData?.company?.authorizer?.emailAddress}
                </Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Phone-Authorizer</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "authorizer", "phoneNumber"]}
                  initialValue={formData?.company.authorizer.phoneNumber}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>
                  {formData?.company?.authorizer?.phoneNumber}
                </Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Title-Authorizer</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "authorizer", "title"]}
                  initialValue={formData?.company.authorizer.title}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item>{formData?.company?.authorizer?.title}</Form.Item>
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={8}>
              <Form.Item>Effective Date *</Form.Item>
            </Col>
            <Col span={16}>
              {editMode ? (
                <Form.Item
                  name={["company", "authorizer", "effectiveDate"]}
                  rules={[{ required: true, message: "Date is required" }]}
                  initialValue={
                    formData?.company?.authorizer?.effectiveDate
                      ? dayjs(formData?.company?.authorizer?.effectiveDate)
                      : undefined
                  }
                >
                  <DatePicker format={"DD-MMM-YYYY"} />
                </Form.Item>
              ) : (
                <Form.Item>
                  {formData?.company?.authorizer?.effectiveDate
                    ? dayjs(
                        formData?.company?.authorizer?.effectiveDate
                      ).format("DD-MMM-YYYY")
                    : undefined}
                </Form.Item>
              )}
            </Col>
          </Row>
        </Card>
      ),
    },
    {
      key: "supportingFiles",
      label: "Supporting Files",
      forceRender: true,
      children: (
        <Card style={{ overflow: "auto", height: "75vh" }}>
          <Table
            dataSource={
              formData.supportingDocuments ? formData?.supportingDocuments : []
            }
            columns={SuppotingDocumentsColumns}
            rowKey="_id"
          />
        </Card>
      ),
    },
  ];
  const onCloseDrawer = () => {
    removeLocalStorage("conflictMinerals");
    onClose();
  };
  const items: MenuProps["items"] = [
    {
      key: "cmrtDocument",
      label: "CMRT",
    },
    {
      key: "cmrtStatement",
      label: "Conflict Minerals Statement",
    },
    {
      key: "policyStatement",
      label: "Policy Statement",
    },
    {
      key: "others",
      label: "Others",
    },
  ];
  const [DocumentKey, setDocumentKey] = useState<string>("");

  const onClick: MenuProps["onClick"] = ({ key }) => {
    setModalVisible(true);
    setDocumentKey(key);
  };
  const [modalVisible, setModalVisible] = useState(false);

  const [file, setfile] = useState<File | null>(null);

  const handleFileUpload = (file: File) => {
    setfile(file);
  };
  const handleCancel = () => {
    setfile(null);
    const inputElement = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement | null;
    if (inputElement) {
      inputElement.value = "";
    }
    setModalVisible(false);
  };
  const uploadSupportingDocument = async () => {
    try {
      if (file) {
        await uploadConflictMineralsDocument({
          file: file,
          foldername: "conflict-minerals",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleClick = async () => {
    setLoading(true);
    await uploadSupportingDocument();
    form.submit();
    setLoading(false);
    handleCancel();
  };
  const [activeKey, setActiveKey] = useState("declaration");

  const handleTabChange = (key: any) => {
    setActiveKey(key);
  };
  return (
    <div>
      <Drawer
        title="Declaration"
        placement={placement}
        width={720}
        closable={true}
        open={open}
        onClose={onCloseDrawer}
        maskClosable={true}
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        extra={
          editMode ? (
            <Space>
              <Button
                type="default"
                onClick={onCancel}
                style={{ marginRight: 8 }}
              >
                Cancel
              </Button>
              {activeKey === "supportingFiles" ? (
                <Dropdown menu={{ items, onClick }} placement="bottomRight">
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={(e) => e.preventDefault()}
                  >
                    Upload Document <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => form.submit()}
                >
                  Save
                </Button>
              )}
            </Space>
          ) : (
            <Button type="primary" onClick={onEditClick}>
              Edit
            </Button>
          )
        }
      >
        <Modal
          title="Upload Document"
          open={modalVisible}
          centered
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="continue"
              type="primary"
              loading={loading}
              onClick={handleClick}
              disabled={!file}
              data-testid="continue-upload-button"
            >
              Continue
            </Button>,
          ]}
        >
          <input
            type="file"
            accept=".xlsx, .xls,.docx,.pdf"
            onChange={(e) => {
              const selectedFile = e.target.files && e.target.files[0];
              if (selectedFile) {
                handleFileUpload(selectedFile);
              }
            }}
          />
        </Modal>
        <Form form={form} onFinish={onFinish}>
          <Tabs
            defaultActiveKey={"declaration"}
            items={TabsItems}
            tabPosition="top"
            onChange={handleTabChange}
            className="card-tabs"
          ></Tabs>
        </Form>
      </Drawer>
    </div>
  );
};
export default ConflictEdit;
