import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  submitCompliance: false,
};
export const supplierPortalSlice = createSlice({
  name: "supplierPortal",
  initialState,
  reducers: {
    submitComplianceTriggered: (state) => {
      state.submitCompliance = !state.submitCompliance;
    },
  },
});

export const { submitComplianceTriggered } = supplierPortalSlice.actions;
export default supplierPortalSlice.reducer;
