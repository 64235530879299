import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Select,
} from "antd";

import { Role } from "../../utils/types/role";
import { Module } from "../../utils/types/module";
import { useAppDispatch } from "../../redux/hooks";
import { createRole, updateRole } from "../../redux/actions/rolesAction";
import { listModules } from "../../redux/actions/modulesAction";
import { getLocalStorage } from "../../utils/localStore";

const RolesAndPermissionsForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Role;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { workspace } = getLocalStorage("user");
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const fetchModules = (value: string) => {
    dispatch(
      listModules({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: value,
      })
    ).then((response: any) => {
      setOptions(
        response.modules.map((module: Module) => ({
          label: module.name,
          value: module.number,
        }))
      );
    });
  };

  const onFormSubmit = (values: Role) => {
    setLoading(true);
    if (props.type === "create") {
      dispatch(createRole(values))
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(updateRole({ updates: values }, props.formData._id))
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (open && props.type === "create") {
      const { modules } = getLocalStorage("user");
      if (!modules) {
        fetchModules(
          JSON.stringify({
            softDelete: false,
            type:
              workspace === "acquiscompliance"
                ? ["admin", "both", "limited"]
                : ["client", "both"],
          })
        );
      } else {
        setOptions(
          modules.map((module: Module) => ({
            label: module.name,
            value: module.number,
          }))
        );
      }
    }
    if (open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <>
      {permissions[props.type === "create" ? "create" : "update"]?.includes(
        "roles"
      ) && (
        <>
          <Button
            type={props.type === "create" ? "primary" : "text"}
            title={props.type === "create" ? "New Role" : "Edit Role"}
            onClick={showDrawer}
            icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
          >
            {props.type === "create" ? "New Role" : null}
          </Button>
          <Drawer
            title={props.type === "create" ? "Create a New Role" : "Edit Role"}
            width={720}
            onClose={onClose}
            open={open}
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={form.submit} type="primary" loading={loading}>
                  {props.type === "create" ? "Submit" : "Save"}
                </Button>
              </Space>
            }
          >
            <Form
              form={form}
              layout="vertical"
              initialValues={
                props.type === "create" ? {} : { ...props.formData }
              }
              onFinish={onFormSubmit}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="Role Name"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter role name",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter role name" />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Form.List name="permissions">
                {() => (
                  <>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item name="create" label="Create">
                          <Select
                            options={options}
                            onSearch={(text: string) =>
                              fetchModules(
                                JSON.stringify({
                                  softDelete: false,
                                  $or: [
                                    { name: { $regex: text, $options: "i" } },
                                  ],
                                  type:
                                    workspace === "acquiscompliance"
                                      ? ["admin", "both", "limited"]
                                      : ["client", "both"],
                                })
                              )
                            }
                            placeholder="Please select a role"
                            showSearch
                            mode="multiple"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="read" label="Read">
                          <Select
                            options={options}
                            onSearch={(text: string) =>
                              fetchModules(
                                JSON.stringify({
                                  softDelete: false,
                                  $or: [
                                    { name: { $regex: text, $options: "i" } },
                                  ],
                                  type:
                                    workspace === "acquiscompliance"
                                      ? ["admin", "both", "limited"]
                                      : ["client", "both", "limited"],
                                })
                              )
                            }
                            placeholder="Please select a role"
                            showSearch
                            mode="multiple"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="update" label="Update">
                          <Select
                            options={options}
                            onSearch={(text: string) =>
                              fetchModules(
                                JSON.stringify({
                                  softDelete: false,
                                  $or: [
                                    { name: { $regex: text, $options: "i" } },
                                  ],
                                  type:
                                    workspace === "acquiscompliance"
                                      ? ["admin", "both", "limited"]
                                      : ["client", "both"],
                                })
                              )
                            }
                            placeholder="Please select a role"
                            showSearch
                            mode="multiple"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="delete" label="Delete">
                          <Select
                            options={options}
                            onSearch={(text: string) =>
                              fetchModules(
                                JSON.stringify({
                                  softDelete: false,
                                  $or: [
                                    { name: { $regex: text, $options: "i" } },
                                  ],
                                  type:
                                    workspace === "acquiscompliance"
                                      ? ["admin", "both", "limited"]
                                      : ["client", "both"],
                                })
                              )
                            }
                            placeholder="Please select a role"
                            showSearch
                            mode="multiple"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="import" label="Import">
                          <Select
                            options={options}
                            onSearch={(text: string) =>
                              fetchModules(
                                JSON.stringify({
                                  softDelete: false,
                                  $or: [
                                    { name: { $regex: text, $options: "i" } },
                                  ],
                                  type:
                                    workspace === "acquiscompliance"
                                      ? ["admin", "both", "limited"]
                                      : ["client", "both"],
                                })
                              )
                            }
                            placeholder="Please select a role"
                            showSearch
                            mode="multiple"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="export" label="Export">
                          <Select
                            options={options}
                            onSearch={(text: string) =>
                              fetchModules(
                                JSON.stringify({
                                  softDelete: false,
                                  $or: [
                                    { name: { $regex: text, $options: "i" } },
                                  ],
                                  type:
                                    workspace === "acquiscompliance"
                                      ? ["admin", "both", "limited"]
                                      : ["client", "both"],
                                })
                              )
                            }
                            placeholder="Please select a role"
                            showSearch
                            mode="multiple"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Form>
          </Drawer>
        </>
      )}
    </>
  );
};

export default RolesAndPermissionsForm;
