import React, { useEffect, useState } from "react";

import { ExportOutlined, LinkOutlined } from "@ant-design/icons";
import { Space, Typography, List, Row, Col, Card, Divider, Table } from "antd";
import type {
  TableProps,
  TablePaginationConfig,
  ColumnsType,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { useAppDispatch } from "../../../redux/hooks";
import { readCampaignInbox } from "../../../redux/actions/CampaignManager/campaignManagerAction";
import ReadSupportingDocument from "../../../components/modals/ReadSupportingDocuments";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Text, Title } = Typography;

interface Inbox {
  _id: string;
  from: {
    name: string;
    address: string;
  };
  subject: string;
  date: string;
  attachments: any[];
  messageText: string;
  messageHtml: string;
}

const CampaignInbox: React.FC = () => {
  const dispatch = useAppDispatch();
  const [inbox, setInbox] = useState<any[]>([]);
  const [inboxCount, setInboxCount] = useState<number>(0);
  const [inboxMessage, setInboxMessage] = useState<Inbox>();

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Inbox>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const handleMessageSelection = (value: any) => {
    setInboxMessage(value);
  };

  const columns: ColumnsType<Inbox> = [
    {
      title: "Messages",
      dataIndex: "message",
      key: "message",
      sorter: true,
      width: 150,
      render: (_, record: any) => (
        <List.Item.Meta
          title={
            <Space
              align="end"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Text>{record.from.name} </Text>
              <Space>
                {record.attachments.length ? (
                  <Text>
                    <LinkOutlined />
                    <small> {record.attachments.length} </small>
                  </Text>
                ) : (
                  ""
                )}
              </Space>
            </Space>
          }
          description={new Date(record.date).toLocaleString()}
        />
      ),
    },
  ];

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      readCampaignInbox({
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: search?.query ? search?.query : "",
      })
    )
      .then((response: any) => {
        setInbox(response?.inbox);
        setInboxMessage(response?.inbox[0]);
        setInboxCount(response?.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Inbox>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Inbox> | SorterResult<Inbox>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Inbox>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Row style={{ background: "white" }}>
          <Col span={inboxMessage ? "8" : "24"}>
            <Table
              loading={loading}
              dataSource={inbox}
              scroll={{
                // x: 1000,
                y: "calc(100vh - 285px)",
              }}
              columns={columns}
              rowKey="_id"
              pagination={{ ...tablePagination, total: inboxCount }}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: () => handleMessageSelection(record),
              })}
            />
          </Col>
          <Col span={16}>
            {inboxMessage && (
              <div>
                <Card
                  title={inboxMessage.subject}
                  bordered={false}
                  style={{ height: "100%" }}
                >
                  <Space
                    align="end"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Title level={5}>{inboxMessage.from.name} </Title>
                    <Space>
                      {new Date(inboxMessage.date).toLocaleString()}
                    </Space>
                  </Space>
                  <Text>{inboxMessage.from.address}</Text>
                  <Divider />
                  {inboxMessage.attachments.map((item, index) =>
                    item?.includes("http") ? (
                      <span key={index}>
                        <ExportOutlined
                          key={index}
                          onClick={() => onClickViewLink(item)}
                        />{" "}
                        Link
                      </span>
                    ) : (
                      <ReadSupportingDocument
                        key={index}
                        postUrl="campaignmanager/inbox/document/download"
                        filename={item}
                        foldername="campaign-inbox"
                      />
                    )
                  )}
                  <Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: inboxMessage?.messageHtml,
                      }}
                    />
                  </Text>
                </Card>
              </div>
            )}
          </Col>
        </Row>
      </ErrorBoundary>
    </div>
  );
};

export default CampaignInbox;
