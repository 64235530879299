import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  BellFilled,
  ClockCircleFilled,
  CheckCircleFilled,
  ExceptionOutlined,
  EditFilled,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  FileTextOutlined,
  MessageOutlined,
  StarOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";

import {
  Space,
  Card,
  Typography,
  Col,
  Row,
  Divider,
  Tag,
  Avatar,
  Button,
  Tooltip,
} from "antd";
import { Component } from "../../../../utils/types/component";
import { getRegulationsFromLocalStorage } from "../../../../utils/localStore";

const { Title, Text, Paragraph } = Typography;

const Overview: React.FC<{
  componentCampaign: any;
  component: any;
  alternates: any;
}> = (props) => {
  const [compliances, setCompliances] = React.useState<any[]>();
  const regulations = getRegulationsFromLocalStorage() ?? [];
  const [alternateCompliances, setalternateCompliances] =
    React.useState<any[]>();
  const navigate = useNavigate();

  const fetchData = () => {
    const compliances: any[] = [];
    regulations?.forEach((regulation: any) => {
      const compliance = props?.component?.compliances.find(
        (item: any) => item.regulationNumber === regulation.regulationNumber
      );
      if (compliance) {
        compliances.push({
          regulationName: regulation.name,
          ...compliance,
        });
      }
    });
    setCompliances(compliances);
  };

  const fetchAlternateData = () => {
    const compliances: any[] = [];
    regulations?.forEach((regulation: any) => {
      const compliance = props?.alternates.map((e: any) =>
        e.compliances.find(
          (item: any) => regulation.regulationNumber === item.regulationNumber
        )
      );
      if (compliance) {
        compliances.push({
          regulationName: regulation.name,
          regulationNumber: regulation.regulationNumber,
        });
      }
    });
    setalternateCompliances(compliances);
  };

  const tagColors = () => {
    const colors = ["#73d13d", "#ff7875", "#ffa940", "grey"];
    const index = Math.floor(Math.random() * colors.length);
    return colors[index];
  };

  interface componentProfileParams {
    id: string;
  }

  const viewAlternateComponent = (record: any) => {
    const params: componentProfileParams = {
      id: record?._id,
    };
    const route = `/${params.id}`;
    const currentRoute = window.location.pathname;
    const basePath = currentRoute.split("/").slice(0, 3).join("/");
    const finalPath = basePath + route;
    navigate(finalPath);
  };

  useEffect(() => {
    if (props.alternates && props.alternates.length > 0) {
      fetchAlternateData();
    }
    fetchData();
  }, [props.component]);

  return (
    <>
      {/* <Row>
        <Col span={6} xs={12} sm={6} md={6}>
          <Card style={{ marginRight: "1rem" }}>
            <Space
              align="center"
              size="large"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <Space>
                <CheckCircleFilled
                  style={{ fontSize: "50px", color: "#D3D3D3" }}
                />
                <Title level={5}>
                  Submissions <br></br>
                  <small style={{ color: "grey" }}>For active campaigns</small>
                </Title>
              </Space>
              <div>
                <Title style={{ color: "#69b1ff" }}>
                  {props?.componentCampaign &&
                    props?.componentCampaign?.totalSubmissions}{" "}
                </Title>
              </div>
            </Space>
          </Card>
        </Col>
        <Col span={6} xs={12} sm={6} md={6}>
          <Card style={{ maxHeight: 150, marginRight: "1rem" }}>
            <Space
              align="center"
              size="large"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <Space>
                <BellFilled style={{ fontSize: "50px", color: "#D3D3D3" }} />
                <Title level={5}>
                  Awaiting Responses <br></br>
                  <small style={{ color: "grey", fontSize: "10px" }}>
                    Last Approved:{" "}
                    {(props.componentCampaign &&
                      props.componentCampaign?.campaignDate &&
                      new Date(
                        props.componentCampaign?.campaignDate
                      ).toLocaleString()) ||
                      "N/A"}{" "}
                  </small>
                </Title>
              </Space>
              <div>
                <Title style={{ color: "#69b1ff" }}>
                  {props?.componentCampaign &&
                    props?.componentCampaign?.awaitingResponse}{" "}
                </Title>
              </div>
            </Space>
          </Card>
        </Col>
        <Col span={6} xs={12} sm={6} md={6}>
          <Card style={{ marginRight: "1rem" }}>
            <Space
              align="center"
              size="large"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <Space>
                <ClockCircleFilled
                  style={{ fontSize: "50px", color: "#D3D3D3" }}
                />
                <Title level={5}>
                  Pending Approval <br></br>
                  <small style={{ color: "grey", fontSize: "10px" }}>
                    Last Approved:{" "}
                    {(props.componentCampaign &&
                      props.componentCampaign?.approvalDate &&
                      new Date(
                        props.componentCampaign?.approvalDate
                      ).toLocaleString()) ||
                      "N/A"}{" "}
                  </small>
                </Title>
              </Space>
              <div>
                <Title style={{ color: "#69b1ff" }}>
                  {props?.componentCampaign &&
                    props?.componentCampaign?.totalPending}{" "}
                </Title>
              </div>
            </Space>
          </Card>
        </Col>
        <Col span={6} xs={12} sm={6} md={6}>
          <Card style={{ marginRight: "1rem" }}>
            <Space
              align="center"
              size="large"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <Space>
                <ExceptionOutlined
                  style={{ fontSize: "50px", color: "#D3D3D3" }}
                />
                <Title level={5}>
                  Material Disclosure <br></br>
                  <small style={{ color: "grey" }}>
                    Total Part weight:{" "}
                    {props.component ? props.component?.partWeight : "N/A"}{" "}
                  </small>
                </Title>
              </Space>
              <div>
                <Title style={{ color: "#69b1ff" }}>
                  {props.component ? props.component?.partWeight : "N/A"}
                </Title>
              </div>
            </Space>
          </Card>
        </Col>
      </Row>
      <Divider /> */}
      <Row>
        {compliances &&
          compliances.map((item: any) => (
            <Col key={item.regulationNumber} span={8}>
              <Card
                style={{
                  marginRight: "1rem",
                  // background:
                  //   item.complianceOutputs?.compliant.toUpperCase().trim() === 'YES' ?
                  //   'linear-gradient(to bottom, #73d13d, #FFFFFF)' :
                  //   item.complianceOutputs?.compliant.toUpperCase().trim() === 'NO' ?
                  //   'linear-gradient(to bottom, #ff7875, #FFFFFF)' :
                  //   item.complianceOutputs?.compliant.toUpperCase().trim() === 'YES WITH EXEMPTION' ?
                  //   'linear-gradient(to bottom, #ffa940, #FFFFFF)' :
                  //   'linear-gradient(to bottom, #D3D3D3, #FFFFFF)'
                }}
                title={
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Space>
                      <Text>{item.regulationName}</Text>
                    </Space>
                    {/* <Avatar
                      style={{ backgroundColor: "#69b1ff" }}
                      icon={<EditFilled />}
                    /> */}
                  </Space>
                }
              >
                <div style={{ textAlign: "center" }}>
                  <Tag
                    color={
                      item.complianceOutputs?.compliant
                        ?.toUpperCase()
                        .trim() === "YES"
                        ? "#73d13d"
                        : item.complianceOutputs?.compliant
                            ?.toUpperCase()
                            .trim() === "NO"
                        ? "#ff7875"
                        : item.complianceOutputs?.compliant
                            ?.toUpperCase()
                            .trim() === "YES WITH EXEMPTION"
                        ? "#ffa940"
                        : "grey"
                    }
                    icon={
                      item.complianceOutputs?.compliant
                        ?.toUpperCase()
                        .trim() === "YES" ? (
                        <CheckCircleFilled />
                      ) : item.complianceOutputs?.compliant
                          ?.toUpperCase()
                          .trim() === "NO" ? (
                        <CloseCircleOutlined />
                      ) : item.complianceOutputs?.compliant
                          ?.toUpperCase()
                          .trim() === "YES WITH EXEMPTION" ? (
                        <ExclamationCircleOutlined />
                      ) : (
                        <QuestionCircleOutlined />
                      )
                    }
                  >
                    {item?.complianceOutputs &&
                      item.complianceOutputs?.compliant}
                  </Tag>{" "}
                  <br></br>
                  <Text>
                    {" "}
                    <b> Version: </b>
                    {(item?.complianceOutputs &&
                      item.complianceOutputs?.version) ||
                      "NA"}{" "}
                  </Text>{" "}
                  <br></br>
                  {item?.complianceOutputs &&
                    item?.complianceOutputs?.substance && (
                      <Text>
                        {" "}
                        <b> Substance of concern: </b>
                        {item.complianceOutputs?.substance}{" "}
                      </Text>
                    )}
                  {item?.complianceOutputs &&
                    item?.complianceOutputs?.exemption && (
                      <Text>
                        {" "}
                        <b> Exemption Claimed: </b>
                        {item.complianceOutputs?.exemption}{" "}
                      </Text>
                    )}
                </div>
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "2rem",
                  }}
                >
                  {/* <Space>
                    <FileTextOutlined
                      style={{ fontSize: "20px", color: "#69b1ff" }}
                    />
                    <MessageOutlined
                      style={{ fontSize: "20px", color: "#69b1ff" }}
                    />
                    <StarOutlined
                      style={{ fontSize: "20px", color: "#69b1ff" }}
                    />
                  </Space> */}
                  <Text type="secondary">
                    <small>
                      Last updated :{" "}
                      {props.component &&
                        props.component?.updatedAt &&
                        new Date(
                          props.component?.updatedAt
                        ).toLocaleString()}{" "}
                      <br></br>
                      By: {props.component && props.component?.updatedBy}
                    </small>
                  </Text>
                </Space>
              </Card>
            </Col>
          ))}
      </Row>
      {props.alternates && props.alternates.length > 0 && (
        <>
          <Divider />
          <Row>
            {props.alternates &&
              props.alternates.map((item: any) => (
                <Col key={item._id} span={8}>
                  <Card
                    style={{
                      marginRight: "1rem",
                      marginBottom: "1rem",
                      overflow: "hidden",
                      background:
                        "linear-gradient(to bottom, #69b1ff, #91caff)",
                    }}
                  >
                    <Space
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      {alternateCompliances &&
                        alternateCompliances.slice(0, 3).map((compliance) => (
                          <Tag
                            key={compliance.regulationName}
                            color={tagColors()}
                          >
                            {compliance.regulationName}
                          </Tag>
                        ))}
                      {alternateCompliances &&
                        alternateCompliances.length > 3 && (
                          <Tooltip
                            title={alternateCompliances
                              .slice(3)
                              .map((tag) => tag.regulationName)
                              .join(", ")}
                          >
                            <Tag color={tagColors()}>
                              +{alternateCompliances.length - 3}
                            </Tag>
                          </Tooltip>
                        )}
                    </Space>
                    <div>
                      <Title level={5} style={{ color: "white" }}>
                        {item?.internalItemNumber} <br></br>
                        <small>Internal #</small>
                      </Title>
                      <Title level={5} style={{ color: "white" }}>
                        {item?.manufacturer?.itemNumber} <br></br>
                        <small>Manufacturer Item Number </small>
                      </Title>
                      <Title level={5} style={{ color: "white" }}>
                        {item?.manufacturer?.name} <br></br>
                        <small>Manufacturer Name </small>
                      </Title>
                    </div>
                    <Space
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        type="text"
                        icon={
                          <RightCircleOutlined
                            style={{ fontSize: "25px", color: "white" }}
                          />
                        }
                        onClick={() => viewAlternateComponent(item)}
                      />
                    </Space>
                  </Card>
                </Col>
              ))}
          </Row>
        </>
      )}
    </>
  );
};

export default Overview;
