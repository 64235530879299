import React, { useState } from "react";
import { Form, Input, Button, Typography, Card } from "antd";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Paragraph } = Typography;

const Workspace: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = (values: any) => {
    setLoading(true);
    navigate(`/login/${values.workspace.toLowerCase()}`);
  };

  return (
    <ErrorBoundary>
      <Card>
        <Paragraph
          strong
          style={{
            textAlign: "center",
          }}
        >
          Sign in to your workspace
        </Paragraph>
        <Form
          layout="inline"
          form={form}
          initialValues={{}}
          onFinish={onSubmit}
          style={{
            marginTop: "3rem",
            minWidth: 450,
          }}
        >
          <Form.Item
            name="workspace"
            rules={[
              {
                required: true,
                message: "Please input your workspace!",
              },
            ]}
          >
            <Input
              placeholder="Your Workspace"
              prefix="app.acquiscompliance.com/"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </ErrorBoundary>
  );
};

export default Workspace;
