import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FilePdfOutlined,
  FilterOutlined,
  FileSyncOutlined,
  MoreOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { Space, Typography, Button, Table, Input, Spin } from "antd";
import type { MenuProps } from "antd";

import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Supplier } from "../../../../utils/types/supplier";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  listSuppliers,
  listSuppliersFilters,
  generateSupplier,
} from "../../../../redux/actions/suppliersAction";

import SupplierModal from "../../../../components/forms/SupplierForm";
import { setLocalStorage, getLocalStorage } from "../../../../utils/localStore";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";

const { Search } = Input;
const { Title, Text } = Typography;

const Suppliers: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [supplierCount, setSupplierCount] = useState<number>(0);

  const [search, setSearch] = useState<any>();

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Supplier>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [filterData, setFilterData] = useState<any>({});

  const onSearch = (value: any) => {
    setSearch({
      searchField: true,
      query:
        value?.key === "Enter"
          ? value?.target?.value.replace(/[()/[\]]/g, "\\$&").trim()
          : value.replace(/[()/[\]]/g, "\\$&").trim(),
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  interface SupplierProfileParams {
    id: string;
  }

  const handlePreviewClick = (record: any) => {
    const params: SupplierProfileParams = {
      id: record._id,
    };
    navigate(`${params.id}`);
    // setLocalStorage("supplier", record.name);
  };

  const handleDeleteClick = (record: any) => {
    console.log("Delete record", record);
  };

  const fetchData = () => {
    const filters: { [x: string]: FilterValue | null }[] = [];
    Object.keys(tableFilters).forEach((key) => {
      if (tableFilters[key] && tableFilters[key]?.length) {
        filters.push({ [key]: tableFilters[key] });
      }
    });
    setLoading(true);
    dispatch(
      listSuppliers({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["name"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        searchField:
          !filters.length && search?.searchField ? search?.query : "",
        filtersUsed: filters.length ? "useFilters" : false,
        filters: filters.length
          ? JSON.stringify({
              elements: filters,
              path: [],
            })
          : [],
      })
    )
      .then((response: any) => {
        setSuppliers(response.suppliers);
        setSupplierCount(response.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchFilterData = (columnName: string) => {
    setLoadingData(true);
    dispatch(listSuppliersFilters({ column: columnName })).then(
      (response: any) => {
        setFilterData((prev: any) => ({ ...prev, [columnName]: response }));
        setLoadingData(false);
      }
    );
  };

  const onClickGenerate = () => {
    dispatch(generateSupplier({ alert: true })).then(() => {
      fetchData();
    });
  };
  const handleTableChange: TableProps<Supplier>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Supplier> | SorterResult<Supplier>[]
  ) => {
    const entries = Object.entries(filters);
    const filteredEntries = entries.filter(([key, value]) => value !== null);
    const cleanFilters = Object.fromEntries(filteredEntries);
    setTablePagination(pagination);
    setTableFilters(cleanFilters);
    setTableSorter(sorter as SorterResult<Supplier>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  // const menu = (value: any): MenuProps => ({
  //   items: [
  //     (
  //       permissions?.delete?.includes("suppliers") &&
  //       {
  //         key: "Delete",
  //         onClick: () => handleDeleteClick(value),
  //         label: "Delete",
  //         icon: <DeleteOutlined />,
  //       }
  //     ),
  //     {
  //       key: "Preview",
  //       onClick: () => handlePreviewClick(value),
  //       label: "Preview",
  //       icon: <EyeOutlined />,
  //     },
  //   ],
  // });

  const columns: ColumnsType<Supplier> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (text: any, record: any) => (
        <Space>
          {permissions?.update?.includes("suppliers") && (
            <SupplierModal
              type="edit"
              formData={record}
              fetchData={fetchData}
            />
          )}
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
            data-testid="preview-supplier-button"
            title="Preview"
          />
          {/* <Dropdown menu={menu(record)}>
            <Space>
              <Button type="text" icon={<MoreOutlined />} />
            </Space>
          </Dropdown> */}
        </Space>
      ),
    },
    {
      title: "Supplier ID",
      dataIndex: "number",
      key: "number",
      sorter: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "name",
      key: "name",
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("name"),
      filters: filterData?.name || [],
      filteredValue: tableFilters.name || null,
      filterSearch: true,
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("country"),
      filters: filterData?.country || [],
      filteredValue: tableFilters.country || null,
      filterSearch: true,
      sorter: true,
    },
    {
      title: "Active Contacts",
      dataIndex: "activeContacts",
      key: "activeContacts",
      // render: (contacts) => (
      //   <Text>
      //     {contacts &&
      //     contacts.length > 0 &&
      //     contacts.find((e: any) => e.isPrimary)
      //       ? contacts.find((e: any) => e.isPrimary).firstName
      //       : ""}
      //   </Text>
      // ),
    },
    permissions?.read?.includes("components") && {
      title: "Active Components",
      dataIndex: "activeComponents",
      key: "activeComponents",
    },
    {
      title: "In Scope for",
      dataIndex: "inScope",
      key: "inScope",
      ellipsis: true,
      render: (inScope: any) => (
        <Text>{inScope?.map((e: any) => e.name).join(", ")}</Text>
      ),
    },
    {
      title: "Last Reported",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ].filter(Boolean);

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  return (
    <>
      <ErrorBoundary>
        <Spin fullscreen spinning={loadingData} />
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Suppliers
            </Title>
          </div>
          <Space>
            {Object.keys(tableFilters).length > 0 ? (
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setTableFilters({});
                  setSearch({});
                }}
              >
                Reset Filters
              </Button>
            ) : (
              <Search
                placeholder="Search using supplier name or number"
                onSearch={onSearch}
                onPressEnter={onSearch}
                allowClear
                style={{ width: 350 }}
              />
            )}
            {permissions?.create?.includes("components") && (
              <Button
                type="primary"
                onClick={onClickGenerate}
                icon={<FileSyncOutlined />}
                data-testid="generate-button"
                title="Generate Suppliers"
              >
                Generate
              </Button>
            )}
            {permissions?.create?.includes("suppliers") && (
              <SupplierModal type="create" fetchData={fetchData} />
            )}
            {/* <Button type="text" icon={<FilterOutlined />} /> */}
          </Space>
        </Space>
        {permissions?.read?.includes("suppliers") && (
          <Table
            loading={loading}
            dataSource={suppliers}
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            columns={columns}
            rowKey="_id"
            pagination={{
              ...tablePagination,
              total: supplierCount,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
          />
        )}
      </ErrorBoundary>
    </>
  );
};

export default Suppliers;
