import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Select,
} from "antd";

import { User } from "../../utils/types/user";
import { Role } from "../../utils/types/role";
import { useAppDispatch } from "../../redux/hooks";
import { createUser, updateUser } from "../../redux/actions/usersAction";
import { listRoles } from "../../redux/actions/rolesAction";
import { getLocalStorage } from "../../utils/localStore";

const UserProfileForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: User;
  tenant?: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const fetchRoles = (value: string) => {
    dispatch(
      listRoles({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify({ number: { $regex: value, $options: "i" } }),
        type: props?.tenant ? props.tenant : "",
      })
    ).then((response) => {
      setOptions(
        response.roles.map((role: Role) => ({
          label: role.name,
          value: role.number,
        }))
      );
    });
  };

  const onFormSubmit = (values: User) => {
    setLoading(true);
    if (props.type === "create") {
      dispatch(
        createUser({
          ...values,
          type: props.tenant ? props.tenant : "",
        })
      )
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(updateUser({ updates: values }, props.formData._id))
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (open) {
      fetchRoles("");
      form.resetFields();
    }
  }, [open]);

  return (
    <>
      {permissions[props.type === "create" ? "create" : "update"]?.includes(
        "users"
      ) && (
        <>
          <Button
            type={props.type === "create" ? "primary" : "text"}
            title={props.type === "create" ? "New User" : "Edit User"}
            onClick={showDrawer}
            icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
          >
            {props.type === "create" ? "New User" : null}
          </Button>
          <Drawer
            title={props.type === "create" ? "Create a New User" : "Edit User"}
            width={720}
            onClose={onClose}
            open={open}
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={form.submit} type="primary" loading={loading}>
                  {props.type === "create" ? "Submit" : "Save"}
                </Button>
              </Space>
            }
          >
            <Form
              form={form}
              layout="vertical"
              initialValues={
                props.type === "create" ? {} : { ...props.formData }
              }
              onFinish={onFormSubmit}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter first name",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter first name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter last name",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter last name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="designation" label="Designation">
                    <Input placeholder="Please enter designation" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="emailAddress"
                    label="Email Address"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter email address",
                      },
                      {
                        type: "email",
                        message: "Please input a valid email address",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please enter email address"
                      disabled={props.type !== "create"}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter phone number",
                      },
                      {
                        pattern: new RegExp(/^[0-9]{10}$/),
                        message: "Please input a valid phone number",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter phone number" />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="roleNumber"
                    label="Role"
                    rules={[
                      { required: true, message: "Please select an role" },
                    ]}
                  >
                    <Select
                      options={options}
                      onSearch={(text) => fetchRoles(text)}
                      placeholder="Please select a role"
                      showSearch
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="remarks" label="Remarks">
                    <Input.TextArea
                      rows={4}
                      placeholder="Please enter remarks"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Drawer>
        </>
      )}
    </>
  );
};

export default UserProfileForm;
