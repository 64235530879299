import React, { useState } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Card,
  Row,
  Col,
  Divider,
  ColorPicker,
} from "antd";

import ImageCropper from "../../../../components/layouts/ImageCropper";
import FileUploader from "../../../../components/layouts/FileUploader";

import { Subscription } from "../../../../utils/types/subscription";
import { Module } from "../../../../utils/types/module";
import { useAppDispatch } from "../../../../redux/hooks";
import { listModules } from "../../../../redux/actions/modulesAction";
import { listSubscriptions } from "../../../../redux/actions/subscriptionsAction";
import { updateTenant } from "../../../../redux/actions/tenantsAction";

const TenantSubscription: React.FC<{
  id: string;
  tenantNumber: string;
  subscription?: Subscription;
  companyLogo?: string;
  primaryColor?: string;
  secondaryColor?: string;
  letterOfAuthorisation?: string;
  authorisedSender?: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [formSubscription] = Form.useForm();
  const [formWhitelabel] = Form.useForm();
  const [formLetterOfAuthorisation] = Form.useForm();
  const [moduleOptions, setModuleOptions] = useState<any[]>([]);
  const [subscriptionOptions, setSubscriptionOptions] = useState<any[]>([]);
  const [newSubscription, setNewSubscription] = useState<Subscription>();
  const [loadingSubscription, setLoadingSubscription] =
    useState<boolean>(false);
  const [loadingWhitelabel, setLoadingWhitelabel] = useState<boolean>(false);
  const [loadingLetterOfAuthorisation, setLoadingLetterOfAuthorisation] =
    useState<boolean>(false);

  const fetchModules = (value: string) => {
    dispatch(
      listModules({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify({
          type: ["client", "both"],
          number: { $regex: value, $options: "i" },
        }),
      })
    ).then((response) => {
      setModuleOptions(
        response.modules.map((module: Module) => ({
          label: module.name,
          value: module.name,
          default: module,
        }))
      );
    });
  };

  const fetchSubscriptions = (value: string) => {
    dispatch(
      listSubscriptions({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify({
          number: { $regex: value, $options: "i" },
        }),
      })
    ).then((response) => {
      setSubscriptionOptions(
        response.subscriptions.map((subscription: Subscription) => ({
          label: subscription.name,
          value: subscription.name,
          default: subscription,
        }))
      );
    });
  };

  const fetchNewSubscription = (value: string) => {
    if (value && subscriptionOptions.find((e) => e.label === value)) {
      setNewSubscription(
        subscriptionOptions.find((e) => e.label === value).default
      );
      setTimeout(() => {
        formSubscription.resetFields();
      }, 1000);
    }
  };

  const onFormSubscriptionSubmit = (values: any) => {
    setLoadingSubscription(true);
    values.subscription.modules = values.subscription.modules.map((e: any) => {
      if (!e.number) {
        return {
          ...e,
          number: moduleOptions.find((m) => m.label === e.name).default.number,
        };
      } else return e;
    });
    dispatch(
      updateTenant(
        {
          currentLocationOrigin: window.location.origin,
          updates: {
            ...values,
          },
        },
        props.id
      )
    )
      .then(() => {
        formWhitelabel.resetFields();
        setLoadingSubscription(false);
      })
      .catch((e) => setLoadingSubscription(false));
  };

  const onFormWhitelabelSubmit = (values: any) => {
    setLoadingWhitelabel(true);
    dispatch(
      updateTenant(
        {
          updates: {
            ...values,
            primaryColor:
              typeof values.primaryColor !== "string"
                ? values.primaryColor.toHexString()
                : values.primaryColor,
            secondaryColor:
              typeof values.secondaryColor !== "string"
                ? values.secondaryColor.toHexString()
                : values.secondaryColor,
          },
        },
        props.id
      )
    )
      .then(() => {
        formWhitelabel.resetFields();
        setLoadingWhitelabel(false);
      })
      .catch((e) => setLoadingWhitelabel(false));
  };

  const onFormLetterOfAuthorisationSubmit = (values: any) => {
    setLoadingLetterOfAuthorisation(true);
    dispatch(
      updateTenant(
        {
          updates: {
            ...values,
          },
        },
        props.id
      )
    )
      .then(() => {
        setLoadingLetterOfAuthorisation(false);
      })
      .catch((e) => setLoadingLetterOfAuthorisation(false));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Card
          title="Subscription"
          extra={
            <Button
              type="primary"
              onClick={formSubscription.submit}
              loading={loadingSubscription}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formSubscription}
            layout="vertical"
            onFinish={onFormSubscriptionSubmit}
            initialValues={{
              subscription: newSubscription
                ? newSubscription
                : props.subscription,
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.List name="subscription">
                  {(fields) => (
                    <>
                      <Form.Item
                        name="name"
                        label="Change Subscription"
                        rules={[{ required: true, message: "Missing name" }]}
                      >
                        <Select
                          options={subscriptionOptions}
                          onSearch={(text) => fetchSubscriptions(text)}
                          onChange={(text) => fetchNewSubscription(text)}
                          placeholder="Please select a subscription"
                          showSearch
                          allowClear
                        />
                      </Form.Item>
                      <Divider orientation="left" plain>
                        Module Limits
                      </Divider>
                      <Form.List name="modules">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field) => (
                              <Row
                                key={field.key}
                                gutter={[16, 16]}
                                justify="start"
                              >
                                <Col span={12}>
                                  <Form.Item
                                    name={[field.name, "name"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing name",
                                      },
                                    ]}
                                  >
                                    <Select
                                      options={moduleOptions}
                                      onSearch={(text) => fetchModules(text)}
                                      placeholder="Please select a module"
                                      showSearch
                                    />
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Form.Item
                                    name={[field.name, "count"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing count",
                                      },
                                    ]}
                                  >
                                    <InputNumber placeholder="Count" min={0} />
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Button
                                    type="text"
                                    danger
                                    icon={<MinusOutlined />}
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  />
                                </Col>
                              </Row>
                            ))}
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Module
                            </Button>
                          </>
                        )}
                      </Form.List>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title="Whitelabeling"
          style={{ height: "100%" }}
          extra={
            <Button
              type="primary"
              onClick={formWhitelabel.submit}
              loading={loadingWhitelabel}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formWhitelabel}
            layout="vertical"
            onFinish={onFormWhitelabelSubmit}
            initialValues={{
              companyLogo: props.companyLogo,
              primaryColor: props.primaryColor,
              secondaryColor: props.secondaryColor,
            }}
          >
            <Form.Item label="Brand Logo" name="companyLogo">
              <ImageCropper
                postUrl="admins/tenants/media/upload"
                tenantNumber={props.tenantNumber}
                setFormField={(value: string) =>
                  formWhitelabel.setFieldValue("companyLogo", value)
                }
                formField={props.companyLogo}
              />
            </Form.Item>
            <Form.Item name="primaryColor" label="Primary Color">
              <ColorPicker showText />
            </Form.Item>
            <Form.Item name="secondaryColor" label="Secondary Color">
              <ColorPicker showText />
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          title="Campaign Configuration"
          style={{ height: "100%" }}
          extra={
            <Button
              type="primary"
              onClick={formLetterOfAuthorisation.submit}
              loading={loadingLetterOfAuthorisation}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formLetterOfAuthorisation}
            layout="vertical"
            onFinish={onFormLetterOfAuthorisationSubmit}
            initialValues={{
              letterOfAuthorisation: props.letterOfAuthorisation,
              authorisedSender: props.authorisedSender,
            }}
          >
            <Form.Item
              label="Authorised Sender"
              name="authorisedSender"
              rules={[
                {
                  type: "email",
                  message: "Please input a valid email address",
                },
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>
            <Form.Item
              label="Attach Letter of Authorisation"
              name="letterOfAuthorisation"
              rules={[]}
            >
              <FileUploader
                postUrl="admins/tenants/media/upload"
                tenantNumber={props.tenantNumber}
                setFormField={(value: string) =>
                  formLetterOfAuthorisation.setFieldValue(
                    "letterOfAuthorisation",
                    value
                  )
                }
                formField={props.letterOfAuthorisation}
              />
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default TenantSubscription;
