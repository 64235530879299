import React, { useEffect, useState } from "react";
import {
  PaperClipOutlined,
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SwapOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Table,
  Select,
  Row,
  Space,
  Steps,
  Result,
} from "antd";

import FileUploader from "../layouts/FileUploader";

import { Import } from "../../utils/types/import";
import { useAppDispatch } from "../../redux/hooks";

import {
  createFileRecord,
  updateFileRecord,
  readHeaders,
  startImport,
} from "../../redux/actions/importDataAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../utils/localStore";

const { Option } = Select;

const ImportDataForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Import;
  disabled?: boolean;
  xmlImport?: boolean;
  importTypeData?: any;
  module?: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [formUpload] = Form.useForm();
  const [formMap] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [fileDetails, setFileDetails] = useState<any>({});
  const [fileHeaders, setFileHeaders] = useState<any[]>([]);
  const [filePreview, setFilePreview] = useState<any[]>([]);
  const { tenantNumber, workspace, modules, specifications } =
    getLocalStorage("user");
  const regulations = getRegulationsFromLocalStorage() ?? [];
  const [mapHeaders, setMapHeaders] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    if (currentStep >= 0) {
      formUpload.resetFields();
    }
    if (currentStep >= 1) {
      formMap.resetFields();
    }
    setCurrentStep(0);
    setFileHeaders([]);
    setFilePreview([]);
    setFileDetails({});
    setMapHeaders([]);
    setLoading(false);
    props.fetchData();
    setOpen(false);
  };

  const onFormUploadSubmit = (values: any) => {
    setLoading(true);
    if (values.filename.includes("csv")) {
      dispatch(readHeaders({ filename: values.filename }))
        .then((response: any) => {
          setFilePreview(response.previewData);
          if (props.formData) {
            setFileHeaders(JSON.parse(props.formData.headers));
          } else {
            setFileHeaders(
              response.headers.map((e: any) => ({
                ...e,
                value: mapHeaders.find(
                  (m) =>
                    m.module.includes(formUpload.getFieldValue("importType")) &&
                    m.value === e.map
                )?.value,
              }))
            );
          }
          setFileDetails(formUpload.getFieldsValue());
          if (formUpload.getFieldsValue().importType.includes("Substances")) {
            const tempHeader = mapHeaders
              .filter((e) =>
                e.module.includes(formUpload.getFieldsValue().importType)
              )
              .map((e) => e.value.toLowerCase());
            const tempResponseHeader = response.headers.filter(
              (e: any) =>
                !tempHeader.includes(
                  e.initialValue.replace(/[-+.^:,_]/g, "").toLowerCase()
                )
            );
            setMapHeaders((prev) => [
              ...prev,
              ...tempResponseHeader.map((e: any) => ({
                label: e.initialValue,
                value: e.initialValue,
                module: [formUpload.getFieldsValue().importType],
              })),
            ]);
          }
          setCurrentStep(1);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      // setCurrentStep(2);
      // setLoading(false);
      if (props.type === "create") {
        dispatch(
          createFileRecord({
            path: tenantNumber,
            filename: values.filename,
            status: "drafted",
            importType: values.importType,
            importTypeData: JSON.stringify(props.importTypeData),
          })
        )
          .then((response: any) => {
            setFileDetails({
              ...response,
            });
            setCurrentStep(2);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      } else if (props.formData) {
        setFileDetails(props.formData);
        setCurrentStep(2);
        setLoading(false);
      }
    }
  };

  const onFormMapSubmit = (values: any) => {
    setLoading(true);
    if (props.type === "create") {
      const regulation = fileDetails.importType.split(" ");
      const importType = regulation.pop();
      dispatch(
        createFileRecord({
          path: tenantNumber,
          filename: fileDetails.filename,
          status: "drafted",
          headers: JSON.stringify(values.headers),
          importType: importType,
          importTypeData:
            fileDetails.importType.includes("Compliance") ||
            fileDetails.importType.includes("Substances") ||
            fileDetails.importType.includes("Exemptions")
              ? JSON.stringify({
                  regulationNumber: regulations.find(
                    (r: any) => r.name === regulation.join(" ")
                  ).regulationNumber,
                })
              : null,
        })
      )
        .then((response: any) => {
          setFileHeaders(JSON.parse(response.headers));
          setFileDetails({
            ...response,
            headers: JSON.parse(response.headers),
          });
          setCurrentStep(2);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      const regulation = fileDetails.importType.split(" ");
      const importType = regulation.pop();
      dispatch(
        updateFileRecord(
          {
            path: tenantNumber,
            filename: fileDetails.filename,
            status: "drafted",
            headers: JSON.stringify(values.headers),
            importType: importType,
            importTypeData:
              fileDetails.importType.includes("Compliance") ||
              fileDetails.importType.includes("Substances") ||
              fileDetails.importType.includes("Exemptions")
                ? JSON.stringify({
                    regulationNumber: regulations.find(
                      (r: any) => r.name === regulation.join(" ")
                    ).regulationNumber,
                  })
                : null,
          },
          props.formData._id
        )
      )
        .then((response: any) => {
          setFileHeaders(JSON.parse(response.headers));
          setFileDetails({
            ...response,
            headers: JSON.parse(response.headers),
          });
          setCurrentStep(2);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const onStartImport = () => {
    setLoading(true);
    dispatch(
      startImport({ id: fileDetails._id, filename: fileDetails.filename })
    )
      .then((response: any) => {
        setCurrentStep(3);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const onDownloadTemplate = () => {
    const data = mapHeaders.filter(
      (e: any) =>
        e.module.includes(formUpload.getFieldValue("importType")) &&
        e.value !== "do_not_import"
    );
    const csvData = data.map((e: any) => e.label);
    const blob = new Blob([csvData.toString()], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute(
      "download",
      formUpload.getFieldValue("importType") + " Template.csv"
    );
    a.click();
  };

  useEffect(() => {
    if (open) {
      const complianceHeaders = regulations
        ? regulations.map((r: any) => {
            return r.configuration
              .filter((e: any) => e.edit)
              .map((e: any) => ({
                label: e.text,
                value: e.value,
                module: [r.name + " Compliance"],
              }));
          })
        : [];
      const specificationsHeaders = specifications
        ? specifications.map((s: any) => ({
            label: s.name,
            value: "specification." + s.value,
            module: ["Components"],
          }))
        : [];
      setMapHeaders([
        {
          label: "Do Not Import",
          value: "do_not_import",
          module: [
            "Components",
            "Compliances",
            "Materials",
            "Suppliers",
            "Smelters",
            ...regulations.map((r: any) => r.name + " Compliance"),
            ...regulations.map((r: any) => r.name + " Substances"),
            ...regulations.map((r: any) => r.name + " Exemptions"),
          ],
        },
        { label: "Name", value: "name", module: ["Smelters"] },
        { label: "Number", value: "number", module: ["Smelters"] },
        { label: "Metal", value: "metal", module: ["Smelters"] },
        { label: "Risk", value: "risk", module: ["Smelters"] },
        { label: "State", value: "address.state", module: ["Smelters"] },
        { label: "City", value: "address.city", module: ["Smelters"] },
        { label: "Country", value: "address.country", module: ["Smelters"] },
        { label: "Product Name", value: "productName", module: ["Components"] },
        {
          label: "Product Number",
          value: "productNumber",
          module: ["Components"],
        },
        { label: "Revision", value: "revision", module: ["Components"] },
        { label: "BOM Level", value: "bomLevel", module: ["Components"] },
        {
          label: "Internal Item Number",
          value: "internalItemNumber",
          module: [
            "Components",
            "Compliances",
            "Materials",
            ...regulations.map((r: any) => r.name + " Compliance"),
          ],
        },
        {
          label: "Manufacturer Name",
          value: "manufacturer.name",
          module: [
            "Components",
            "Compliances",
            "Materials",
            ...regulations.map((r: any) => r.name + " Compliance"),
          ],
        },
        {
          label: "Manufacturer Item Number",
          value: "manufacturer.itemNumber",
          module: [
            "Components",
            "Compliances",
            "Materials",
            ...regulations.map((r: any) => r.name + " Compliance"),
          ],
        },
        {
          label: "FMD Document",
          value: "fmdDocument",
          module: ["Components", "Materials"],
        },
        {
          label: "FMD Comments",
          value: "fmdComments",
          module: ["Components", "Materials"],
        },
        {
          label: "Part Weight",
          value: "partWeight",
          module: ["Materials"],
        },
        {
          label: "Material Name",
          value: "material.name",
          module: ["Materials"],
        },
        {
          label: "Material Weight",
          value: "material.weight",
          module: ["Materials"],
        },
        {
          label: "Substance Name",
          value: "substance.name",
          module: ["Materials"],
        },
        {
          label: "Substance CAS Number",
          value: "substance.casNumber",
          module: ["Materials"],
        },
        {
          label: "Substance CAS Synonyms",
          value: "substance.casSynonyms",
          module: ["Materials"],
        },
        {
          label: "Substance Weight",
          value: "substance.weight",
          module: ["Materials"],
        },
        {
          label: "Supplier Number",
          value: "number",
          module: ["Suppliers"],
        },
        {
          label: "Supplier Name",
          value: "name",
          module: ["Suppliers"],
        },
        { label: "Type", value: "type", module: ["Suppliers"] },
        { label: "Website", value: "website", module: ["Suppliers"] },
        { label: "Phone Number", value: "phoneNumber", module: ["Suppliers"] },
        { label: "Address", value: "address", module: ["Suppliers"] },
        { label: "City", value: "city", module: ["Suppliers"] },
        { label: "State", value: "state", module: ["Suppliers"] },
        { label: "Country", value: "country", module: ["Suppliers"] },
        { label: "Zip Code", value: "zipcode", module: ["Suppliers"] },
        { label: "Comments", value: "comments", module: ["Suppliers"] },
        {
          label: "contacts.0.firstName",
          value: "contacts.0.firstName",
          module: ["Suppliers"],
        },
        {
          label: "contacts.0.lastName",
          value: "contacts.0.lastName",
          module: ["Suppliers"],
        },
        {
          label: "contacts.0.isPrimary",
          value: "contacts.0.isPrimary",
          module: ["Suppliers"],
        },
        {
          label: "contacts.0.designation",
          value: "contacts.0.designation",
          module: ["Suppliers"],
        },
        {
          label: "contacts.0.emailAddress",
          value: "contacts.0.emailAddress",
          module: ["Suppliers"],
        },
        {
          label: "contacts.0.primaryNumber",
          value: "contacts.0.primaryNumber",
          module: ["Suppliers"],
        },
        {
          label: "contacts.0.secondaryNumber",
          value: "contacts.0.secondaryNumber",
          module: ["Suppliers"],
        },
        {
          label: "contacts.0.state",
          value: "contacts.0.state",
          module: ["Suppliers"],
        },
        {
          label: "contacts.0.country",
          value: "contacts.0.country",
          module: ["Suppliers"],
        },
        {
          label: "contacts.0.zipcode",
          value: "contacts.0.zipcode",
          module: ["Suppliers"],
        },
        {
          label: "contacts.0.comments",
          value: "contacts.0.comments",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.firstName",
          value: "contacts.1.firstName",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.lastName",
          value: "contacts.1.lastName",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.isPrimary",
          value: "contacts.1.isPrimary",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.designation",
          value: "contacts.1.designation",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.emailAddress",
          value: "contacts.1.emailAddress",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.primaryNumber",
          value: "contacts.1.primaryNumber",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.secondaryNumber",
          value: "contacts.1.secondaryNumber",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.state",
          value: "contacts.1.state",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.country",
          value: "contacts.1.country",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.zipcode",
          value: "contacts.1.zipcode",
          module: ["Suppliers"],
        },
        {
          label: "contacts.1.comments",
          value: "contacts.1.comments",
          module: ["Suppliers"],
        },
        {
          label: "Substance Name",
          value: "substance",
          module: [...regulations.map((r: any) => r.name + " Substances")],
        },
        {
          label: "Substance Synonyms",
          value: "substanceSynonyms",
          module: [...regulations.map((r: any) => r.name + " Substances")],
        },
        {
          label: "CAS Number",
          value: "casNumber",
          module: [...regulations.map((r: any) => r.name + " Substances")],
        },
        {
          label: "CAS Synonyms",
          value: "casSynonyms",
          module: [...regulations.map((r: any) => r.name + " Substances")],
        },
        {
          label: "EC Number",
          value: "ecNumber",
          module: [...regulations.map((r: any) => r.name + " Substances")],
        },
        {
          label: "EC Synonyms",
          value: "ecSynonyms",
          module: [...regulations.map((r: any) => r.name + " Substances")],
        },
        {
          label: "Threshold",
          value: "threshold",
          module: [...regulations.map((r: any) => r.name + " Substances")],
        },
        {
          label: "Exemption",
          value: "exemption",
          module: [...regulations.map((r: any) => r.name + " Exemptions")],
        },
        {
          label: "Clause",
          value: "clause",
          module: [...regulations.map((r: any) => r.name + " Exemptions")],
        },
        {
          label: "Categories",
          value: "categories",
          module: [...regulations.map((r: any) => r.name + " Exemptions")],
        },
        {
          label: "Description",
          value: "description",
          module: [...regulations.map((r: any) => r.name + " Exemptions")],
        },
        {
          label: "Start Date",
          value: "startDate",
          module: [...regulations.map((r: any) => r.name + " Exemptions")],
        },
        {
          label: "End Date",
          value: "endDate",
          module: [...regulations.map((r: any) => r.name + " Exemptions")],
        },
        {
          label: "Renewal Request Date",
          value: "renewalRequestDate",
          module: [...regulations.map((r: any) => r.name + " Exemptions")],
        },
        ...complianceHeaders.flat(),
        ...specificationsHeaders,
      ]);
    }
    if (open && props.formData) {
      onFormUploadSubmit({ filename: props.formData.filename });
    }
  }, [open]);
  return (
    <>
      <Button
        type={
          props.type === "create"
            ? props.xmlImport
              ? "text"
              : "primary"
            : "text"
        }
        title={
          props.type === "create"
            ? props.xmlImport
              ? "XML Import"
              : "Import Data"
            : "Edit Import Data"
        }
        onClick={showDrawer}
        icon={
          props.type === "create" ? (
            props.xmlImport ? (
              <PaperClipOutlined
                style={{
                  color: props.module === "product" ? "white" : "black",
                }}
              />
            ) : (
              <PlusOutlined />
            )
          ) : (
            <EditOutlined />
          )
        }
        disabled={props.disabled}
        data-testid="import-button"
      >
        {props.type === "create"
          ? props.xmlImport
            ? null
            : "Import Data"
          : null}
      </Button>
      <Drawer
        title={
          props.type === "create"
            ? props.xmlImport
              ? "Import XML Data"
              : "Import Data"
            : "Edit Import Data"
        }
        width={720}
        onClose={onClose}
        open={open}
        destroyOnClose
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="close-button">
              {currentStep === 3 ? "Close" : "Cancel"}
            </Button>
            {currentStep !== 3 && (
              <Button
                onClick={
                  currentStep === 0
                    ? formUpload.submit
                    : currentStep === 1
                    ? formMap.submit
                    : onStartImport
                }
                type="primary"
                loading={loading}
                data-testid="start-import-button"
              >
                {currentStep < 2 ? "Next" : "Start Import"}
              </Button>
            )}
          </Space>
        }
      >
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <Steps
            items={[
              {
                title: "Upload",
                status: currentStep < 0 ? "wait" : "finish",
                icon: <UploadOutlined />,
              },
              {
                title: "Map",
                status: currentStep < 1 ? "wait" : "finish",
                icon: <SwapOutlined />,
              },
              {
                title: "Preview",
                status: currentStep < 2 ? "wait" : "finish",
                icon: <EyeOutlined />,
              },
              {
                title: "Done",
                status: currentStep < 3 ? "wait" : "finish",
                icon: <FileDoneOutlined />,
              },
            ]}
          />
          {currentStep === 0 ? (
            <Form
              form={formUpload}
              layout="vertical"
              initialValues={
                props.type === "create"
                  ? props.xmlImport
                    ? {
                        importType: "Materials",
                      }
                    : {}
                  : {
                      importType:
                        props.formData?.importTypeData &&
                        props.formData?.importTypeData !== "undefined"
                          ? regulations.find(
                              (e: any) =>
                                e.regulationNumber ===
                                JSON.parse(
                                  props.formData?.importTypeData
                                    ? props.formData?.importTypeData
                                    : ""
                                ).regulationNumber
                            )?.name +
                            " " +
                            props.formData?.importType
                          : props.formData?.importType,
                      filename: props.formData?.filename,
                    }
              }
              onFinish={onFormUploadSubmit}
              disabled={props.type === "create" ? false : true}
            >
              <Row gutter={16} align="middle">
                <Col span={18}>
                  <Form.Item
                    name="importType"
                    label="Type of Data"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please select the type of data",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select the type of data"
                      onChange={(value) =>
                        setFileDetails({ importType: value })
                      }
                      disabled={props.xmlImport}
                      data-testid="import-type-select"
                    >
                      {modules &&
                        modules.find(
                          (e: any) =>
                            e.number === "products" || e.number === "components"
                        ) && (
                          <Option value="Components">Bill of Materials</Option>
                        )}
                      {modules &&
                        modules.find((e: any) => e.number === "compliances") &&
                        regulations.map((r: any) => (
                          <Option
                            value={r.name + " Compliance"}
                            key={r.regulationNumber + "compliance"}
                          >
                            {r.name} Compliance
                          </Option>
                        ))}
                      {modules &&
                        modules.find((e: any) => e.number === "materials") && (
                          <Option value="Materials">
                            Full Material Disclosure
                          </Option>
                        )}
                      {modules &&
                        modules.find((e: any) => e.number === "suppliers") && (
                          <Option value="Suppliers">Suppliers</Option>
                        )}
                      {workspace === "acquiscompliance" && (
                        <Option value="Smelters">Smelters</Option>
                      )}
                      {workspace === "acquiscompliance" &&
                        regulations.map((r: any) => (
                          <Option
                            value={r.name + " Substances"}
                            key={r.regulationNumber + "substances"}
                          >
                            {r.name} Substances
                          </Option>
                        ))}
                      {workspace === "acquiscompliance" &&
                        regulations
                          .filter((e: any) => e.hasExemptions)
                          .map((r: any) => (
                            <Option
                              value={r.name + " Exemptions"}
                              key={r.regulationNumber + "exemptions"}
                            >
                              {r.name} Exemptions
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    disabled={fileDetails.importType ? false : true}
                    onClick={onDownloadTemplate}
                    data-testid="download-template-button"
                  >
                    Download Template
                  </Button>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="filename"
                    label="Attach a File"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please choose a file",
                      },
                    ]}
                  >
                    <FileUploader
                      postUrl={
                        props.xmlImport
                          ? "materials/document/upload"
                          : "filerecords/media/upload"
                      }
                      tenantNumber={tenantNumber}
                      setFormField={(value: string) => {
                        formUpload.setFieldValue("filename", value);
                      }}
                      formField={props.formData?.filename}
                      importType={props.xmlImport ? "xml" : "csv"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : null}

          {currentStep === 1 && mapHeaders.length ? (
            <Form
              form={formMap}
              layout="vertical"
              onFinish={onFormMapSubmit}
              initialValues={{
                headers: fileHeaders,
              }}
            >
              <Form.List name="headers">
                {(fields) => (
                  <>
                    {fields.map((field) => (
                      <Row gutter={16} align="middle" key={field.key}>
                        <Col span={12}>{fileHeaders[field.name]?.map}</Col>
                        <Col span={12}>
                          <Form.Item name={[field.name, "value"]} label="Field">
                            <Select
                              placeholder="Please select a field"
                              options={mapHeaders
                                .filter((e) =>
                                  e.module.includes(
                                    formUpload.getFieldValue("importType")
                                  )
                                )
                                .map((mapHeader) => ({
                                  value: mapHeader.value,
                                  label: mapHeader.label,
                                }))}
                              data-testid="mapping-select"
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </Form>
          ) : null}

          {currentStep === 2 ? (
            <Table
              dataSource={filePreview}
              columns={fileHeaders
                .filter((e) => e.value && e.value !== "do_not_import")
                .map((e) => ({
                  title: mapHeaders.find((m) => m.value === e.value)?.label
                    ? mapHeaders.find((m) => m.value === e.value)?.label
                    : e.initialValue,
                  dataIndex: e.initialValue,
                  key: e.initialValue,
                }))}
              scroll={{
                x:
                  fileHeaders.filter(
                    (e) => e.value && e.value !== "do_not_import"
                  ).length * 150,
                y: "calc(100vh - 285px)",
              }}
              pagination={false}
              rowKey={(record) => JSON.stringify(record)}
            />
          ) : null}

          {currentStep === 3 ? (
            <Result
              status="info"
              title="We are currently processing your file data."
              subTitle="The import will take 3-15 mins time to complete depending on the size of the file. You can check the status of the import in the Import section."
            />
          ) : null}
        </Space>
      </Drawer>
    </>
  );
};

export default ImportDataForm;
