import React from "react";
import type { ColumnsType } from "antd/es/table";
import { Table, Typography } from "antd";

const { Text } = Typography;

const AuditLogsTab: React.FC<{
  supplierHistory: any;
}> = (props) => {
  const columns: ColumnsType<any> = [
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      width: 150,
      render: (_, record: any) => <Text>{record?.document?.__v}</Text>,
    },
    {
      title: "Supplier Id #",
      dataIndex: "number",
      key: "number",
      width: 150,
      render: (_, record: any) => <Text>{record?.document?.number}</Text>,
    },
    {
      title: "Supplier Name",
      dataIndex: "name",
      key: "name",
      render: (_, record: any) => <Text> {record?.document?.name}</Text>,
      width: 150,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 150,
      render: (_, record: any) => <Text>{record?.document?.country}</Text>,
    },
    {
      title: "Primary Contact",
      dataIndex: "firstName",
      key: "firstName",
      width: 150,
      render: (_, record: any) => (
        <Text>
          {record?.document?.contacts?.map(
            (e: any) => e.isPrimary && e.firstName
          )}
        </Text>
      ),
    },
    {
      title: "Primary Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
      width: 150,
      render: (_, record: any) => (
        <Text>
          {record?.document?.contacts?.map(
            (e: any) => e.isPrimary && e.emailAddress
          )}
        </Text>
      ),
    },
    {
      title: "Secondary Contact",
      dataIndex: "firstName",
      key: "firstName",
      width: 150,
      render: (_, record: any) => (
        <Text>
          {record?.document?.contacts?.map(
            (e: any) => !e.isPrimary && e.firstName
          )}
        </Text>
      ),
    },
    {
      title: "Secondary Contact",
      dataIndex: "emailAddress",
      key: "emailAddress",
      width: 150,
      render: (_, record: any) => (
        <Text>
          {record?.document?.contacts?.map(
            (e: any) => !e.isPrimary && e.emailAddress
          )}
        </Text>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={props?.supplierHistory}
        scroll={{
          x: 1000,
          y: "calc(100vh - 285px)",
        }}
        columns={columns}
        rowKey={(record) => (record?._id ? record?._id : record?.document?._id)}
      />
    </>
  );
};

export default AuditLogsTab;
