import React, { useEffect, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Space, Typography, Table, List, Row, Col, Card, Checkbox } from "antd";
import { PaginationConfig } from "antd/es/pagination";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Role } from "../../../../utils/types/role";
import { Module } from "../../../../utils/types/module";
import { useAppDispatch } from "../../../../redux/hooks";
import { listRoles, updateRole } from "../../../../redux/actions/rolesAction";
import { listModules } from "../../../../redux/actions/modulesAction";
import { getLocalStorage } from "../../../../utils/localStore";
import RolesAndPermissionsForm from "../../../../components/forms/RolesAndPermissionsForm";
import ErrorBoundary from "../../../../utils/errorBoundary";

const { Title } = Typography;

const Roles: React.FC = () => {
  const dispatch = useAppDispatch();
  const [role, setRole] = useState<Role>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [roleCount, setRoleCount] = useState<number>(0);
  const [modules, setModules] = useState<Module[]>([]);
  const [moduleCount, setModuleCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [listPagination, setListPagination] = useState<PaginationConfig>({
    pageSize: 10,
    current: 1,
    position: "bottom",
    align: "end",
  });
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Module>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();
  const { workspace } = getLocalStorage("user");
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const columns: ColumnsType<Module> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Create",
      dataIndex: "number",
      key: "number",
      render: (text: string, record: Module) => (
        <Checkbox
          checked={role?.permissions.create.includes(text)}
          onChange={() => updateRolesPermissions("create", text)}
          disabled={
            !permissions["update"]?.includes("roles") ||
            role?.number === workspace + "_admin" ||
            (workspace === "acquiscompliance" &&
              role?.number === "super_admin") ||
            (workspace !== "acquiscompliance" && record.type === "limited")
          }
        />
      ),
    },
    {
      title: "Read",
      dataIndex: "number",
      key: "number",
      render: (text: string, record: Module) => (
        <Checkbox
          checked={role?.permissions.read.includes(text)}
          onChange={() => updateRolesPermissions("read", text)}
          disabled={
            !permissions["update"]?.includes("roles") ||
            role?.number === workspace + "_admin" ||
            (workspace === "acquiscompliance" && role?.number === "super_admin")
          }
        />
      ),
    },
    {
      title: "Update",
      dataIndex: "number",
      key: "number",
      render: (text: string, record: Module) => (
        <Checkbox
          checked={role?.permissions.update.includes(text)}
          onChange={() => updateRolesPermissions("update", text)}
          disabled={
            !permissions["update"]?.includes("roles") ||
            role?.number === workspace + "_admin" ||
            (workspace === "acquiscompliance" &&
              role?.number === "super_admin") ||
            (workspace !== "acquiscompliance" && record.type === "limited")
          }
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "number",
      key: "number",
      render: (text: string, record: Module) => (
        <Checkbox
          checked={role?.permissions.delete.includes(text)}
          onChange={() => updateRolesPermissions("delete", text)}
          disabled={
            !permissions["update"]?.includes("roles") ||
            role?.number === workspace + "_admin" ||
            (workspace === "acquiscompliance" &&
              role?.number === "super_admin") ||
            (workspace !== "acquiscompliance" && record.type === "limited")
          }
        />
      ),
    },
    {
      title: "Import",
      dataIndex: "number",
      key: "number",
      render: (text: string, record: Module) => (
        <Checkbox
          checked={role?.permissions.import.includes(text)}
          onChange={() => updateRolesPermissions("import", text)}
          disabled={
            !permissions["update"]?.includes("roles") ||
            role?.number === workspace + "_admin" ||
            (workspace === "acquiscompliance" &&
              role?.number === "super_admin") ||
            (workspace !== "acquiscompliance" && record.type === "limited")
          }
        />
      ),
    },
    {
      title: "Export",
      dataIndex: "number",
      key: "number",
      render: (text: string, record: Module) => (
        <>
          <Checkbox
            checked={role?.permissions.export.includes(text)}
            onChange={() => updateRolesPermissions("export", text)}
            disabled={
              !permissions["update"]?.includes("roles") ||
              role?.number === workspace + "_admin" ||
              (workspace === "acquiscompliance" &&
                role?.number === "super_admin") ||
              (workspace !== "acquiscompliance" && record.type === "limited")
            }
          />
        </>
      ),
    },
  ];

  const fetchData = () => {
    setLoading(true);
    dispatch(
      listRoles({
        pageSize: listPagination.pageSize,
        pageNum: listPagination.current,
        sortBy: ["createdAt"],
        sortDesc: false,
      })
    )
      .then((response) => {
        setRole(response.roles[0]);
        setRoles(response.roles);
        setRoleCount(response.roleCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchModules = () => {
    setLoading(true);
    dispatch(
      listModules({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        filters: JSON.stringify({
          type:
            workspace === "acquiscompliance"
              ? ["admin", "both", "limited"]
              : ["client", "both", "limited"],
        }),
      })
    )
      .then((response) => {
        setModules(response.modules);
        setModuleCount(response.moduleCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Module>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Module> | SorterResult<Module>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Module>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setModules([]);
    }
  };

  const updateRolesPermissions = (permission: string, module: string) => {
    setLoading(true);
    const index = role?.permissions[permission]?.indexOf(module) || -1;
    const id = role?._id || "";
    if (index > -1) {
      role?.permissions[permission].splice(index, 1);
    } else {
      role?.permissions[permission].push(module);
    }
    dispatch(
      updateRole(
        {
          updates: {
            name: role?.name,
            permissions: role?.permissions,
          },
        },
        id
      )
    )
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const { modules } = getLocalStorage("user");
    if (!modules) {
      fetchModules();
    } else {
      setModules(modules);
      setModuleCount(modules.length);
    }
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Roles & Permissions
            </Title>
          </div>
          <Space>
            <RolesAndPermissionsForm type="create" fetchData={fetchData} />
          </Space>
        </Space>
        <Row style={{ background: "white", height: "calc(100vh - 200px)" }}>
          <Col span={8}>
            <List
              pagination={{
                ...listPagination,
                total: roleCount,
                showQuickJumper: true,
                showTotal: (total) => `Total ${total} items`,
              }}
              dataSource={roles}
              bordered
              rowKey="_id"
              renderItem={(item) => (
                <List.Item
                  key={item._id}
                  onClick={() => setRole(item)}
                  extra={role?.number === item.number && <ArrowRightOutlined />}
                  style={{ cursor: "pointer" }}
                >
                  <List.Item.Meta
                    title={item.name}
                    description={new Date(item.createdAt).toLocaleString()}
                  />
                </List.Item>
              )}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            />
          </Col>
          <Col span={16}>
            <Card
              title={role?.name}
              bordered={false}
              style={{ height: "100%" }}
            >
              <Table
                loading={loading}
                dataSource={modules}
                scroll={{
                  x: 600,
                  y: "calc(100vh - 420px)",
                }}
                columns={columns}
                rowKey="number"
                pagination={{
                  ...tablePagination,
                  total: moduleCount,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (totalCount) => `Total  ${totalCount}  items`,
                }}
                onChange={handleTableChange}
              />
            </Card>
          </Col>
        </Row>
      </ErrorBoundary>
    </div>
  );
};

export default Roles;
