import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Typography, Table, Card, Row, Col, Space } from "antd";
import type { ColumnsType } from "antd/es/table";

import { useAppDispatch } from "../../../../../redux/hooks";
import {
  readCampaignSupplierAnalytics,
  readCampaignData,
  readCampaignManager,
} from "../../../../../redux/actions/CampaignManager/campaignManagerAction";
import { getRegulationsFromLocalStorage } from "../../../../../utils/localStore";

import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { Campaign } from "../../../../../utils/types/CampaignManager/campaignManager";
import ErrorBoundary from "../../../../../utils/errorBoundary";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const { Title, Text } = Typography;

interface ChartProps {
  data: any[];
  chart: any;
}

const FushionChart: FC<ChartProps> = ({ data, chart }) => {
  useEffect(() => {
    FusionCharts.ready(function () {
      new FusionCharts({
        type: "doughnut3d",
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: { data, chart: { ...chart } },
      }).render("chartRef");
    });
  }, [data, chart]);

  return <div id={"chartRef"} />;
};

const CampaignOverview: React.FC<{
  campaignData: any;
}> = (props) => {
  const { id } = useParams<{ id: any }>();
  const dispatch = useAppDispatch();
  const [supplierAnalytics, setSupplierAnalytics] = useState<any[]>([]);
  const [supplierOverview, setSupplierOverview] = useState<any[]>([]);
  const [campagin, setCampaign] = useState<Campaign>();
  const [campaignData, setCampaignData] = useState<any[]>([]);
  const [campaignListedData, setCampaignListedData] = useState<any[]>([]);

  const regulations = getRegulationsFromLocalStorage() ?? [];

  const [loading, setLoading] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: "Status",
      dataIndex: "_id",
      key: "_id",
      sorter: true,
    },
    {
      title: "Email address",
      dataIndex: "emailAddress",
      key: "emailAddress",
      render: (value: any, record: any) => (
        <Text>
          {record.emailAddress.map((e: any) => e?.emailAddress).join(", ")}
        </Text>
      ),
    },
  ];

  const chart = {
    caption: "Campaign Analytics",
    subcaption: "Campaign Analytics",
    enablesmartlabels: "1",
    showlabels: "1",
    numbersuffix: " %",
    usedataplotcolorforlabels: "1",
    plottooltext: "$label, <b>$value</b>",
    theme: "fusion",
  };

  const fetchCampaign = () => {
    setLoading(true);
    dispatch(
      readCampaignManager({
        id: id,
      })
    )
      .then((response: any) => {
        const regulationData = response.campaign?.regulations?.map(
          (value: any) =>
            regulations.find(
              (regulation: any) => regulation.regulationNumber === value
            )?.name || value
        );
        if (response.campaign?.materials === "Yes") {
          regulationData.push("Materials");
        }
        if (response.campaign?.conflict === "Yes") {
          regulationData.push("Conflict Minerals");
        }
        if (response.campaign?.extended === "Yes") {
          regulationData.push("Extended Minerals");
        }
        setCampaign({ ...response.campaign, regulations: regulationData });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readCampaignSupplierAnalytics({
        sendgridCampaignId: id,
      })
    )
      .then((response: any) => {
        setSupplierOverview(response?.logs);
        setSupplierAnalytics(response?.document);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchCampaignData = () => {
    setLoading(true);
    dispatch(
      readCampaignData({
        sendgridCampaignId: id,
      })
    )
      .then((response: any) => {
        const mappedData = [];
        for (const [key, value] of Object.entries(response?.graphData)) {
          mappedData.push({ label: key, value: value });
        }
        setCampaignData(mappedData);
        setCampaignListedData(response?.logData);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchCampaign();
    fetchCampaignData();
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Row
          gutter={[16, 16]}
          style={{ justifyContent: "center", marginBottom: "1rem" }}
        >
          <Col span={4}>
            <Card title={supplierAnalytics.length} bordered={false}>
              Total Suppliers
            </Card>
          </Col>
          {supplierOverview
            ? supplierOverview.map((value, index) => (
                <Col span={4} key={index}>
                  <Card title={value.total} bordered={false}>
                    {value._id.charAt(0).toUpperCase() + value._id.slice(1)}
                  </Card>
                </Col>
              ))
            : ""}
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Space>
              <FushionChart data={campaignData} chart={chart} />
            </Space>
          </Col>
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={campaignListedData}
              scroll={{
                x: 800,
                y: "calc(80vh - 280px)",
              }}
              columns={columns}
              rowKey="supplierId"
            />
          </Col>
          {campagin && (
            <Col span={24}>
              <Card>
                <Title level={5}>
                  Requested Data: <Text>{campagin?.regulations.join(",")}</Text>
                </Title>
                <Title level={5}>
                  Content :{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: campagin?.message || "",
                    }}
                  />
                </Title>
              </Card>
            </Col>
          )}
        </Row>
      </ErrorBoundary>
    </>
  );
};

export default CampaignOverview;
