import React, { useState } from "react";
import { Form, Input, Button, Typography, Card, Flex } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import {
  authVerifyOTP,
  authRegenerateOTP,
} from "../../../redux/actions/authAction";
import { getLocalStorage } from "../../../utils/localStore";
import { message } from "antd";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Paragraph } = Typography;

type AuthResponse = {
  success: boolean;
  message?: string;
  status?: number;
};

const Authentication: React.FC = () => {
  const { workspace } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [seconds, setSeconds] = useState(30);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const otpToNum = Number(values.otp);
      if (
        values.otp.length !== 6 ||
        isNaN(otpToNum) === true ||
        values.otp.length === 0
      ) {
        message.error("Invalid OTP");
        setLoading(false);
        return;
      }
      const response: AuthResponse = await dispatch(
        authVerifyOTP({
          otpToNum,
          tenantNumber: workspace,
          emailAddress: getLocalStorage("emailAddress"),
        })
      );
      if (response.success) {
        navigate("/app");
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      console.error("Unexpected error during login:", error);
    }
  };

  const regenerateOTP = async () => {
    setSeconds(30);
    try {
      setDisable(true);
      const response: AuthResponse = await dispatch(
        authRegenerateOTP({
          emailAddress: getLocalStorage("emailAddress"),
          tenantNumber: workspace,
        })
      );
      if (response.success) {
        message.success("OTP Sent Succesfully !");
      }
    } catch (error: any) {
      console.error("Unexpected error during login:", error);
    }
  };

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [regenerateOTP]);

  return (
    <ErrorBoundary>
      <Card>
        <Paragraph strong style={{ textAlign: "center" }}>
          To help keep your account safe,
          <br />
          Acquis wants to make sure it’s really you trying to sign in.
        </Paragraph>
        <Form
          form={form}
          onFinish={onFinish}
          style={{ marginTop: "1.5rem", minWidth: 400 }}
        >
          <Form.Item
            name="otp"
            rules={[{ required: true, message: "Please input your OTP!" }]}
          >
            <Input placeholder="OTP" />
          </Form.Item>
          <Flex justify="space-between">
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
            <Button
              type="default"
              htmlType="button"
              onClick={regenerateOTP}
              {...(disable && { disabled: true })}
            >
              Resend OTP {disable && `( ${seconds} )`}
            </Button>
          </Flex>
        </Form>
        <br />
        <Paragraph strong style={{ textAlign: "center" }}>
          Acquis sent you an OTP via e-mail to verify it’s you.
        </Paragraph>
      </Card>
    </ErrorBoundary>
  );
};

export default Authentication;
