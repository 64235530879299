import React from "react";
import { Col, Row, Card } from "antd";
import ErrorBoundary from "../../../../utils/errorBoundary";

const RegulationOverview: React.FC = () => {
  return (
    <ErrorBoundary>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Card title="Some Insights">Coming Soon...</Card>
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

export default RegulationOverview;
