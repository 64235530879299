import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { getLocalStorage } from "../../utils/localStore";
import { ModulesState } from "../../utils/types/module";

/**
 * Bulk Operations
 */
export const listModules = (payload: any) => () => {
  return new Promise<ModulesState>((resolve, reject) => {
    const user = getLocalStorage("user");
    const { workspace } = user;
    privateGetApi(
      (workspace === "acquiscompliance" ? "/admins" : "/public") + "/modules",
      payload
    )
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createModule = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/admins/modules", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readModule = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/admins/modules/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateModule = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/admins/modules/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteModule = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/admins/modules/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
