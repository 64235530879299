import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import {
  setSuppliers,
  setSupplierComponents,
  setSupplierSmelters,
  setSupplierContacts,
} from "../slices/suppliersSlice";
import { Dispatch } from "redux";

/**
 * Bulk Operations
 */
export const listSuppliers = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<void>((resolve, reject) => {
    privateGetApi("/suppliers", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setSuppliers(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const listSuppliersFilters = (payload: any) => () => {
  return new Promise<void>((resolve, reject) => {
    privateGetApi("/suppliers/filters", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createSupplier = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/suppliers", payload)
      .then((response: any) => {
        if (response && response.status === 201 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readSupplier = (id: string, payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/suppliers/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readSupplierComponents =
  (payload: any) => (dispatch: Dispatch) => {
    return new Promise<void>((resolve, reject) => {
      privateGetApi("/suppliers/components/" + payload.id, payload)
        .then((response: any) => {
          if (response && response.status === 200 && response.data) {
            dispatch(setSupplierComponents(response.data.data));
            resolve(response.data.data);
          } else {
            reject();
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

export const readSupplierSmelters = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<void>((resolve, reject) => {
    privateGetApi("/suppliers/smelters/" + payload.id, payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          dispatch(setSupplierSmelters(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readSupplierContacts = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<void>((resolve, reject) => {
    privateGetApi("/suppliers/contacts/" + payload.id, payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          dispatch(setSupplierContacts(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const checkIfCampaignAlreadyRunning = (payload: any) => () => {
  return new Promise<void>((resolve, reject) => {
    privateGetApi("/suppliers/checkcampaign/" + payload.id, payload)
    .then((response) => {
      if (response && response.status === 200 && response.data) {
        resolve(response.data);
      } else {
        reject();
      }
    })
    .catch((error) => {
      reject(error);
    });
  });
};

export const updateSupplier = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/suppliers/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const generateSupplier = (payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/suppliers/generate", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSupplier = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/suppliers/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
