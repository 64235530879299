import React, { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";

import { useAppDispatch } from "../../redux/hooks";
import { deleteCampaigns } from "../../redux/actions/CampaignManager/campaignManagerAction";

const DeleteCampaign: React.FC<{
  fetchData: any;
  formData?: any;
}> = (props) => {
  const dispatch = useAppDispatch();

  const confirm = () => {
    dispatch(deleteCampaigns(props?.formData?._id))
      .then(() => {
        props.fetchData();
      })
      .catch((e) => console.log(e));
  };

  const cancel = () => {
    props.fetchData();
  };

  return (
    <div>
      <Popconfirm
        title="Delete Campaign"
        description={`Are you sure you want to delete this campaign ${props.formData?.name} ?`}
        onConfirm={confirm}
        onCancel={cancel}
        okText="Ok"
        cancelText="Cancel"
      >
        <Button
          type="text"
          icon={<DeleteOutlined />}
          data-testid="delete-campaign-button"
          title="Delete Campaign"
        ></Button>
      </Popconfirm>
    </div>
  );
};

export default DeleteCampaign;
