import React, { useState } from "react";
import { Modal, Button } from "antd";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { uploadConflictMineralsDocument } from "../../redux/actions/conflictMineralsAction";
import { getLocalStorage, setLocalStorage } from "../../utils/localStore";
import dayjs, { Dayjs } from "dayjs";
import { Tour } from "antd";
import type { TourProps } from "antd";
import { useRef } from "react";
import Conflict1 from "../../assets/supplier portal/conflict/1.png";
import Conflict2 from "../../assets/supplier portal/conflict/2.png";
import Conflict3 from "../../assets/supplier portal/conflict/3.png";
import Conflict4 from "../../assets/supplier portal/conflict/4.png";
import Conflict5 from "../../assets/supplier portal/conflict/5.png";
import Conflict6 from "../../assets/supplier portal/conflict/6.png";
import Conflict7 from "../../assets/supplier portal/conflict/7.png";
import Conflict8 from "../../assets/supplier portal/conflict/8.png";
import Conflict9 from "../../assets/supplier portal/conflict/9.png";
import Support1 from "../../assets/supplier portal/support/1.png";

interface ExcelModalProps {
  supplier: string;
  open: boolean;
  onCancel: () => void;
  importButtonRef?: any;
  showModal?: () => void;
  show?: any;
  setShow?: any;
}

interface Declaration {
  question: string;
  name: string;
  state: null | string;
  comments: null | string;
}

interface Policy {
  question: string;
  name: string;
  state: null | string;
  comments: null | string;
}
interface Smelter {
  number: string;
  metal: string;
  lookUp: string;
  sourceOfIdentificationNumber: string;
  address: {
    country: string;
    city: string;
    state: string;
  };
}
interface Products {
  manufacturer: {
    itemNumber: string;
    name: string;
  };
  comments: string;
}

interface FormGenerate {
  cmrtVersion: null | string;
  company: {
    name: string;
    declarationScope: string;
    declarationDescription: string;
    uniqueId: string;
    uniqueIdAuthority: string;
    address: string;
    contact: {
      name: string;
      emailAddress: string;
      phoneNumber: string;
    };
    authorizer: {
      name: string;
      title: string;
      emailAddress: string;
      phoneNumber: string;
      effectiveDate: Dayjs | null;
    };
  };
  declaration: Declaration[];
  declarationScope: Declaration[];
  policy: Policy[];
  smelter: Smelter[];
  products: Products[];
  rawFileName: string;
}

const ImportCMRT: React.FC<ExcelModalProps> = ({
  open,
  onCancel,
  supplier,
  importButtonRef,
  showModal,
  show,
  setShow,
}) => {
  const navigate = useNavigate();
  const [formGenerate, setFormGenerate] = useState<FormGenerate | null>(null);
  const [file, setfile] = useState<File | null>(null);
  const [fileReadError, setFileReadError] = useState<string | null>(null);

  const handleFileUpload = (file: File) => {
    setFileReadError(null);
    setfile(file);
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const data = e.target?.result;
      try {
        if (data) {
          const workbook = XLSX.read(data, { type: "binary" });
          const Revisionworksheet = workbook.Sheets["Revision"];

          const Declarationworksheet = workbook.Sheets["Declaration"];

          const Smelterworksheet = workbook.Sheets["Smelter List"];

          const Productworksheet = workbook.Sheets["Product List"];

          if (!Revisionworksheet["!ref"]) {
            return;
          }
          const Range = XLSX.utils.decode_range(Revisionworksheet["!ref"]);
          let secondLastValue;

          let nonEmptyCount = 0;
          for (let rowNum = Range.e.r; rowNum >= Range.s.r; rowNum--) {
            const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: 1 });
            const cell = Revisionworksheet[cellAddress];

            if (
              cell &&
              cell.v !== undefined &&
              cell.v !== null &&
              cell.v !== ""
            ) {
              nonEmptyCount++;

              if (nonEmptyCount === 2) {
                secondLastValue = cell.v;
                break;
              }
            }
          }

          if (!Smelterworksheet["!ref"]) {
            throw new Error("Worksheet range is not defined");
          }

          const range = XLSX.utils.decode_range(Smelterworksheet["!ref"]);

          const Smelterdata: Smelter[] = [];

          for (let rowNum = 4; rowNum <= range.e.r; rowNum++) {
            const row: Smelter = {
              number: "",
              metal: "",
              lookUp: "",
              sourceOfIdentificationNumber: "",
              address: {
                country: "",
                city: "",
                state: "",
              },
            };

            for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
              const cellAddress = { r: rowNum, c: colNum };
              const cellRef = XLSX.utils.encode_cell(cellAddress);
              const cell = Smelterworksheet[cellRef];

              if (cell && cell.v !== undefined) {
                switch (colNum) {
                  case 0:
                    row.number = cell.v;
                    break;
                  case 5:
                    if (row.number === "") {
                      row.number = cell.v;
                    }
                    break;
                  case 1:
                    row.metal = cell.v;
                    break;
                  case 2:
                    row.lookUp = cell.v;
                    break;
                  case 6:
                    row.sourceOfIdentificationNumber = cell.v;
                    break;
                  case 4:
                    row.address.country = cell.v;
                    break;
                  case 8:
                    row.address.city = cell.v;
                    break;
                  case 9:
                    row.address.state = cell.v;
                    break;
                  default:
                    break;
                }
              }
            }

            if (
              row.number ||
              row.metal ||
              row.lookUp ||
              row.address.country ||
              row.address.city ||
              row.address.state ||
              row.sourceOfIdentificationNumber
            ) {
              Smelterdata.push(row);
            } else {
              break;
            }
          }
          if (!Productworksheet["!ref"]) {
            throw new Error("Worksheet range is not defined");
          }

          const productrange = XLSX.utils.decode_range(
            Productworksheet["!ref"]
          );
          const Productdata: Products[] = [];
          for (let rowNum = 5; rowNum <= productrange.e.r; rowNum++) {
            const row: Products = {
              manufacturer: {
                itemNumber: "",
                name: "",
              },
              comments: "",
            };

            for (
              let colNum = productrange.s.c + 1;
              colNum <= productrange.e.c;
              colNum++
            ) {
              const cellAddress = { r: rowNum, c: colNum };
              const cellRef = XLSX.utils.encode_cell(cellAddress);
              const cell = Productworksheet[cellRef];

              if (cell && cell.v !== undefined) {
                if (colNum === 1) {
                  row.manufacturer.itemNumber = cell.v;
                } else if (colNum === 2) {
                  row.manufacturer.name = cell.v;
                } else if (colNum === 3) {
                  row.comments = cell.v;
                }
              } else {
                break;
              }
            }

            if (
              row.manufacturer.itemNumber ||
              row.manufacturer.name ||
              row.comments
            ) {
              Productdata.push(row);
            } else {
              break;
            }
          }
          const excelDateToJSDate = (excelDate: number): Date => {
            const date = new Date(Date.UTC(1900, 0, excelDate - 1));
            return date;
          };
          const isISO8601Format = (value: string): boolean => {
            return (
              /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/.test(value) ||
              /\d{4}-\d{2}-\d{2}/.test(value)
            );
          };
          const cellValue = Declarationworksheet["D22"]?.v || null;
          const capitalizeFirstLetter = (string: any) => {
            return (
              string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
            );
          };
          const extractedData = {
            cmrtVersion: secondLastValue || null,
            company: {
              name: Declarationworksheet["D8"]?.v?.trim() || null,
              declarationScope: Declarationworksheet["D9"]?.v?.trim() || null,
              declarationDescription:
                Declarationworksheet["D10"]?.v?.trim() || null,
              uniqueId: Declarationworksheet["D12"]?.v?.trim() || null,
              uniqueIdAuthority: Declarationworksheet["D13"]?.v?.trim() || null,
              address: Declarationworksheet["D14"]?.v?.trim() || null,
              contact: {
                name: Declarationworksheet["D15"]?.v?.trim() || null,
                emailAddress: Declarationworksheet["D16"]?.v?.trim() || null,
                phoneNumber: Declarationworksheet["D17"]?.v?.trim() || null,
              },
              authorizer: {
                name: Declarationworksheet["D18"]?.v?.trim() || null,
                title: Declarationworksheet["D19"]?.v?.trim() || null,
                emailAddress: Declarationworksheet["D20"]?.v?.trim() || null,
                phoneNumber: Declarationworksheet["D21"]?.v?.trim() || null,
                effectiveDate: isISO8601Format(cellValue)
                  ? dayjs(cellValue)
                  : dayjs(excelDateToJSDate(cellValue)),
              },
            },
            declaration: [
              {
                question:
                  "Is any 3TG intentionally added or used in the product(s) or in the production process?",
                name: "Tantalum",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D26"]?.v) || null,
                comments: Declarationworksheet["G26"]?.v || null,
              },
              {
                question:
                  "Is any 3TG intentionally added or used in the product(s) or in the production process?",
                name: "Tin",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D27"]?.v) || null,
                comments: Declarationworksheet["G27"]?.v || null,
              },
              {
                question:
                  "Is any 3TG intentionally added or used in the product(s) or in the production process?",
                name: "Gold",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D28"]?.v) || null,
                comments: Declarationworksheet["G28"]?.v || null,
              },
              {
                question:
                  "Is any 3TG intentionally added or used in the product(s) or in the production process?",
                name: "Tungsten",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D29"]?.v) || null,
                comments: Declarationworksheet["G29"]?.v || null,
              },
              {
                question: "Does any 3TG remain in the product(s)?",
                name: "Tantalum",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D32"]?.v) || null,
                comments: Declarationworksheet["G32"]?.v || null,
              },
              {
                question: "Does any 3TG remain in the product(s)?",
                name: "Tin",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D33"]?.v) || null,
                comments: Declarationworksheet["G33"]?.v || null,
              },
              {
                question: "Does any 3TG remain in the product(s)?",
                name: "Gold",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D34"]?.v) || null,
                comments: Declarationworksheet["G34"]?.v || null,
              },
              {
                question: "Does any 3TG remain in the product(s)?",
                name: "Tungsten",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D35"]?.v) || null,
                comments: Declarationworksheet["G35"]?.v || null,
              },
            ],
            declarationScope: [
              {
                question:
                  "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)",
                name: "Tantalum",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D38"]?.v) || null,
                comments: Declarationworksheet["G38"]?.v || null,
              },
              {
                question:
                  "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)",
                name: "Tin",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D39"]?.v) || null,
                comments: Declarationworksheet["G39"]?.v || null,
              },
              {
                question:
                  "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)",
                name: "Gold",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D40"]?.v) || null,
                comments: Declarationworksheet["G40"]?.v || null,
              },
              {
                question:
                  "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)",
                name: "Tungsten",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D41"]?.v) || null,
                comments: Declarationworksheet["G41"]?.v || null,
              },
              {
                question:
                  "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?",
                name: "Tantalum",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D44"]?.v) || null,
                comments: Declarationworksheet["G44"]?.v || null,
              },
              {
                question:
                  "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?",
                name: "Tin",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D45"]?.v) || null,
                comments: Declarationworksheet["G45"]?.v || null,
              },
              {
                question:
                  "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?",
                name: "Gold",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D46"]?.v) || null,
                comments: Declarationworksheet["G46"]?.v || null,
              },
              {
                question:
                  "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?",
                name: "Tungsten",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D47"]?.v) || null,
                comments: Declarationworksheet["G47"]?.v || null,
              },
              {
                question:
                  "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?",
                name: "Tantalum",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D50"]?.v) || null,
                comments: Declarationworksheet["G50"]?.v || null,
              },
              {
                question:
                  "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?",
                name: "Tin",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D51"]?.v) || null,
                comments: Declarationworksheet["G51"]?.v || null,
              },
              {
                question:
                  "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?",
                name: "Gold",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D52"]?.v) || null,
                comments: Declarationworksheet["G52"]?.v || null,
              },
              {
                question:
                  "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?",
                name: "Tungsten",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D53"]?.v) || null,
                comments: Declarationworksheet["G53"]?.v || null,
              },
              {
                question:
                  "What percentage of relevant suppliers have provided a response to your supply chain survey?",
                name: "Tantalum",
                state: Declarationworksheet["D56"]?.v || null,
                comments: Declarationworksheet["G56"]?.v || null,
              },
              {
                question:
                  "What percentage of relevant suppliers have provided a response to your supply chain survey?",
                name: "Tin",
                state: Declarationworksheet["D57"]?.v || null,
                comments: Declarationworksheet["G57"]?.v || null,
              },
              {
                question:
                  "What percentage of relevant suppliers have provided a response to your supply chain survey?",
                name: "Gold",
                state: Declarationworksheet["D58"]?.v || null,
                comments: Declarationworksheet["G58"]?.v || null,
              },
              {
                question:
                  "What percentage of relevant suppliers have provided a response to your supply chain survey?",
                name: "Tungsten",
                state: Declarationworksheet["D59"]?.v || null,
                comments: Declarationworksheet["G59"]?.v || null,
              },
              {
                question:
                  "Have you identified all of the smelters supplying the 3TG to your supply chain?",
                name: "Tantalum",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D62"]?.v) || null,
                comments: Declarationworksheet["G62"]?.v || null,
              },
              {
                question:
                  "Have you identified all of the smelters supplying the 3TG to your supply chain?",
                name: "Tin",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D63"]?.v) || null,
                comments: Declarationworksheet["G63"]?.v || null,
              },
              {
                question:
                  "Have you identified all of the smelters supplying the 3TG to your supply chain?",
                name: "Gold",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D64"]?.v) || null,
                comments: Declarationworksheet["G64"]?.v || null,
              },
              {
                question:
                  "Have you identified all of the smelters supplying the 3TG to your supply chain?",
                name: "Tungsten",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D65"]?.v) || null,
                comments: Declarationworksheet["G65"]?.v || null,
              },
              {
                question:
                  "Has all applicable smelter information received by your company been reported in this declaration?",
                name: "Tantalum",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D68"]?.v) || null,
                comments: Declarationworksheet["G68"]?.v || null,
              },
              {
                question:
                  "Has all applicable smelter information received by your company been reported in this declaration?",
                name: "Tin",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D69"]?.v) || null,
                comments: Declarationworksheet["G69"]?.v || null,
              },
              {
                question:
                  "Has all applicable smelter information received by your company been reported in this declaration?",
                name: "Gold",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D70"]?.v) || null,
                comments: Declarationworksheet["G70"]?.v || null,
              },
              {
                question:
                  "Has all applicable smelter information received by your company been reported in this declaration?",
                name: "Tungsten",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D71"]?.v) || null,
                comments: Declarationworksheet["G71"]?.v || null,
              },
            ],
            policy: [
              {
                question:
                  "A. Have you established a responsible minerals sourcing policy?",
                name: "Point A",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D75"]?.v) || null,
                comments: Declarationworksheet["G75"]?.v || null,
              },
              {
                question:
                  "B. Is your responsible minerals sourcing policy publicly available on your website? (Note – If yes, the user shall specify the URL in the comment field.)",
                name: "Point B",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D77"]?.v) || null,
                comments: Declarationworksheet["G77"]?.v || null,
              },
              {
                question:
                  "C. Do you require your direct suppliers to source the 3TG from smelters whose due diligence practices have been validated by an independent third party audit program?",
                name: "Point C",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D79"]?.v) || null,
                comments: Declarationworksheet["G79"]?.v || null,
              },
              {
                question:
                  "D. Have you implemented due diligence measures for responsible sourcing?",
                name: "Point D",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D81"]?.v) || null,
                comments: Declarationworksheet["G81"]?.v || null,
              },
              {
                question:
                  "E. Does your company conduct Conflict Minerals survey(s) of your relevant supplier(s)?",
                name: "Point E",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D83"]?.v) || null,
                comments: Declarationworksheet["G83"]?.v || null,
              },
              {
                question:
                  "F. Do you review due diligence information received from your suppliers against your company’s expectations?",
                name: "Point F",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D85"]?.v) || null,
                comments: Declarationworksheet["G85"]?.v || null,
              },
              {
                question:
                  "G. Does your review process include corrective action management?",
                name: "Point G",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D87"]?.v) || null,
                comments: Declarationworksheet["G87"]?.v || null,
              },
              {
                question:
                  "H. Is your company required to file an annual conflict minerals disclosure with the SEC?",
                name: "Point H",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D89"]?.v) || null,
                comments: Declarationworksheet["G89"]?.v || null,
              },
            ],
            smelter: Smelterdata,
            products: Productdata,
            rawFileName: file.name,
          };

          setFormGenerate(extractedData);
        }
      } catch (error) {
        setFileReadError(
          `We are unable to read the file and extract CMRT. Please try fixing errors and re-upload. ${
            (error as Error).message
          }`
        );
      }
    };
    fileReader.onerror = (event) => {
      setFileReadError(
        `Error reading the file: ${
          fileReader.error?.message || "Unknown error"
        }`
      );
    };
    fileReader.readAsBinaryString(file);
  };
  const fetchData = async () => {
    try {
      if (file) {
        await uploadConflictMineralsDocument({
          file: file,
          foldername: "conflict-minerals",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick = () => {
    if (getLocalStorage("accessToken")) {
      fetchData();
      setLocalStorage("supplier", supplier);
      setLocalStorage("conflictMinerals", formGenerate);
      return navigate("../generator", { replace: true });
    } else {
      setLocalStorage("supplier", supplier);
      setLocalStorage("conflictMinerals", formGenerate);
      return navigate("generator");
    }
  };

  const handleCancel = () => {
    setfile(null);
    const inputElement = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement | null;
    if (inputElement) {
      inputElement.value = "";
    }
    setFileReadError(null);
    onCancel();
  };
  const inputRef = useRef(null);
  const continueRef = useRef(null);

  const steps: TourProps["steps"] = [
    {
      title:
        "Select 'IMPORT' to upload the most recent reporting template file, as demonstrated further for your convenience.",
      description: "1/12",
      target: () => importButtonRef?.current,
      placement: "right",
      nextButtonProps: { children: "Next", onClick: showModal },
    },
    {
      title:
        "This example illustrates how to upload a CMRT, and the process will be similar for Extended Minerals as well.",
      description: "2/12",
      cover: <img alt="" src={Conflict1} />,
      placement: "center",
      prevButtonProps: { children: "Previous", onClick: onCancel },
    },
    {
      title: "Click here to attach the latest CMRT file.",
      description: "3/12",
      cover: <img alt="" src={Conflict1} />,
      target: () => inputRef?.current,
      placement: "left",
    },
    {
      title: "And click on this continue button.",
      description: "4/12",
      target: () => continueRef?.current,
      placement: "left",
    },
    {
      title:
        "After the CMRT file is successfully imported, tool will capture the information automatically from your file and you will see a message “Submit & Close”.",
      description: "5/12",
      cover: <img alt="" src={Conflict2} />,
      placement: "center",
      nextButtonProps: { children: "Next", onClick: onCancel },
    },
    {
      title:
        "Click 'Submit & Close' OR if you wish to review the data before submitting, click on the tabs as shown above. Further screen shots are examples for your easy reference.",
      description: "6/12",
      cover: <img alt="" src={Conflict3} />,
      placement: "center",
      prevButtonProps: { children: "Previous", onClick: showModal },
    },
    {
      title: "The above screenshot shows the Company Information Tab.",
      description: "7/12",
      cover: <img alt="" src={Conflict4} />,
      placement: "center",
      prevButtonProps: { children: "Previous", onClick: showModal },
    },
    {
      title: "This is the Declaration Tab.",
      description: "8/12",
      cover: <img alt="" src={Conflict5} />,
      placement: "center",
      prevButtonProps: { children: "Previous", onClick: showModal },
    },
    {
      title: "This is the Declaration Scope Tab.",
      description: "9/12",
      cover: <img alt="" src={Conflict6} />,
      placement: "center",
      prevButtonProps: { children: "Previous", onClick: showModal },
    },
    {
      title: "This is the Policy Tab.",
      description: "10/12",
      cover: <img alt="" src={Conflict7} />,
      placement: "center",
      prevButtonProps: { children: "Previous", onClick: showModal },
    },
    {
      title: "And this is the Smelter List Tab.",
      description: "11/12",
      cover: <img alt="" src={Conflict8} />,
      placement: "center",
      prevButtonProps: { children: "Previous", onClick: showModal },
    },
    {
      title: "Once you have verified the CMRT data click on 'Submit & Close'.",
      description: "11/12",
      cover: <img alt="" src={Conflict9} />,
      placement: "center",
      prevButtonProps: { children: "Previous", onClick: showModal },
    },
    {
      title: "IMPORTANT",
      description:
        "Ensure that all the required information is uploaded. If you encounter any issues, please don't hesitate to contact our support team via phone or email. We are here to assist you in completing this process.",
      cover: <img alt="" src={Support1} />,
      placement: "center",
      prevButtonProps: { children: "Previous", onClick: showModal },
    },
  ];
  return (
    <>
      <Tour
        open={show}
        onClose={() => setShow(false)}
        steps={steps}
        disabledInteraction={true}
      />
      <Modal
        open={open}
        centered
        title="Upload CMRT File"
        onCancel={handleCancel}
        footer={[
          <Button
            key="onCancel"
            onClick={handleCancel}
            data-testid="cancel-import-cmrt-button"
          >
            Cancel
          </Button>,
          <Button
            key="continue"
            type="primary"
            onClick={handleClick}
            disabled={
              !file ||
              parseInt(formGenerate?.cmrtVersion ?? "") !== 6 ||
              fileReadError !== null
            }
            data-testid="continue-import-cmrt-button"
            ref={continueRef}
          >
            Continue
          </Button>,
        ]}
        {...formGenerate}
      >
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={(e) => {
            const selectedFile = e.target.files && e.target.files[0];
            if (selectedFile) {
              handleFileUpload(selectedFile);
            }
          }}
          ref={inputRef}
        />
        {fileReadError && (
          <p style={{ color: "red" }}>
            <br></br>
            {fileReadError}
          </p>
        )}{" "}
        {formGenerate?.cmrtVersion && file && !fileReadError && (
          <p>
            <span>
              {`Document version ${formGenerate.cmrtVersion} is ${
                6 >= parseFloat(formGenerate.cmrtVersion) ? "not" : ""
              } supported.`}
            </span>
            <br></br>
            CMRT version. 6.31 or higher is Recommended for the Reporting Year.
          </p>
        )}
      </Modal>
    </>
  );
};

export default ImportCMRT;
