import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Typography, Table } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { useAppDispatch } from "../../../../redux/hooks";
import { listRegulationSubtances } from "../../../../redux/actions/regulationsAction";
import { getLocalStorage } from "../../../../utils/localStore";
import { ExportOutlined } from "@ant-design/icons";
import SubstanceForm from "../../../../components/forms/SubstanceForm";
import ErrorBoundary from "../../../../utils/errorBoundary";

const { Text } = Typography;

interface SubstanceProps {
  regulationNumber: string;
  search?: string;
}
export interface SubstanceHandle {
  focusInput: () => void;
}

const RegulationSubstances = forwardRef<SubstanceHandle, SubstanceProps>(
  (props, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      focusInput: () => {
        if (inputRef.current) {
          inputRef.current.focus();
          fetchData();
        }
      },
    }));

    const dispatch = useAppDispatch();
    const [substances, setSubstances] = useState<any[]>([]);
    const [substanceCount, setSubstanceCount] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [tablePagination, setTablePagination] =
      useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
      });
    const [tableSorter, setTableSorter] = useState<SorterResult<any>>({});
    const [tableFilters, setTableFilters] =
      useState<Record<string, FilterValue | null>>();

    const { tenantNumber } = getLocalStorage("user");
    const permissions = getLocalStorage("role")
      ? getLocalStorage("role").permissions
      : null;
    let substanceHeaders;
    substances.forEach((item: any) => {
      const key = Object.keys(item);
      const deleteValue = [
        "_id",
        "createdBy",
        "createdAt",
        "createdVia",
        "importRecord",
        "softDelete",
        "regulationNumber",
        "tenantNumber",
        "substance",
        "ecNumber",
        "casNumber",
        "synonyms",
        "casSynonyms",
        "ecSynonyms",
        "updatedAt",
        "authorised",
        "substanceSynonyms",
      ];
      substanceHeaders = key.filter((e) => !deleteValue.includes(e));
    });

    const onClickViewLink = (filename: string) => {
      window.open(filename, "_blank");
    };

    const columns: ColumnsType<any> = [
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 150,
        hidden: tenantNumber !== "acquiscompliance" ? true : false,
        render: (_: string, record: any) => (
          <>
            {permissions?.update.includes("substances") && (
              <SubstanceForm
                type="edit"
                formData={record}
                fetchData={fetchData}
                id={props.regulationNumber}
              />
            )}
          </>
        ),
      },
      {
        title: "Substance",
        dataIndex: "substance",
        key: "substance",
        width: 200,
        hidden: false,
      },
      {
        title: "EC Number",
        dataIndex: "ecNumber",
        key: "ecNumber",
        width: 150,
        hidden:
          substances.some((e: any) => !e.ecNumber) ||
          props.regulationNumber === "ca_prop_65"
            ? true
            : false,
      },
      {
        title: "CAS Number",
        dataIndex: "casNumber",
        key: "casNumber",
        width: 150,
        hidden: substances.some((e: any) => !e.casNumber),
      },
      {
        title: "Substance Synonyms",
        dataIndex: "substanceSynonyms",
        key: "substanceSynonyms",
        width: 150,
        hidden: substances.some((e: any) => !e.substanceSynonyms),
      },
      {
        title: "CAS Synonyms",
        dataIndex: "casSynonyms",
        key: "casSynonyms",
        width: 150,
        hidden: substances.some((e: any) => !e.casSynonyms),
      },
      {
        title: "EC Synonyms",
        dataIndex: "ecSynonyms",
        key: "ecSynonyms",
        width: 150,
        hidden: substances.some((e: any) => !e.ecSynonyms),
      },
      ...(substanceHeaders || []).map((e: any) => ({
        title: e.charAt(0).toUpperCase() + e.slice(1),
        dataIndex: e,
        key: e,
        width: 150,
        hidden: false,
        render: (value: any) => {
          if (typeof value === "string") {
            if (value?.startsWith("<") && value?.endsWith(">")) {
              return (
                <div
                  style={{
                    maxWidth: 200,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: value.replace(/<a\s/g, '<a target="_blank" '),
                    }}
                  />
                </div>
              );
            } else if (value?.startsWith("http") || value?.startsWith("www")) {
              return (
                <span>
                  <ExportOutlined onClick={() => onClickViewLink(value)} /> Link
                </span>
              );
            } else if (value?.length > 50) {
              return (
                <Text style={{ width: 200 }} ellipsis={{ tooltip: value }}>
                  {value}
                </Text>
              );
            } else {
              return <Text>{value}</Text>;
            }
          } else if (value?.length > 50) {
            return (
              <Text style={{ width: 200 }} ellipsis={{ tooltip: value }}>
                {value}
              </Text>
            );
          } else {
            return <Text>{value}</Text>;
          }
        },
      })),
      {
        title: "Created",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 250,
        render: (text: string, record: any) => (
          <Text>
            {new Date(text).toLocaleString()}
            <br />
            {record.createdBy}
          </Text>
        ),
        hidden: tenantNumber !== "acquiscompliance" ? true : false,
      },
    ].filter((e: any) => !e.hidden);

    const fetchData = (payload?: any) => {
      setLoading(true);
      dispatch(
        listRegulationSubtances({
          pageSize:
            props.search && props.search?.length > 0
              ? 10
              : tablePagination.pageSize,
          pageNum:
            props.search && props.search?.length > 0
              ? 1
              : tablePagination.current,
          sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
          sortDesc: tableSorter.column
            ? tableSorter.order === "descend"
              ? false
              : true
            : false,
          filters: payload
            ? JSON.stringify({
                ...payload,
                regulationNumber: props.regulationNumber,
              })
            : JSON.stringify({
                regulationNumber: props.regulationNumber,
              }),
        })
      )
        .then((response) => {
          setSubstances(response.substances);
          setSubstanceCount(response.substanceCount);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    };

    const handleTableChange: TableProps<any>["onChange"] = (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[]
    ) => {
      setTablePagination(pagination);
      setTableFilters(filters);
      setTableSorter(sorter as SorterResult<any>);

      if (pagination.pageSize !== tablePagination.pageSize) {
        setSubstances([]);
      }
    };

    useEffect(() => {
      const escapeRegex = (str: string): string => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      };

      const escapeRegexWithOptionalParentheses = (str: string): string => {
        return str
          .split("")
          .map((char) => escapeRegex(char) + "[()]*")
          .join("");
      };

      let query;

      if (props.search === undefined || props.search === "") {
        // If search field is empty, list all elements
        query = { softDelete: false };
      } else {
        const regexSubstance = {
          substance: {
            $regex: escapeRegex(props.search),
            $options: "i",
          },
        };

        const regexSubstanceWithParentheses = {
          substance: {
            $regex: escapeRegexWithOptionalParentheses(props.search),
            $options: "i",
          },
        };

        const regexCasNumber = {
          casNumber: {
            $regex: escapeRegex(props.search),
            $options: "i",
          },
        };

        const regexCasNumberWithParentheses = {
          casNumber: {
            $regex: escapeRegexWithOptionalParentheses(props.search),
            $options: "i",
          },
        };

        const regexEcNumber = {
          ecNumber: {
            $regex: escapeRegex(props.search),
            $options: "i",
          },
        };

        const regexEcNumberWithParentheses = {
          ecNumber: {
            $regex: escapeRegexWithOptionalParentheses(props.search),
            $options: "i",
          },
        };

        query = {
          softDelete: false,
          $or: [
            regexSubstance,
            regexSubstanceWithParentheses,
            regexCasNumber,
            regexCasNumberWithParentheses,
            regexEcNumber,
            regexEcNumberWithParentheses,
          ],
        };
      }

      fetchData(query);
    }, [
      JSON.stringify(tablePagination),
      JSON.stringify(tableFilters),
      JSON.stringify(tableSorter),
      props.search,
    ]);

    return (
      <div ref={inputRef}>
        <ErrorBoundary>
          <Table
            loading={loading}
            dataSource={substances}
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            columns={columns}
            rowKey="_id"
            pagination={{
              ...tablePagination,
              total: substanceCount,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
          />
        </ErrorBoundary>
      </div>
    );
  }
);

RegulationSubstances.displayName = "RegulationSubstances";

export default RegulationSubstances;
