import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
} from "../../utils/apiHelper";
import { ImportState } from "../../utils/types/import";

/**
 * Bulk Operations
 */
export const listFileRecords = (payload: any) => () => {
  return new Promise<ImportState>((resolve, reject) => {
    privateGetApi("/filerecords", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createFileRecord = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/filerecords", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateFileRecord = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/filerecords/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Other Operations
 */
export const readHeaders = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/filerecords/headers", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const startImport = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/filerecords/start-import", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
