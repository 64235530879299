import React, { useEffect, useState } from "react";
import { Space, Typography, Input, Table, Tag } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Import } from "../../../utils/types/import";
import { useAppDispatch } from "../../../redux/hooks";
import { listFileRecords } from "../../../redux/actions/importDataAction";

import ImportDataForm from "../../../components/forms/ImportDataForm";
import SupportingDocumentsForm from "../../../components/forms/SupportingDocumentsForm";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Title, Text } = Typography;
const { Search } = Input;

const ImportData: React.FC = () => {
  const dispatch = useAppDispatch();
  const [imports, setImports] = useState<Import[]>([]);
  const [importCount, setImportCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Import>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();

  const columns: ColumnsType<Import> = [
    {
      title: "Action",
      key: "_id",
      width: 150,
      render: (_, record: Import) => (
        <Space>
          <ImportDataForm
            type="update"
            fetchData={fetchData}
            formData={record}
            disabled={
              record.status === "complete" || record.status === "processing"
            }
          />
        </Space>
      ),
    },
    {
      title: "File",
      dataIndex: "filename",
      key: "filename",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (text: string) => {
        let color = "blue";
        if (text === "complete") {
          color = "green";
        } else if (text === "initiated") {
          color = "orange";
        } else if (text === "failed") {
          color = "red";
        }
        return <Tag color={color}>{text.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Total",
      dataIndex: "totalRecords",
      key: "totalRecords",
      render: (text: number, record: any) =>
        record.status === "complete" && (
          <>
            <Tag color="green">
              {record.successRecords} out of {text}
            </Tag>
            {record.errorRecords > 0 && (
              <Tag color="red">{record.errorRecords} errors</Tag>
            )}
            {record.duplicateRecords > 0 && (
              <Tag color="orange">{record.duplicateRecords} duplicates</Tag>
            )}
          </>
        ),
    },
    {
      title: "Module",
      dataIndex: "importType",
      key: "importType",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: any) => (
        <Text>
          {new Date(text).toLocaleString()}
          <br />
          {record.createdBy}
        </Text>
      ),
    },
  ];

  const fetchData = (payload?: any) => {
    setLoading(true);
    dispatch(
      listFileRecords({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        filters: payload ? JSON.stringify(payload) : null,
      })
    )
      .then((response) => {
        setImports(response.imports);
        setImportCount(response.importCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Import>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Import> | SorterResult<Import>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Import>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setImports([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  return (
    <>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Imports
            </Title>
          </div>
          <Space>
            <Search
              placeholder="Search using filename or status or module"
              allowClear
              onSearch={(text) =>
                fetchData({
                  softDelete: false,
                  $or: [
                    { filename: { $regex: text, $options: "i" } },
                    { status: { $regex: text, $options: "i" } },
                    { importType: { $regex: text, $options: "i" } },
                  ],
                })
              }
              style={{ width: 350 }}
            />
            <ImportDataForm type="create" fetchData={fetchData} />
            <SupportingDocumentsForm />
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={imports}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: importCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </>
  );
};

export default ImportData;
