import React, { useEffect, useState } from "react";
import { Table, Space, Button, Tag, Typography, Tooltip } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";
import { useParams } from "react-router-dom";

import { Component } from "../../../utils/types/component";
import { useAppDispatch } from "../../../redux/hooks";
import {
  fetchComponents,
  fetchRegulation,
} from "../../../redux/actions/supplierPortalAction";
import ReadSupportingDocument from "../../../components/modals/ReadSupportingDocuments";
import ComplianceForm from "../../../components/forms/ComplianceForm";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Title, Text } = Typography;

const CampaignRegulations: React.FC = () => {
  const { uniqueName, linkCampaignId, linkTenantNumber, regulationNumber } =
    useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [regulation, setRegulation] = useState<any>(null);
  const [components, setComponents] = useState<any>([]);
  const [componentCount, setComponentCount] = useState<number>(0);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Component>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [search, setSearch] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedComponents, setSelectedComponents] = useState<Component[]>([]);
  const [show, setShow] = useState<boolean>(false);

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      fetchComponents({
        name: uniqueName,
        regulationNumber: regulationNumber,
        campaignId: window.atob(linkCampaignId!),
        tenantNumber: window.atob(linkTenantNumber!),
        page: tablePagination.current,
        limit: tablePagination.pageSize,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        filters: search ? search : null,
      })
    )
      .then((response) => {
        setComponents(response.components);
        setComponentCount(response.componentsCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Component>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Component> | SorterResult<Component>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Component>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      // setComponents([]);
    }
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Component[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedComponents(selectedRows);
  };
  const rowSelection: TableRowSelection<Component> = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  useEffect(() => {
    dispatch(
      fetchRegulation({
        regulationNumber: regulationNumber,
        tenantNumber: window.atob(linkTenantNumber!),
      })
    ).then((response) => {
      setRegulation(response);
    });
    fetchData();
  }, [regulationNumber]);

  useEffect(() => {
    fetchData(JSON.stringify(search));
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    JSON.stringify(search),
  ]);

  return (
    <>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Title level={5} style={{ marginBottom: 0 }}>
            {regulation && regulation.name}
          </Title>
          {!show && regulation && !selectedRowKeys.length && (
            <Button
              type="primary"
              onClick={() => {
                setShow(true);
              }}
            >
              Begin Tour 
            </Button>
          )}
          {regulation && selectedRowKeys.length ? (
            <ComplianceForm
              type="bulk"
              campaignId={window.atob(linkCampaignId!)}
              formRegulations={{
                name: regulation.name,
                regulationNumber: regulation.regulationNumber,
                configuration: regulation.configuration.map((e: any) => ({
                  ...e,
                  regulationNumber: regulation.regulationNumber,
                })),
              }}
              formData={selectedComponents}
              fetchData={fetchData}
              onChildClick={() => console.log("clicked")}
            />
          ) : null}
        </Space>
        {regulation && (
          <Table
            loading={loading}
            dataSource={components}
            scroll={{
              x:
                regulation.configuration.filter((e: any) => !e.hidden).length *
                210,
              y: "calc(100vh - 285px)",
            }}
            columns={[
              {
                title: "Action",
                key: "operation",
                width: 150,
                render: (_, record) => (
                  <Space>
                    {regulationNumber && !selectedRowKeys.length && (
                      <ComplianceForm
                        type="update"
                        campaignId={window.atob(linkCampaignId!)}
                        formRegulations={{
                          name: regulation.name,
                          regulationNumber: regulation.regulationNumber,
                          configuration: regulation.configuration.map(
                            (e: any) => ({
                              ...e,
                              regulationNumber: regulation.regulationNumber,
                            })
                          ),
                        }}
                        formData={[record]}
                        showTourGuide={record._id === components[0]._id}
                        fetchData={fetchData}
                        onChildClick={() => console.log("clicked")}
                        show={show}
                        setShow={setShow}
                      />
                    )}
                  </Space>
                ),
              },
              ...regulation.configuration
                .filter((e: any) => !e.hidden)
                .map((k: any, i: any) => ({
                  title: `${k.text}`,
                  dataIndex: `${k.value}`,
                  key: `${k.value}`,
                  render: (value: any, record: any) => {
                    if (k.value === "internalItemNumber") {
                      return <Text>{`${record?.internalItemNumber}`}</Text>;
                    }
                    if (k.value === "manufacturer") {
                      return (
                        <Text>
                          {value?.itemNumber}
                          <br></br>
                          <small>{value?.name}</small>
                        </Text>
                      );
                    } else if (k.value === "complianceDocument") {
                      return value ? (
                        <ReadSupportingDocument
                          postUrl="public/suppliers/document/download"
                          filename={value}
                          foldername="campaign"
                          campaignId={window.atob(linkCampaignId!)}
                        />
                      ) : null;
                    } else if (
                      k.value.includes("complianceOutputs.compliant")
                    ) {
                      if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "YES"
                      ) {
                        return <Tag color="green"> YES </Tag>;
                      } else if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "NO"
                      ) {
                        return <Tag color="red"> NO </Tag>;
                      } else if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "YES WITH EXEMPTION"
                      ) {
                        return <Tag color="orange"> YES WITH EXEMPTION </Tag>;
                      } else if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "UNKNOWN"
                      ) {
                        return <Tag color="default"> UNKNOWN </Tag>;
                      } else if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant
                      ) {
                        return record && record.complianceOutputs ? (
                          <Tag color="grey">
                            {record &&
                              record.complianceOutputs &&
                              record.complianceOutputs.compliant}
                          </Tag>
                        ) : (
                          ""
                        );
                      } else {
                        return (
                          record &&
                          record.complianceOutputs &&
                          record.complianceOutputs.compliant
                        );
                      }
                    } else if (k.value.includes("complianceOutputs.version")) {
                      return (
                        <Text>{`${
                          record &&
                          record.complianceOutputs &&
                          record.complianceOutputs.version
                            ? record.complianceOutputs.version
                            : ""
                        }`}</Text>
                      );
                    } else if (
                      k.value.includes("complianceOutputs.exemption")
                    ) {
                      return (
                        <Text
                          style={{ width: 200 }}
                          ellipsis={{
                            tooltip: record?.complianceOutputs?.exemption,
                          }}
                        >
                          {record &&
                          record.complianceOutputs &&
                          record.complianceOutputs.exemption
                            ? record.complianceOutputs.exemption.toString()
                            : ""}
                        </Text>
                      );
                    } else if (
                      k.value.includes("complianceOutputs.substance")
                    ) {
                      return (
                        <Text
                          style={{ width: 200 }}
                          ellipsis={{
                            tooltip: record?.complianceOutputs?.substance,
                          }}
                        >
                          {record &&
                          record.complianceOutputs &&
                          record.complianceOutputs.substance
                            ? record.complianceOutputs.substance.toString()
                            : ""}
                        </Text>
                      );
                    } else {
                      return (
                        <Text>
                          {record && record?.complianceOutputs
                            ? record?.complianceOutputs[
                                k.value?.split(".").pop().toString()
                              ]
                            : ""}
                        </Text>
                      );
                    }
                  },
                })),
              {
                title: "Status",
                key: "status",
                dataIndex: "status",
                render: (text: any) =>
                  text && (
                    <Tag
                      color={
                        text === ""
                          ? "blue"
                          : text === "pending"
                          ? "orange"
                          : "green"
                      }
                    >
                      {text === "" ? "INITIATED" : text.toUpperCase()}
                    </Tag>
                  ),
              },
            ]}
            rowSelection={rowSelection}
            rowKey="_id"
            pagination={{
              ...tablePagination,
              total: componentCount,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
          />
        )}
      </ErrorBoundary>
    </>
  );
};

export default CampaignRegulations;
