import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TenantsState, Tenant } from "../../utils/types/tenant";

const initialState: TenantsState = {
  tenants: [],
  tenantCount: 0,
};

export const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    setTenants: (
      state,
      action: PayloadAction<{ tenants: Tenant[]; count: number }>
    ) => {
      state.tenants = action.payload.tenants;
      state.tenantCount = action.payload.count;
    },
  },
});

export const { setTenants } = tenantsSlice.actions;

export default tenantsSlice.reducer;
