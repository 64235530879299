import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Typography,
  Row,
  Col,
  Space,
  Result,
} from "antd";
import { getLocalStorage } from "../../utils/localStore";
import { ArrowRightOutlined } from "@ant-design/icons";

import { useAppDispatch } from "../../redux/hooks";
import { sendRequest } from "../../redux/actions/supplierPortalAction";

const { Text } = Typography;

const SupplierExpireContactForm: React.FC<{
  tenantNumber: string;
  type?: string;
}> = ({ tenantNumber, type }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const onFormSubmit = (values: any) => {
    setLoading(true);
    const campaignData = getLocalStorage("campaignData");
    dispatch(
      sendRequest({
        receiver: [
          campaignData.sendTo,
          `${tenantNumber}@data.acquiscompliance.com`,
        ],
        ...values,
      })
    )
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        setSuccess(false);
        setLoading(false);
      });
  };
 
  return (
    <>
      {type === "support" && (
        <Button
          type="text"
          icon={<ArrowRightOutlined />}
          onClick={() => setIsModalOpen(true)}
        />
      )}
      <Modal
        title={
          type === "support"
            ? "Contact Support"
            : success
            ? null
            : "Campaign Ended"
        }
        footer={
          !success && (
            <Button type="primary" onClick={form.submit} loading={loading}>
              Send Mail
            </Button>
          )
        }
        open={type === "support" ? isModalOpen : true}
        maskClosable={type === "support"}
        closable={type === "support"}
        onCancel={onCancel}
      >
        {success ? (
          <Result
            status="success"
            title={
              !type ? "Reactivation request sent!" : "Support request sent"
            }
            subTitle={
              !type
                ? "The administrator has received your request, once the link is reactivated the administrator will contact you soon."
                : "The administrator has received your request, the administrator will contact you soon."
            }
          />
        ) : (
          <Space direction="vertical" size="large">
            {!type && (
              <Text>
                Campaign link is expired, please contact the administrator to
                reactivate this link.
              </Text>
            )}

            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
              <Row gutter={16} justify="center">
                <Col span={12}>
                  <Form.Item
                    label="Your Name"
                    name="name"
                    rules={[{ required: true, message: "Name is required" }]}
                  >
                    <Input placeholder="Your Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Your Email"
                    name="sender"
                    rules={[
                      { required: true, message: "Email address is required" },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input type="email" placeholder="Your Email" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is required" }]}
                  >
                    <Input placeholder="Subject" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Message"
                    name="message"
                    rules={[{ required: true, message: "Message is required" }]}
                  >
                    <Input.TextArea placeholder="Your Message" rows={4} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Space>
        )}
      </Modal>
    </>
  );
};

export default SupplierExpireContactForm;
