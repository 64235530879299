import React, { FC, useEffect, useState } from "react";
import { Card, Spin, Typography } from "antd";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Chart from "./Chart";
import { getLocalStorage } from "../../../../utils/localStore";

const { Text } = Typography;

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const contentStyle: React.CSSProperties = {
  padding: 50,
  background: "rgba(0, 0, 0, 0.05)",
  borderRadius: 4,
};
const content = <div style={contentStyle} />;

interface Statistic {
  label: string;
  value: number;
  color: string;
}

interface Regulation {
  number: string;
  name: string;
  description: string;
  substances: any[];
  exemptions: any[];
  completedStatus: string;
  compliantStatus: string;
  nonCompliantStatus: string;
  statistics: Statistic[];
}

interface DataSource {
  chart: {
    caption: string;
    yaxisname: string;
    xaxisname: string;
    formatnumberscale: string;
    plottooltext: string;
    theme: string;
    drawcrossline: string;
    showvalues: string;
    stack100percent: string;
    divlinecolor: string;
    divlinealpha: string;
    legendbgcolor: string;
    legendborderalpha: string;
    plotspacepercent: string;
    useRoundEdges?: string;
    plotfillangle?: string;
  };
  categories: { category: { label: string }[] }[];
  dataset: {
    seriesname: string;
    color: string;
    data: { value: string; showvalue?: string }[];
  }[];
}

const ProgressChart: FC<{
  loading: boolean;
  regulation: Regulation[] | null;
  materials: any;
}> = ({ loading, regulation, materials }) => {
  const [dataSource, setDataSource] = useState<DataSource | null>(null);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  useEffect(() => {
    if (
      !loading &&
      regulation &&
      materials &&
      regulation?.length > 0 &&
      materials?.length > 0
    ) {
      const filteredRegulations = regulation.filter(
        (reg) => reg.statistics.length > 0
      );
      const analytics = [
        ...filteredRegulations,
        ...(permissions?.read.includes("materials") ? [materials[0]] : []),
      ];
      const newDataSource: DataSource = {
        chart: {
          caption: "Progress",
          yaxisname: "",
          xaxisname: "",
          formatnumberscale: "1",
          plottooltext: "<b>$dataValue</b> of $seriesName",
          theme: "fusion",
          drawcrossline: "1",
          showvalues: "1",
          stack100percent: "1",
          divlinecolor: "#ffffff",
          divlinealpha: "0",
          legendbgcolor: "#ffffff",
          legendborderalpha: "0",
          plotspacepercent: "50",
          useRoundEdges: "1",
          plotfillangle: "90",
        },
        categories: [
          {
            category: analytics?.map((reg) => ({ label: reg?.name })),
          },
        ],
        dataset: [],
      };

      // Collect all unique labels and their colors
      const uniqueLabels: { [key: string]: string } = {};
      analytics.forEach((reg) => {
        reg.statistics?.forEach((stat: any) => {
          if (!uniqueLabels[stat.label]) {
            uniqueLabels[stat.label] = stat.color || "#F39237";
          }
        });
      });

      // Initialize dataset series for each unique label
      for (const label in uniqueLabels) {
        newDataSource.dataset.push({
          seriesname: label,
          color: uniqueLabels[label],
          data: analytics.map((reg) => {
            const stat = reg.statistics.find(
              (stat: any) => stat.label === label
            );
            const value = stat ? stat.value.toString() : "0";
            return value === "0" ? { value, showvalue: "0" } : { value };
          }),
        });
      }
      // Check if all values are 0 for only one statistic and set it to 100%
      const hasAllZeroValues = (values: number[]) =>
        values.every((value) => value === 0);

      if (Object.keys(uniqueLabels).length === 1) {
        const onlyLabel = Object.keys(uniqueLabels)[0];
        const allValues = analytics.flatMap((reg) =>
          reg.statistics
            .filter((stat: any) => stat.label === onlyLabel)
            .map((stat: any) => stat.value)
        );

        if (hasAllZeroValues(allValues)) {
          newDataSource.dataset.forEach((data) => {
            data.data = data.data.map(() => ({ value: "100" }));
          });
        }
      }

      setDataSource(newDataSource);
    } else {
      setDataSource(null); // Clear the dataSource if no regulations are available
    }
  }, [loading, regulation]);

  return loading ? (
    <Spin tip="Loading" size="large">
      {content}
    </Spin>
  ) : (
    <Card title="Progress" style={{ width: "100%" }}>
      {dataSource && dataSource?.dataset?.length > 0 ? (
        <Chart dataSource={dataSource} chartType="stackedbar3d" />
      ) : (
        <Text>No data to display.</Text>
      )}
    </Card>
  );
};

export default ProgressChart;
