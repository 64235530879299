import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { setConflictMinerals } from "../slices/conflictMineralsSlice";
import { StateSmelter } from "../../utils/types/smelter";
import { analyticsCMRT } from "../../utils/types/summary";
import { consolidatedCMRT } from "../../utils/types/summary";
import { Dispatch } from "redux";

/**
 * Bulk Operations
 */
export const listConflictMinerals = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<void>((resolve, reject) => {
    privatePostApi("/conflictminerals/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setConflictMinerals(response.data.data));
          resolve();
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
// export const createConflictMinerals= (payload: any) => () => {
//   return new Promise<any>((resolve, reject) => {
//     privatePostApi("/conflictminerals/read", payload)
//       .then((response: any) => {
//         if (response && response.status === 200 && response.data) {
//           resolve(response.data.data);
//         } else {
//           reject();
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export const readConflictMinerals = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/conflictminerals/read", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateConflictMinerals = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/conflictminerals/read" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteConflictMinerals = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/conflictminerals/read" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Smelter List
export const listSmelter = (payload: any) => () => {
  return new Promise<StateSmelter>((resolve, reject) => {
    privateGetApi("/smelters/suppliers/smelters", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// consolidated CMRT Table
export const consolidatedtable = (
  payload: any,
  id: string
): Promise<consolidatedCMRT> => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/conflictminerals/read/latest/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//analytics
export const conflictAnalytics = (payload: any): Promise<analyticsCMRT> => {
  return new Promise<analyticsCMRT>((resolve, reject) => {
    privateGetApi("/analytics/conflictminerals", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//create CMRT
export const createConflictMinerals = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/conflictminerals", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Generateconflict
export const generateConflict = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/conflictminerals/read/generateconflict", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//read anaylytics for consolidate
export const readAnalytics = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/conflictminerals/read/analytics", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//upload file
export const uploadConflictMineralsDocument = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("foldername", payload.foldername);
    privatePostApi(
      "/conflictminerals/document/upload/" + payload.foldername,
      formData
    )
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readConflictMineralsDocument = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi(
      "/conflictminerals/document/read/" + payload.foldername,
      payload
    )
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createConflictMineralsSmelter = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/conflictminerals/smelter", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const bulkWriteConflictMineralsDocuments = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/conflictminerals/update", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
