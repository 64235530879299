import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Descriptions, Tabs, Flex, Card, Avatar } from "antd";
import type { DescriptionsProps, TabsProps } from "antd";

import TenantOverview from "./Overview";
import TenantSubscription from "./Subscription";
import TenantRegulation from "./Regulation";
import TenantConfiguration from "./Configuration";
import TenantUsers from "./Users";

import { Tenant } from "../../../../utils/types/tenant";
import { useAppDispatch } from "../../../../redux/hooks";
import { readTenant } from "../../../../redux/actions/tenantsAction";
import TenantForm from "../../../../components/forms/TenantForm";
import ErrorBoundary from "../../../../utils/errorBoundary";

const { Meta } = Card;

const TenantDetails: React.FC = () => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [tenant, setTenant] = useState<Tenant>();
  const [tabItems, setTabItems] = useState<TabsProps["items"]>([]);
  const [descriptionItems, setDescriptionItems] = useState<
    DescriptionsProps["items"]
  >([]);
  const [loading, setLoading] = useState(false);

  const fetchData = (tenantId?: string, payload?: any) => {
    setLoading(true);
    dispatch(readTenant(tenantId ? tenantId : tenant ? tenant._id : ""))
      .then((response: Tenant) => {
        setTenant(response);
        setDescriptionItems([
          {
            key: "1",
            label: "Company Name",
            children: response.companyName,
          },
          {
            key: "2",
            label: "POC Name",
            children: response.firstName + " " + response.lastName,
          },
          {
            key: "3",
            label: "Designation",
            children: response.designation,
          },
          {
            key: "4",
            label: "Email Address",
            children: response.emailAddress,
          },
          {
            key: "5",
            label: "Phone Number",
            children: response.phoneNumber,
          },
          {
            key: "6",
            label: "Subscription",
            children: response.subscription.name,
          },
          {
            key: "7",
            label: "Regulations",
            children: response.regulations.map((e) => e.name).join(", "),
          },
          {
            key: "8",
            label: "Address",
            children: (
              <>
                {response.addressLine1} <br />
                {response.addressLine2} <br />
                {response.country} {response.state} {response.pincode}
              </>
            ),
          },
        ]);
        setTabItems([
          {
            key: "",
            label: "Overview",
            children: (
              <TenantOverview id={response._id} number={response.number} />
            ),
          },
          {
            key: "subscription",
            label: "Subscription",
            children: (
              <TenantSubscription
                id={response._id}
                tenantNumber={response.number}
                subscription={response.subscription}
                companyLogo={response.companyLogo}
                primaryColor={response.primaryColor}
                secondaryColor={response.secondaryColor}
                letterOfAuthorisation={response.letterOfAuthorisation}
                authorisedSender={response.authorisedSender}
              />
            ),
          },
          {
            key: "regulations",
            label: "Regulations",
            disabled: response.subscription.modules.find(
              (e) => e.number === "regulations"
            )
              ? false
              : true,
            children: (
              <TenantRegulation
                id={response._id}
                tenantNumber={response.number}
              />
            ),
          },
          {
            key: "configuration",
            label: "Configuration",
            disabled: response.subscription.modules.find(
              (e) => e.number === "products" || e.number === "components"
            )
              ? false
              : true,
            children: (
              <TenantConfiguration
                id={response._id}
                tenantNumber={response.number}
                productSpecifications={response.productSpecifications}
                specifications={response.specifications}
                gridConfiguration={response.gridConfiguration}
              />
            ),
          },
          {
            key: "users",
            label: "Users",
            children: (
              <TenantUsers id={response._id} tenantNumber={response.number} />
            ),
          },
        ]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTabChange = (key: string) => {
    navigate(key);
  };

  useEffect(() => {
    if (tenantId) {
      fetchData(tenantId);
    }
  }, [tenantId]);

  return (
    <>
      <ErrorBoundary>
        <Card style={{ marginTop: "2rem" }} loading={loading}>
          <Meta
            avatar={
              <Avatar
                shape="square"
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              >
                {tenant?.companyName
                  ? tenant?.companyName.slice(0, 2).toUpperCase()
                  : "NA"}
              </Avatar>
            }
            title={
              <>
                {tenant?.number.toUpperCase()}{" "}
                <TenantForm
                  type="update"
                  fetchData={fetchData}
                  formData={tenant}
                />
              </>
            }
            description={<Descriptions size="small" items={descriptionItems} />}
          />
        </Card>

        <Tabs
          activeKey={
            location.pathname.split("/").pop() === tenantId
              ? ""
              : location.pathname.split("/").pop()
          }
          items={tabItems}
          onChange={handleTabChange}
        />
      </ErrorBoundary>
    </>
  );
};

export default TenantDetails;
