import React, { useEffect, useState } from "react";
import { Space, Typography, Table, Input } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Smelter } from "../../../utils/types/smelter";
import { useAppDispatch } from "../../../redux/hooks";
import { listSmelters } from "../../../redux/actions/smeltersAction";

import SmelterForm from "../../../components/forms/SmelterForm";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Search } = Input;
const { Title, Text } = Typography;

const Smelters: React.FC = () => {
  const dispatch = useAppDispatch();
  const [smelters, setSmelters] = useState<Smelter[]>([]);
  const [smelterCount, setSmelterCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Smelter>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();
  const [search, setSearch] = useState<any>();

  const columns: ColumnsType<Smelter> = [
    {
      title: "Action",
      key: "_id",
      width: 150,
      render: (_, record: Smelter) => (
        <Space>
          <SmelterForm type="update" fetchData={fetchData} formData={record} />
        </Space>
      ),
    },
    {
      title: "Metal",
      dataIndex: "metal",
      key: "metal",
    },
    {
      title: "Smelter ID",
      dataIndex: "number",
      key: "number",
      sorter: true,
    },
    {
      title: "Smelter Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "State or Province",
      dataIndex: ["address", "state"],
      key: "name",
      sorter: true,
    },
    {
      title: "Country",
      key: "country",
      dataIndex: ["address", "country"],
      sorter: true,
    },
    {
      title: "Acquis Risk Ranking",
      dataIndex: "risk",
      key: "risk",
      render: (text: string) => (
        <Text>{text.charAt(0).toUpperCase() + text.slice(1)}</Text>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: any) => (
        <Text>
          {new Date(text).toLocaleString()}
          <br />
          {record.createdBy}
        </Text>
      ),
    },
  ];

  const fetchData = (payload?: any) => {
    setLoading(true);
    dispatch(
      listSmelters({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        filters: payload ? payload : null,
      })
    )
      .then((response) => {
        setSmelters(response.smelters);
        setSmelterCount(response.smelterCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Smelter>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Smelter> | SorterResult<Smelter>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Smelter>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setSmelters([]);
    }
  };

  useEffect(() => {
    fetchData(JSON.stringify(search));
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    JSON.stringify(search),
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Smelters
            </Title>
          </div>
          <Space>
            <Search
              placeholder="Search using number, name or country"
              allowClear
              onSearch={(text) =>
                setSearch({
                  softDelete: false,
                  $or: [
                    { number: { $regex: text.trim(), $options: "i" } },
                    { name: { $regex: text.trim(), $options: "i" } },
                  ],
                })
              }
              style={{ width: 350 }}
            />
            <SmelterForm type="create" fetchData={fetchData} />
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={smelters}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: smelterCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default Smelters;
