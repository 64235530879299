import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductsState, Product } from "../../utils/types/product";
import { Component } from "../../utils/types/component";

// interface Product {
//   // Define the properties of a product here
// }

// interface ProductsState {
//   products: Product[];
//   productCount: number;
// }

const initialState: ProductsState = {
  products: [],
  productCount: 0,
  components: [],
  componentCount: 0,
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (
      state,
      action: PayloadAction<{ products: Product[]; count: number }>
    ) => {
      state.products = action.payload.products;
      state.productCount = action.payload.count;
    },
    setComponents: (
      state,
      action: PayloadAction<{ components: Component[]; componentCount: number }>
    ) => {
      state.components = action.payload.components;
      state.componentCount = action.payload.componentCount;
    },
  },
});

export const { setProducts, setComponents } = productsSlice.actions;

export default productsSlice.reducer;
