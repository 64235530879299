import React, { useState } from "react";
import { Button, Col, Card, Form, Input, Row } from "antd";

import { User } from "../../utils/types/user";
import { useAppDispatch } from "../../redux/hooks";
import { updatePasswordUser } from "../../redux/actions/usersAction";

const UpdatePasswordForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFormSubmit = (values: User) => {
    setLoading(true);
    dispatch(
      updatePasswordUser({
        updates: values,
      })
    )
      .then(() => {
        form.resetFields();
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Card
      title="Update Password"
      extra={
        <Button type="primary" onClick={form.submit} loading={loading}>
          Save
        </Button>
      }
    >
      <Form form={form} layout="vertical" onFinish={onFormSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="oldPassword"
              label="Old Password"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter old password",
                },
              ]}
            >
              <Input.Password placeholder="Please enter old password" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter new password",
                },
              ]}
            >
              <Input.Password placeholder="Please enter new password" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter new password again",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("The passwords do not match!");
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Please new password again" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default UpdatePasswordForm;
