import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../../utils/apiHelper";
import { Dispatch } from "redux";

export const deleteContacts = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/campaignmanager/contacts" , payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};