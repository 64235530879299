import React, { useEffect, useState } from "react";
import { Smelter } from "../../../../utils/types/smelter";

import { useAppDispatch } from "../../../../redux/hooks";

import { Space, Typography, Table, Input, Tag, Avatar } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { readSupplierSmelters } from "../../../../redux/actions/suppliersAction";

import Tantalum from "../../../../assets/conflict/tantalum.svg";
import Tin from "../../../../assets/conflict/tin.svg";
import Gold from "../../../../assets/conflict/gold.svg";
import Tungsten from "../../../../assets/conflict/tungsten.svg";
import Cobalt from "../../../../assets/extended/cobalt.svg";
import Mica from "../../../../assets/extended/mica.svg";

const { Title, Text } = Typography;
const { Search } = Input;

interface SmelterProps {
  supplierName: string | null;
}
const SmeltersPage: React.FC<SmelterProps> = ({ supplierName }) => {
  const [supplierSmelters, setSupplierSmelters] = useState<Smelter[]>([]);
  const [supplierSmeltersLength, setSupplierSmeltersLength] =
    useState<number>(0);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Smelter>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();

  const onSearch = (value: any) => {
    fetchData({
      query:
        value?.key === "Enter"
          ? [
              {
                lookUp: {
                  $regex: value?.target?.value
                    ? value?.target?.value.replace(/[()/[\]]/g, "\\$&")
                    : value?.target?.value,
                  $options: "i",
                },
              },
              {
                number: {
                  $regex: value?.target?.value
                    ? value?.target?.value.replace(/[()/[\]]/g, "\\$&")
                    : value?.target?.value,
                  $options: "i",
                },
              },
              {
                "address.country": {
                  $regex: value?.target?.value
                    ? value?.target?.value.replace(/[()/[\]]/g, "\\$&")
                    : value?.target?.value,
                  $options: "i",
                },
              },
            ]
          : [
              {
                lookUp: {
                  $regex: value ? value.replace(/[()/[\]]/g, "\\$&") : value,
                  $options: "i",
                },
              },
              {
                number: {
                  $regex: value ? value.replace(/[()/[\]]/g, "\\$&") : value,
                  $options: "i",
                },
              },
              {
                "address.country": {
                  $regex: value ? value.replace(/[()/[\]]/g, "\\$&") : value,
                  $options: "i",
                },
              },
            ],
    });
  };

  const handleTableChange: TableProps<Smelter>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Smelter> | SorterResult<Smelter>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Smelter>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const fetchData = (filters?: any) => {
    setLoading(true);
    dispatch(
      readSupplierSmelters({
        id: supplierName,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        softDelete: false,
        filters: filters?.query?.length
          ? encodeURIComponent(
              JSON.stringify({
                "references.supplier": supplierName,
                // $or: filters.query,
              })
            )
          : JSON.stringify({
              "references.supplier": supplierName,
            }),
      })
    )
      .then((response: any) => {
        setSupplierSmelters(response.documentSmelters);
        setSupplierSmeltersLength(response.documentSmeltersLength);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  const columns: ColumnsType<Smelter> = [
    {
      title: "Metal",
      dataIndex: "metal",
      key: "metal",
      width: 100,
      render: (value) => (
        <>
          {value && value === "Tantalum" && (
            <Avatar
              shape="square"
              size={30}
              src={<img src={Tantalum} alt={value} />}
            />
          )}
          {value && value === "Tin" && (
            <Avatar
              shape="square"
              size={30}
              src={<img src={Tin} alt={value} />}
            />
          )}
          {value && value === "Gold" && (
            <Avatar
              shape="square"
              size={30}
              src={<img src={Gold} alt={value} />}
            />
          )}
          {value && value === "Tungsten" && (
            <Avatar
              shape="square"
              size={30}
              src={<img src={Tungsten} alt={value} />}
            />
          )}
          {value && value === "Cobalt" && (
            <Avatar
              shape="square"
              size={30}
              src={<img src={Cobalt} alt={value} />}
            />
          )}
          {value && value === "Mica" && (
            <Avatar
              shape="square"
              size={30}
              src={<img src={Mica} alt={value} />}
            />
          )}
        </>
      ),
    },
    {
      title: "Smelter ID",
      dataIndex: "number",
      key: "number",
      width: 100,
    },
    {
      title: "Smelter Name",
      dataIndex: "lookUp",
      key: "lookUp",
      width: 200,
    },
    {
      title: "State or Province",
      dataIndex: ["address", "state"],
      key: "address.state",
      width: 100,
    },
    {
      title: "Country",
      dataIndex: ["address", "country"],
      key: "country",
      width: 100,
    },
    {
      title: "Country RMI Assessment Status",
      dataIndex: "sourceOfIdentificationNumber",
      key: "sourceOfIdentificationNumber",
      width: 100,
    },
    {
      title: "Acquis Risk Ranking",
      dataIndex: "risk",
      key: "risk",
      width: 100,
      render: (value, record) => (
        <>
          {value && value === "mid" && <Tag color="#F08000">Medium</Tag>}
          {value && value === "low" && <Tag color="#228B22">Low</Tag>}
          {value && value === "high" && (
            <>
              <Tag color="#cd201f">High</Tag>
              <Tag color="#cd201f">{record && record?.smelterRegions}</Tag>
            </>
          )}
          {value && value === "unknown" && <Tag color="grey">Unknown</Tag>}
        </>
      ),
    },
  ];

  return (
    <div>
      <Space
        align="end"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <div>
          <Title level={5} style={{ marginBottom: 0 }}>
            Smelters
          </Title>
        </div>
        <Space>
          {/* <Search
            placeholder="input search text"
            onSearch={onSearch}
            onPressEnter={onSearch}
            allowClear
            style={{ width: 200 }}
          /> */}
        </Space>
      </Space>
      <Table
        columns={columns}
        loading={loading}
        rowKey="_id"
        scroll={{
          x: 1000,
          y: "calc(100vh - 285px)",
        }}
        dataSource={supplierSmelters}
        pagination={{
          ...tablePagination,
          total: supplierSmeltersLength,
          showQuickJumper: true,
          showTotal: (supplierSmeltersLength) =>
            `Total ${supplierSmeltersLength} items`,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default SmeltersPage;
