import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Space,
  Button,
  Drawer,
  Checkbox,
  Alert
} from "antd";
import type { SelectProps } from "antd";

import { Supplier } from "../../utils/types/supplier";
import { useAppDispatch } from "../../redux/hooks";
import {
  createSupplier,
  updateSupplier,
  checkIfCampaignAlreadyRunning,
} from "../../redux/actions/suppliersAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../utils/localStore";
import { message } from "antd";

const { Option } = Select;

const SupplierForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Supplier;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isPrimary, setIsPrimary] = useState<boolean>(false);
  const [disable, setDisable] = useState<any>(null);

  const regulations = getRegulationsFromLocalStorage() ?? [];
  const options: SelectProps["options"] = [];

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  regulations?.forEach((regulation: any) => {
    options.push({
      label: regulation.name,
      value: regulation.regulationNumber,
    });
  });

  if (permissions.read?.includes?.("conflictminerals")) {
    options.push({
      label: "Conflict Minerals",
      value: "conflict",
    });
  }
  if (permissions.read?.includes?.("extendedminerals")) {
    options.push({
      label: "Extended Minerals",
      value: "extended",
    });
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleIsPrimary = (value: string, field: any) => {
    const contactsData = [...form.getFieldValue("contacts")];

    if (
      value === "Primary" &&
      contactsData.some(
        (contact, index) => contact.isPrimary && index !== field.name
      )
    ) {
      form.setFields([
        {
          name: ["contacts", field.name, "contactStatus"],
          errors: ["Only one contact can be set as Primary"],
        },
      ]);
      return;
    }

    setIsPrimary(value === "Primary");
    contactsData[field.name].isPrimary = value === "Primary";
    form.setFieldsValue({ contacts: contactsData });
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
      dispatch(checkIfCampaignAlreadyRunning({ id: props.formData?._id })).then(
        (res: any) => {
          setDisable(res.data);
        }
      );
    }
  }, [open]);
  

  const onFormSubmit = (values: Supplier) => {
    setLoading(true);
    if (props.type === "create") {
      dispatch(createSupplier(values))
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(
        updateSupplier(
          { type: "supplier", updates: values },
          props.formData._id
        )
      )
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <div>
      <Button
        type={props.type === "create" ? "primary" : "text"}
        onClick={showDrawer}
        title={props.type === "create" ? "New Supplier" : "Edit Supplier"}
        data-testid={
          props.type === "create"
            ? "create-supplier-button"
            : "edit-supplier-button"
        }
        icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
      >
        {props.type === "create" ? "Add Supplier" : null}
      </Button>
      <Drawer
        title={props.type === "create" ? "Add Supplier" : "Edit Supplier"}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-supplier-button">
              Cancel
            </Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              data-testid="submit-supplier-button"
            >
              {props.type === "create" ? "Submit" : "Save"}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={props.type === "create" ? {} : { ...props.formData }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="name"
                label="Supplier Name"
                rules={[
                  {
                    required: true,
                    message: "Please input Supplier Name!",
                  },
                  {
                    pattern:
                      /^(?!.*\s{2,})[a-zA-Z0-9()-/.,&_@*\u00C0-\u024F\u00A9|\u2122|\u00AEu4E00-\u9FFF\u{20000}-\u{2FA1F}\p{L}\p{Mn}\p{P}\p{N}\s]+$/u,
                    message: "Not Allowed",
                  },
                ]}
              >
                <Input disabled={props.type === "edit"} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="number" label="Supplier ID">
                <Input disabled={props.type === "edit"} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="type" label="Supplier Type">
                <Select placeholder="Select Supplier Type">
                  <Option value="Distributor">Distributor</Option>
                  <Option value="Manufacturer">Manufacturer</Option>
                  <Option value="Connector">Connector</Option>
                  <Option value="OTS">OTS</Option>
                  <Option value="Package & Labels">Package & Labels</Option>
                  <Option value="Chemical">Chemical</Option>
                  <Option value="Line Card">Line Card</Option>
                  <Option value="Others">Others</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="website" label="Website">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="outOfScope" label="Out Of Scope For">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select Regulations"
                  options={options}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="phoneNumber" label="Ph no.">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="address" label="Street Address">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="city" label="City">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="state" label="State">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="country" label="Country">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="zipcode" label="Zipcode">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} sm={24} md={24}>
            {disable && <Alert
                  message="Contacts are disabled due to a running campaign."
                  type="warning"
                  style={{marginBottom:"1.40rem"}}
                  showIcon
                />}
              <Form.List name="contacts">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Space
                        key={field.key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          name={[field.name, "firstName"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter first name",
                            },
                            {
                              pattern: new RegExp(/^[a-zA-Z]+$/),
                              message: "Please input alphabet characters only",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Name"
                            disabled={disable ? true : false}
                          />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "emailAddress"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter email address",
                            },
                            {
                              pattern: new RegExp(
                                /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
                              ),
                              message: "Please input a valid email address",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Email Address"
                            disabled={disable ? true : false}
                          />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "contactStatus"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select contact status",
                            },
                            {
                              validator: (_, value, callback) => {
                                const contactsData =
                                  form.getFieldValue("contacts");
                                if (
                                  value === "Primary" &&
                                  contactsData.some(
                                    (contact: any, index: any) =>
                                      contact.isPrimary && index !== field.name
                                  )
                                ) {
                                  callback(
                                    "Only one contact can be set as Primary"
                                  );
                                } else {
                                  callback();
                                }
                              },
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Contact Status"
                            onChange={(value) => handleIsPrimary(value, field)}
                            disabled={disable ? true : false}
                          >
                            <Option value="Primary">Primary</Option>
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name={[field.name, "isPrimary"]} hidden>
                          <Checkbox checked={isPrimary} />
                        </Form.Item>
                        {!disable && (
                          <CloseOutlined onClick={() => remove(field.name)} />
                        )}
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        disabled={disable ? true : false}
                      >
                        Add Contact
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
            <Col span={24}>
              <Form.Item name="comments" label="Remarks">
                <Input.TextArea rows={4} placeholder="Remarks" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default SupplierForm;
