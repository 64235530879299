import React, { useEffect, useState } from "react";
import { Alert, Space, Tabs, Typography } from "antd";
import type { TabsProps } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  getLocalStorage,
  removeLocalStorage,
} from "../../../../utils/localStore";
import { consolidatedtable } from "../../../../redux/actions/conflictMineralsAction";
import axios from "axios";
import { baseUrl } from "../../../../utils/apiHelper";
const { Title } = Typography;

const items: TabsProps["items"] = [
  {
    key: "summary",
    label: "Summary",
  },
  {
    key: "responsetracker",
    label: "Response Tracker",
  },
  {
    key: "smelterlist",
    label: "Smelter List",
  },
];

const Conflict: React.FC = () => {
  const [activeKey, setActiveKey] = useState();
  const [autoDownload, setautoDownload] = useState(false);

  const handleTabChange = (key: any) => {
    setActiveKey(key);
  };
  useEffect(() => {
    if (getLocalStorage("autoDownload")) {
      fetchConsolidatedData();
    }
  });
  const viewFile = async (filename: string) => {
    if (filename.includes("xlsx")) {
      try {
        const response = await axios.post(
          baseUrl + "/conflictminerals/document/read/conflict-minerals",
          {
            filename: filename,
            foldername: "conflict-minerals",
          },
          {
            headers: {
              "x-service-call": "conflictminerals",
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
            responseType: "arraybuffer",
          }
        );

        if (response && response.status === 200 && response.data) {
          const blobPDF = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });

          const blobURL = window.URL.createObjectURL(blobPDF);
          const fileLink = document.createElement("a");
          fileLink.href = blobURL;
          fileLink.download = filename;
          fileLink.click();
          setautoDownload(false);
        }
      } catch (error) {
        fetchConsolidatedData();
      }
    }
  };

  const fetchConsolidatedData = async () => {
    try {
      const user = getLocalStorage("user");

      if (user) {
        const parsedData = user;
        const tenantNumber = parsedData.tenantNumber;

        const response = await consolidatedtable(
          {
            sortBy: "createdAt",
            sortDesc: false,
            pageSize: 10,
            pageNum: 1,
            filters: JSON.stringify({ softDelete: false }),
            version: getLocalStorage("cmrtversion"),
          },
          tenantNumber
        );
        if (response && response.data) {
          setautoDownload(true);
          setTimeout(() => {
            viewFile(response.data.conflictMineral[0]?.filename);
          }, 30000);

          removeLocalStorage("autoDownload");
        } else {
          console.error("Invalid response format:", response);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  const hideNavigationBar =
    location.pathname === "/app/responsibleminerals/conflictminerals/generator";
  const onClick = (activeKey: any) => {
    if (activeKey === "responsetracker" || activeKey === "smelterlist") {
      navigate(activeKey?.toString());
    } else if (activeKey === "summary") {
      navigate("");
    }
  };
  let active;
  const parts =
    location.pathname.split("/").length > 1
      ? location.pathname.split("/")[location.pathname.split("/").length - 1]
      : "";
  if (parts === "responsetracker") {
    active = "responsetracker";
  } else if (parts === "smelterlist") {
    active = "smelterlist";
  } else {
    active = "summary";
  }
  return (
    <div className="custom-tab">
      {!hideNavigationBar && (
        <>
          <Space
            align="end"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <div>
              <Title level={5} style={{ marginBottom: 0 }}>
                Conflict Minerals
              </Title>
            </div>
          </Space>
          {autoDownload && (
            <Alert
              message="Your latest consolidated file will be downloaded when its ready."
              type="info"
              showIcon
            />
          )}
          <Tabs
            activeKey={active}
            tabPosition="top"
            items={items}
            onTabClick={(activeKey) => onClick(activeKey)}
            onChange={handleTabChange}
          ></Tabs>
        </>
      )}
      <Outlet />
    </div>
  );
};
export default Conflict;
