import React, { FC, useEffect, useState } from "react";
import {
  FilePdfOutlined,
  FilterOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Space,
  Typography,
  Button,
  Row,
  Col,
  Statistic,
  Card,
  Timeline,
  Select,
  Spin,
} from "antd";
import {
  listProducts,
  productAnalytics,
} from "../../../redux/actions/productsAction";
import { AnalyticsAdmin } from "../../../redux/actions/analyticsAction";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { getLocalStorage, setLocalStorage } from "../../../utils/localStore";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../../../utils/errorBoundary";
const { Title, Text } = Typography;

const { Option } = Select;

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface ChartProps {
  data: any[];
  chartConfig: any;
  chartType: string;
  chartSpecificConfig?: any;
  colorRange?: any;
  uniqueId?: any;
}

const Chart: FC<ChartProps> = ({
  data,
  chartConfig,
  chartType,
  chartSpecificConfig,
  colorRange,
  uniqueId,
}) => {
  useEffect(() => {
    FusionCharts.ready(function () {
      new FusionCharts({
        type: chartType,
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: {
          data,
          chart: { ...chartConfig, ...chartSpecificConfig },
          colorRange,
        },
      }).render(`chart-container-${chartType}-${uniqueId}`);
    });
  }, [data, chartConfig, chartType, chartSpecificConfig, colorRange, uniqueId]);

  return <div id={`chart-container-${chartType}-${uniqueId}`} />;
};

const Dashboard: FC = () => {
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>("");
  const dispatch = useAppDispatch();
  const { products, productCount } = useAppSelector((state) => state.products);
  const [productData, setproductData] = useState<any>();

  const [userActivity, setuserActivity] = useState<any[]>([]);
  const [adminAnalytics, setadminAnalytics] = useState<any>({});
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const fetchAdminAnalyticsData = async () => {
    setLoading(true);
    try {
      const response = await AnalyticsAdmin({
        pageSize: 10,
        pageNum: 1,
        filters: JSON.stringify({
          softDelete: false,
        }),
        sortBy: ["timeStamp"],
        sortDesc: [false],
      });
      if (response) {
        setuserActivity(response.analytics);

        setadminAnalytics(response.tenantAnalytics[0]?.overAll || {});
      } else {
        console.error("Invalid or missing data in the API response.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const chartConfig = {
    theme: "fusion",
  };

  const smelterChartConfig = {
    caption: "Smelters",
    showValues: "1",
    enableMultiSlicing: "1",
    showPercentValues: 0,
  };
  const subscriptionChartConfig = {
    caption: "Subscription",
    showValues: "1",
    enableMultiSlicing: "1",
    showPercentValues: 0,
  };
  const tenantDataChartConfig = {
    caption: "Tenants",
    showValues: "1",
    enableMultiSlicing: "1",
    showPercentValues: 0,
  };
  const smelter = adminAnalytics?.smelters;
  const low = smelter?.smelterRisk
    ?.filter((v: { _id: string }) => v._id.includes("low"))
    .map((v: { count: number }) => v.count)
    .toString();
  const mid = smelter?.smelterRisk
    ?.filter((v: { _id: string }) => v._id.includes("mid"))
    .map((v: { count: number }) => v.count)
    .toString();
  const high = smelter?.smelterRisk
    ?.filter((v: { _id: string }) => v._id.includes("high"))
    .map((v: { count: number }) => v.count)
    .toString();
  const Smelters = [
    {
      label: smelter?.smelterRisk
        ?.filter((v: { _id: string }) => v._id.includes("low"))
        .map((v: { _id: string }) => v._id)
        .toString(),
      value: low,
    },
    {
      label: smelter?.smelterRisk
        ?.filter((v: { _id: string }) => v._id.includes("mid"))
        .map((v: { _id: string }) => v._id)
        .toString(),
      value: mid,
    },
    {
      label: smelter?.smelterRisk
        ?.filter((v: { _id: string }) => v._id.includes("high"))
        .map((v: { _id: string }) => v._id)
        .toString(),
      value: high,
    },
  ];
  const [analytics, setanalytics] = useState<any>();
  const [user, setuser] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      try {
        const getuser = await getLocalStorage("user");
        setuser(getuser.tenantNumber);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchUser();
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (user === "acquiscompliance") {
        fetchAdminAnalyticsData();
      }

      if (
        user !== "acquiscompliance" &&
        permissions?.read.includes("components")
      ) {
        if (permissions?.read.includes("products")) {
          fetchData();
          productsAnalytics("overall");
        }
        if (getLocalStorage("overall")) {
          const parsedData = getLocalStorage("overall");
          setanalytics(parsedData);
        } else {
          productsAnalytics("overall");
        }
      } else if (user === "acquiscompliance") {
        navigate("/app", { replace: true });
      } else {
        navigate("/app/responsibleminerals/conflictminerals", {
          replace: true,
        });
      }
    }
  }, [user, inputValue, options]);
  const productsAnalytics = (id: any) => {
    setLoading(true);
    dispatch(productAnalytics({ skipMutation: true }, id))
      .then((response) => {
        if (response) {
          if (id === "overall") {
            setLocalStorage("overall", response);
            setanalytics(response);
            setLoading(false);
          } else {
            setanalytics(response);
            setLoading(false);
          }
        } else {
          setLoading(false);
          console.error("Invalid or missing data in the API response.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      listProducts({
        search: true,
        pageSize: 10,
        pageNum: 1,
        softDelete: false,
        path: search?.productsPath,
        searchField: search?.searchField ? search?.query : "",
        filtersUsed: "",
        filters:
          search?.query && search?.query.length
            ? encodeURIComponent(
                JSON.stringify({
                  elements: search?.query,
                  path: search?.productsPath,
                })
              )
            : [],
      })
    )
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSearch = (value: any) => {
    fetchData({
      searchField: true,
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
  };
  const handleClear = () => {
    setOptions([]);
    setInputValue("");
    fetchData();
    productsAnalytics("overall");
  };
  const tenants = adminAnalytics?.tenants;
  const tenantData = [
    {
      label: "Active",
      value: tenants?.active,
    },
    {
      label: "Blocked",
      value: tenants?.active - tenants?.total,
    },
  ];
  const subscription = adminAnalytics?.subscription;
  const subscriptionData = subscription?.map(
    ({ _id, count }: { _id: string; count: number }) => ({
      label: _id,
      value: count,
    })
  );
  const handleSelectChange = (value: any) => {
    const selectedData = products.find((option) => option.name === value);
    setOptions(value);
    if (value && selectedData) {
      productsAnalytics(selectedData?._id);
    }
  };
  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Welcome Back,
            </Title>
            <Text type="secondary">Dashboard Overview</Text>
          </div>
          {/* <Space>
          <Button type="default" icon={<ReloadOutlined />} />
          <Button type="default" icon={<FilterOutlined />}>
            Filter by
          </Button>
          <Button type="primary" icon={<FilePdfOutlined />}>
            Export
          </Button>
        </Space> */}
        </Space>
        {user === "acquiscompliance" ? (
          <>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Card>
                  <Row>
                    <Col span={6}>
                      <Statistic
                        title="Total Tenants"
                        value={
                          adminAnalytics?.tenants?.total
                            ? adminAnalytics.tenants?.total
                            : "NA"
                        }
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Total Users"
                        value={
                          adminAnalytics?.users ? adminAnalytics.users : "NA"
                        }
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Total Suppliers"
                        value={
                          adminAnalytics?.suppliers
                            ? adminAnalytics.suppliers
                            : "NA"
                        }
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Total Smelters"
                        value={
                          adminAnalytics?.smelters
                            ? adminAnalytics.smelters?.total
                            : "NA"
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Recent Activities">
                  <Timeline>
                    {userActivity.map((item, index) => (
                      <Timeline.Item key={index}>
                        {`User ${item.firstName} ${
                          item.requestMethod === "POST" &&
                          item.requestUrl !== "/login"
                            ? "created"
                            : item.requestUrl !== "/login"
                            ? "updated"
                            : "logged in"
                        } ${item.requestUrl
                          .split("/")
                          .slice(2)
                          .join(" ")} where name : ${item.name} on ${
                          item.requestMethod === "POST"
                            ? new Date(item.createdAt).toLocaleString("en-GB", {
                                hour12: false,
                              })
                            : new Date(item.updatedAt).toLocaleString("en-GB", {
                                hour12: false,
                              })
                        }.`}
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <Card>
                      <Chart
                        data={Smelters}
                        chartConfig={chartConfig}
                        chartType="pie2d"
                        chartSpecificConfig={smelterChartConfig}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card>
                      <Chart
                        data={tenantData}
                        chartConfig={chartConfig}
                        chartType="pie2d"
                        chartSpecificConfig={tenantDataChartConfig}
                      />
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card>
                      <Chart
                        data={subscriptionData}
                        chartConfig={chartConfig}
                        chartType="pie2d"
                        chartSpecificConfig={subscriptionChartConfig}
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <div>
            <Row>
              <Col xs={24} md={24}>
                {permissions?.read.includes("products") && (
                  <Select
                    showSearch
                    style={{ height: 50, width: "100%", marginBottom: "1rem" }}
                    value={inputValue || options}
                    placeholder="Start typing to search for a product"
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onClear={handleClear}
                    allowClear
                    onSearch={onSearch}
                    onChange={handleSelectChange}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                  >
                    {products?.map((option) => (
                      <Option key={option.name} value={option.name}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              {analytics?.regulations?.map((regulation: any, index: any) => (
                <Col key={index} xs={24} md={12}>
                  <Card key={index} className="mr-3">
                    <Chart
                      data={regulation.statistics}
                      chartConfig={chartConfig}
                      chartType="doughnut3d"
                      chartSpecificConfig={{
                        caption: regulation.name,
                        subcaption: regulation.description,
                        enablesmartlabels: "1",
                        showlabels: "1",
                        numbersuffix: " %",
                        usedataplotcolorforlabels: "1",
                        plottooltext: "$label, <b>$value</b>",
                      }}
                      uniqueId={`${index}-regulation`}
                    />
                  </Card>
                </Col>
              ))}

              {analytics?.specifications?.map(
                (specification: any, index: any) => (
                  <Col key={index} xs={24} md={12}>
                    <Card key={index} className="mr-3">
                      <Chart
                        data={specification.statistics}
                        chartConfig={chartConfig}
                        chartType="doughnut3d"
                        chartSpecificConfig={{
                          caption: specification.name,
                          subcaption: specification.description,
                          enablesmartlabels: "1",
                          showlabels: "1",
                          numbersuffix: " %",
                          usedataplotcolorforlabels: "1",
                          plottooltext: "$label, <b>$value</b>",
                        }}
                        uniqueId={`${index}-specification`}
                      />
                    </Card>
                  </Col>
                )
              )}
              {permissions?.read.includes("materials") &&
                analytics?.materials?.map((material: any, index: any) => (
                  <Col key={index} xs={24} md={12}>
                    <Card key={index} className="mr-3">
                      <Chart
                        data={material.statistics}
                        chartConfig={chartConfig}
                        chartType="doughnut3d"
                        chartSpecificConfig={{
                          caption: material.name,
                          enablesmartlabels: "1",
                          showlabels: "1",
                          numbersuffix: " %",
                          usedataplotcolorforlabels: "1",
                          plottooltext: "$label, <b>$value</b>",
                        }}
                        uniqueId={`${index}-materials`}
                      />
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default Dashboard;
