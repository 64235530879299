import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  MenuProps,
  Pagination,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { listSmelter } from "../../../../redux/actions/conflictMineralsAction";
import { Smelter } from "../../../../utils/types/smelter";
import { ColumnsType } from "antd/es/table";
import Cobalt from "../../../../../src/assets/extended/cobalt.svg";
import Mica from "../../../../../src/assets/extended/mica.svg";
import EditSmelter from "../../../../components/modals/edit-smelter-modal";
import type { TableProps, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { useAppDispatch } from "../../../../redux/hooks";
import { DownOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import ErrorBoundary from "../../../../utils/errorBoundary";
const ExtendedSmelterList: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Smelter>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [search, setSearch] = useState<any>();

  const onSearch = (value: any) => {
    setSearch({
      searchField: true,
      query:
        value?.key === "Enter" ? value?.target?.value.trim() : value.trim(),
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };
  useEffect(() => {
    fetchData(search);
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  const columns: ColumnsType<Smelter> = [
    {
      title: "",
      key: "operation",
      width: 70,
      render: (_, record) => (
        <Space>
          <EditSmelter
            rowdata={record}
            open={open}
            onClose={onClose}
            type={"edit"}
            mineral={"ExtendedMinerals"}
            smelterListData={data}
            fetchData={fetchData}
          />
        </Space>
      ),
    },
    {
      title: "Metal",
      width: 100,
      key: "metal",
      dataIndex: "metal",
      sorter: true,
      render: (text: { metal: string }, record: Smelter) => {
        const metal = record.metal;
        if (metal === "Cobalt") {
          return (
            <Tooltip title={metal}>
              <Avatar
                src={<img src={Cobalt} alt="Cobalt" />}
                style={{ backgroundColor: "#87d068" }}
                shape="square"
                size={30}
              />
            </Tooltip>
          );
        } else if (metal === "Mica") {
          return (
            <Tooltip title={metal}>
              <Avatar
                src={<img src={Mica} alt="Mica" />}
                shape="square"
                size={30}
              />
            </Tooltip>
          );
        }
      },
    },
    {
      title: "Smelter ID",
      width: 150,
      key: "number",
      dataIndex: "number",
      sorter: true,
    },
    {
      title: "Smelter Name",
      width: 250,
      key: "lookUp",
      dataIndex: "lookUp",
      sorter: true,
      render: (text, record) => record.lookUp || record.name,
    },
    {
      title: "State or Province",
      width: 200,
      key: "state",
      dataIndex: ["address", "state"],
      sorter: true,
    },
    {
      title: "Country",
      width: 200,
      key: "country",
      dataIndex: ["address", "country"],
      sorter: true,
    },
    {
      title: "Country RMI Assessment Status",
      width: 200,
      key: "sourceOfIdentificationNumber",
      dataIndex: "sourceOfIdentificationNumber",
      sorter: true,
    },
    {
      title: "Acquis Risk Ranking",
      width: 150,
      key: "risk",
      dataIndex: "risk",
      sorter: true,
      render: (text: { risk: string }, record: Smelter) => {
        const risk = record.risk;
        if (risk === "high") {
          return <Tag color="#cd201f">High</Tag>;
        } else if (risk === "low") {
          return <Tag color="#1EB119">Low</Tag>;
        } else if (risk === "mid") {
          return <Tag color="#E58602">Medium</Tag>;
        } else if (risk === "unknown") {
          return <Tag color="#9F9A93">Unknown</Tag>;
        }
        if (risk === "high") {
          return (
            <Tag color="#cd201f">
              {record.smelterRegions ? record.smelterRegions.toString() : ""}{" "}
            </Tag>
          );
        }
      },
    },
    {
      title: "Reported Supplier",
      width: 200,
      key: "references",
      dataIndex: "references",
      sorter: true,
      render: (references: { supplier: string; mineral: string }[]) => {
        const supplierName = references[0]?.supplier || "";
        const remainingSupplierName = references.slice(1);
        return (
          <Tooltip
            title={remainingSupplierName
              .map((supp) => supp.supplier)
              .join(", ")}
          >
            <span>
              {supplierName}
              {remainingSupplierName.length > 0 && (
                <span style={{ color: "blue" }}>
                  {" "}
                  +{remainingSupplierName.length} more
                </span>
              )}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const dispatch = useAppDispatch();

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      listSmelter({
        sortBy: tableSorter?.field,
        sortDesc: tableSorter?.order === "descend",
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        filters: search
          ? JSON.stringify({
              "references.mineral": "ExtendedMinerals",
              $or: [
                { lookUp: { $regex: search?.query?.trim(), $options: "i" } },
                { number: { $regex: search?.query?.trim(), $options: "i" } },
                {
                  "address.country": {
                    $regex: search?.query?.trim(),
                    $options: "i",
                  },
                },
                {
                  "references.supplier": {
                    $regex: search?.query?.trim(),
                    $options: "i",
                  },
                },
              ],
            })
          : JSON.stringify({ "references.mineral": "ExtendedMinerals" }),
      })
    )
      .then((response) => {
        setData(response.data.smelters);
        setCount(response.data.count);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };
  const handleTableChange: TableProps<Smelter>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Smelter> | SorterResult<Smelter>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Smelter>);
    if (sorter) {
      setTablePagination({
        current: tablePagination.current,
        pageSize: tablePagination.pageSize,
      });
    }
    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const [loading, setLoading] = useState(false);
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Cobalt",
      icon: (
        <Avatar
          src={<img src={Cobalt} alt="Cobalt" />}
          shape="square"
          size={34}
          style={{ border: "none" }}
        />
      ),
    },
    {
      key: "2",
      label: "Mica",
      icon: (
        <Avatar
          src={<img src={Mica} alt="Mica" />}
          shape="square"
          size={34}
          style={{ border: "none" }}
        />
      ),
    },
  ];
  return (
    <div>
      <ErrorBoundary>
        <div style={{ textAlign: "right" }}>
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
            }}
          >
            <Search
              placeholder="Search using keywords name, number, supplier or country"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
            <EditSmelter
              open={open}
              onClose={onClose}
              type={"create"}
              mineral={"ExtendedMinerals"}
              smelterListData={data}
              fetchData={fetchData}
            />
          </Space>
        </div>
        <div>
          <Table
            columns={columns}
            loading={loading}
            rowKey="_id"
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            dataSource={data}
            pagination={false}
            onChange={handleTableChange}
            footer={() => (
              <>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Dropdown menu={{ items }}>
                      <Button type="primary">
                        Legends
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col>
                    <Pagination
                      {...tablePagination}
                      total={count}
                      showTotal={(total: number, range: [number, number]) =>
                        `${range[0]}-${range[1]} of ${total} items`
                      }
                      showSizeChanger={true}
                      showQuickJumper={true}
                      onChange={(page, pageSize) =>
                        setTablePagination({
                          ...tablePagination,
                          current: page,
                          pageSize,
                        })
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default ExtendedSmelterList;
