import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";

import { Smelter } from "../../utils/types/smelter";
import { useAppDispatch } from "../../redux/hooks";
import {
  createSmelter,
  updateSmelter,
} from "../../redux/actions/smeltersAction";
import { getLocalStorage } from "../../utils/localStore";

const { Option } = Select;

const SmelterForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Smelter;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFormSubmit = (values: Smelter) => {
    setLoading(true);
    if (props.type === "create") {
      dispatch(createSmelter(values))
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(updateSmelter({ updates: values }, props.formData._id))
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <>
      {permissions[props.type === "create" ? "create" : "update"]?.includes(
        "smelters"
      ) && (
        <>
          <Button
            type={props.type === "create" ? "primary" : "text"}
            title={props.type === "create" ? "New Smelter" : "Edit Smelter"}
            onClick={showDrawer}
            icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
          >
            {props.type === "create" ? "New Smelter" : null}
          </Button>
          <Drawer
            title={
              props.type === "create" ? "Create a New Smelter" : "Edit Smelter"
            }
            width={720}
            onClose={onClose}
            open={open}
            destroyOnClose={true}
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={form.submit} type="primary" loading={loading}>
                  {props.type === "create" ? "Submit" : "Save"}
                </Button>
              </Space>
            }
          >
            <Form
              form={form}
              layout="vertical"
              initialValues={
                props.type === "create" ? {} : { ...props.formData }
              }
              onFinish={onFormSubmit}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="metal"
                    label="Metal"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please choose metal",
                      },
                    ]}
                  >
                    <Select placeholder="Please choose metal">
                      <Option value="Tungsten">Tungsten</Option>
                      <Option value="Gold">Gold</Option>
                      <Option value="Zinc">Zinc</Option>
                      <Option value="Mica">Mica</Option>
                      <Option value="Nickel">Nickel</Option>
                      <Option value="Tin">Tin</Option>
                      <Option value="Cobalt">Cobalt</Option>
                      <Option value="Tantalum">Tantalum</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="risk"
                    label="Risk"
                    rules={[{ required: true, message: "Please choose risk" }]}
                  >
                    <Select placeholder="Please choose risk">
                      <Option value="low">Low</Option>
                      <Option value="mid">Mid</Option>
                      <Option value="high">High</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter smelter name",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter smelter name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="number"
                    label="Number"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter smelter number",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter smelter number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="lookUp"
                    label="Smelter Look Up"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter smelter look up",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter smelter look up" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="sourceOfIdentificationNumber"
                    label="Source Of Identification Number"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter source of identification number",
                      },
                    ]}
                  >
                    <Input placeholder="Please enter source of identification number" />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Form.List name="address">
                {() => (
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="streetAddress" label="Street Address">
                        <Input placeholder="Please enter street address" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="country"
                        label="Country"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please enter country",
                          },
                        ]}
                      >
                        <Input placeholder="Please enter country" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please enter state",
                          },
                        ]}
                      >
                        <Input placeholder="Please enter state" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="city"
                        label="City"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please enter city",
                          },
                        ]}
                      >
                        <Input placeholder="Please enter city" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="pincode" label="ZIP Code">
                        <Input placeholder="Please enter zip code" />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form.List>
            </Form>
          </Drawer>
        </>
      )}
    </>
  );
};

export default SmelterForm;
