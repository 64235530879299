import React, { useEffect, useState } from "react";
import { Contact } from "../../../../utils/types/supplier";

import { Space, Typography, Button, Table, Tooltip } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { useParams } from "react-router-dom";

import { useAppDispatch } from "../../../../redux/hooks";
import SupplierContactModal from "../../../../components/forms/SupplierContactForm";

import { readSupplierContacts } from "../../../../redux/actions/suppliersAction";

import { MessageOutlined, WarningFilled } from "@ant-design/icons";
const { Title, Text } = Typography;

interface ContactsTableProps {
  contacts: Contact[];
}

const ContactsPage: React.FC<ContactsTableProps> = ({ contacts }) => {
  const [supplierContacts, setSupplierContacts] = useState<Contact[]>([]);
  const [supplierContactsLength, setSupplierContactsLength] =
    useState<number>(0);
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Contact>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readSupplierContacts({
        id: id,
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        // filters: encodeURIComponent(
        //   JSON.stringify({
        //     _id: id,
        //   })
        // )
      })
    )
      .then((response: any) => {
        setSupplierContacts(response.supplierContacts);
        setSupplierContactsLength(response.supplierContactsLength);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Contact>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Contact> | SorterResult<Contact>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Contact>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  const columns: ColumnsType<Contact> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (value) => (
        <Space>
          <SupplierContactModal
            type="edit"
            fetchData={fetchData}
            formData={value}
          />
        </Space>
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: 100,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: 100,
    },
    {
      title: "Job Title",
      dataIndex: "designation",
      key: "designation",
      width: 100,
    },
    {
      title: "Contact E-mail",
      dataIndex: "emailAddress",
      key: "emailAddress",
      width: 150,
      render: (value: any, record: any) => (
        <Text>
          {!record.emailAddress ? (
            <>
              <Tooltip title="No contact attached">
                <WarningFilled style={{ color: "red" }} />
              </Tooltip>
            </>
          ) : (
            record.emailAddress
          )}
        </Text>
      ),
    },
    {
      title: "Mobile Phone",
      dataIndex: "primaryNumber",
      key: "primaryNumber",
      width: 100,
    },
    {
      title: "Contact Status",
      dataIndex: "contactStatus",
      key: "contactStatus",
      width: 100,
    },
    {
      title: "Regulation Tags",
      dataIndex: "regulationTags",
      key: "regulationTags",
      width: 100,
      render: (regulationTags) => (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: regulationTags }}>
          {regulationTags
            ? regulationTags
                .toString()
                .toUpperCase()
                .replace(/[^a-zA-Z, ]/g, " ")
            : ""}
        </Text>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "comments",
      key: "comments",
      width: 100,
      render: (_, record) => (
        <Space>
          <Tooltip title={record?.comments}>
            <Button
              style={{ color: record?.comments?.length ? "#4096ff" : "black" }}
              type="text"
              icon={<MessageOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Space
        align="end"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <div>
          <Title level={5} style={{ marginBottom: 0 }}>
            Contacts
          </Title>
        </div>
        <Space>
          <SupplierContactModal type="create" fetchData={fetchData} />
        </Space>
      </Space>
      {contacts && (
        <div>
          <Table
            loading={loading}
            dataSource={supplierContacts}
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            columns={columns}
            rowKey="_id"
            pagination={{
              ...tablePagination,
              total: supplierContactsLength,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
          />
        </div>
      )}
    </div>
  );
};

export default ContactsPage;
