import React, { useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";

import { useAppDispatch } from "../../redux/hooks";
import { updateMaterial } from "../../redux/actions/materialsAction";
import {
  updateCampaignResponse,
  uploadCampaignDocument,
} from "../../redux/actions/CampaignManager/campaignManagerAction";
// import { PendingResponse } from '../../utils/types/CampaignManager/campaignManager';

const AddMaterialApprove: React.FC<{
  type: string;
  fetchData: any;
  formData?: any;
  sendgridCampaignId?: string;
}> = (props) => {
  const dispatch = useAppDispatch();

  const confirm = () => {
    const formValues = props?.formData;
    const { internalItemNumber, createdAt, manufacturer, _id, ...updates } =
      formValues;

    dispatch(
      updateMaterial({
        updates: updates,
        campaignData: props.sendgridCampaignId,
      })
    )
      .then(() => {
        dispatch(
          updateCampaignResponse({
            filter: {
              sendgridCampaignId: props?.sendgridCampaignId,
              componentId: props?.formData?.componentId,
            },
            updates: { ...props?.formData },
            responseType: "materials",
          })
        );
      })
      .then(() => {
        props?.formData?.fmdDocument &&
          dispatch(
            uploadCampaignDocument({
              foldername: "campaign",
              subfolder: "materials",
              campaignData: props?.sendgridCampaignId,
              file: props?.formData?.fmdDocument,
            })
          );
      })
      .then(() => {
        props.fetchData();
      })
      .catch((e) => console.log(e));
  };

  const cancel = () => {
    props.fetchData();
  };

  return (
    <div>
      <Popconfirm
        title="Approve Materials"
        description="Are you sure you want to approve selected Component Materials ?"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Ok"
        cancelText="Cancel"
        placement="topLeft"
      >
        <Button
          type={props?.type === "icon" ? "text" : "primary"}
          icon={<CheckCircleOutlined />}
          style={{ background: "#52c41a" }}
          data-testid="approve-materials-button"
          title="Approve Material"
        >
          {props?.type === "button" && "Approve"}
        </Button>
      </Popconfirm>
    </div>
  );
};

export default AddMaterialApprove;
