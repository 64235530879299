import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Button,
  Drawer,
  Switch,
  Slider,
} from "antd";

import { Substance } from "../../utils/types/regulation";
import { useAppDispatch } from "../../redux/hooks";
import {
  createSubstance,
  updateSubstance,
} from "../../redux/actions/regulationsAction";

const SubstanceForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Substance;
  id: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFormSubmit = (values: any) => {
    setLoading(true);
    if (props.type === "create") {
      dispatch(createSubstance({ regulationNumber: props.id, ...values }))
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(
        updateSubstance(
          { updates: { regulationNumber: props.id, ...values } },
          props.formData._id
        )
      )
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <div>
      <Button
        type={props.type === "create" ? "primary" : "text"}
        onClick={showDrawer}
        title={props.type === "create" ? "New Substance" : "Edit Substance"}
        data-testid={
          props.type === "create"
            ? "create-substance-button"
            : "edit-substance-button"
        }
        icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
      >
        {props.type === "create" ? "Add Substance" : null}
      </Button>
      <Drawer
        title={props.type === "create" ? "Add Substance" : "Edit Substance"}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-substance-button">
              Cancel
            </Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              data-testid="submit-substance-button"
            >
              {props.type === "create" ? "Submit" : "Save"}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={props.type === "create" ? {} : { ...props.formData }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="substance"
                label="Substance Name"
                rules={[
                  { required: true, message: "Please input Substance Name!" },
                  // {
                  //   pattern: /^([a-zA-Z0-9()]+\s?)*$/,
                  //   message: "Not allowed",
                  // },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="synonyms" label="Substance Synonyms">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="casNumber"
                label="CAS Number"
                rules={[
                  { required: true, message: "Please input CAS Number!" },
                  {
                    pattern: /^\d{1,7}-\d{2}-\d$/,
                    message: "Not allowed",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="casSynonyms" label="CAS Synonyms">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="ecNumber"
                label="EC Number"
                rules={[
                  { required: true, message: "Please input EC Number!" },
                  {
                    pattern: /^\d{1,7}-\d{1,3}-\d$/,
                    message: "Not allowed",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="ecSynonyms" label="EC Synonyms">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="threshold" label="Threshold">
                <Slider max={1} min={0} step={0.01} defaultValue={0} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="authorised"
                label="Authorised"
                extra="Lastest regulations are not authorised"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default SubstanceForm;
