import React, { useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";

import { useAppDispatch } from "../../redux/hooks";
import { bulkUpdateComplaince } from "../../redux/actions/compliancesAction";
import {
  updateCampaignResponse,
  uploadCampaignDocument,
} from "../../redux/actions/CampaignManager/campaignManagerAction";
// import { PendingResponse } from '../../utils/types/CampaignManager/campaignManager';

const AddComplianceApprove: React.FC<{
  type: string;
  fetchData: any;
  formData?: any;
  sendgridCampaignId?: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const buttonStyle =
    props.type === "button" ? { backgroundColor: "#52c41a" } : {};

  const confirm = () => {
    const formValues = props?.formData;
    const { internalItemNumber, createdAt, manufacturer, _id, ...updates } =
      formValues;

    if (props?.type === "button") {
      updates["supplierComplianceResponse"] = {
        ...updates,
      };
      delete updates.regulationNumber;
      delete updates.complianceDocument;
      delete updates.complianceOutputs;
      delete updates.status;
      delete updates.updatedAt;
      delete updates.updatedBy;
    }

    dispatch(
      bulkUpdateComplaince({
        updates: updates,
        campaignData: props?.sendgridCampaignId,
      })
    )
      .then(() => {
        dispatch(
          updateCampaignResponse({
            filter: {
              sendgridCampaignId: props?.sendgridCampaignId,
              componentId: props?.formData?.componentId,
            },
            updates:
              props?.type === "button" ? updates : { ...props?.formData },
          })
        );
      })
      .then(() => {
        props?.formData?.supplierComplianceResponse?.complianceDocument &&
          dispatch(
            uploadCampaignDocument({
              foldername: "campaign",
              subfolder: "compliances",
              campaignData: props?.sendgridCampaignId,
              file: props?.formData?.supplierComplianceResponse
                ?.complianceDocument,
            })
          );
      })
      .then(() => {
        props.fetchData();
      })
      .catch((e) => console.log(e));
  };

  const cancel = () => {
    props.fetchData();
  };

  return (
    <div>
      <Popconfirm
        title="Approve Compliance"
        description="Are you sure you want to approve selected Component Compliance ?"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Ok"
        cancelText="Cancel"
        placement="topLeft"
      >
        <Button
          type={props?.type === "icon" ? "text" : "primary"}
          icon={<CheckCircleOutlined />}
          style={buttonStyle}
          data-testid="approve-compliance-button"
          title="Approve Compliance"
        >
          {props?.type === "button" && "Approve"}
        </Button>
      </Popconfirm>
    </div>
  );
};

export default AddComplianceApprove;
