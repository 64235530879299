import React, { useState, Suspense } from "react";
import { Form, Input, Button, Typography, Card, Modal, Flex } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import {
  authLogin,
  authForgotPassword,
} from "../../../redux/actions/authAction";
import { setLocalStorage } from "../../../utils/localStore";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Paragraph } = Typography;

type AuthResponse = {
  success: boolean;
  message?: string;
  status?: number;
  mfa?: boolean;
  emailAddress?: string;
};

const Login: React.FC = () => {
  const { workspace } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const response: AuthResponse = await dispatch(
        authLogin({ ...values, tenantNumber: workspace })
      );
      if (response.mfa) {
        setLocalStorage("emailAddress", response.emailAddress);
        navigate(`/login/authentication/${workspace}`);
      } else {
        navigate(`/app`);
      }
    } catch (error: any) {
      console.error("Unexpected error during login:", error);
    }
  };

  const onForgotPasswordClick = async () => {
    try {
      const values = await form.validateFields(["emailAddress"]);

      Modal.confirm({
        title: "Are you sure?",
        content: (
          <Paragraph>
            You will receive an email with a reset password link which expires
            in 24 hrs. Please click &#34;Yes&#34; to confirm.
          </Paragraph>
        ),
        onOk: async () => {
          try {
            await dispatch(
              authForgotPassword({
                emailAddress: values.emailAddress,
                tenantNumber: workspace,
                currentLocationOrigin: window.location.origin,
              })
            );
          } catch (error) {
            console.error("Error sending reset password link:", error);
          }
        },
      });
    } catch (error) {
      form.setFields([
        { name: "emailAddress", errors: ["Please input your email address!"] },
      ]);
    }
  };

  const onSendInvitation = async () => {
    try {
      const values = await form.validateFields(["invitationEmailAddress"]);
      console.log("Invitation sent to:", values.invitationEmailAddress);

      Modal.success({
        title: "Invitation Sent",
        content: `Invitation sent to ${values.invitationEmailAddress}.`,
      });
    } catch (error) {
      console.error("Error sending invitation:", error);
    }
  };

  return (
    <ErrorBoundary>
      <Card>
        <Paragraph strong style={{ textAlign: "center" }}>
          Login to {workspace}
        </Paragraph>
        <Form
          form={form}
          onFinish={onFinish}
          style={{ marginTop: "1.5rem", minWidth: 400 }}
        >
          <Form.Item
            name="emailAddress"
            rules={[
              { required: true, message: "Please input your email address!" },
              { type: "email", message: "Please enter a valid email address" },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Flex justify="space-between">
            <Button type="link" onClick={onForgotPasswordClick}>
              Forgot Password?
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Login
            </Button>
          </Flex>
        </Form>

        <Suspense fallback={<div>Loading...</div>}>
          <Modal
            open={resetPasswordSuccess}
            title="Reset Password Email Sent"
            onOk={() => setResetPasswordSuccess(false)}
            onCancel={() => setResetPasswordSuccess(false)}
          >
            {/* ... Modal content ... */}
          </Modal>
        </Suspense>

        {/* IV Modal */}
        <Modal
          visible={false}
          title="Send Invitation"
          onCancel={() => form.resetFields(["invitationEmailAddress"])}
          onOk={onSendInvitation}
        >
          <Paragraph>
            You are about to send an invitation to this email address. Please
            click &#34;OK&#34; to confirm.
          </Paragraph>
          <Form.Item
            name="invitationEmailAddress"
            rules={[
              { required: true, message: "Please input the email address!" },
              { type: "email", message: "Please enter a valid email address" },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
        </Modal>
      </Card>
    </ErrorBoundary>
  );
};

export default Login;
