import React, { useState } from "react";
import { Button, Card, Col, Drawer, Row, Tabs, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import Compliance1 from "../../../assets/supplier portal/compliance/1.png";
import Compliance2 from "../../../assets/supplier portal/compliance/2.png";
import Compliance3 from "../../../assets/supplier portal/compliance/3.png";
import Compliance4 from "../../../assets/supplier portal/compliance/4.png";
import Compliance5 from "../../../assets/supplier portal/compliance/5.png";
import Compliance6 from "../../../assets/supplier portal/compliance/6.png";
import Compliance7 from "../../../assets/supplier portal/compliance/7.png";
import Compliance8 from "../../../assets/supplier portal/compliance/8.png";
import Compliance9 from "../../../assets/supplier portal/compliance/9.png";
import Compliance10 from "../../../assets/supplier portal/compliance/10.png";

import ComplianceBulk1 from "../../../assets/supplier portal/compliance bulk/1.png";
import ComplianceBulk2 from "../../../assets/supplier portal/compliance bulk/2.png";
import ComplianceBulk3 from "../../../assets/supplier portal/compliance bulk/3.png";
import ComplianceBulk4 from "../../../assets/supplier portal/compliance bulk/4.png";
import ComplianceBulk5 from "../../../assets/supplier portal/compliance bulk/5.png";

import Material1 from "../../../assets/supplier portal/material/1.png";
import Material2 from "../../../assets/supplier portal/material/2.png";

import Conflict1 from "../../../assets/supplier portal/conflict/1.png";
import Conflict2 from "../../../assets/supplier portal/conflict/2.png";
import Conflict3 from "../../../assets/supplier portal/conflict/3.png";
import Conflict4 from "../../../assets/supplier portal/conflict/4.png";
import Conflict5 from "../../../assets/supplier portal/conflict/5.png";
import Conflict6 from "../../../assets/supplier portal/conflict/6.png";
import Conflict7 from "../../../assets/supplier portal/conflict/7.png";
import Conflict8 from "../../../assets/supplier portal/conflict/8.png";
import Conflict9 from "../../../assets/supplier portal/conflict/9.png";

import Support1 from "../../../assets/supplier portal/support/1.png";
import Support2 from "../../../assets/supplier portal/support/2.png";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Title, Text } = Typography;

const InstructionModal: React.FC<{ selected: any }> = (props) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const instructions = (
    <>
      <Title level={3} style={{ textAlign: "center" }}>
        <span style={{ background: "yellow" }}> IMPORTANT: </span>
      </Title>
      <Text>
        <span style={{ background: "red" }}> Support :</span> <br></br>
        Ensure that all the required information is uploaded. If you encounter
        any issues, please don&#39;t hesitate to contact our support team via
        phone or email. We are here to assist you in completing this process.
      </Text>
      <Col span={12}>
        <img
          src={Support1}
          alt=""
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        />
      </Col>
      <Text>
        <span style={{ background: "red" }}> Declaration Templates: </span>{" "}
        <br></br>
        You can find declaration templates for the regulations in the
        introduction tab, along with additional information about them. Should
        you have any queries, please don&#39;t hesitate to visit the support tab
        and reach out to us for assistance.
      </Text>
      <Col span={12}>
        <img
          src={Support2}
          alt=""
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        />
      </Col>
    </>
  );
  const items = [
    {
      key: "1",
      label: "Method 1: Part by Part Approach",
      children: (
        <>
          <Text italic>
            <b> Tip : </b>
            If you have internally stored your compliance data product by
            product basis then this approach will be convenient for you. Click a
            line item and submit data together for all the requested regulation
            for that particular product/Part.
          </Text>
          <ul style={{ lineHeight: "2" }}>
            <li>
              Once you enter the supplier portal using the link received through
              email, you will find the list of part(s) you supply to your
              customer in &#39;Campaign&#39; page. Below snapshot is an example
              of how it looks
            </li>
            <Col span={12}>
              <img
                src={Compliance1}
                alt=""
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
              />
            </Col>
            <li>
              Choose the regulation you wish to update, then click the
              &#39;Edit&#39; button to modify the compliance status for each
              section, and attach the relevant compliance document as required
              for the specified regulations.
            </li>
            <Col span={12}>
              <img src={Compliance2} alt="" style={{ marginTop: "1rem" }} />
            </Col>
            <Col span={12}>
              <img src={Compliance3} alt="" style={{ marginBottom: "1rem" }} />
            </Col>
            <li>
              Once you have entered &#39;Edit Compliance&#39; page, please
              update the compliance details.
            </li>
            <li>
              You can use the dropdown to make the selection and attach the
              compliance certificate in the compliance document field as shown
              below.
            </li>
            <Col span={12}>
              <img src={Compliance4} alt="" style={{ marginTop: "1rem" }} />
            </Col>
            <Col span={12}>
              <img src={Compliance5} alt="" />
            </Col>
            <Col span={12}>
              <img src={Compliance6} alt="" />
            </Col>
            <li>
              To complete the process, click on the &#39;SUBMIT&#39; button
              located in the bottom right corner.
            </li>
            <Col span={12}>
              <img src={Compliance7} alt="" style={{ marginTop: "1rem" }} />
            </Col>
            <li>
              The screenshot below serves as an example of the updated view,
              displaying the attached certificate and all the necessary
              information for RoHS or California Prop 65 or any other regulation
              .
            </li>
            <Col span={12}>
              <img
                src={Compliance8}
                alt=""
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
              />
            </Col>
            <li>
              After you have updated the compliance data for a regulation,
              please navigate to other regulations tab (EU REACH, CA Prop 65 &
              TSCA 8(a)(7) PFAS) as shown below and update the information in
              the similar way.
            </li>
            <Col span={12}>
              <img src={Compliance9} alt="" style={{ marginTop: "1rem" }} />
            </Col>
            <Col span={12}>
              <img src={Compliance10} alt="" />
            </Col>
          </ul>
          {instructions}
        </>
      ),
    },
    {
      key: "2",
      label: "Method 2: Regulation by Regulation Approach (Bulk Update)",
      children: (
        <>
          <Text italic>
            <b> Tip : </b>
            If you have internally structured your compliance data separately
            for each regulation then this approach will be convenient for you.
            Navigate to the requested compliance tab. You can select multiple
            products/parts for which you wish to submit compliance status
            together with a common compliance document.
          </Text>
          <ul style={{ lineHeight: "2" }}>
            <li>
              Below are few examples with screenshots on how to update
              regulations for multiple products/parts in bulk.
            </li>
            <li>
              To update compliance information in bulk, first you need to
              navigate to the respective regulation tab. Please note that only
              the regulations requested by your customer in a particular
              campaign will be displayed to you as a tab.
            </li>
            <li>
              When you navigate to a particular regulation tab, use the
              checklist box to select multiple parts for which you want to
              update the compliance data in bulk and also upload the common
              compliance document.
            </li>
            <Col span={8}>
              <img
                src={ComplianceBulk1}
                alt=""
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
              />
            </Col>
            <li>
              Now click on &#39;Submit&#39; and provide the compliance
              information with certificate in bulk for all the parts selected.
              In this case I have selected all the parts.
            </li>
            <Col span={12}>
              <img src={ComplianceBulk2} alt="" style={{ marginTop: "1rem" }} />
            </Col>
            <Col span={12}>
              <img src={ComplianceBulk3} alt="" />
            </Col>
            <Col span={12}>
              <img src={ComplianceBulk4} alt="" />
            </Col>
            <li>
              Now navigate to other requested regulation tab to update
              compliance information at bulk as shown above for TSCA 8(a)(7)
              PFAS
            </li>
            <li>
              Click on &#39;Submit&#39; once you have entered the data for all
              required fields.
            </li>
            <li>
              Similarly, below is the screenshot on how to update bulk
              information for regulations like PFAS, CA Prop 65 & TSCA PBT for
              your quick reference
            </li>
            <Col span={12}>
              <img src={ComplianceBulk5} alt="" style={{ marginTop: "1rem" }} />
            </Col>
          </ul>
          {instructions}
        </>
      ),
    },
  ];

  return (
    <div>
      <ErrorBoundary>
        Learn More{" "}
        <Button
          type="text"
          onClick={showDrawer}
          icon={<ArrowRightOutlined />}
        />
        <Drawer onClose={onClose} open={open} width={1800}>
          {props.selected === "regulations" && (
            <>
              <Title level={5}>
                How to submit Compliance information for Restricted Substance
                Regulations (such as EU RoHS, EU REACH, CA PROP65, TSCA PBT, POP
                & so on) using supplier portal?
              </Title>
              <Tabs defaultActiveKey="1" items={items} tabPosition="top" />
            </>
          )}
          {props.selected === "materials" && (
            <>
              <Title level={5}>
                How To Update the Full Material Declaration in Supplier Portal?
              </Title>
              <div>
                <ul style={{ lineHeight: "2" }}>
                  <li>
                    Once you enter the supplier portal using the link received
                    through email, navigate to Material Disclosure tab as shown
                    in the screenshot below to find the part(s) you supply to
                    your customer
                  </li>
                  <Col span={12}>
                    <img src={Material1} alt="" />
                  </Col>
                  <li>
                    Click on the edit (Pencil button) to update Material
                    Disclosure (FMD) information for a part.
                  </li>
                  <li>
                    Once the required fields are updated and Full material
                    declaration document is attached (preferred FMD Format: IPC
                    1752A Class D XML file) please click on &#39;SUBMIT&#39; to
                    save.
                  </li>
                  <Col span={12}>
                    <img src={Material2} alt="" />
                  </Col>
                </ul>
                {instructions}
              </div>
            </>
          )}
          {props.selected &&
            (props.selected.conflict === "Yes" ||
              props.selected.extended === "Yes") && (
              <>
                <Title level={5}>
                  How To Update
                  {props.selected && props.selected.conflict === "Yes"
                    ? " Conflict Minerals (CMRT file)"
                    : ""}
                  {props.selected &&
                  props.selected.conflict === "Yes" &&
                  props.selected.extended === "Yes"
                    ? "and"
                    : ""}
                  {props.selected && props.selected.extended === "Yes"
                    ? " Extended Minerals (EMRT)"
                    : ""}
                  in Supplier Portal
                </Title>
                <div>
                  <ul style={{ lineHeight: "2" }}>
                    <li>
                      After accessing the Supplier Portal through the email link
                      you received, click on either the &#39;Conflict
                      Minerals&#39; or &#39;Extended Minerals&#39; tab,
                      depending on what you intend to upload.
                    </li>
                    <li>
                      Select &#39;IMPORT&#39; to upload the most recent
                      reporting template file, as demonstrated below for your
                      convenience.{" "}
                      <span style={{ background: "#40e0d0" }}>
                        This example illustrates how to upload a CMRT, and the
                        process will be similar for Extended Minerals as well.
                      </span>
                    </li>
                    <Col span={12}>
                      <img
                        src={Conflict1}
                        alt=""
                        style={{ marginTop: "1rem", marginBottom: "1rem" }}
                      />
                    </Col>
                    <li>
                      After the CMRT file is successfully imported, tool will
                      capture the information automatically from your file and
                      you will see a message “Submit & Close”.
                      <li>
                        Click &#39;Submit & Close&#39; OR if you wish to review
                        the data before submitting, click on the tabs as shown
                        below.
                      </li>
                      <li>
                        Below screen shots are examples for your easy reference.
                      </li>
                    </li>
                    <Col span={12}>
                      <img
                        src={Conflict2}
                        alt=""
                        style={{ marginTop: "1rem" }}
                      />
                    </Col>
                    <Col span={12}>
                      <img src={Conflict3} alt="" />
                    </Col>
                    <Col span={12}>
                      <img src={Conflict4} alt="" />
                    </Col>
                    <Col span={12}>
                      <img src={Conflict5} alt="" />
                    </Col>
                    <Col span={12}>
                      <img src={Conflict6} alt="" />
                    </Col>
                    <Col span={12}>
                      <img src={Conflict7} alt="" />
                    </Col>
                    <Col span={12}>
                      <img
                        src={Conflict8}
                        alt=""
                        style={{ marginBottom: "1rem" }}
                      />
                    </Col>
                    <li>
                      Once you have verified the CMRT data click on &#39;Submit
                      & Close&#39;.
                    </li>
                    <Col span={12}>
                      <img
                        src={Conflict9}
                        alt=""
                        style={{ marginTop: "1rem" }}
                      />
                    </Col>
                  </ul>
                  {instructions}
                </div>
              </>
            )}
        </Drawer>
      </ErrorBoundary>
    </div>
  );
};

export default InstructionModal;
