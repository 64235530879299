import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
  publicPostApi,
} from "../../utils/apiHelper";
import { setExtendedMinerals } from "../slices/extendedMineralsSlice";
import { StateSmelter } from "../../utils/types/smelter";
import { analyticsEMRT } from "../../utils/types/summary";
import { consolidatedEMRT } from "../../utils/types/summary";
import { Dispatch } from "redux";

/**
 * Bulk Operations
 */
export const listExtendedMinerals = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<void>((resolve, reject) => {
    privatePostApi("/extendedminerals/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setExtendedMinerals(response.data.data));
          resolve();
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */

export const readExtendedMinerals = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/extendedminerals/read", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateExtendedMinerals = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/extendedminerals/read" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deletExtendedMinerals = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/extendedminerals/read" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Smelter List
export const listSmelter = (payload: any): Promise<StateSmelter> => {
  return new Promise<StateSmelter>((resolve, reject) => {
    privateGetApi("/smelters/suppliers/smelters", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// consolidated CMRT Table
export const extendedMineralsConsolidatedtable = (
  payload: any,
  id: string
): Promise<consolidatedEMRT> => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/extendedminerals/read/latest/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//analytics
export const extendedMineralsAnalytics = (
  payload: any
): Promise<analyticsEMRT> => {
  return new Promise<analyticsEMRT>((resolve, reject) => {
    privateGetApi("/analytics/extendedmineralsanalytics", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//create EMRT
export const createExtendedMinerals = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/extendedminerals", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Generateextended
export const generateExtendedMinerals = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/extendedminerals/read/generateemrt", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//read anaylytics for consolidate
export const readAnalyticsExtendedMinerals = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/extendedminerals/read/analytics", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//upload file
export const uploadExtendedMineralsDocument = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("foldername", payload.foldername);
    privatePostApi(
      "/extendedminerals/document/upload/" + payload.foldername,
      formData
    )
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Read public smelters
export const readPublicSmelter = (payload: any): Promise<StateSmelter> => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/smelters/read", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readExtendedMineralsDocument = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi(
      "/extendedminerals/document/read/" + payload.foldername,
      payload
    )
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const createExtendedMineralsSmelter = (payload: any)=> () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi(
      "/extendedminerals/smelter",
      payload
    )
      .then((response :any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const bulkWriteExtendedMineralsDocuments = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/extendedminerals/update", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

