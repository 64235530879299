import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { UsersState } from "../../utils/types/user";
import { Dispatch } from "redux";
import { message } from "antd";

/**
 * Bulk Operations
 */
export const listUsers = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<UsersState>((resolve, reject) => {
    privateGetApi("/users", payload)
      .then((response: any) => {
        if (response.status === 200) {
          // dispatch(setUsers(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createUser = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/users", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const reactivateSessionToken = (payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/users/reactivatetoken", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readUser = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/users/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUser = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/users/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserMFA = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/users/mfa", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteUser = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/users/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * ADDITIONAL Operations
 */
export const resendInviteUser = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/public/resend-invite", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          message.success(response.data.message);
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const blockAccessUser = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/users/block-user", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          message.success(response.data.message);
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updatePasswordUser = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/users/update-password", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          message.success(response.data.message);
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
