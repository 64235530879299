import React from "react";
import { useState } from "react";
import { Button, Modal } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import { reactivateSessionToken } from "../../redux/actions/usersAction";
import { getLocalStorage, setLocalStorage } from "../../utils/localStore";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../../utils/localStore";

const ReactivateSessionToken: React.FC<{
  isModalOpen: any;
  openModal: any;
  logoutTimer?: any;
}> = (props) => {
  const [seconds, setSeconds] = useState(30);
  const [tokenGenerated, setTokenGenerated] = useState(false);
  const dispatch = useAppDispatch();
  const user = getLocalStorage("user");
  const navigate = useNavigate();

  const onSubmit = () => {
    setTokenGenerated(true);
    dispatch(
      reactivateSessionToken({
        emailAddress: user.emailAddress,
      })
    )
      .then((res) => {
        setLocalStorage("accessToken", res.token);
        setLocalStorage("expireAt", new Date(res.expiresAt).getTime());
        props.openModal();
      })
      .catch((e) => console.log(e));
  };

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (tokenGenerated === false) {
      clearLocalStorage();
      navigate("/");
    }
  });

  return (
    <>
      <Modal
        open={props.isModalOpen}
        title="Session Expire Warning !"
        closable={false}
        footer={
          <Button key="link" type="primary" onClick={onSubmit}>
            Stay Logged In ( {seconds} )
          </Button>
        }
      >
        <p>
          Your session is about to expire. Please renew your access token to
          stay signed in. Otherwise, you will be logged off automatically.
        </p>
      </Modal>
    </>
  );
};

export default ReactivateSessionToken;
