import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { RolesState } from "../../utils/types/role";

/**
 * Bulk Operations
 */
export const listRoles = (payload: any) => () => {
  return new Promise<RolesState>((resolve, reject) => {
    privateGetApi("/users/roles", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createRole = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/users/roles", payload, "roles")
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readRole = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/users/roles/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateRole = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/users/roles/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteRole = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/users/roles/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
