import React, { FC, useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
import { AnalyticsAdmin } from "../../../../redux/actions/analyticsAction";
import { Card, Col, Row } from "antd";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface ChartProps {
  data: any[];
  chartConfig: any; 
  chartType: string; 
}

const Chart: FC<ChartProps> = ({ data, chartConfig, chartType }) => {
  useEffect(() => {
    FusionCharts.ready(function () {
      new FusionCharts({
        type: chartType,
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: { ...chartConfig, data },
      }).render("chart-container");
    });
  }, [data, chartConfig, chartType]);

  return <div id="chart-container" />;
};

const TenantAnalyticsDashboard: FC<{ tenant: string }> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [analytics, setanalytics] = useState<any[]>([]);
  const [adminAnalytics, setadminAnalytics] = useState<any[]>([]);

  const fetchAdminAnalyticsData = async () => {
    setLoading(true);
    try {
      const response = await AnalyticsAdmin({
        pageSize: 10,
        pageNum: 1,
        filters: JSON.stringify({
          softDelete: false,
        }),
        sortBy: ["timeStamp"],
        sortDesc: [false],
      });
      if (response) {
        setadminAnalytics(
          response?.tenantAnalytics[0]?.analytics[0][props.tenant]
        );
      } else {
        console.error("Invalid or missing data in the API response.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAdminAnalyticsData();
  }, []);

  useEffect(() => {
    if (adminAnalytics?.length > 0) {
      setanalytics(
        adminAnalytics
          ?.filter((v: { status: string }) => v?.status && v?.status !== "NA")
          .filter(
            (v: { label: string }) =>
              v?.label === "products" ||
              v?.label === "users" ||
              v?.label === "regulations"
          )
      );
    }
  }, [adminAnalytics]);

  const dataSets = adminAnalytics
    ?.filter((v) => v?.status && v?.status !== "NA")
    .filter(
      (v) =>
        v?.label !== "products" &&
        v?.label !== "users" &&
        v?.label !== "regulations"
    );
  const labelValues = [];
  for (let i = 0; i < dataSets?.length; i++) {
    labelValues.push({ label: dataSets[i].label });
  }

  const subscriptionValues = [];
  for (let i = 0; i < dataSets?.length; i++) {
    subscriptionValues.push({ value: dataSets[i].value });
  }

  const totalUsage = [];
  for (let i = 0; i < dataSets?.length; i++) {
    totalUsage.push({ value: dataSets[i].count });
  }
  const chartConfig = {
    chart: {
      theme: "fusion",
      caption: "Comparison of user data",
      yAxis: {
        interval: "500",
      },
    },
    categories: [
      {
        category: labelValues,
      },
    ],
    dataset: [
      {
        seriesname: "Subscription count",
        data: subscriptionValues,
      },
      {
        seriesname: "Total Usage",
        data: totalUsage,
      },
    ],
  };

  return (
    <div>
      <Chart
        data={chartConfig.dataset}
        chartConfig={chartConfig}
        chartType="mscolumn2d"
      />

      <Row gutter={[16, 16]}>
        {analytics.map((item, i) => (
          <Col key={i} sm={12} md={8}>
            <Card className="mb-4 mt-3" bordered={true} hoverable>
              <Card.Meta title={item.label} />
              <div style={{ textAlign: "center" }}>
                <h3>{item.count}</h3> out of {item.value}
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TenantAnalyticsDashboard;
