import React, { useEffect, useState } from "react";
import { baseUrl } from "../../utils/apiHelper";
import axios from "axios";
import { getLocalStorage } from "../../utils/localStore";
import { LinkOutlined } from "@ant-design/icons";
import { Button, Modal, Result, Tooltip } from "antd";

const ReadSupportingDocument: React.FC<{
  postUrl: any;
  filename: string;
  foldername: string;
  subfolder?: string;
  campaignId?: string;
  type?: string;
}> = (props) => {
  const [fileContent, setFileContent] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    const a = document.createElement("a");
    a.href = fileContent;
    a.download = props.filename;
    a.click();
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileContent("");
  };

  const fetchFileRecord = () => {
    if (props.filename) {
      axios
        .post(
          baseUrl + "/" + props.postUrl,
          {
            tenantNumber: getLocalStorage("user").tenantNumber,
            filename: props.filename,
            foldername: props.foldername,
            campaignId: props?.campaignId,
            subfolder: props.subfolder,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
          }
        )
        .then((response: any) => {
          const fileType = (
            props?.filename?.split(".")?.pop() ?? ""
          ).toLowerCase();
          const defaultType = "application/pdf";
          const fileFormatMap: { [key: string]: string } = {
            pdf: "application/pdf",
            jpg: "image/jpg",
            jpeg: "image/jpeg",
            png: "image/png",
            docx: "application/octet-stream",
            doc: "application/octet-stream",
            xls: "application/vnd.ms-excel",
            xlsx: "application/vnd.ms-excel",
            xml: ".xml",
            csv: "text/csv;charset=utf-8;",
          };
          let blobPDF;
          if (fileType) {
            blobPDF = new Blob([response.data], {
              type: fileFormatMap[fileType],
            });
          } else {
            blobPDF = new Blob([response.data], {
              type: defaultType,
            });
          }
          const blobURL = URL.createObjectURL(blobPDF);
          if (["docx", "doc", "xls", "csv"].includes(fileType)) {
            const a = document.createElement("a");
            a.href = blobURL;
            a.download = props.filename;
            a.click();
          } else {
            setFileContent(blobURL);
          }
        });
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchFileRecord();
    }
  }, [isModalOpen]);

  return (
    <div>
      {props.filename && (
        <Tooltip title={props.filename}>
          {/* <LinkOutlined onClick={showModal} /> */}
          <Button
            type="text"
            icon={
              <LinkOutlined style={{ color: props.type ? "white" : "black" }} />
            }
            onClick={showModal}
          >
            {props.type
              ? undefined
              : props?.filename?.split(".").pop()?.toUpperCase()}
          </Button>
        </Tooltip>
      )}{" "}
      <Modal
        title={props?.filename}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Download"
        width="80%"
      >
        {["pdf", "xml", "png", "jpeg", "jpg"].includes(
          props?.filename?.split(".")?.pop()?.toLowerCase() as string
        ) ? (
          <iframe
            title="File Viewer"
            src={fileContent}
            width="100%"
            height="500px"
          />
        ) : (
          <Result title="Preview unavailable, file format not supported" />
        )}
      </Modal>
    </div>
  );
};

export default ReadSupportingDocument;
