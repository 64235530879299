import React from "react";
import { Card, Col, Row, Typography } from "antd";
import { getLocalStorage } from "../../../utils/localStore";
import Compliance from "../../../assets/supplier portal/compliant.png";
import Material from "../../../assets/supplier portal/material.png";
import Mineral from "../../../assets/supplier portal/conflict.png";
import Technical from "../../../assets/supplier portal/technical-support.png";
import Communicate from "../../../assets/supplier portal/communicate.png";
import InstructionModal from "./instructionModal";
import ContactSupport from "../../../components/forms/ContactSupport";
import { useParams } from "react-router-dom";
import TechnicalSupport from "../../../components/forms/TechnicalSupport";
import { useState } from "react";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Title, Paragraph } = Typography;
const Support: React.FC = () => {
  const campaignData = getLocalStorage("campaignData");
  const { uniqueName, linkTenantNumber, linkCampaignId } = useParams();

  return (
    <div>
      <ErrorBoundary>
        <Row
          gutter={[16, 16]}
          style={{ alignItems: "stretch", justifyContent: "center" }}
        >
          {campaignData?.regulations?.length && (
            <Col span={12}>
              <Card
                style={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img alt="Edit" src={Compliance} height={100} />
                </div>
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Title level={3}>Update Compliance Guide</Title>
                  <Paragraph>
                    How To Update the compliance status or Regulation
                    information ? <InstructionModal selected="regulations" />
                  </Paragraph>
                </div>
              </Card>
            </Col>
          )}
          {campaignData?.materials === "Yes" && (
            <Col span={12}>
              <Card
                style={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img alt="Edit" src={Material} height={100} />
                </div>
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Title level={3}>
                    Update Material Disclosure (FMD) Guide
                  </Title>
                  <Paragraph>
                    How To Update the Full Material Declaration in Supplier
                    Portal? <InstructionModal selected="materials" />
                  </Paragraph>
                </div>
              </Card>
            </Col>
          )}
          {(campaignData?.conflict === "Yes" ||
            campaignData?.extended === "Yes") && (
            <Col span={12}>
              <Card
                style={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img alt="Edit" src={Mineral} height={100} />
                </div>
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Title level={3}>
                    {" "}
                    Update{" "}
                    {campaignData && campaignData.conflict === "Yes"
                      ? " Conflict Minerals (CMRT file) "
                      : ""}
                    {campaignData &&
                    campaignData.conflict === "Yes" &&
                    campaignData.extended === "Yes"
                      ? "and"
                      : ""}
                    {campaignData && campaignData.extended === "Yes"
                      ? " Extended Minerals (EMRT) "
                      : ""}
                    Guide
                  </Title>
                  <Paragraph>
                    How To Update
                    {campaignData && campaignData.conflict === "Yes"
                      ? " Conflict Minerals (CMRT file) "
                      : ""}
                    {campaignData &&
                    campaignData.conflict === "Yes" &&
                    campaignData.extended === "Yes"
                      ? "and"
                      : ""}
                    {campaignData && campaignData.extended === "Yes"
                      ? " Extended Minerals (EMRT) "
                      : ""}
                    in Supplier Portal{" "}
                    <InstructionModal selected={campaignData} />
                  </Paragraph>
                </div>
              </Card>
            </Col>
          )}
          <Col span={12}>
            <Card
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img alt="Edit" src={Technical} height={100} />
              </div>
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Paragraph>Facing issues related to technical ?</Paragraph>
                Technical Support <TechnicalSupport />
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img alt="Edit" src={Communicate} height={100} />
              </div>
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Paragraph>Cannot find what you are looking for ?</Paragraph>
                Contact support{" "}
                <ContactSupport
                  tenantNumber={window.atob(linkTenantNumber!)}
                  type="support"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </ErrorBoundary>
    </div>
  );
};

export default Support;
