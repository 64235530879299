import React from "react";
import {
  ExperimentOutlined,
  ExportOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../../utils/localStore";
import { Button, Table, Typography, Space, Tabs, Tag } from "antd";
import ReadSupportingDocument from "../../../../components/modals/ReadSupportingDocuments";

const { Text } = Typography;

const AuditLogsTab: React.FC<{
  componentHistory: any;
  complianceHistory: any;
}> = (props) => {
  const regulations = getRegulationsFromLocalStorage() ?? [];
  const user = getLocalStorage("user");
  const parseUser = user ? user : null;
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const columns: ColumnsType<any> = [
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      width: 150,
      render: (_, record: any) => <Text>{record?.document?.__v}</Text>,
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      width: 150,
      render: (_, record: any) => (
        <Text>{record?.document?.internalItemNumber}</Text>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      render: (_, record: any) => (
        <Text>
          {" "}
          {record?.document?.manufacturer?.itemNumber} <br></br>
          <small>{record?.document?.manufacturer?.name}</small>
        </Text>
      ),
      width: 200,
    },
    ...(parseUser?.specifications || [])
      .filter((o: any) => !o.hidden)
      .map((e: any) => ({
        title: e.name ? e.name.charAt(0).toUpperCase() + e.name.slice(1) : "",
        dataIndex: `specification.${e.value}`,
        key: `specification.${e.value}`,
        width: 150,
        render(_: any, record: any) {
          if (e.dataType === "Dropdown") {
            if (
              record.document?.specification?.lifecycle
                ?.toUpperCase()
                .trim() === "ACTIVE"
            ) {
              return <Tag color="green">{record.specification?.lifecycle}</Tag>;
            } else if (
              record.document?.specification?.lifecycle
                ?.toUpperCase()
                .trim() === "NRND"
            ) {
              return (
                <Tag color="orange">{record.specification?.lifecycle}</Tag>
              );
            } else if (
              record.document?.specification?.lifecycle
                ?.toUpperCase()
                .trim() === "UNKNOWN"
            ) {
              return (
                <Tag color="grey">
                  {record.document?.specification?.lifecycle}
                </Tag>
              );
            } else {
              return record.document?.specification &&
                record.document?.specification?.lifecycle ? (
                <Tag color="red">
                  {record.document?.specification?.lifecycle}
                </Tag>
              ) : (
                ""
              );
            }
          } else if (e.dataType === "Fileinput") {
            return (
              <>
                {record?.specification &&
                record?.specification[e.value]?.includes("http") ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(record?.specification[e.value])
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="products/components/document/download"
                    filename={
                      record?.specification && record?.specification[e.value]
                    }
                    foldername="components"
                  />
                )}
              </>
            );
          } else {
            return record.document?.specification
              ? record.document?.specification[e.value]
              : "";
          }
        },
      })),
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ];

  const regulationsTabs = regulations?.map((regulation: any) => ({
    key: regulation.regulationNumber,
    label: regulation.name,
    children: (
      <>
        {permissions?.read?.includes("compliances") && (
          <Table
            dataSource={props.complianceHistory?.filter(
              (e: any) =>
                e.document.regulationNumber === regulation.regulationNumber
            )}
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            columns={[
              {
                title: "Version",
                key: "version",
                width: 150,
                render: (_, record: any) => (
                  <Text>{record && record?.__v}</Text>
                ),
              },
              ...regulation.configuration
                .filter(
                  (e: any) => !e.hidden && e.value !== "internalItemNumber"
                )
                .map((k: any, i: any) => ({
                  title: `${k.text}`,
                  dataIndex: `${k.value}`,
                  key: `${k.value}`,
                  width: k.value === "complianceOutputs.substance" ? 255 : 180,
                  render: (_: any, record: any) => {
                    if (record && record?.document) {
                      if (k.value === "manufacturer") {
                        return (
                          <Text>
                            {" "}
                            {record?.document?.manufacturer.itemNumber ||
                              ""}{" "}
                            <br></br>
                            <small>
                              {record?.document?.manufacturer.name || ""}
                            </small>
                          </Text>
                        );
                      } else if (k.value === "complianceDocument") {
                        return (
                          <Text>{`${
                            record?.document?.complianceDocument
                              ? record?.document?.complianceDocument
                              : ""
                          }`}</Text>
                        );
                      } else if (
                        k.value.includes("complianceOutputs.compliant")
                      ) {
                        if (
                          record &&
                          record.document &&
                          record.document?.complianceOutputs &&
                          record.document?.complianceOutputs.compliant &&
                          record.document?.complianceOutputs.compliant?.toUpperCase() ===
                            "YES"
                        ) {
                          return <Tag color="green"> YES </Tag>;
                        } else if (
                          record &&
                          record.document &&
                          record.document?.complianceOutputs &&
                          record.document?.complianceOutputs.compliant &&
                          record.document?.complianceOutputs.compliant?.toUpperCase() ===
                            "NO"
                        ) {
                          return <Tag color="red"> NO </Tag>;
                        } else if (
                          record &&
                          record.document?.complianceOutputs &&
                          record.document?.complianceOutputs.compliant?.toUpperCase() ===
                            "YES WITH EXEMPTION"
                        ) {
                          return <Tag color="orange"> YES WITH EXEMPTION </Tag>;
                        } else if (
                          record &&
                          record.document &&
                          record.document?.complianceOutputs &&
                          record.document?.complianceOutputs.compliant &&
                          record.document?.complianceOutputs.compliant?.toUpperCase() ===
                            "UNKNOWN"
                        ) {
                          return <Tag color="default"> UNKNOWN </Tag>;
                        } else {
                          return record &&
                            record.document &&
                            record.document?.complianceOutputs &&
                            record.document?.complianceOutputs.compliant ? (
                            <Tag color="grey">
                              {record &&
                                record.document &&
                                record.document?.complianceOutputs &&
                                record.document?.complianceOutputs.compliant}
                            </Tag>
                          ) : (
                            ""
                          );
                        }
                      } else if (
                        k.value.includes("complianceOutputs.version")
                      ) {
                        return (
                          <Text>{`${
                            record &&
                            record.document?.complianceOutputs &&
                            record.document?.complianceOutputs.version
                              ? record.document?.complianceOutputs.version
                              : ""
                          }`}</Text>
                        );
                      } else if (
                        k.value.includes("complianceOutputs.exemption")
                      ) {
                        return (
                          <Text
                            style={{ width: 200 }}
                            ellipsis={{
                              tooltip:
                                record?.document?.complianceOutputs?.exemption,
                            }}
                          >
                            {record &&
                            record.document?.complianceOutputs &&
                            record.document?.complianceOutputs.exemption
                              ? record.document?.complianceOutputs.exemption
                              : ""}
                          </Text>
                        );
                      } else if (
                        k.value.includes("complianceOutputs.substance")
                      ) {
                        return (
                          <Text
                            style={{ width: 200 }}
                            ellipsis={{
                              tooltip:
                                record?.document?.complianceOutputs?.substance,
                            }}
                          >
                            {record &&
                            record.document?.complianceOutputs &&
                            record.document?.complianceOutputs.substance
                              ? record.document?.complianceOutputs.substance
                              : ""}
                          </Text>
                        );
                      } else {
                        return null;
                      }
                    } else {
                      return null;
                    }
                  },
                })),
            ]}
            rowKey="_id"
          />
        )}
      </>
    ),
  }));

  const items = [
    {
      key: "component",
      label: "Component Logs",
      children: (
        <>
          {permissions?.read.includes("components") && (
            <Table
              dataSource={props?.componentHistory}
              scroll={{
                x: 1000,
                y: "calc(100vh - 285px)",
              }}
              columns={columns}
              rowKey="_id"
            />
          )}
        </>
      ),
    },
    ...regulationsTabs,
  ];

  return (
    <>
      <Tabs defaultActiveKey="component" items={items} tabPosition="top">
        {" "}
      </Tabs>
    </>
  );
};

export default AuditLogsTab;
