import React, { useState, useEffect } from "react";

import {
  ExportOutlined,
  FilePdfOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { Space, Typography, Button, Table, Input, Tag } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { PendingResponse } from "../../../utils/types/CampaignManager/campaignManager";
import { useAppDispatch } from "../../../redux/hooks";
import { readCampaignPendingResponses } from "../../../redux/actions/CampaignManager/campaignManagerAction";
import AddComplianceApprove from "../../../components/modals/addApproveComplianceModal";
import ReadSupportingDocument from "../../../components/modals/ReadSupportingDocuments";
import { getRegulationsFromLocalStorage } from "../../../utils/localStore";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Search } = Input;
const { Text } = Typography;

const CampaignManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const [pendingResponse, setPendingResponse] = useState<PendingResponse[]>([]);
  const [pendingResponseCount, setPendingResponseCount] = useState<number>(0);
  const regulations = getRegulationsFromLocalStorage() ?? [];

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<PendingResponse>>(
    {}
  );
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [search, setSearch] = useState<any>();

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const columns: ColumnsType<PendingResponse> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      hidden: false,
      render: (record: any) => (
        <Space>
          <AddComplianceApprove
            type={"icon"}
            formData={record}
            fetchData={fetchData}
            sendgridCampaignId={record?.sendgridCampaignId}
          />
        </Space>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      width: 150,
      hidden: false,
      render: (manufacturer: any) => (
        <Text>
          {" "}
          {manufacturer.itemNumber} <br></br>
          <small>{manufacturer.name}</small>
        </Text>
      ),
    },
    {
      title: "Regulation",
      dataIndex: "supplierComplianceResponse.regulationNumber",
      key: "supplierComplianceResponse.regulationNumber",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => (
        <Text>
          {record && record.supplierComplianceResponse
            ? record.supplierComplianceResponse?.regulationNumber
                ?.replaceAll("_", " ")
                .toUpperCase()
            : ""}
        </Text>
      ),
    },
    {
      title: "Compliant",
      dataIndex: "supplierComplianceResponse.complianceOutputs.compliant",
      key: "supplierComplianceResponse.complianceOutputs.compliant",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => {
        if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "YES"
        ) {
          return <Tag color="green"> YES </Tag>;
        } else if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "NO"
        ) {
          return <Tag color="red"> NO </Tag>;
        } else if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "YES WITH EXEMPTION"
        ) {
          return <Tag color="orange"> YES WITH EXEMPTION </Tag>;
        } else if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "UNKNOWN"
        ) {
          return <Tag color="default"> UNKNOWN </Tag>;
        } else {
          return (
            <Tag color="grey">
              {" "}
              {record &&
              record.supplierComplianceResponse?.complianceOutputs &&
              record.supplierComplianceResponse?.complianceOutputs?.compliant
                ? record.supplierComplianceResponse?.complianceOutputs
                    ?.compliant
                : ""}{" "}
            </Tag>
          );
        }
      },
    },
    {
      title: "Version",
      dataIndex: "supplierComplianceResponse.complianceOutputs.version",
      key: "supplierComplianceResponse.complianceOutputs.version",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => (
        <Text>
          {record && record.supplierComplianceResponse
            ? record.supplierComplianceResponse?.complianceOutputs?.version
            : ""}
        </Text>
      ),
    },
    {
      title: "Exemptions",
      dataIndex: "supplierComplianceResponse.complianceOutputs.exemption",
      key: "supplierComplianceResponse.complianceOutputs.exemption",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => (
        <Text
          style={{ width: 200 }}
          ellipsis={{
            tooltip:
              record.supplierComplianceResponse?.complianceOutputs?.exemption,
          }}
        >
          {record && record.supplierComplianceResponse
            ? record.supplierComplianceResponse?.complianceOutputs?.exemption
            : ""}
        </Text>
      ),
    },
    {
      title: "Substances",
      dataIndex: "supplierComplianceResponse.complianceOutputs.substance",
      key: "supplierComplianceResponse.complianceOutputs.substance",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => (
        <Text
          style={{ width: 200 }}
          ellipsis={{
            tooltip:
              record.supplierComplianceResponse?.complianceOutputs?.substance,
          }}
        >
          {record && record.supplierComplianceResponse
            ? record.supplierComplianceResponse?.complianceOutputs?.substance
            : ""}
        </Text>
      ),
    },
    {
      title: "Concentration of PFAS",
      dataIndex:
        "supplierComplianceResponse.complianceOutputs.concentrationOfPfas",
      key: "supplierComplianceResponse.complianceOutputs.concentrationOfPfas",
      sorter: true,
      width: 150,
      hidden: regulations.filter((e: any) =>
        e.configuration.find((v: any) =>
          v.value.includes("complianceOutputs.concentrationOfPfas")
        )
      ).length
        ? false
        : true,
      render: (text: any, record: any) => (
        <Text>
          {record && record.supplierComplianceResponse
            ? record.supplierComplianceResponse?.complianceOutputs
                ?.concentrationOfPfas
            : ""}
        </Text>
      ),
    },
    {
      title: "Concentration Unit",
      dataIndex:
        "supplierComplianceResponse.complianceOutputs.concentrationUnit",
      key: "supplierComplianceResponse.complianceOutputs.concentrationUnit",
      sorter: true,
      width: 150,
      hidden: regulations.filter((e: any) =>
        e.configuration.find((v: any) =>
          v.value.includes("complianceOutputs.concentrationUnit")
        )
      ).length
        ? false
        : true,
      render: (text: any, record: any) => (
        <Text>
          {record && record.supplierComplianceResponse
            ? record.supplierComplianceResponse?.complianceOutputs
                ?.concentrationUnit
            : ""}
        </Text>
      ),
    },
    {
      title: "File",
      dataIndex: "complianceDocument",
      key: "complianceDocument",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: string, record: any) => {
        return record.supplierComplianceResponse?.complianceDocument ? (
          record.supplierComplianceResponse?.complianceDocument?.includes(
            "http"
          ) ? (
            <span>
              <ExportOutlined
                onClick={() =>
                  onClickViewLink(
                    record.supplierComplianceResponse?.complianceDocument
                  )
                }
              />{" "}
              Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="campaignmanager/document/download"
              filename={record.supplierComplianceResponse?.complianceDocument}
              foldername="campaign"
              campaignId={record.sendgridCampaignId}
            />
          )
        ) : null;
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ].filter((e: any) => !e.hidden);

  const onSearch = (value: any) => {
    setSearch({
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      readCampaignPendingResponses({
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: search?.query ? search?.query : "",
      })
    )
      .then((response: any) => {
        setPendingResponse(response?.pendingResponse);
        setPendingResponseCount(response?.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<PendingResponse>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PendingResponse> | SorterResult<PendingResponse>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<PendingResponse>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  useEffect(() => {
    fetchData(search);
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1rem",
          }}
        >
          <Space>
            <Search
              placeholder="Search using manufacturer name or item number text"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={pendingResponse}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey={(record: any) => {
            return `${record._id}+${record.sendgridCampaignId}+${record.supplierComplianceResponse.regulationNumber}`;
          }}
          // rowKey="_id+supplierComplianceResponse.regulationNumber"
          pagination={{
            ...tablePagination,
            total: pendingResponseCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default CampaignManager;
