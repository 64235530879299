import React, { FC, useEffect, useRef, useState } from "react";
import {
  message,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Progress,
} from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { listRegulations } from "../../redux/actions/regulationsAction";
import { useAppDispatch } from "../../redux/hooks";
import { productAnalytics } from "../../redux/actions/productsAction";
import { baseUrl } from "../../utils/apiHelper";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { getLocalStorage } from "../../utils/localStore";
import axios from "axios";
import logo from "../../assets/logo-icon.png";
import jsPDF from "jspdf";
import { myfont } from "../../assets/font/WaterBrush-Regular-normal.js";
import { Rubik } from "../../assets/font/Rubik-Regular-normal.js";
import { RubikBold } from "../../assets/font/RubikBold-normal.js";
import logoIcon from "../../assets/logo-icon.png";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface ChartProps {
  data: any[];
  chart: any;
  id: any;
}

interface CertificateProps {
  yesDescription: string;
  noDescription: string;
  commonDescription: string;
  title: string;
  authorisedBy: string;
  designation: string;
  signature: string;
  certificateLogo: null;
}
const FushionChart: FC<ChartProps> = ({ data, chart, id }) => {
  useEffect(() => {
    FusionCharts.ready(function () {
      new FusionCharts({
        type: "doughnut3d",
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: { data, chart: { ...chart } },
      }).render(`productChart-${id}`);
    });
  }, [data, chart, id]);

  return <div id={`productChart-${id}`} />;
};

const CreateDeclarationModal: React.FC<{
  product: any;
}> = (props) => {
  const [open, setOpen] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const user = getLocalStorage("user");
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const pdfRef = useRef<any>(null);
  const [regulationOptions, setRegulationOptions] = useState<any[]>([]);
  const [chartdataLoading, setChartdataLoading] = useState(false);
  const [completedStatus, setCompletedStatus] = useState<number>(0);
  const [productAnalytic, setProductAnalytic] = useState<any>();
  const [regulationDescription, setRegulationDescription] = useState("");
  const [regulationName, setRegulationName] = useState("");
  const [regulationNumber, setRegulationNumber] = useState("");
  const [complianceFlag, setComplianceFlag] = useState(false);
  const [exemption, setExemption] = useState(false);
  const [substance, setSubstance] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [compliantCertificate, setCompliantCertificate] = useState(false);
  const [nonCompliantCertificate, setNonCompliantCertificate] = useState(false);
  const [certificateData, setCertificateData] = useState<CertificateProps>({
    yesDescription: "",
    noDescription: "",
    commonDescription: "",
    title: "",
    authorisedBy: "",
    designation: "",
    signature: "",
    certificateLogo: null,
  });
  const [tenantLogo, setTenantLogo] = React.useState<string>();
  const [certificateLogo, setCertificateLogo] = React.useState<string>();
  const [declarationLogo, setDeclarationLogo] = React.useState<string>();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
    setChildrenDrawer(false);
    setCertificate(false);
    setChartdataLoading(false);
  };

  const getCurrentDate = (): string => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    return date;
  };

  const currentDate = getCurrentDate();

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const fetchRegulations = (value: string) => {
    const values = value.split(", ");
    let filters = {
      name: { $regex: value, $options: "i" },
    };
    if (values.length > 1) {
      filters = {
        name: { $in: values } as {
          $in: string[];
          $regex: string;
          $options: string;
        },
      };
    }
    dispatch(
      listRegulations({
        pageSize: 0,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: true,
        filters: JSON.stringify(filters),
      })
    ).then((response) => {
      setRegulationOptions(
        response.regulations.map((regulation: any) => ({
          label: regulation.name,
          value: regulation.regulationNumber,
          default: regulation,
        }))
      );
    });
  };

  const fetchProductAnalytics = (id: string) => {
    dispatch(productAnalytics({ skipMutation: true }, id)).then(
      (response: any) => {
        setProductAnalytic(response);
      }
    );
  };

  const handleRegulations = (value: any, option: any) => {
    setRegulationName(option?.default?.name);
    setRegulationNumber(value);
    setRegulationDescription(option?.default?.description);
    setChartdataLoading(
      productAnalytic?.regulations.find((e: any) => e.number === value)
        ? true
        : false
    );
    const analytics = productAnalytic?.regulations.find(
      (e: any) => e.number === value
    );
    setCompletedStatus(analytics.completedStatus);
    setComplianceFlag(
      parseFloat(analytics.completedStatus) === 100.0 ? true : false
    );

    if (
      parseFloat(analytics.completedStatus) === 100.0 &&
      parseFloat(analytics.compliantStatus) === 100.0 &&
      analytics.exemptions.length > 0
    ) {
      console.log(
        parseFloat(analytics.completedStatus) === 100.0 &&
          parseFloat(analytics.compliantStatus) === 100.0 &&
          analytics.exemptions.length > 0
      );
      setExemption(true);
    }
    if (
      parseFloat(analytics.completedStatus) === 100.0 &&
      parseFloat(analytics.nonCompliantStatus) > 0 &&
      analytics.substances.length > 0
    ) {
      setSubstance(true);
    }
  };

  const onSubmit = () => {
    const user = getLocalStorage("user");
    const certificatefilterData = regulationOptions?.find(
      (val: any) => val.value === regulationNumber
    )?.default;
    const declarationData = user?.declaration;
    setCertificateData({
      yesDescription: certificatefilterData?.certification?.yesDescription,
      noDescription: certificatefilterData?.certification?.noDescription,
      commonDescription:
        certificatefilterData?.certification?.commonDescription,
      title: certificatefilterData?.certification?.title,
      authorisedBy: certificatefilterData?.certification?.authorisedBy
        ? certificatefilterData?.certification?.authorisedBy
        : declarationData?.authorisedBy,
      designation: certificatefilterData?.certification?.designation
        ? certificatefilterData?.certification?.designation
        : declarationData?.designation,
      signature: certificatefilterData?.certification?.signature
        ? certificatefilterData?.certification?.signature
        : true,
      certificateLogo: certificatefilterData?.certificateLogo
        ? certificatefilterData?.certificateLogo
        : declarationData?.certificateLogo,
    });

    if (
      productAnalytic?.regulations?.find(
        (e: any) => e.number === regulationNumber
      ).completedStatus !== "100.0"
    ) {
      message.error(
        "Insufficient / Incomplete Data, Update the data to generate compliance report."
      );
      setCertificate(false);
      setCompliantCertificate(false);
      setNonCompliantCertificate(false);
      return certificate && compliantCertificate && nonCompliantCertificate;
    } else if (
      productAnalytic?.regulations?.find(
        (e: any) =>
          e.number === regulationNumber &&
          parseFloat(e.completedStatus) === 100.0 &&
          parseFloat(e.nonCompliantStatus) > 0
      )
    ) {
      message.success(
        "Compliance successful, generate non compliance reports."
      );
      setCertificate(true);
      setNonCompliantCertificate(true);
    } else if (
      productAnalytic?.regulations?.find(
        (e: any) =>
          e.number === regulationNumber &&
          parseFloat(e.completedStatus) === 100.0 &&
          parseFloat(e.compliantStatus) === 100.0
      )
    ) {
      message.success("Compliance successful, generate compliance reports.");
      setCertificate(true);
      setCompliantCertificate(true);
    } else {
      message.success("Compliance successful, generate compliance reports.");
      setCertificate(true);
      setCompliantCertificate(false);
      setNonCompliantCertificate(false);
    }

    if (certificateData.certificateLogo) {
      axios
        .post(
          baseUrl + "/tenants/media/download",
          {
            tenantNumber: user.tenantNumber,
            filename: certificateData.certificateLogo,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
          }
        )
        .then((response) => {
          const imageBlob = new Blob([response.data], { type: "image/png" });
          const URL = window.URL || window.webkitURL;
          setCertificateLogo(URL.createObjectURL(imageBlob));
        });
    }
    if (user.companyLogo) {
      axios
        .post(
          baseUrl + "/tenants/media/download",
          {
            tenantNumber: user.tenantNumber,
            filename: user.companyLogo,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
          }
        )
        .then((response) => {
          const imageBlob = new Blob([response.data], { type: "image/png" });
          const URL = window.URL || window.webkitURL;
          setTenantLogo(URL.createObjectURL(imageBlob));
        });
    }
    if (user.declaration?.certificateLogo) {
      axios
        .post(
          baseUrl + "/tenants/media/download",
          {
            tenantNumber: user.tenantNumber,
            filename: user.declaration?.certificateLogo,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
          }
        )
        .then((response) => {
          const imageBlob = new Blob([response.data], { type: "image/png" });
          const URL = window.URL || window.webkitURL;
          setDeclarationLogo(URL.createObjectURL(imageBlob));
        });
    }
    setChildrenDrawer(true);
  };

  const onDownload = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: 3,
    });
    doc.addFileToVFS("WaterBrush-Regular-normal.ttf", myfont);
    doc.addFont("WaterBrush-Regular-normal.ttf", "myfont", "normal");
    doc.setFont("myfont");
    doc.addFileToVFS("Rubik-Regular-normal.ttf", Rubik);
    doc.addFont("Rubik-Regular-normal.ttf", "Rubik", "normal");
    doc.setFont("Rubik");
    doc.addFileToVFS("RubikBold-normal.ttf", RubikBold);
    doc.addFont("RubikBold-normal.ttf", "Rubik", "bold");
    doc.setFont("RubikBold");

    const pdf = pdfRef?.current;
    const descriptionElement = pdf.querySelector("#description");
    const removeStyle = descriptionElement.querySelectorAll("[style]");

    removeStyle.forEach((element: any) => {
      element.removeAttribute("style");
    });

    const list = descriptionElement.querySelectorAll("ul, ol");

    list.forEach((e: any) => {
      e.style.paddingLeft = "20px";
      e.style.marginBottom = "10px";
    });

    doc.html(pdf, {
      callback: (doc) => {
        doc.save(`${user.companyName}-${regulationName}.pdf`);
        onClose();
      },
      margin: [10, 0, 10, 0],
      // font: 12,
      image: {
        type: "webp",
        quality: 90,
      },
      html2canvas: {
        width: 170,
      },
      // fontFaces: [{
      //   family: "Helvetica",
      //   weight: "100"
      // }, {
      //   family: "Courier",
      //   weight: "100"
      // }],
      x: 15,
      y: 10,
    });
  };

  const headerStyle: React.CSSProperties = {
    padding: "8px",
    textAlign: "center",
    border: "1px solid black",
    color: "white",
    fontFamily: "Rubik, sans-serif",
  };

  const bodyStyle: React.CSSProperties = {
    padding: "8px",
    textAlign: "center",
    border: "1px solid black",
    fontFamily: "Rubik, sans-serif",
  };

  useEffect(() => {
    if (open) {
      fetchRegulations("");
      fetchProductAnalytics(props.product?._id);
    }
  }, [open]);

  return (
    <>
      <Button
        type="text"
        onClick={showDrawer}
        title="Declaration"
        icon={<FileTextOutlined />}
        data-testid="declaration-button"
      />
      <Drawer
        title="Declaration"
        onClose={onClose}
        width={720}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-declaration-button">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              type="primary"
              disabled={!complianceFlag}
              data-testid="create-declaration-button"
            >
              Create Declaration
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ ...props.product }}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="number" label="Product Number">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="regulations"
                label="Regulations Type"
                rules={[
                  { required: true, message: "Please select regulation Type!" },
                ]}
              >
                <Select
                  placeholder="Select Regulations"
                  options={regulationOptions}
                  // onSearch={(text) => fetchRegulations(text)}
                  onChange={handleRegulations}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {chartdataLoading && (
          <Row>
            <Col span={24}>
              <Space style={{ marginTop: "1rem" }}>
                <FushionChart
                  data={
                    productAnalytic?.regulations.find(
                      (e: any) => e.number === regulationNumber
                    )?.statistics
                  }
                  chart={{
                    caption: regulationName,
                    subcaption: regulationDescription,
                    enablesmartlabels: "1",
                    showlabels: "1",
                    numbersuffix: " %",
                    usedataplotcolorforlabels: "1",
                    plottooltext: "$label, <b>$value</b>",
                    theme: "fusion",
                  }}
                  id={props.product?.number}
                />
              </Space>
            </Col>
            <Col span={24}>
              <Progress
                percent={completedStatus}
                strokeWidth={30}
                strokeLinecap="square"
              ></Progress>
              <small>
                The product should be 100% complete to generate compliant or
                non-compliant declaration.{" "}
              </small>
            </Col>
          </Row>
        )}
        <Drawer
          title="Declaration Template"
          width={500}
          closable={false}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
          extra={
            <Space>
              <Button
                onClick={onChildrenDrawerClose}
                data-testid="cancel-download-button"
              >
                Cancel
              </Button>
              <Button
                onClick={onDownload}
                type="primary"
                data-testid="download-declaration-button"
              >
                Download Template
              </Button>
            </Space>
          }
        >
          {certificate && (
            <div>
              <Row>
                <Col ref={pdfRef} style={{ width: "420px" }}>
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{
                      background: user.declaration?.backgroundColor,
                      color: user?.declaration?.fontColor,
                    }}
                  >
                    <Col
                      style={{
                        width: "210px",
                        marginTop: "10px",
                        marginLeft: "1rem",
                      }}
                    >
                      <h2
                        style={{
                          fontFamily: "RubikBold, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {user.declaration?.companyName
                          ? user.declaration?.companyName
                          : user.companyName}
                      </h2>
                      <p
                        style={{
                          fontFamily: "RubikBold, sans-serif",
                          fontSize: "8px",
                        }}
                      >
                        {user.declaration?.addressLine1
                          ? user.declaration?.addressLine1 + ","
                          : ""}
                        {user.declaration?.addressLine2
                          ? user.declaration?.addressLine2 + ","
                          : ""}
                        {user.declaration?.state
                          ? user.declaration?.state + ","
                          : ""}
                        {user.declaration?.country
                          ? user.declaration?.country + ","
                          : ""}
                        {user.declaration?.pincode
                          ? user.declaration?.pincode + ","
                          : ""}
                        <br></br>
                        {user?.declaration?.companyPhoneNumber
                          ? " Ph:" + user?.declaration?.companyPhoneNumber
                          : ""}
                      </p>
                    </Col>
                    <Col style={{ textAlign: "right", marginRight: "1rem" }}>
                      {certificateLogo ? (
                        <img
                          src={certificateLogo ? certificateLogo : logo}
                          alt="Logo"
                          height={30}
                        />
                      ) : declarationLogo ? (
                        <img
                          src={declarationLogo ? declarationLogo : logo}
                          alt="Logo"
                          height={30}
                        />
                      ) : tenantLogo ? (
                        <img
                          src={tenantLogo ? tenantLogo : logo}
                          alt="Logo"
                          height={30}
                        />
                      ) : (
                        <img src={logoIcon} alt="Logo" height={30} />
                      )}
                      <p
                        style={{
                          fontFamily: "RubikBold, sans-serif",
                          fontSize: "8px",
                        }}
                      >
                        {user.declaration?.website
                          ? user.declaration?.website
                          : ""}{" "}
                        <br></br>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ width: "420px" }}>
                      <p
                        style={{
                          textAlign: "right",
                          fontFamily: "Rubik, sans-serif",
                          fontSize: "10px",
                          marginTop: "2px",
                        }}
                      >
                        Date: {currentDate}
                      </p>
                    </Col>
                  </Row>
                  <h3
                    style={{
                      fontFamily: "RubikBold, sans-serif",
                      textAlign: "center",
                      fontSize: "12px",
                      marginBottom: "15px",
                    }}
                  >
                    {certificateData.title}
                  </h3>
                  <p
                    id="description"
                    style={{
                      fontFamily: "Rubik, RubikBold , sans-serif",
                      fontSize: "8px",
                      marginBottom: "15px",
                      lineHeight: "1.5",
                    }}
                  >
                    {!nonCompliantCertificate ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: certificateData.yesDescription
                            ? certificateData.yesDescription
                            : certificateData.commonDescription,
                        }}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: certificateData.noDescription
                            ? certificateData.noDescription
                            : certificateData.commonDescription,
                        }}
                      />
                    )}
                  </p>
                  <div>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        marginTop: "20px",
                        fontSize: "8px",
                      }}
                    >
                      <thead>
                        <tr
                          style={{
                            background: "#151B54",
                            borderLeft: "1px solid black",
                          }}
                        >
                          <th style={headerStyle}>Product Number</th>
                          <th style={headerStyle}>Product Name</th>
                          <th style={headerStyle}>
                            {regulationName} Compliant Status
                          </th>
                          {productAnalytic?.regulations?.find(
                            (e: any) => e.number === regulationNumber
                          )?.exemptions?.length > 0 &&
                            exemption && (
                              <th style={headerStyle}>Exemptions</th>
                            )}
                          {productAnalytic?.regulations?.find(
                            (e: any) => e.number === regulationNumber
                          )?.substances?.length > 0 &&
                            substance && (
                              <th style={headerStyle}>Substances</th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ borderBottom: "1px solid #ddd" }}>
                          <td style={bodyStyle}>{props.product?.number}</td>
                          <td style={bodyStyle}>{props.product?.name}</td>
                          <td style={bodyStyle}>
                            {parseFloat(completedStatus.toString()) !== 100.0
                              ? "Un-determined/ Under review"
                              : nonCompliantCertificate
                              ? "Non-Compliant"
                              : exemption
                              ? "Compliant with Exemption"
                              : "Compliant"}
                          </td>
                          {productAnalytic?.regulations?.find(
                            (e: any) => e.number === regulationNumber
                          )?.exemptions?.length > 0 &&
                            exemption && (
                              <td style={bodyStyle}>
                                {productAnalytic?.regulations
                                  ?.find(
                                    (e: any) => e.number === regulationNumber
                                  )
                                  ?.exemptions.toString()}
                              </td>
                            )}
                          {productAnalytic?.regulations?.find(
                            (e: any) => e.number === regulationNumber
                          )?.substances?.length > 0 &&
                            substance && (
                              <td style={bodyStyle}>
                                {productAnalytic?.regulations
                                  ?.find(
                                    (e: any) => e.number === regulationNumber
                                  )
                                  ?.substances.toString()}
                              </td>
                            )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p
                    style={{
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "8px",
                      marginTop: "50px",
                      marginBottom: "0px",
                    }}
                  >
                    Authorised Representative
                  </p>
                  {Boolean(certificateData?.signature) && (
                    <p
                      style={{
                        fontFamily: "myFont, Water Brush",
                        fontSize: "18px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      {certificateData.authorisedBy
                        ? certificateData.authorisedBy
                        : user.declaration?.authorisedBy}
                    </p>
                  )}
                  <p
                    style={{
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "8px",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    {certificateData.authorisedBy
                      ? certificateData.authorisedBy
                      : user.declaration?.authorisedBy}
                  </p>
                  {certificateData.designation && (
                    <p
                      style={{
                        fontFamily: "Rubik, sans-serif",
                        fontSize: "8px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      {certificateData.designation
                        ? certificateData.designation
                        : user.declaration?.designation}
                    </p>
                  )}
                  {user.declaration?.emailAddress && (
                    <p
                      style={{
                        fontFamily: "Rubik, sans-serif",
                        fontSize: "8px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      {user.declaration?.emailAddress}
                    </p>
                  )}
                  {user.declaration?.phoneNumber && (
                    <p
                      style={{
                        fontFamily: "Rubik, sans-serif",
                        fontSize: "8px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      {user.declaration?.phoneNumber}
                    </p>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </Drawer>
      </Drawer>
    </>
  );
};

export default CreateDeclarationModal;
