import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { Dispatch } from "redux";

/**
 * CRUD Operations
 */
export const createCompliance = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/compliances", payload)
      .then((response: any) => {
        if (response && response.status === 201 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createBulkCompliance = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/compliances/bulk/insert", payload)
      .then((response: any) => {
        if (response && response.status === 201 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readComplaince = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/complainces/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateComplaince = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/compliances" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateBulkCompliance = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/compliances" , payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const bulkUpdateComplaince = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/compliances/bulk/update" , payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadComplianceDocument = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/compliances/document/upload" , payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};