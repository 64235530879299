import React from "react";
import { useState } from "react";
import { Button, Modal } from "antd";
import { fetchWelcomeStatus } from "../../redux/actions/supplierPortalAction";
import { useAppDispatch } from "../../redux/hooks";
import { useParams } from "react-router-dom";


const Welcome: React.FC<{
  isModalOpen: any;
  openModal: any;
}> = (props) => {

  const { uniqueName ,linkTenantNumber, linkCampaignId } = useParams();
  const dispatch = useAppDispatch();

  const onSubmit = () => {

    const payload = {
      name: uniqueName,
      tenantNumber: window.atob(linkTenantNumber!),
      campaignId: window.atob(linkCampaignId!),
    };
    dispatch(fetchWelcomeStatus(payload)).then(()=>{
      props.openModal();
    
    })
  };

  return (
    <>
      <Modal
        open={props.isModalOpen}
        width={450}
        onCancel={props.openModal}
        closable={false}
        footer={
          <Button  onClick={onSubmit}>
            Cancel
          </Button>
        }
      >
        <h3 style={{textAlign:"center"}}>Welcome</h3>
        <p>
          Acquis compliance tool (ACT) is a software product to manage, automate
          and authenticate product material compliance data. This unified
          platform brings people, processes, and paperwork together to enable
          time tracking and updates on going compliance projects.
        </p>
        <p>
          Act is designed to help compliance teams to save time and avoid
          administrative burnot with:
        </p>
        <ul>
                <li>Automated and Streamlined Data collection, Validation and Classification.</li>
                <li>Detailed Compliance Modules (RoHS, REACH, Conflict Minerals, Prop 65 and more)</li>
                <li>Supply Chain Outreach and Timely Stakeholder Communication.</li>
                <li>Real-time, Detailed Reporting and  Dcoumentation</li>
                <li> Bulding Customized Compliance Workflows.</li>
              </ul>
      </Modal>
    </>
  );
};

export default Welcome;
