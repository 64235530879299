import { publicPostApi, publicGetApi } from "../../utils/apiHelper";
import { Dispatch } from "redux";
import { submitComplianceTriggered } from "../slices/supplierPortalSlice";

// https://acquiscomplfmprodapi.azureedge.net/public/suppliers/theme
// https://acquiscomplfmprodapi.azureedge.net/public/suppliers/analytics
// https://acquiscomplfmprodapi.azureedge.net/media/read

/**
 * Fetch campaign data
 */
export const fetchCampaignData = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/suppliers/theme", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
export const fetchWelcomeStatus = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/suppliers/welcome/update", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * Fetch analytics
 */
export const fetchAnalytics = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/suppliers/analytics", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * Fetch regulation
 */
export const fetchRegulation = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicGetApi("/public/regulations", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * Fetch components
 */
export const fetchComponents = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/suppliers/components", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * Fetch conflict minerals
 */
export const fetchConflictMinerals = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/suppliers/conflictminerals", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * Fetch conflict minerals
 */
export const fetchConflictMineralsDocumentRead = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/suppliers/document/download", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * Fetch extended minerals
 */
export const fetchExtendedMinerals = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/suppliers/extendedminerals", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * Submit compliances
 */
export const updateSupplierCompliances =
  (payload: any) => (dispatch: Dispatch) => {
    return new Promise<any>((resolve, reject) => {
      publicPostApi("/public/suppliers/submit/compliances", payload)
        .then((response: any) => {
          if (response.status === 201) {
            dispatch(submitComplianceTriggered());
            resolve(response.data.data);
          } else {
            reject();
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

/**
 * Submit compliances
 */
export const updateSupplierCompliancesBulk =
  (payload: any) => (dispatch: Dispatch) => {
    return new Promise<any>((resolve, reject) => {
      publicPostApi("/public/suppliers/submit/compliances/bulk", payload)
        .then((response: any) => {
          if (response.status === 201) {
            dispatch(submitComplianceTriggered());
            resolve(response.data.data);
          } else {
            reject();
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

/**
 * Submit conflit minerals
 */
export const updateSupplierConflictMinerals = (payload: any): Promise<any> =>{
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/suppliers/submit/conflictminerals", payload)
      .then((response: any) => {
        if (response.status === 201) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * Submit extended minerals
 */
export const updateSupplierExtendedMinerals = (payload: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/suppliers/submit/extendedminerals", payload)
      .then((response: any) => {
        if (response.status === 201) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * Send request to administrator
 */
export const sendRequest = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/sendmail", payload)
      .then((response: any) => {
        if (response.status === 201) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
