import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Space, Typography, Table, Tag } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Subscription, Module } from "../../../utils/types/subscription";
import { useAppDispatch } from "../../../redux/hooks";
import { listSubscriptions } from "../../../redux/actions/subscriptionsAction";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Title, Text } = Typography;

const Subscriptions: React.FC = () => {
  const dispatch = useAppDispatch();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [subscriptionCount, setSubscriptionCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Subscription>>(
    {}
  );
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();

  const columns: ColumnsType<Subscription> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Auto Approved",
      dataIndex: "autoApprove",
      key: "autoApprove",
      render: (text: boolean) => (text ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: "Pricing",
      dataIndex: "pricing",
      key: "pricing",
      render: (text: string) => (
        <Tag
          color={
            text === "free"
              ? "green"
              : text === "enterprise"
              ? "blue"
              : "orange"
          }
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Modules",
      dataIndex: "modules",
      key: "modules",
      render: (text: Module[]) =>
        text.map((module: Module) => (
          <Tag key={module.number}>{module.name}</Tag>
        )),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: any) => (
        <Text>
          {new Date(text).toLocaleString()}
          <br />
          {record.createdBy}
        </Text>
      ),
    },
  ];

  const fetchData = () => {
    setLoading(true);
    dispatch(
      listSubscriptions({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
      })
    )
      .then((response) => {
        setSubscriptions(response.subscriptions);
        setSubscriptionCount(response.subscriptionCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Subscription>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Subscription> | SorterResult<Subscription>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Subscription>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setSubscriptions([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Subscriptions
            </Title>
          </div>
        </Space>
        <Table
          loading={loading}
          dataSource={subscriptions}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: subscriptionCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default Subscriptions;
