import React, { useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import CampaignOverview from "./overview";
import CampaignSuppliers from "./suppliers";
import CampaignResponse from "./response";

import { Button, Tabs, Typography } from "antd";
import type { TabsProps } from "antd";
import { getLocalStorage } from "../../../../../utils/localStore";

const { Title } = Typography;

const CampaignDetails: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const location = useLocation();
  const navigate = useNavigate();

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const handleTabChange = (key: any) => {
    navigate(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "",
      label: "Overview",
      children: <CampaignOverview campaignData={location?.state?.record} />,
    },
    {
      key: "suppliers",
      label: "Suppliers",
      children: <CampaignSuppliers />,
    },
    permissions?.read?.includes("components") && {
      key: "response",
      label: "Response",
      children: <CampaignResponse />,
    },
  ].filter(Boolean);

  const onClickBack = () => {
    navigate("/app/campaignmanager/campaign");
  };

  return (
    <Tabs
      tabBarExtraContent={{
        left: (
          <Button style={{ marginRight: "1rem" }} onClick={onClickBack}>
            Back
          </Button>
        ),
      }}
      tabPosition="top"
      activeKey={
        location.pathname.split("/").pop() === id
          ? ""
          : location.pathname.split("/").pop()
      }
      items={items}
      onChange={handleTabChange}
    />
  );
};

export default CampaignDetails;
