import React, { useEffect, useState } from "react";
import { LockOutlined, UnlockOutlined, MailOutlined } from "@ant-design/icons";
import { Space, Typography, Button, Table, Popconfirm } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { User } from "../../../../utils/types/user";
import { useAppDispatch } from "../../../../redux/hooks";
import { listTenantUsers } from "../../../../redux/actions/tenantsAction";
import {
  resendInviteUser,
  blockAccessUser,
} from "../../../../redux/actions/usersAction";
import UserProfileForm from "../../../../components/forms/UserProfileForm";

const { Text } = Typography;

const TenantUsers: React.FC<{
  id: string;
  tenantNumber: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [users, setUsers] = useState<User[]>([]);
  const [userCount, setUserCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<User>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();
  const [search, setSearch] = useState<any>();

  const columns: ColumnsType<User> = [
    {
      title: "Action",
      key: "_id",
      width: 150,
      render: (_, record: User) => (
        <Space>
          <Button
            type="text"
            title="Resend Invite"
            icon={<MailOutlined />}
            onClick={() =>
              resendInvite({
                currentLocationOrigin: window.location.origin,
                emailAddress: record.emailAddress,
                tenantNumber: props.tenantNumber,
              })
            }
            disabled={record.verified}
          />
          <Popconfirm
            title={record.blocked ? "Unblock User" : "Block User"}
            description={
              "Are you sure you want to " +
              (record.blocked ? "unblock" : "block") +
              " user?"
            }
            onConfirm={() =>
              blockAccess({
                filter: { _id: record._id },
                updates: { blocked: !record.blocked },
                tenantNumber: props.tenantNumber,
              })
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              danger={record.blocked}
              title={record.blocked ? "Unblock User" : "Block User"}
              icon={record.blocked ? <LockOutlined /> : <UnlockOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text: string, record: User) => (
        <Text>
          {text} {record.lastName}
        </Text>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: User) => (
        <Text>
          {new Date(text).toLocaleString()}
          <br />
          {record.createdBy}
        </Text>
      ),
    },
  ];

  const resendInvite = (payload: any) => {
    setLoading(true);
    dispatch(resendInviteUser(payload))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const blockAccess = (payload: any) => {
    setLoading(true);
    dispatch(blockAccessUser(payload))
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchData = (payload?: any) => {
    setLoading(true);
    dispatch(
      listTenantUsers(props.id, {
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        filters: payload ? payload : null,
      })
    )
      .then((response) => {
        setUsers(response.users);
        setUserCount(response.userCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<User>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<User>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setUsers([]);
    }
  };

  useEffect(() => {
    fetchData(JSON.stringify(search));
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    JSON.stringify(search),
  ]);

  return (
    <>
      <Space
        align="end"
        style={{
          display: "flex",
          justifyContent: "end",
          marginBottom: "1rem",
        }}
      >
        <UserProfileForm
          type="create"
          tenant={props.tenantNumber}
          fetchData={fetchData}
        />
      </Space>
      <Table
        loading={loading}
        dataSource={users}
        scroll={{
          x: 1000,
          y: "calc(100vh - 285px)",
        }}
        columns={columns}
        rowKey="_id"
        pagination={{
          ...tablePagination,
          total: userCount,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (totalCount) => `Total  ${totalCount}  items`,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TenantUsers;
