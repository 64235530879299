import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Space, Spin, Typography } from "antd";

import { baseUrl } from "../../../utils/apiHelper";
import axios from "axios";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Title } = Typography;

const LetterOfAuthorisation: React.FC = () => {
  const { linkTenantNumber, filename } = useParams();
  const [fileContent, setFileContent] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(linkTenantNumber, filename);
    setLoading(true);
    axios
      .post(
        baseUrl + "/media/read",
        {
          tenantNumber: window.atob(linkTenantNumber!),
          filename: filename + ".pdf",
        },
        {
          responseType: "arraybuffer",
        }
      )
      .then((response: any) => {
        const blobPDF = new Blob([response.data], {
          type: "application/pdf",
        });
        const blobURL = URL.createObjectURL(blobPDF);
        setFileContent(blobURL);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Title level={5} style={{ marginBottom: 0 }}>
            Letter of Authorisation
          </Title>
        </Space>
        <Spin spinning={loading} fullscreen />
        <iframe
          title="File Viewer"
          src={fileContent}
          width="100%"
          height="95%"
        />
      </ErrorBoundary>
    </>
  );
};

export default LetterOfAuthorisation;
