import React, { useEffect } from "react";
import { ConfigProvider, Layout, Space, Typography } from "antd";
import { Outlet, useNavigate } from "react-router-dom";

import { getLocalStorage } from "../../utils/localStore";
import logo from "../../assets/logo-text-color.png";
import "./../../index.css";

const { Text } = Typography;

const AuthLayout: React.FC = () => {
  const navigate = useNavigate();
  const user = getLocalStorage("user");

  useEffect(() => {
    if (user) {
      navigate("/app");
    }
  }, [navigate]);

  useEffect(() => {
    // Update the CSS variable for secondary color
    if (user?.secondaryColor) {
      document.documentElement.style.setProperty('--secondary-color', user.secondaryColor);
      document.documentElement.style.setProperty('--primary-color', user.primaryColor);
    }
  }, [user]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#F39237",
          borderRadius: 4,
          fontFamily: "Onest",
        },
        components: {
          Layout: {
            bodyBg: "#F5F5F5",
          },
          Table: {
            rowSelectedBg: '#fff',
            rowSelectedHoverBg: '#fafafa',
            headerColor: "fff",
            headerSortHoverBg: user?.primaryColor || "#F39237",
            headerSortActiveBg:  user?.primaryColor || "#F39237",
            headerFilterHoverBg: user?.primaryColor || "#F39237",
            headerBg: user?.primaryColor || "#F39237",
          },
          Card: {
            headerBg: user?.primaryColor || "#F39237",
            extraColor: "#fff"
          }
        },
      }}
    >
      <Layout>
        <Space
          size="large"
          direction="vertical"
          align="center"
          style={{ display: "flex", height: "100vh", justifyContent: "center" }}
        >
          <img src={logo} alt="Logo" height={30} />
          <Outlet />
          <Text type="secondary">
            &copy; Copyrights Acquis Compliance 2020 -{" "}
            {new Date().getFullYear()}
          </Text>
        </Space>
      </Layout>
    </ConfigProvider>
  );
};

export default AuthLayout;
