import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../../utils/apiHelper";
import {
  Campaign,
  PendingResponse,
} from "../../../utils/types/CampaignManager/campaignManager";
import {
  setCampaigns,
  setCampaignData,
  setPendingResponse,
  setInbox,
  setCampaignResponse,
} from "../../slices/CampaignManager/campaignManagersSlice";
import { Dispatch } from "redux";

/**
 * Bulk Operations
 */
export const listCampaigns = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<void>((resolve, reject) => {
    privatePostApi("/campaignmanager/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setCampaigns(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createCampaignManager = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager", payload)
      .then((response: any) => {
        if (response && response.status === 201 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readCampaignManager = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<Campaign>((resolve, reject) => {
    privatePostApi("/campaignmanager/read/single", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setCampaignData(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readCampaignInbox = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<PendingResponse>((resolve, reject) => {
    privatePostApi("/campaignmanager/inbox/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setInbox(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readCampaignPendingResponses =
  (payload: any) => (dispatch: Dispatch) => {
    return new Promise<PendingResponse>((resolve, reject) => {
      privatePostApi("/campaignmanager/response/read", payload)
        .then((response: any) => {
          if (response.status === 200) {
            dispatch(setPendingResponse(response.data.data));
            resolve(response.data.data);
          } else {
            reject();
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

export const readCampaignSupplierAnalytics = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/supplier/analytics", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readCampaignData = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/data/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readCampaignComplianceResponse = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/supplier/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          setCampaignResponse(response.data.data);
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const updateCampaignResponse = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/supplier/update", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateCampaigns = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/campaignmanager/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCampaigns = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/campaignmanager/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadCampaignDocument = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/document/upload", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
