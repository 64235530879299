import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { setComponents } from "../slices/componentsSlice";
import { Dispatch } from "redux";
import { ComponentsState } from "../../utils/types/component";

/**
 * Bulk Operations
 */
export const listComponents = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<ComponentsState>((resolve, reject) => {
    privateGetApi("/products/components", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setComponents(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const listComponentFilters = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<ComponentsState>((resolve, reject) => {
    privateGetApi("/products/components/filters", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createComponent = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/products/components", payload)
      .then((response: any) => {
        if (response && response.status === 201 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readComponent = (id: string, payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/products/components/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAutoCompleteData = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/products/components/autocomplete", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getComponentReferenceData = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/products/components/componentreference", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readComponentChildren = (id: string, payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/products/components/component/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateComponent = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/products/components/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const bulkUpdateComponent = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/products/components/all/" + payload.id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readAlternatesFromComponent = (id: string, payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/products/components/alternates/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const bulkUpdateSpecifications = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/products/components/bulk/update", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteComponent = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/products/components/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const updateComponentFMD = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/products/components/fmdcomponent/update/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
