import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../../utils/apiHelper";
import { List } from "../../../utils/types/CampaignManager/list";
import {
  setLists,
  setSupplierLists,
  setComponentLists,
  setList,
} from "../../slices/CampaignManager/listsSlice";
import { Dispatch } from "redux";

/**
 * Bulk Operations
 */
export const listCampaignsList = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<void>((resolve, reject) => {
    privatePostApi("/campaignmanager/lists/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setLists(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createLists = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/lists/", payload)
      .then((response: any) => {
        if (response && response.status === 201 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readList = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<List>((resolve, reject) => {
    privatePostApi("/campaignmanager/lists/read/single", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setList(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

// export const readSingleList = (payload: any) => () => {
//   return new Promise<any>((resolve, reject) => {
//     privatePostApi("/campaignmanager/lists/read/single", payload)
//       .then((response: any) => {
//         if (response && response.status === 200 && response.data) {
//           resolve(response.data.data);
//         } else {
//           reject();
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export const readListsSuppliers = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<void>((resolve, reject) => {
    privateGetApi("/campaignmanager/lists/suppliers/" + payload.id, payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          dispatch(setSupplierLists(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readListsComponents = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<void>((resolve, reject) => {
    privateGetApi("/campaignmanager/lists/components/" + payload.id, payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          dispatch(setComponentLists(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const checkLists = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/lists/listjob", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateLists = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/campaignmanager/lists/" + payload.id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteLists = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/campaignmanager/lists/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
