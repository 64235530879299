import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Card,
  Typography,
  ColorPicker,
} from "antd";

import ImageCropper from "../../../components/layouts/ImageCropper";
import FileUploader from "../../../components/layouts/FileUploader";

import { useAppDispatch } from "../../../redux/hooks";
import { updateTenantDetails } from "../../../redux/actions/tenantsAction";
import { getLocalStorage, setLocalStorage } from "../../../utils/localStore";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Title } = Typography;
const { Option } = Select;

const AccountSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const [formProductSpecification] = Form.useForm();
  const [formComponentSpecification] = Form.useForm();
  const [formProductConfiguration] = Form.useForm();
  const [formComponentConfiguration] = Form.useForm();
  const [formSupplierConfiguration] = Form.useForm();
  const [formWhitelabel] = Form.useForm();
  const [formLetterOfAuthorisation] = Form.useForm();
  const {
    productSpecifications,
    specifications,
    gridConfiguration,
    tenantNumber,
    primaryColor,
    secondaryColor,
    companyLogo,
    letterOfAuthorisation,
    modules,
  } = getLocalStorage("user") ?? [];
  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setLoading4] = React.useState(false);
  const [productDataValue, setProductDataValue] = useState("");
  const [componentDataValue, setComponentDataValue] = useState("");

  const onFormProductSpecificationSubmit = (values: any) => {
    setLoading1(true);
    dispatch(
      updateTenantDetails({
        updates: {
          productSpecifications: values.productSpecifications?.map(
            (e: any) => ({
              ...e,
              value: e.name.replace(/\s/g, "").toLowerCase(),
            })
          ),
        },
      })
    )
      .then((response) => {
        const user = getLocalStorage("user");
        user.productSpecifications = response.productSpecifications;
        setLocalStorage("user", user);
        setLoading1(false);
      })
      .catch((e) => setLoading1(false));
  };

  const onFormComponentSpecificationSubmit = (values: any) => {
    setLoading2(true);
    dispatch(
      updateTenantDetails({
        updates: {
          specifications: values.specifications?.map((e: any) => ({
            ...e,
            value: e.name.replace(/\s/g, "").toLowerCase(),
          })),
        },
      })
    )
      .then((response) => {
        const user = getLocalStorage("user");
        user.specifications = response.specifications;
        setLocalStorage("user", user);
        setLoading2(false);
      })
      .catch((e) => setLoading2(false));
  };

  const onFormWhitelabelSubmit = (values: any) => {
    setLoading3(true);
    dispatch(
      updateTenantDetails({
        updates: {
          ...values,
          primaryColor:
            typeof values.primaryColor !== "string"
              ? values.primaryColor.toHexString()
              : values.primaryColor,
          secondaryColor:
            typeof values.secondaryColor !== "string"
              ? values.secondaryColor.toHexString()
              : values.secondaryColor,
        },
      })
    )
      .then((response) => {
        const user = getLocalStorage("user");
        user.primaryColor = response.primaryColor;
        user.secondaryColor = response.secondaryColor;
        user.companyLogo = response.companyLogo;
        setLocalStorage("user", user);
        setTimeout(() => {
          setLoading3(false);
          window.location.reload();
        }, 5000);
      })
      .catch((e) => setLoading3(false));
  };

  const onFormLetterOfAuthorisationSubmit = (values: any) => {
    setLoading4(true);
    dispatch(
      updateTenantDetails({
        updates: {
          ...values,
        },
      })
    )
      .then((response) => {
        const user = getLocalStorage("user");
        user.letterOfAuthorisation = response.letterOfAuthorisation;
        setLocalStorage("user", user);
        setLoading4(false);
      })
      .catch((e) => setLoading4(false));
  };

  return (
    <>
      <ErrorBoundary>
        <Title level={5}>Account Settings</Title>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card
              title="Letter of Authorisation"
              style={{ height: "100%" }}
              extra={
                <Button
                  type="primary"
                  onClick={formLetterOfAuthorisation.submit}
                  loading={loading4}
                >
                  Save
                </Button>
              }
            >
              <Form
                form={formLetterOfAuthorisation}
                layout="vertical"
                onFinish={onFormLetterOfAuthorisationSubmit}
                initialValues={{
                  letterOfAuthorisation: letterOfAuthorisation,
                }}
              >
                <Form.Item
                  label="Attach a File"
                  name="letterOfAuthorisation"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please choose a file",
                    },
                  ]}
                >
                  <FileUploader
                    postUrl="tenants/media/upload"
                    tenantNumber={tenantNumber}
                    setFormField={(value: string) =>
                      formLetterOfAuthorisation.setFieldValue(
                        "letterOfAuthorisation",
                        value
                      )
                    }
                    formField={letterOfAuthorisation}
                  />
                </Form.Item>
              </Form>
            </Card>
          </Col>
          {/* <Col span={12}>
          <Card
            title="Whitelabeling"
            style={{ height: "100%" }}
            extra={
              <Button
                type="primary"
                onClick={formWhitelabel.submit}
                loading={loading3}
              >
                Save
              </Button>
            }
          >
            <Form
              form={formWhitelabel}
              layout="vertical"
              onFinish={onFormWhitelabelSubmit}
              initialValues={{
                companyLogo: companyLogo,
                primaryColor: primaryColor,
                secondaryColor: secondaryColor,
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item label="Brand Logo" name="companyLogo">
                    <ImageCropper
                      postUrl="tenants/media/upload"
                      tenantNumber={tenantNumber}
                      setFormField={(value: string) =>
                        formWhitelabel.setFieldValue("companyLogo", value)
                      }
                      formField={companyLogo}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="primaryColor" label="Primary Color">
                    <ColorPicker showText />
                  </Form.Item>
                  <Form.Item name="secondaryColor" label="Secondary Color">
                    <ColorPicker showText />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col> */}
          {modules?.find((e: any) => e.name === "Products") && (
            <Col span={12}>
              <Card
                title="Product Specifications"
                extra={
                  <Button
                    type="primary"
                    onClick={formProductSpecification.submit}
                    loading={loading1}
                  >
                    Save
                  </Button>
                }
              >
                <Form
                  form={formProductSpecification}
                  layout="vertical"
                  onFinish={onFormProductSpecificationSubmit}
                  initialValues={{
                    productSpecifications: productSpecifications,
                  }}
                >
                  <Form.List name="productSpecifications">
                    {(fields, { add, remove }) => (
                      <Space
                        size="middle"
                        direction="vertical"
                        style={{ display: "flex" }}
                      >
                        {fields.map((field) => (
                          <Card
                            type="inner"
                            size="small"
                            title={`Item ${field.name + 1}`}
                            key={field.key}
                            extra={
                              <CloseOutlined
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            }
                          >
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item
                                  label="Name"
                                  name={[field.name, "name"]}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Missing name",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Please enter name" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  label="Data Type"
                                  name={[field.name, "dataType"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing data type",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Please choose data type"
                                    onChange={(value: any) => {
                                      setProductDataValue(value);
                                    }}
                                  >
                                    <Option value="Textfield">Textfield</Option>
                                    <Option value="Fileinput">Fileinput</Option>
                                    <Option value="Dropdown">Dropdown</Option>
                                    <Option value="Autocomplete">
                                      Autocomplete
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  label="Data Value"
                                  name={[field.name, "dataValue"]}
                                  rules={
                                    productDataValue.includes("Dropdown")
                                      ? [
                                          {
                                            required: true,
                                            message: `Please input data value !`,
                                          },
                                        ]
                                      : []
                                  }
                                >
                                  <Input
                                    placeholder="Please enter data value"
                                    disabled={
                                      productDataValue.includes("Dropdown")
                                        ? false
                                        : true
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[field.name, "supplierPortal"]}
                                  label="Supplier"
                                  extra="Show to supplier"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[field.name, "hidden"]}
                                  label="Table"
                                  extra="Hide in table"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[field.name, "analytics"]}
                                  label="Analytics"
                                  extra="Show in analytics"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[field.name, "filter"]}
                                  label="Filter"
                                  extra="Show in filter"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Configuration
                        </Button>
                      </Space>
                    )}
                  </Form.List>
                </Form>
              </Card>
            </Col>
          )}
          {modules?.find((e: any) => e.name === "Components") && (
            <Col span={12}>
              <Card
                title="Component Specifications"
                extra={
                  <Button
                    type="primary"
                    onClick={formComponentSpecification.submit}
                    loading={loading2}
                  >
                    Save
                  </Button>
                }
              >
                <Form
                  form={formComponentSpecification}
                  layout="vertical"
                  onFinish={onFormComponentSpecificationSubmit}
                  initialValues={{
                    specifications: specifications,
                  }}
                >
                  <Form.List name="specifications">
                    {(fields, { add, remove }) => (
                      <Space
                        size="middle"
                        direction="vertical"
                        style={{ display: "flex" }}
                      >
                        {fields.map((field) => (
                          <Card
                            type="inner"
                            size="small"
                            title={`Item ${field.name + 1}`}
                            key={field.key}
                            extra={
                              <CloseOutlined
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            }
                          >
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item
                                  label="Name"
                                  name={[field.name, "name"]}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Missing name",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Please enter name" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  label="Data Type"
                                  name={[field.name, "dataType"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing data type",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Please choose data type"
                                    onChange={(value: any) => {
                                      setComponentDataValue(value);
                                    }}
                                  >
                                    <Option value="Textfield">Textfield</Option>
                                    <Option value="Fileinput">Fileinput</Option>
                                    <Option value="Dropdown">Dropdown</Option>
                                    <Option value="Autocomplete">
                                      Autocomplete
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  label="Data Value"
                                  name={[field.name, "dataValue"]}
                                  rules={
                                    componentDataValue.includes("Dropdown")
                                      ? [
                                          {
                                            required: true,
                                            message: `Please input data value !`,
                                          },
                                        ]
                                      : []
                                  }
                                >
                                  <Input
                                    placeholder="Please enter data value"
                                    disabled={
                                      componentDataValue.includes("Dropdown")
                                        ? false
                                        : true
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[field.name, "supplierPortal"]}
                                  label="Supplier"
                                  extra="Show to supplier"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[field.name, "hidden"]}
                                  label="Table"
                                  extra="Hide in table"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[field.name, "analytics"]}
                                  label="Analytics"
                                  extra="Show in analytics"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[field.name, "filter"]}
                                  label="Filter"
                                  extra="Show in filter"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Configuration
                        </Button>
                      </Space>
                    )}
                  </Form.List>
                </Form>
              </Card>
            </Col>
          )}
          {/* <Col span={12}>
          <Card title="Product Table">
            <Form
              form={formProductConfiguration}
              layout="vertical"
              onFinish={onFormComponentSpecificationSubmit}
              initialValues={{
                gridConfiguration: gridConfiguration.filter(
                  (e: any) => e.type === "products"
                ),
              }}
            >
              <Form.List name="gridConfiguration">
                {(fields) => (
                  <Space
                    size="middle"
                    direction="vertical"
                    style={{ display: "flex" }}
                  >
                    {fields.map((field) => (
                      <Row gutter={16} key={field.key}>
                        <Col span={12}>
                          <Form.Item
                            label="Name"
                            name={[field.name, "text"]}
                            rules={[
                              { required: true, message: "Missing name" },
                            ]}
                          >
                            <Input placeholder="Please enter text" />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "grid"]}
                            label="Table"
                            extra="Show in table"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "filter"]}
                            label="Filter"
                            extra="Show in filter"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </Space>
                )}
              </Form.List>
            </Form>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Component Table">
            <Form
              form={formComponentConfiguration}
              layout="vertical"
              onFinish={onFormComponentSpecificationSubmit}
              initialValues={{
                gridConfiguration: gridConfiguration.filter(
                  (e: any) => e.type === "components"
                ),
              }}
            >
              <Form.List name="gridConfiguration">
                {(fields) => (
                  <Space
                    size="middle"
                    direction="vertical"
                    style={{ display: "flex" }}
                  >
                    {fields.map((field) => (
                      <Row gutter={16} key={field.key}>
                        <Col span={12}>
                          <Form.Item
                            label="Name"
                            name={[field.name, "text"]}
                            rules={[
                              { required: true, message: "Missing name" },
                            ]}
                          >
                            <Input placeholder="Please enter text" />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "grid"]}
                            label="Table"
                            extra="Show in table"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "filter"]}
                            label="Filter"
                            extra="Show in filter"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </Space>
                )}
              </Form.List>
            </Form>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Supplier Table">
            <Form
              form={formSupplierConfiguration}
              layout="vertical"
              onFinish={onFormComponentSpecificationSubmit}
              initialValues={{
                gridConfiguration: gridConfiguration.filter(
                  (e: any) => e.type === "suppliers"
                ),
              }}
            >
              <Form.List name="gridConfiguration">
                {(fields) => (
                  <Space
                    size="middle"
                    direction="vertical"
                    style={{ display: "flex" }}
                  >
                    {fields.map((field) => (
                      <Row gutter={16} key={field.key}>
                        <Col span={12}>
                          <Form.Item
                            label="Name"
                            name={[field.name, "text"]}
                            rules={[
                              { required: true, message: "Missing name" },
                            ]}
                          >
                            <Input placeholder="Please enter text" />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "grid"]}
                            label="Table"
                            extra="Show in table"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "filter"]}
                            label="Filter"
                            extra="Show in filter"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </Space>
                )}
              </Form.List>
            </Form>
          </Card>
        </Col> */}
        </Row>
      </ErrorBoundary>
    </>
  );
};

export default AccountSettings;
