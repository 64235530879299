import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { SubscriptionsState } from "../../utils/types/subscription";

/**
 * Bulk Operations
 */
export const listSubscriptions = (payload: any) => () => {
  return new Promise<SubscriptionsState>((resolve, reject) => {
    privateGetApi("/admins/subscriptions", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createSubscription = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/admins/subscriptions", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readSubscription = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/admins/subscriptions/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateSubscription = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/admins/subscriptions/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSubscription = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/admins/subscriptions/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
