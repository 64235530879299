import React, { useEffect, useState } from "react";
import { SettingOutlined, EditOutlined } from "@ant-design/icons";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import Overview from "./Overview";
import Contacts from "./Contacts";
import Components from "./Components";
import Smelters from "./Smelters";
import AuditLogs from "./AuditLogs";

import {
  Space,
  Typography,
  Button,
  Input,
  Card,
  Col,
  Row,
  Divider,
  Form,
  Tabs,
  Select,
  Avatar,
} from "antd";
import type { TabsProps, SelectProps } from "antd";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import type { TablePaginationConfig } from "antd/es/table";
import { Supplier, Contact } from "../../../../utils/types/supplier";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  readSupplier,
  readSupplierComponents,
  updateSupplier,
} from "../../../../redux/actions/suppliersAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../../utils/localStore";
import { listUsers } from "../../../../redux/actions/usersAction";
import { User } from "../../../../utils/types/user";
import { Component } from "../../../../utils/types/component";
import ErrorBoundary from "../../../../utils/errorBoundary";
import supplierIcon from "../../../../assets/supplier portal/supplier-profile-icon.png";

const { Option } = Select;
const { Title, Text } = Typography;
const { TextArea } = Input;

function getLinearGradient(hexColor: string) {
  let hex = hexColor.replace(/^#/, '');
  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }
  const num = parseInt(hex, 16);
  const r = (num >> 16) & 255;
  const g = (num >> 8) & 255;
  const b = num & 255;

  const fromColor = (r+10) + "," + (g+10) + "," + (b+10);
  const color2 = (r-40) + "," + (g-40) + "," + (b-40);
  const toColor = (r-70) + "," + (g-70) + "," + (b-70);
  return `linear-gradient(90deg, rgba(${fromColor},1) 0%, rgba(${color2}, 1) 40%, rgba(${toColor}, 1) 100%)`;
}

const SupplierProfile: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const [form] = Form.useForm();
  const { Meta } = Card;
  const navigate = useNavigate();
  const location = useLocation();
  const user = getLocalStorage("user");

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [usersOptions, setUsersOptions] = useState<any>([]);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Component>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const [supplierDetail, setSupplierDetail] = useState<Supplier>();
  const [supplierCampaigns, setsupplierCampaignss] = useState<null | null>(
    null
  );
  const [isHideDetails, setDetailsVisible] = useState(true);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [supplierHistory, setSupplierHistory] = useState<any>([]);

  const [supplierComponents, setSupplierComponents] = useState<Component[]>([]);
  const [supplierComponentsLength, setSupplierComponentsLength] =
    useState<number>(0);
  const [emitChild, setChildEmit] = useState("");
  const [childLoading, setChildLoading] = useState(false);

  const contacts: Contact[] = supplierDetail ? supplierDetail.contacts : [];

  const items: TabsProps["items"] = [
    // {
    //   key: "overview",
    //   label: "Overview",
    //   children: <Overview supplierCampaigns={supplierCampaigns} />,
    // },
    {
      key: "",
      label: "Contacts",
      children: <Contacts contacts={contacts} />,
    },
    permissions?.read?.includes("components") && {
      key: "components",
      label: "Components",
      children: (
        <Components
          components={supplierComponents}
          count={supplierComponentsLength}
          childLoading={childLoading}
          type={emitChild}
        />
      ),
    },
    (permissions?.read?.includes("conflictminerals") ||
      permissions?.read?.includes("extendedminerals")) && {
      key: "smelters",
      label: "Smelters",
      children: (
        <Smelters
          supplierName={(supplierDetail && supplierDetail.name) || null}
        />
      ),
    },
    {
      key: "auditlogs",
      label: "Audit Logs",
      children: <AuditLogs supplierHistory={supplierHistory} />,
    },
  ].filter(Boolean);

  const fetchData = (id: string) => {
    setLoading(true);
    dispatch(readSupplier(id))
      .then((response) => {
        setSupplierDetail(response.supplier as Supplier);
        setsupplierCampaignss(response.documentCampaigns);
        form.setFieldsValue(response.supplier);
        setSupplierHistory(response?.documentHistory);
        setSupplierHistory(
          response?.documentHistory.map((e: any) => {
            return {
              ...e,
              document: {
                ...e.document,
                number: response?.supplier?.number,
                name: response?.supplier?.name,
              },
            };
          })
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchComponentData = () => {
    dispatch(
      readSupplierComponents({
        id: id,
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: "",
      })
    )
      .then((response: any) => {
        setSupplierComponents(response.documentComponents);
        setSupplierComponentsLength(response.documentComponentsLength);
        setTimeout(() => {
          setChildLoading(false);
        }, 1500);
      })
      .catch(() => {
        setChildLoading(false);
      });
  };

  const fetchChildData = () => {
    setChildLoading(true);
    permissions?.read?.includes("components") && fetchComponentData();
    setChildEmit("emitChild");
  };

  const handleHideDetails = () => {
    setDetailsVisible(!isHideDetails);
  };

  const handleTabChange = (key: string) => {
    navigate(key);
  };
  const regulations = getRegulationsFromLocalStorage() ?? [];
  const options: SelectProps["options"] = [];

  regulations?.forEach((regulation: any) => {
    options.push({
      label: regulation.name,
      value: regulation.regulationNumber,
    });
  });

  if (permissions.read?.includes?.("conflictminerals")) {
    options.push({
      label: "Conflict Minerals",
      value: "conflict",
    });
  }
  if (permissions.read?.includes?.("extendedminerals")) {
    options.push({
      label: "Extended Minerals",
      value: "extended",
    });
  }

  const fetchUsersData = (search?: any) => {
    setLoading(true);
    dispatch(
      listUsers({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify({
          firstName: { $regex: search, $options: "i" },
        }),
      })
    )
      .then((response) => {
        setUsersOptions(
          response.users.map((e: User) => ({
            label: e.firstName,
            value: e.emailAddress,
            ...e,
          }))
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(id);
    fetchUsersData("");
  }, [id]);

  const onFormSubmit = (updates: Supplier) => {
    dispatch(updateSupplier({ type: "supplier", updates: updates }, id))
      .then(() => {
        fetchData(id);
        form.resetFields();
        setShowEditBtn(false);
        fetchChildData();
      })
      .catch((e: any) => console.log(e));
  };

  return (
    <>
      <ErrorBoundary>
        <Card
          bordered={false}
          loading={loading}
          style={{
            background: getLinearGradient(user?.secondaryColor),
            margin: "-2em -30px 0",
          }}
        >
          <Row justify="space-between" align="bottom">
            <Col>
              <Row align="middle" gutter={25}>
                <Col>
                  <Avatar
                    size={85}
                    src={supplierIcon}
                    style={{ backgroundColor: "#fff" }}
                  />
                </Col>
                <Col>
                  <div>
                    <Title level={5} style={{ color: "white", marginTop: 0 }}>
                      {supplierDetail && supplierDetail.name}
                    </Title>
                    <Title level={4} style={{ color: "white", margin: 0 }}>
                      {supplierDetail && supplierDetail.number}
                    </Title>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Space>
                <Button
                  type="primary"
                  onClick={handleHideDetails}
                  className="btn-light"
                >
                  {" "}
                  {isHideDetails ? "Hide Details" : "Show Details"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col span={24} style={{ marginTop: "1rem" }}>
            {isHideDetails && (
              <Card style={{ marginTop: "1rem" }}>
                <Space
                  align="end"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                  }}
                >
                  <Meta
                    title="About the Supplier :"
                    description={
                      supplierDetail?.createdAt
                        ? `Created on : ${new Date(
                            supplierDetail?.createdAt
                          ).toLocaleString("en-US", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                          })}`
                        : "N/A"
                    }
                  />
                  <Space>
                    {!showEditBtn ? (
                      <Button
                        type="primary"
                        ghost
                        onClick={() => setShowEditBtn(!showEditBtn)}
                        icon={<SettingOutlined />}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={form.submit}
                      >
                        Save
                      </Button>
                    )}
                  </Space>
                </Space>
                <Divider />
                <Form
                  wrapperCol={{ span: 24 }}
                  layout="vertical"
                  initialValues={{ ...supplierDetail }}
                  onFinish={onFormSubmit}
                  form={form}
                >
                  <Row gutter={24}>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item name="website" label="Website">
                          <Input />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Website</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.website || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item name="city" label="City">
                          <Input />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>City</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.city || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item name="country" label="Country">
                          <Input />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Country</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.country || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                     
                      {showEditBtn ? (
                         <Form.Item name="phoneNumber" label="Company Phone">
                         <Input />
                       </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Company Phone</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.phoneNumber || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginTop: "16px" }}>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item name="type" label="Supplier Type">
                          <Select placeholder="Select Supplier Type">
                            <Option value="Distributor">Distributor</Option>
                            <Option value="Manufacturer">Manufacturer</Option>
                            <Option value="Connector">Connector</Option>
                            <Option value="OTS">OTS</Option>
                            <Option value="Package & Labels">
                              Package & Labels
                            </Option>
                            <Option value="Chemical">Chemical</Option>
                            <Option value="Line Card">Line Card</Option>
                            <Option value="Others">Others</Option>
                          </Select>
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Supplier Type</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.type || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                       <Form.Item name="state" label="State/Province">
                       <Input />
                     </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>State/Province</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.state || "N/A"}
                          </Text>
                        </div>
                      )}
                      
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item name="zipcode" label="ZipCode">
                        <Input />
                      </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>ZipCode</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.zipcode || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                         <Form.Item name="comments" label="Supplier Remarks">
                         <TextArea rows={3} />
                       </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Supplier Remarks</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.city || "N/A"}
                          </Text>
                        </div>
                      )}
                     
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginTop: "16px" }}>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item name="status" label="Supplier Status">
                        <Select>
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                        </Select>
                      </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>City</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.city || "N/A"}
                          </Text>
                        </div>
                      )}
                      
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item
                          name="countryOfOrigin"
                          label="Country of Origin"
                        >
                          <Input />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Country of Origin</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.countryOfOrigin || "N/A"}
                          </Text>
                        </div>
                      )}
                      
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item name="outOfScope" label="Out of scope for">
                          <Select
                            mode="multiple"
                            allowClear
                            placeholder="Select Regulations"
                            options={options}
                          />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Out of scope for</Text>
                          <br />
                          <Text type="secondary">
                            {supplierDetail?.outOfScope?.join(", ") || "N/A"}
                          </Text>
                        </div>
                      )}
                      
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item
                          name="assignedTo"
                          label="Assigned to"
                          initialValue={
                            (supplierDetail && supplierDetail?.assignedTo) ||
                            "N/A"
                          }
                        >
                          <Select
                            options={usersOptions}
                            onSearch={(text) => fetchUsersData(text)}
                            placeholder="Please select a users"
                            allowClear
                          />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Assigned to</Text>
                          <br />
                          <Text type="secondary">
                            {(supplierDetail && supplierDetail?.assignedTo) ||
                              "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
            <Tabs
              items={items}
              activeKey={
                location.pathname.split("/").pop() === id
                  ? ""
                  : location.pathname.split("/").pop()
              }
              onChange={handleTabChange}
            />
          </Col>
        </Row>
      </ErrorBoundary>
    </>
  );
};

export default SupplierProfile;
