import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ComponentsState, Component } from "../../utils/types/component";

const initialState: ComponentsState = {
  components: [],
  componentCount: 0,
  component: {},
  alternates: [],
  componentHistory: [],
};

export const componentsSlice = createSlice({
  name: "components",
  initialState,
  reducers: {
    setComponents: (
      state,
      action: PayloadAction<{
        components: Component[];
        componentCount: number;
      }>
    ) => {
      state.components = action.payload.components;
      state.componentCount = action.payload.componentCount;
    },
    readSupplier: (
      state,
      action: PayloadAction<any>
    ) => {
      state.component = action.payload.component;
      state.alternates = action.payload.documentAlternates;
      state.componentHistory = action.payload.documentHistory;
    },
  },
});

export const { setComponents } = componentsSlice.actions;

export default componentsSlice.reducer;
