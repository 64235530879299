export const storeRegulationsInLocalStorage = (payload: any) => {
  try {
    const storeRegulations = payload?.map(
      ({
        name,
        regulationNumber,
        configuration,
        hasExemptions,
      }: {
        name: string;
        regulationNumber: string;
        configuration: any;
        hasExemptions: boolean;
      }) => ({ name, regulationNumber, configuration, hasExemptions })
    );
    const regulations = JSON.stringify(storeRegulations);
    localStorage.setItem("regulations", btoa(regulations));
  } catch (error) {
    console.error(error);
  }
};

export const getRegulationsFromLocalStorage = (): any | null => {
  try {
    const regulations = atob(localStorage.getItem("regulations") || "");

    if (regulations) {
      return JSON.parse(regulations);
    }

    return null;
  } catch (error) {
    return null;
  }
};

/**
 * Set data to local storage
 */
export const setLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, btoa(encodeURIComponent(JSON.stringify(value))));

    return null;
  } catch (error) {
    return null;
  }
};

/**
 * Get data from local storage
 */
export const getLocalStorage = (key: string) => {
  try {
    // const value = atob(localStorage.getItem(key) || "");
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(decodeURIComponent(atob(value)));
    }

    return null;
  } catch (error) {
    return null;
  }
};

/**
 * Remove data from local storage
 */
export const removeLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Clear local storage
 */
export const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.error(error);
  }
};
