import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Button,
  Drawer,
  Select,
  DatePicker,
} from "antd";

import { Exemption } from "../../utils/types/regulation";
import { useAppDispatch } from "../../redux/hooks";
import {
  createExemption,
  updateExemption,
} from "../../redux/actions/regulationsAction";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const { Option } = Select;

const ExemptionForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Exemption;
  id: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFormSubmit = (values: any) => {
    setLoading(true);
    const data = {
      ...values,
      startDate: values.startDate
        ? dayjs(values.startDate).format("DD-MMM-YYYY")
        : "",
      endDate: values.endDate
        ? dayjs(values.endDate).format("DD-MMM-YYYY")
        : "",
      renewalRequestDate: values.renewalRequestDate
        ? dayjs(values.renewalRequestDate).format("DD-MMM-YYYY")
        : "",
    };
    if (props.type === "create") {
      dispatch(createExemption({ regulationNumber: props.id, ...data }))
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(
        updateExemption(
          { updates: { regulationNumber: props.id, ...data } },
          props.formData._id
        )
      )
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <div>
      <Button
        type={props.type === "create" ? "primary" : "text"}
        onClick={showDrawer}
        title={props.type === "create" ? "New Exemption" : "Edit Exemption"}
        data-testid={
          props.type === "create"
            ? "create-exemption-button"
            : "edit-exemption-button"
        }
        icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
      >
        {props.type === "create" ? "Add Exemption" : null}
      </Button>
      <Drawer
        title={props.type === "create" ? "Add Exemption" : "Edit Exemption"}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-exemption-button">
              Cancel
            </Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              data-testid="submit-exemption-button"
            >
              {props.type === "create" ? "Submit" : "Save"}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={
            props.type === "create"
              ? {}
              : {
                  ...props.formData,
                  startDate: props.formData?.startDate
                    ? dayjs(props.formData?.startDate.toString(), "DD-M-YYYY")
                    : "",
                  endDate: props.formData?.endDate
                    ? dayjs(props.formData?.endDate.toString(), "DD-M-YYYY")
                    : "",
                  renewalRequestDate: props.formData?.renewalRequestDate
                    ? dayjs(props.formData?.renewalRequestDate, "DD-M-YYYY")
                    : "",
                }
          }
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="exemption"
                label="Exemption Number*"
                rules={[
                  { required: true, message: "Please input Exemption Number!" },
                  {
                    pattern: /^([a-zA-Z0-9-IX|IV|V?I).(-]+\s?)*$/,
                    message: "Not allowed",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="clause"
                label="Exemption Clause"
                rules={[
                  { required: true, message: "Please input CAS Number!" },
                  {
                    pattern: /^([a-zA-Z0-9-IX|IV|V?I).(-]+\s?)*$/,
                    message: "Not allowed",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="categories"
                label="Categories"
                extra="Enter Categories values separated by comma"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} sm={24} md={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: "Please enter description!" },
                ]}
              >
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="startDate" label="Start Date">
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="endDate" label="End Date">
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="renewalRequestDate" label="Renewal Request Date">
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="valid" label="Valid">
                <Select>
                  <Option value="Valid">Valid</Option>
                  <Option value="Valid - requested for renewal">
                    Valid - requested for renewal
                  </Option>
                  <Option value="No longer valid">No longer valid</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default ExemptionForm;
