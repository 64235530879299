import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Radio,
  Select,
  Space,
  Table,
  Card,
  Result,
  Tabs,
  TabsProps,
  Steps,
  Row,
  Col,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { ColumnsType } from "antd/es/table";
import {
  conflictAnalytics,
  createConflictMinerals,
  generateConflict,
} from "../../../../redux/actions/conflictMineralsAction";
import { listSmelter } from "../../../../redux/actions/extendedMineralsActions";
import { updateSupplierConflictMinerals } from "../../../../redux/actions/supplierPortalAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { analyticsCMRT, generateCMRT } from "../../../../utils/types/summary";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  PlusOutlined,
  WarningFilled,
} from "@ant-design/icons";
import AddSmelter from "../../../../components/modals/add-smelter-modal";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../../../utils/localStore";
import ErrorBoundary from "../../../../utils/errorBoundary";

interface Declaration {
  question: string | null;
  name: string | null;
  state: string | null;
  comments: string | null;
}

interface Policy {
  question: string | null;
  name: string | null;
  state: string | null;
  comments: string | null;
}
interface Smelter {
  name: string;
  number: string;
  metal: string;
  lookUp: string;
  sourceOfIdentificationNumber: string;
  address: {
    country: string;
    city: string;
    state: string;
  };
}
interface Products {
  manufacturer: {
    itemNumber: string;
    name: string;
  };
  comments: string;
}
interface FormGenerate {
  cmrtVersion: null | string;
  company: {
    name: string;
    declarationScope: string;
    declarationDescription: string;
    uniqueId: string;
    uniqueIdAuthority: string;
    address: string;
    contact: {
      name: string;
      emailAddress: string;
      phoneNumber: string;
    };
    authorizer: {
      name: string;
      title: string;
      emailAddress: string;
      phoneNumber: string;
      effectiveDate: Dayjs | null;
    };
  };
  declaration: Declaration[];
  declarationScope: Declaration[];
  policy: Policy[];
  smelter: Smelter[];
  products: Products[];
  rawFileName: string;
}

const ExcelTabs: React.FC = () => {
  const [ConflictformGenerate, setConflictformGenerate] =
    useState<FormGenerate>({
      cmrtVersion: "",
      company: {
        name: "",
        declarationScope: "",
        declarationDescription: "",
        uniqueId: "",
        uniqueIdAuthority: "",
        address: "",
        contact: {
          name: "",
          emailAddress: "",
          phoneNumber: "",
        },
        authorizer: {
          name: "",
          title: "",
          emailAddress: "",
          phoneNumber: "",
          effectiveDate: dayjs(),
        },
      },
      declaration: [
        {
          question:
            "Is any 3TG intentionally added or used in the product(s) or in the production process?",
          name: "Tantalum",
          state: null,
          comments: null,
        },
        {
          question:
            "Is any 3TG intentionally added or used in the product(s) or in the production process?",
          name: "Tin",
          state: null,
          comments: null,
        },
        {
          question:
            "Is any 3TG intentionally added or used in the product(s) or in the production process?",
          name: "Gold",
          state: null,
          comments: null,
        },
        {
          question:
            "Is any 3TG intentionally added or used in the product(s) or in the production process?",
          name: "Tungsten",
          state: null,
          comments: null,
        },
        {
          question: "Does any 3TG remain in the product(s)?",
          name: "Tantalum",
          state: null,
          comments: null,
        },
        {
          question: "Does any 3TG remain in the product(s)?",
          name: "Tin",
          state: null,
          comments: null,
        },
        {
          question: "Does any 3TG remain in the product(s)?",
          name: "Gold",
          state: null,
          comments: null,
        },
        {
          question: "Does any 3TG remain in the product(s)?",
          name: "Tungsten",
          state: null,
          comments: null,
        },
      ],
      products: [],
      declarationScope: [
        {
          question:
            "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)",
          name: "Tantalum",
          state: null,
          comments: null,
        },
        {
          question:
            "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)",
          name: "Tin",
          state: null,
          comments: null,
        },
        {
          question:
            "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)",
          name: "Gold",
          state: null,
          comments: null,
        },
        {
          question:
            "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)",
          name: "Tungsten",
          state: null,
          comments: null,
        },
        {
          question:
            "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?",
          name: "Tantalum",
          state: null,
          comments: null,
        },
        {
          question:
            "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?",
          name: "Tin",
          state: null,
          comments: null,
        },
        {
          question:
            "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?",
          name: "Gold",
          state: null,
          comments: null,
        },
        {
          question:
            "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?",
          name: "Tungsten",
          state: null,
          comments: null,
        },
        {
          question:
            "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?",
          name: "Tantalum",
          state: null,
          comments: null,
        },
        {
          question:
            "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?",
          name: "Tin",
          state: null,
          comments: null,
        },
        {
          question:
            "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?",
          name: "Gold",
          state: null,
          comments: null,
        },
        {
          question:
            "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?",
          name: "Tungsten",
          state: null,
          comments: null,
        },
        {
          question:
            "What percentage of relevant suppliers have provided a response to your supply chain survey?",
          name: "Tantalum",
          state: null,
          comments: null,
        },
        {
          question:
            "What percentage of relevant suppliers have provided a response to your supply chain survey?",
          name: "Tin",
          state: null,
          comments: null,
        },
        {
          question:
            "What percentage of relevant suppliers have provided a response to your supply chain survey?",
          name: "Gold",
          state: null,
          comments: null,
        },
        {
          question:
            "What percentage of relevant suppliers have provided a response to your supply chain survey?",
          name: "Tungsten",
          state: null,
          comments: null,
        },
        {
          question:
            "Have you identified all of the smelters supplying the 3TG to your supply chain?",
          name: "Tantalum",
          state: null,
          comments: null,
        },
        {
          question:
            "Have you identified all of the smelters supplying the 3TG to your supply chain?",
          name: "Tin",
          state: null,
          comments: null,
        },
        {
          question:
            "Have you identified all of the smelters supplying the 3TG to your supply chain?",
          name: "Gold",
          state: null,
          comments: null,
        },
        {
          question:
            "Have you identified all of the smelters supplying the 3TG to your supply chain?",
          name: "Tungsten",
          state: null,
          comments: null,
        },
        {
          question:
            "Has all applicable smelter information received by your company been reported in this declaration?",
          name: "Tantalum",
          state: null,
          comments: null,
        },
        {
          question:
            "Has all applicable smelter information received by your company been reported in this declaration?",
          name: "Tin",
          state: null,
          comments: null,
        },
        {
          question:
            "Has all applicable smelter information received by your company been reported in this declaration?",
          name: "Gold",
          state: null,
          comments: null,
        },
        {
          question:
            "Has all applicable smelter information received by your company been reported in this declaration?",
          name: "Tungsten",
          state: null,
          comments: null,
        },
      ],
      policy: [
        {
          question:
            "A. Have you established a responsible minerals sourcing policy?",
          name: "Point A",
          state: null,
          comments: null,
        },
        {
          question:
            "B. Is your responsible minerals sourcing policy publicly available on your website? (Note – If yes, the user shall specify the URL in the comment field.)",
          name: "Point B",
          state: null,
          comments: null,
        },
        {
          question:
            "C. Do you require your direct suppliers to source the 3TG from smelters whose due diligence practices have been validated by an independent third party audit program?",
          name: "Point C",
          state: null,
          comments: null,
        },
        {
          question:
            "D. Have you implemented due diligence measures for responsible sourcing?",
          name: "Point D",
          state: null,
          comments: null,
        },
        {
          question:
            "E. Does your company conduct Conflict Minerals survey(s) of your relevant supplier(s)?",
          name: "Point E",
          state: null,
          comments: null,
        },
        {
          question:
            "F. Do you review due diligence information received from your suppliers against your company’s expectations?",
          name: "Point F",
          state: null,
          comments: null,
        },
        {
          question:
            "G. Does your review process include corrective action management?",
          name: "Point G",
          state: null,
          comments: null,
        },
        {
          question:
            "H. Is your company required to file an annual conflict minerals disclosure with the SEC?",
          name: "Point H",
          state: null,
          comments: null,
        },
      ],
      smelter: [],
      rawFileName: "",
    });

  const [form] = Form.useForm();
  const location = useLocation();
  const selectedTableRows = location.state;
  const navigate = useNavigate();
  const { uniqueName, linkTenantNumber, linkCampaignId } = useParams();
  const [companyIcon, setcompanyIcon] = useState<boolean>(false);
  const [declarationIcon, setdeclarationIcon] = useState<boolean>(false);
  const [declarationScopeIcon, setdeclarationScopeIcon] =
    useState<boolean>(false);
  const [policyIcon, setpolicyIcon] = useState<boolean>(false);
  const [smelterIcon, setsmelterIcon] = useState<boolean>(false);
  const [productIcon, setproductIcon] = useState<boolean>(false);

  const [fieldvalidation, setfieldvalidation] = useState<boolean>(
    false || true
  );
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    sorter: {
      field: "metal",
      order: ["ascend"],
    },
  });

  const [loading, setLoading] = useState(false);
  const [disabledtab, setdisabledtab] = useState<boolean>(true);

  const readConflictMineralAnalytic = async () => {
    setLoading(true);
    try {
      const response: analyticsCMRT = await conflictAnalytics({
        version: getLocalStorage("cmrtversion"),
      });
      if (response) {
        const suppliers = selectedTableRows
          ? selectedTableRows?.selectedTableRows?.length
          : response.suppliers;
        const supplierResponses = selectedTableRows
          ? selectedTableRows?.selectedTableRows?.flatMap(
              (e: { conflictMineral: any }) => e?.conflictMineral || []
            ).length
          : response.supplierResponses;
        await readConflictMineralsGenerate(suppliers, supplierResponses);
      } else {
        console.error("Invalid or missing data in the API response.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const readConflictMineralsGenerate = async (
    suppliers: any,
    supplierResponses: any
  ) => {
    setLoading(true);
    const user = getLocalStorage("user");
    const tenantNumber = user.tenantNumber;
    try {
      const response: generateCMRT = await generateConflict({
        tenantNumber: tenantNumber,
      });
      if (response) {
        const updateDeclaration = ConflictformGenerate.declaration?.map((e) => {
          const obj = response.declarationDocument.find(
            (f) => f.name === e.name && f.question === e.question
          );
          return {
            ...e,
            state: obj?.state || null,
            comments: obj?.comments || null,
          };
        });

        const updateDeclarationScope =
          ConflictformGenerate.declarationScope?.map((e) => {
            const obj = response?.declarationScopeDocument?.find(
              (f) => f.name === e.name && f.question === e.question
            );

            if (
              obj?.question ===
              "What percentage of relevant suppliers have provided a response to your supply chain survey?"
            ) {
              let state = obj?.state;
              if (supplierResponses === 0) {
                state = "None";
              } else if ((supplierResponses / suppliers) * 100 < 50) {
                state = "50% or less";
              } else if ((supplierResponses / suppliers) * 100 < 75) {
                state = "Greater than 50%";
              } else if ((supplierResponses / suppliers) * 100 < 90) {
                state = "Greater than 75%";
              } else if ((supplierResponses / suppliers) * 100 < 100) {
                state = "Greater than 90%";
              } else if ((supplierResponses / suppliers) * 100 === 100) {
                state = 1;
              }

              e.state = state;
              e.comments = obj?.comments;
            } else {
              e.state = obj?.state;
              e.comments = obj?.comments;
            }

            return e;
          });
        const updatePolicy = ConflictformGenerate.policy?.map((e) => {
          const obj = response.policyDocument.find(
            (f) => f.name === e.name && f.question === e.question
          );
          return {
            ...e,
            state: obj?.state || null,
            comments: obj?.comments || null,
          };
        });
        const user = getLocalStorage("user");
        let companyDetails: any;
        if (user) {
          const parsedData = user;
          if (response.company === undefined) {
            companyDetails = {
              name: parsedData.companyName,
              declarationScope: "A. Company",
              declarationDescription: "",
              uniqueId: "",
              uniqueIdAuthority: "",
              address: "",
              contact: {
                name:
                  parsedData.firstName +
                  " " +
                  (parsedData.lastName ? parsedData.lastName : ""),
                emailAddress: parsedData.emailAddress,
                phoneNumber: parsedData.phoneNumber,
              },
              authorizer: {
                name: "",
                title: "",
                emailAddress: "",
                phoneNumber: "",
                effectiveDate: dayjs().format("DD-MMM-YYYY HH:mm:ss"),
              },
            };
          } else {
            const updatedCompanyDetails = response.company;
            companyDetails = {
              ...updatedCompanyDetails,
              authorizer: {
                name: updatedCompanyDetails.authorizer.name,
                title: updatedCompanyDetails.authorizer.title,
                emailAddress: updatedCompanyDetails.authorizer.emailAddress,
                phoneNumber: updatedCompanyDetails.authorizer.phoneNumber,
                effectiveDate: dayjs().format("DD-MMM-YYYY HH:mm:ss"),
              },
            };
          }
          setConflictformGenerate((prevFormData) => ({
            ...prevFormData,
            company: companyDetails,
            declaration: updateDeclaration,
            declarationScope: updateDeclarationScope,
            policy: updatePolicy,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedDataString = getLocalStorage("conflictMinerals");
    const fetchData = async () => {
      try {
        if (storedDataString) {
          try {
            setdisabledtab(false);
            const storedData: FormGenerate = storedDataString;
            setConflictformGenerate(storedData);
            form.setFieldsValue(storedData);
          } catch (error) {
            console.error("Error parsing stored data:", error);
          }
        } else {
          readConflictMineralAnalytic();
        }
      } catch (error) {
        console.error("Error parsing stored data:", error);
      }
    };
    fetchData();
    if (getLocalStorage("accessToken")) {
      handleTableChange(
        { pageSize: 10, current: 1 },
        {},
        { field: tableParams.sorter.field, order: tableParams.sorter.order }
      );
    }
  }, [form, selectedTableRows]);

  const [disabledgold, setDisabledgold] = useState<boolean>(false || true);
  const [disabledtin, setDisabledtin] = useState<boolean>(false || true);
  const [disabledtungsten, setDisabledtungsten] = useState<boolean>(
    false || true
  );
  const [disabledtantalum, setDisabledtantalum] = useState<boolean>(
    false || true
  );
  const [isUpdated, setisUpdated] = useState<boolean>(false);

  const [dec, setdec] = useState<any[]>([]);
  const [policyField, setpolicyField] = useState<any[]>([]);

  const [scope, setscope] = useState<any[]>([]);
  const handleChangeTab = (key: string) => {
    setCurrentStep(parseInt(key));
  };

  useEffect(() => {
    if (
      !dayjs.isDayjs(ConflictformGenerate?.company?.authorizer?.effectiveDate)
    ) {
      ConflictformGenerate.company.authorizer.effectiveDate = dayjs(
        ConflictformGenerate.company.authorizer.effectiveDate
      );
    }
    if (disabledtab === true) {
      setCurrentStep(0);
    } else if (
      disabledtab === false &&
      selectedOption === "B. Product (or List of Products)"
    ) {
      setCurrentStep(7);
    } else {
      setCurrentStep(6);
    }
    form.setFieldsValue(ConflictformGenerate);
    if (
      ConflictformGenerate?.declaration &&
      ConflictformGenerate?.declaration?.length > 0 &&
      !isUpdated
    ) {
      setDisabledgold(
        ConflictformGenerate.declaration.some(
          (val) =>
            val.name === "Gold" &&
            val.question ===
              "Is any 3TG intentionally added or used in the product(s) or in the production process?" &&
            val.state &&
            ["No", "Unknown"].includes(val.state)
        ) ||
          ConflictformGenerate.declaration.some(
            (val) =>
              val.name === "Gold" &&
              val.question === "Does any 3TG remain in the product(s)?" &&
              val.state &&
              ["No", "Unknown"].includes(val.state)
          )
      );
      setDisabledtin(
        ConflictformGenerate.declaration.some(
          (val) =>
            val.name === "Tin" &&
            val.question ===
              "Is any 3TG intentionally added or used in the product(s) or in the production process?" &&
            val.state &&
            ["No", "Unknown"].includes(val.state)
        ) ||
          ConflictformGenerate.declaration.some(
            (val) =>
              val.name === "Tin" &&
              val.question === "Does any 3TG remain in the product(s)?" &&
              val.state &&
              ["No", "Unknown"].includes(val.state)
          )
      );
      setDisabledtantalum(
        ConflictformGenerate.declaration.some(
          (val) =>
            val.name === "Tantalum" &&
            val.question ===
              "Is any 3TG intentionally added or used in the product(s) or in the production process?" &&
            val.state &&
            ["No", "Unknown"].includes(val.state)
        ) ||
          ConflictformGenerate.declaration.some(
            (val) =>
              val.name === "Tantalum" &&
              val.question === "Does any 3TG remain in the product(s)?" &&
              val.state &&
              ["No", "Unknown"].includes(val.state)
          )
      );
      setDisabledtungsten(
        ConflictformGenerate.declaration.some(
          (val) =>
            val.name === "Tungsten" &&
            val.question ===
              "Is any 3TG intentionally added or used in the product(s) or in the production process?" &&
            val.state &&
            ["No", "Unknown"].includes(val.state)
        ) ||
          ConflictformGenerate.declaration.some(
            (val) =>
              val.name === "Tungsten" &&
              val.question === "Does any 3TG remain in the product(s)?" &&
              val.state &&
              ["No", "Unknown"].includes(val.state)
          )
      );
    }
    setSelectedOption(ConflictformGenerate.company.declarationScope);
    const allFields = form.getFieldsValue();
    const fieldNames = Object.keys(allFields);
    const policyValFields: string[] = [];
    fieldNames.forEach((name) => {
      if (
        (name.startsWith("A.") ||
          name.startsWith("B.") ||
          name.startsWith("C.") ||
          name.startsWith("D.") ||
          name.startsWith("E.") ||
          name.startsWith("F.") ||
          name.startsWith("G.") ||
          name.startsWith("H.")) &&
        name.endsWith("state")
      ) {
        policyValFields.push(name);
      }
    });

    setpolicyField(policyValFields);
    if (!getLocalStorage("conflictMinerals")) {
      setfieldvalidation(false);
    }
  }, [ConflictformGenerate, form]);

  const columns: ColumnsType<Smelter> = [
    {
      title: "Number #",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Look Up Name",
      dataIndex: "lookUp",
      key: "lookUp",
      render: (text, record) => record.lookUp || record.name,
    },
    {
      title: "Metal",
      dataIndex: "metal",
      key: "metal",
    },
    {
      title: "Country",
      dataIndex: ["address", "country"],
      key: "country",
    },
  ];

  const columns1: ColumnsType<Products> = [
    {
      title: "itemNumber",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "itemNumber",
    },
    {
      title: "name",
      dataIndex: ["manufacturer", "name"],
      key: "manuname",
    },
  ];

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const declaration = [];
      const declarationscope = [];
      for (const question of [
        "Is any 3TG intentionally added or used in the product(s) or in the production process?",
        "Does any 3TG remain in the product(s)?",
      ]) {
        for (const name of ["Tantalum", "Tin", "Gold", "Tungsten"]) {
          const keyPrefix = `${question}-${name}`;
          const stateKey = Object.keys(values).find(
            (key) => key.startsWith(keyPrefix) && key.endsWith("-state")
          );
          const commentkey = Object.keys(values).find(
            (key) => key.startsWith(keyPrefix) && key.endsWith("-comments")
          );

          if (stateKey && commentkey) {
            const stateValue = values[stateKey];
            const state = stateValue === null ? "None" : stateValue;
            const comment = values[commentkey];

            declaration.push({
              question,
              name,
              state,
              comments: comment,
            });
          }
        }
      }
      for (const question of [
        "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)",
        "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?",
        "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?",
        "What percentage of relevant suppliers have provided a response to your supply chain survey?",
        "Have you identified all of the smelters supplying the 3TG to your supply chain?",
        "Has all applicable smelter information received by your company been reported in this declaration?",
      ]) {
        for (const name of ["Tantalum", "Tin", "Gold", "Tungsten"]) {
          const keyPrefix = `${question}-${name}`;
          const stateKey = Object.keys(values).find(
            (key) => key.startsWith(keyPrefix) && key.endsWith("-state")
          );
          const commentkey = Object.keys(values).find(
            (key) => key.startsWith(keyPrefix) && key.endsWith("-comments")
          );
          if (stateKey && commentkey) {
            const stateValue = values[stateKey];
            const state = stateValue === null ? "None" : stateValue;
            const comment = values[commentkey];

            declarationscope.push({
              question,
              name,
              state,
              comments: comment,
            });
          }
        }
      }

      const policy = ConflictformGenerate?.policy.map(
        (
          item: { question: any; name: any; comments: any; state: any },
          index
        ) => {
          const statekey = `${item.question}-${item.name}-${item.state}-${index}-state`;
          const commentkey = `${item.question}-${item.name}-${item.comments}-${index}-comments`;
          return {
            question: item.question,
            name: item.name,
            state: values[statekey],
            comments: values[commentkey],
          };
        }
      );

      const companyDetails = {
        name: values.company.name,
        declarationScope: values.company.declarationScope,
        declarationDescription: values.company.declarationDescription,
        uniqueId: values.company.uniqueId,
        uniqueIdAuthority: values.company.uniqueIdAuthority,
        address: values.company.address,
        contact: {
          name: values.company.contact?.name,
          emailAddress: values.company.contact?.emailAddress,
          phoneNumber: values.company.contact?.phoneNumber,
        },
        authorizer: {
          name: values.company.authorizer?.name,
          title: values.company.authorizer?.title,
          emailAddress: values.company.authorizer?.emailAddress,
          phoneNumber: values.company.authorizer?.phoneNumber,
          effectiveDate: (
            values.company.authorizer?.effectiveDate || ""
          ).format("DD-MMM-YYYY HH:mm:ss"),
        },
      };

      const user = getLocalStorage("user");
      const supplier = getLocalStorage("supplier");
      if (user) {
        let smelterVal;
        const parsedData = user;
        const companyName = parsedData.companyName;
        const storedDataString = getLocalStorage("conflictMinerals");
        if (storedDataString) {
          smelterVal = ConflictformGenerate?.smelter;
        } else {
          smelterVal = smelterData;
        }

        if (getLocalStorage("accessToken")) {
          await createConflictMinerals({
            cmrtVersion: ConflictformGenerate?.cmrtVersion,
            declaration: declaration,
            declarationScope: declarationscope,
            policy: policy,
            company: companyDetails,
            smelters:
              disabledgold &&
              disabledtantalum &&
              disabledtin &&
              disabledtungsten
                ? []
                : smelterVal,
            products: ConflictformGenerate?.products,
            rawFileName: ConflictformGenerate?.rawFileName,
            supplierName: companyName,
            cmrtSupplier: supplier,
            sendgridCampaignId: null,
            supportingDocuments: null,
            type: storedDataString ? "" : "generator",
          });
        } else {
          await updateSupplierConflictMinerals({
            cmrtVersion: ConflictformGenerate?.cmrtVersion,
            declaration: declaration,
            declarationScope: declarationscope,
            policy: policy,
            company: companyDetails,
            smelters: smelterVal,
            products: ConflictformGenerate?.products,
            rawFileName: ConflictformGenerate?.rawFileName,
            supplierName: uniqueName,
            cmrtSupplier: supplier,
            supportingDocuments: null,
            tenantNumber: window.atob(linkTenantNumber!),
            sendgridCampaignId: window.atob(linkCampaignId!),
          });
        }
      }
    } catch (error) {
      console.error("Error sending data:", error);
    } finally {
      if (getLocalStorage("accessToken")) {
        if (getLocalStorage("conflictMinerals")) {
          navigate(
            "/app/responsibleminerals/conflictminerals/responsetracker",
            {
              replace: true,
            }
          );
        } else {
          navigate("/app/responsibleminerals/conflictminerals", {
            replace: true,
          });
          setLocalStorage("autoDownload", "consolidated");
        }
        setTimeout(() => {
          removeLocalStorage("conflictMinerals");
          setLoading(false);
        }, 1000);
      } else {
        navigate("../campaign/conflict minerals", { replace: true });
        removeLocalStorage("conflictMinerals");
        setLoading(false);
      }
    }
  };
  const [smelterData, setSmelterData] = useState<any[]>([]);
  const [smelterCount, setSmelterCount] = useState<number>(0);

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    setLoading(true);
    try {
      const response = await listSmelter({
        sortBy: sorter?.field,
        sortDesc: sorter?.order === "descend",
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
        filters: selectedTableRows?.selectedTableRows
          ? JSON.stringify({
              "references.mineral": "ConflictMinerals",
              "references.supplier": {
                $in: selectedTableRows?.selectedTableRows.map(
                  (e: { name: any }) => e.name
                ),
              },
            })
          : JSON.stringify({
              "references.mineral": "ConflictMinerals",
            }),
        type: "generator",
      });
      setTableParams({
        pagination: { ...pagination },
        sorter: sorter,
      });
      setSmelterData(response.data.smelters);
      setSmelterCount(response.data.count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [modalVisible, setModalVisible] = useState(false);

  const openSmelterModal = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };
  const handleAddToTable = (formData: any) => {
    if (getLocalStorage("conflictMinerals")) {
      ConflictformGenerate.smelter = [
        formData,
        ...(ConflictformGenerate?.smelter || []),
      ];
    } else {
      setSmelterData([formData, ...smelterData]);
    }
  };

  const handleFieldChange = (fieldName: string, name: any) => {
    const value = form.getFieldValue(fieldName);
    if (name === "Tantalum") {
      setDisabledtantalum(value === "No" || value === "Unknown");
    }
    if (name === "Tin") {
      setDisabledtin(value === "No" || value === "Unknown");
    }
    if (name === "Gold") {
      setDisabledgold(value === "No" || value === "Unknown");
    }
    if (name === "Tungsten") {
      setDisabledtungsten(value === "No" || value === "Unknown");
    }
    setisUpdated(true);
  };
  const [currentStep, setCurrentStep] = useState(0);
  const [executed, setExecuted] = useState(false);

  const handleNext = async () => {
    try {
      const allFields = form.getFieldsValue();
      const fieldNames = Object.keys(allFields);
      const declarationFields: string[] = [];
      const declarationScopeFields: string[] = [];

      fieldNames.forEach((name) => {
        if (
          (name.startsWith(
            "Is any 3TG intentionally added or used in the product(s) or in the production process?"
          ) ||
            name.startsWith("Does any 3TG remain in the product(s)?")) &&
          name.endsWith("state")
        ) {
          declarationFields.push(name);
        }
        if (
          (name.startsWith(
            "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)"
          ) ||
            name.startsWith(
              "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?"
            ) ||
            name.startsWith(
              "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?"
            ) ||
            name.startsWith(
              "What percentage of relevant suppliers have provided a response to your supply chain survey?"
            ) ||
            name.startsWith(
              "Have you identified all of the smelters supplying the 3TG to your supply chain?"
            ) ||
            name.startsWith(
              "Has all applicable smelter information received by your company been reported in this declaration?"
            )) &&
          name.endsWith("state")
        ) {
          declarationScopeFields.push(name);
        }
      });
      setdec(declarationFields);
      setscope(declarationScopeFields);
      if (currentStep === 0) {
        await form.validateFields([
          ["company", "name"],
          ["company", "authorizer", "effectiveDate"],
          ["company", "contact", "emailAddress"],
          ["company", "contact", "name"],
          ["company", "contact", "phoneNumber"],
          ["company", "declarationScope"],
          ["company", "authorizer", "name"],
          ["company", "authorizer", "emailAddress"],
          ["company", "declarationDescription"],
        ]);
        setcompanyIcon(false);
      } else if (currentStep === 1) {
        await form.validateFields(dec);
        setdeclarationIcon(false);
      } else if (currentStep === 2) {
        await form.validateFields(scope);
        setdeclarationScopeIcon(false);
      }
      if (
        currentStep === 3 &&
        !Object.values(form.getFieldsValue(policyField)).some(
          (value) => value === null
        )
      ) {
        setpolicyIcon(false);
      } else if (
        currentStep === 3 &&
        !(disabledgold && disabledtantalum && disabledtin && disabledtungsten)
      ) {
        await form.validateFields(policyField);
        setpolicyIcon(false);
      }

      if (
        currentStep === 4 &&
        selectedOption !== "B. Product (or List of Products)"
      ) {
        const nextStep = currentStep + 2;
        setCurrentStep(nextStep);
      } else {
        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
        setExecuted(false);
      }
      if (ConflictformGenerate?.smelter?.length !== 0) {
        setsmelterIcon(false);
      }
      if (ConflictformGenerate?.products?.length !== 0) {
        setproductIcon(false);
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };
  if (getLocalStorage("conflictMinerals") && currentStep === 6 && !executed) {
    form
      .validateFields()
      .then((value: any) => {
        if (Object.values(value).length > 0) {
          setfieldvalidation(false);
        } else {
          setfieldvalidation(true);
        }
      })
      .catch((error) => {
        if (error.errorFields.length > 0) {
          if (error.errorFields.some((e: any) => e.name.includes("company"))) {
            setcompanyIcon(true);
          }
          if (
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Is any 3TG intentionally added or used in the product(s) or in the production process?"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Does any 3TG remain in the product(s)?"
              )
            )
          ) {
            setdeclarationIcon(true);
          }
          if (
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "What percentage of relevant suppliers have provided a response to your supply chain survey?"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Have you identified all of the smelters supplying the 3TG to your supply chain?"
              )
            ) ||
            error.errorFields.some((e: any) =>
              JSON.stringify(e.name).includes(
                "Has all applicable smelter information received by your company been reported in this declaration?"
              )
            )
          ) {
            setdeclarationScopeIcon(true);
          }
        }
        if (ConflictformGenerate?.smelter?.length === 0) {
          setsmelterIcon(true);
        }
        if (ConflictformGenerate?.products?.length === 0) {
          setproductIcon(true);
        }
        if (
          Object.values(form.getFieldsValue(policyField)).some(
            (value) => value === null
          )
        ) {
          setpolicyIcon(true);
        }
      });
    setExecuted(true);
  }
  const fixError = () => {
    form
      .validateFields()
      .then((value: any) => {
        return false;
      })
      .catch((error) => {
        if (error.errorFields.some((e: any) => e.name.includes("company"))) {
          setCurrentStep(0);
        } else if (
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Is any 3TG intentionally added or used in the product(s) or in the production process?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Does any 3TG remain in the product(s)?"
            )
          )
        ) {
          setCurrentStep(1);
        } else if (
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "What percentage of relevant suppliers have provided a response to your supply chain survey?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Have you identified all of the smelters supplying the 3TG to your supply chain?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "Has all applicable smelter information received by your company been reported in this declaration?"
            )
          )
        ) {
          setCurrentStep(2);
        } else if (
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "A. Have you established a responsible minerals sourcing policy?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "B. Is your responsible minerals sourcing policy publicly available on your website? (Note – If yes, the user shall specify the URL in the comment field.)"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "C. Do you require your direct suppliers to source the 3TG from smelters whose due diligence practices have been validated by an independent third party audit program?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "D. Have you implemented due diligence measures for responsible sourcing?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "E. Does your company conduct Conflict Minerals survey(s) of your relevant supplier(s)?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "F. Do you review due diligence information received from your suppliers against your company’s expectations?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "G. Does your review process include corrective action management?"
            )
          ) ||
          error.errorFields.some((e: any) =>
            JSON.stringify(e.name).includes(
              "H. Is your company required to file an annual conflict minerals disclosure with the SEC?"
            )
          )
        ) {
          setCurrentStep(3);
        }
      });
  };
  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };
  const actionCards = [
    <Space key="1" style={{ display: "flex", justifyContent: "end" }}>
      {currentStep > 0 && (
        <Button style={{ margin: "0 8px" }} type="primary" onClick={handlePrev}>
          Back
        </Button>
      )}
      {currentStep <= 5 && (
        <Button
          style={{ background: "#045B7C", color: "white", marginRight: 8 }}
          onClick={handleNext}
          loading={loading}
        >
          Next
        </Button>
      )}
    </Space>,
  ];

  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  );

  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
  };

  const items: TabsProps["items"] = [
    {
      label: (
        <>
          COMPANY INFORMATION
          {getLocalStorage("conflictMinerals") && (
            <span style={{ marginLeft: "8px" }}>
              {companyIcon ? (
                <ExclamationCircleFilled
                  style={{ color: "#F5222D", fontSize: "16px" }}
                />
              ) : (
                <CheckCircleFilled
                  style={{ color: "#237804", fontSize: "16px" }}
                />
              )}
            </span>
          )}
        </>
      ),
      key: "0",
      forceRender: true,
      disabled: disabledtab === false && currentStep < 0,
      children: (
        <Card
          title="Company Information"
          style={{ overflow: "auto", height: "64vh" }}
          actions={actionCards}
        >
          <Row>
            <Col span={6}>
              <Form.Item>Company name *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "name"]}
                initialValue={ConflictformGenerate?.company.name}
                rules={[
                  {
                    required: true,
                    message: "Company name is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^(?!.*\s{2,})[a-zA-Z0-9()-/.,&_@*\u00C0-\u024F\u00A9|\u2122|\u00AEu4E00-\u9FFF\u{20000}-\u{2FA1F}\p{L}\p{Mn}\p{P}\p{N}\s]+$/u.test(
                            value
                          )) ||
                        (value &&
                          getLocalStorage("conflictMinerals")?.company?.name ===
                            null &&
                          !/^(?!.*\s{2,})[a-zA-Z0-9()-/.,&_@*\u00C0-\u024F\u00A9|\u2122|\u00AEu4E00-\u9FFF\u{20000}-\u{2FA1F}\p{L}\p{Mn}\p{P}\p{N}\s]+$/u.test(
                            value
                          ))
                      ) {
                        return Promise.reject("Not Allowed");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Declaration Scope or Class *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "declarationScope"]}
                initialValue={ConflictformGenerate?.company.declarationScope}
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select onChange={handleSelectChange}>
                  <Select.Option value="A. Company">A. Company</Select.Option>
                  <Select.Option value="B. Product (or List of Products)">
                    B. Product (or List of Products)
                  </Select.Option>
                  <Select.Option value="C. User defined [Specify in 'Description of scope']">
                    C. User defined [Specify in &#39;Description of scope&#39;]
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Description of Scope</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "declarationDescription"]}
                initialValue={
                  ConflictformGenerate?.company.declarationDescription
                }
                rules={[
                  {
                    required:
                      selectedOption ===
                      "C. User defined [Specify in 'Description of scope']",
                    message: "Required",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Company Unique ID</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "uniqueId"]}
                initialValue={ConflictformGenerate?.company.uniqueId}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Company Unique ID Authority</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "uniqueIdAuthority"]}
                initialValue={ConflictformGenerate?.company.uniqueIdAuthority}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Address</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "address"]}
                initialValue={ConflictformGenerate?.company.address}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Contact Name *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "contact", "name"]}
                initialValue={ConflictformGenerate?.company.contact.name}
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^([a-zA-Z0-9().-/&]+\s?)*$/i.test(value)) ||
                        (value &&
                          getLocalStorage("conflictMinerals")?.company?.contact
                            ?.name === null &&
                          !/^([a-zA-Z0-9().-/&]+\s?)*$/i.test(value))
                      ) {
                        return Promise.reject("Not Allowed");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Contact Number *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "contact", "phoneNumber"]}
                initialValue={ConflictformGenerate?.company.contact.phoneNumber}
                rules={[
                  {
                    required: true,
                    message: "Number is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(
                            value
                          )) ||
                        (value &&
                          getLocalStorage("conflictMinerals")?.company?.contact
                            ?.phoneNumber === null &&
                          !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(
                            value
                          ))
                      ) {
                        return Promise.reject("Phone Number must be valid");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Contact Email *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "contact", "emailAddress"]}
                initialValue={
                  ConflictformGenerate?.company.contact.emailAddress
                }
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            value
                          )) ||
                        (value &&
                          getLocalStorage("conflictMinerals")?.company?.contact
                            ?.emailAddress === null &&
                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            value
                          ))
                      ) {
                        return Promise.reject("E-mail must be valid");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Authorizer *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "authorizer", "name"]}
                initialValue={ConflictformGenerate?.company.authorizer.name}
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^([a-zA-Z0-9().-/&]+\s?)*$/i.test(value)) ||
                        (value &&
                          getLocalStorage("conflictMinerals")?.company
                            ?.authorizer?.name === null &&
                          !/^([a-zA-Z0-9().-/&]+\s?)*$/i.test(value))
                      ) {
                        return Promise.reject("Not Allowed");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Title-Authorizer</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "authorizer", "title"]}
                initialValue={ConflictformGenerate?.company.authorizer.title}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Email-Authorizer *</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "authorizer", "emailAddress"]}
                initialValue={
                  ConflictformGenerate?.company.authorizer.emailAddress
                }
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        (value &&
                          !getLocalStorage("conflictMinerals") &&
                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            value
                          )) ||
                        (value &&
                          getLocalStorage("conflictMinerals")?.company
                            ?.authorizer?.emailAddress === null &&
                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            value
                          ))
                      ) {
                        return Promise.reject("E-mail must be valid");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Phone-Authorizer</Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name={["company", "authorizer", "phoneNumber"]}
                initialValue={
                  ConflictformGenerate?.company.authorizer.phoneNumber
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item>Effective Date *</Form.Item>
            </Col>
            <Col span={16}>
              {ConflictformGenerate?.company?.authorizer?.effectiveDate &&
                ConflictformGenerate?.company?.authorizer?.effectiveDate !==
                  null &&
                dayjs.isDayjs(
                  ConflictformGenerate?.company?.authorizer?.effectiveDate
                ) && (
                  <Form.Item
                    name={["company", "authorizer", "effectiveDate"]}
                    rules={[{ required: true, message: "Date is required" }]}
                  >
                    <DatePicker
                      format={"DD-MMM-YYYY"}
                      defaultValue={
                        ConflictformGenerate?.company?.authorizer?.effectiveDate
                          ? dayjs(
                              ConflictformGenerate?.company?.authorizer
                                ?.effectiveDate
                            )
                          : undefined
                      }
                    />
                  </Form.Item>
                )}
            </Col>
          </Row>
        </Card>
      ),
    },
    {
      label: (
        <>
          DECLARATION
          {getLocalStorage("conflictMinerals") && (
            <span style={{ marginLeft: "8px" }}>
              {declarationIcon ? (
                <ExclamationCircleFilled
                  style={{ color: "#F5222D", fontSize: "16px" }}
                />
              ) : (
                <CheckCircleFilled
                  style={{ color: "#237804", fontSize: "16px" }}
                />
              )}
            </span>
          )}
        </>
      ),
      key: "1",
      forceRender: true,
      disabled: currentStep < 1,
      children: (
        <Card
          title="Declaration"
          style={{ overflow: "auto", height: "64vh" }}
          actions={actionCards}
        >
          <Card.Meta description="Answer the following questions based on the selected declaration scope" />
          <br></br>
          <b>
            1. Is any 3TG intentionally added or used in the product(s) or in
            the production process?
          </b>

          {ConflictformGenerate?.declaration
            .filter(
              (item) =>
                item.question ===
                "Is any 3TG intentionally added or used in the product(s) or in the production process?"
            )
            .map((item, index) => (
              <Row
                key={`Is any 3TG intentionally added or used in the product(s) or in the production process?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[
                      `Is any 3TG intentionally added or used in the product(s) or in the production process?-${item.name}-${item.state}-state`,
                    ]}
                    initialValue={item.state}
                    dependencies={[
                      `Is any 3TG intentionally added or used in the product(s) or in the production process?-${item.name}-${item.state}-state`,
                    ]}
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Radio.Group
                      onChange={() =>
                        handleFieldChange(
                          `Is any 3TG intentionally added or used in the product(s) or in the production process?-${item.name}-${item.state}-state`,
                          item.name
                        )
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}

          <b>2. Does any 3TG remain in the product(s)?</b>

          {ConflictformGenerate?.declaration
            .filter(
              (item) =>
                item.question === "Does any 3TG remain in the product(s)?"
            )
            .map((item, index) => (
              <Row
                key={`Does any 3TG remain in the product(s)?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Does any 3TG remain in the product(s)?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledtantalum
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledtin
                        ? { required: true, message: "Required" }
                        : index === 2 && !disabledgold
                        ? { required: true, message: "Required" }
                        : index === 3 && !disabledtungsten
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                    dependencies={[
                      `Does any 3TG remain in the product(s)?-${item.name}-${item.state}-state`,
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        (["No", "Unknown"].includes(
                          form.getFieldValue(
                            Object.keys(form.getFieldsValue()).find(
                              (key) =>
                                key.includes(
                                  "Is any 3TG intentionally added or used in the product(s) or in the production process?-Tantalum"
                                ) && key.endsWith("-state")
                            )
                          )
                        ) &&
                          index === 0) ||
                        (["No", "Unknown"].includes(
                          form.getFieldValue(
                            Object.keys(form.getFieldsValue()).find(
                              (key) =>
                                key.includes(
                                  "Is any 3TG intentionally added or used in the product(s) or in the production process?-Tin"
                                ) && key.endsWith("-state")
                            )
                          )
                        ) &&
                          index === 1) ||
                        (["No", "Unknown"].includes(
                          form.getFieldValue(
                            Object.keys(form.getFieldsValue()).find(
                              (key) =>
                                key.includes(
                                  "Is any 3TG intentionally added or used in the product(s) or in the production process?-Gold"
                                ) && key.endsWith("-state")
                            )
                          )
                        ) &&
                          index === 2) ||
                        (["No", "Unknown"].includes(
                          form.getFieldValue(
                            Object.keys(form.getFieldsValue()).find(
                              (key) =>
                                key.includes(
                                  "Is any 3TG intentionally added or used in the product(s) or in the production process?-Tungsten"
                                ) && key.endsWith("-state")
                            )
                          )
                        ) &&
                          index === 3)
                      }
                      onChange={() =>
                        handleFieldChange(
                          `Does any 3TG remain in the product(s)?-${item.name}-${item.state}-state`,
                          item.name
                        )
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
        </Card>
      ),
    },
    {
      label: (
        <>
          DECLARATION SCOPE
          {getLocalStorage("conflictMinerals") && (
            <span style={{ marginLeft: "8px" }}>
              {declarationScopeIcon ? (
                <ExclamationCircleFilled
                  style={{ color: "#F5222D", fontSize: "16px" }}
                />
              ) : (
                <CheckCircleFilled
                  style={{ color: "#237804", fontSize: "16px" }}
                />
              )}
            </span>
          )}
        </>
      ),
      key: "2",
      forceRender: true,
      disabled: currentStep < 2,

      children: (
        <Card
          title="Declaration Scope"
          style={{ overflow: "auto", height: "64vh" }}
          actions={actionCards}
        >
          <Card.Meta description="Answer the following questions based on the selected declaration scope" />
          <br></br>

          <b>
            3. Do any of the smelters in your supply chain source the 3TG from
            the covered countries? (SEC term, see definitions tab)
          </b>

          {ConflictformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)"
            )
            .map((item, index) => (
              <Row
                key={`Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Do any of the smelters in your supply chain source the 3TG from the covered countries? (SEC term, see definitions tab)-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledtantalum
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledtin
                        ? { required: true, message: "Required" }
                        : index === 2 && !disabledgold
                        ? { required: true, message: "Required" }
                        : index === 3 && !disabledtungsten
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        (index === 0 && disabledtantalum === true) ||
                        (index === 1 && disabledtin === true) ||
                        (index === 2 && disabledgold === true) ||
                        (index === 3 && disabledtungsten === true)
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          <b>
            4. Do any of the smelters in your supply chain source the 3TG from
            conflict-affected and high-risk areas?
          </b>

          {ConflictformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?"
            )
            .map((item, index) => (
              <Row
                key={`Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Do any of the smelters in your supply chain source the 3TG from conflict-affected and high-risk areas?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledtantalum
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledtin
                        ? { required: true, message: "Required" }
                        : index === 2 && !disabledgold
                        ? { required: true, message: "Required" }
                        : index === 3 && !disabledtungsten
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        (index === 0 && disabledtantalum === true) ||
                        (index === 1 && disabledtin === true) ||
                        (index === 2 && disabledgold === true) ||
                        (index === 3 && disabledtungsten === true)
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          <b>
            5. Does 100 percent of the 3TG (necessary to the functionality or
            production of your products) originate from recycled or scrap
            sources?
          </b>

          {ConflictformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?"
            )
            .map((item, index) => (
              <Row
                key={`Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Does 100 percent of the 3TG (necessary to the functionality or production of your products) originate from recycled or scrap sources?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledtantalum
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledtin
                        ? { required: true, message: "Required" }
                        : index === 2 && !disabledgold
                        ? { required: true, message: "Required" }
                        : index === 3 && !disabledtungsten
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        (index === 0 && disabledtantalum === true) ||
                        (index === 1 && disabledtin === true) ||
                        (index === 2 && disabledgold === true) ||
                        (index === 3 && disabledtungsten === true)
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          <b>
            6. What percentage of relevant suppliers have provided a response to
            your supply chain survey?
          </b>

          {ConflictformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "What percentage of relevant suppliers have provided a response to your supply chain survey?"
            )
            .map((item, index) => (
              <Row
                key={`What percentage of relevant suppliers have provided a response to your supply chain survey?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`What percentage of relevant suppliers have provided a response to your supply chain survey?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0
                        ? { required: !disabledtantalum, message: "Required" }
                        : index === 1
                        ? { required: !disabledtin, message: "Required" }
                        : index === 2
                        ? { required: !disabledgold, message: "Required" }
                        : index === 3
                        ? { required: !disabledtungsten, message: "Required" }
                        : {},
                    ]}
                  >
                    <Select
                      disabled={
                        (index === 0 && disabledtantalum === true) ||
                        (index === 1 && disabledtin === true) ||
                        (index === 2 && disabledgold === true) ||
                        (index === 3 && disabledtungsten === true)
                      }
                      options={[
                        { label: "100%", value: 1 },
                        {
                          label: "Greater than 90%",
                          value: "Greater than 90%",
                        },
                        {
                          label: "Greater than 75%",
                          value: "Greater than 75%",
                        },
                        {
                          label: "Greater than 50%",
                          value: "Greater than 50%",
                        },
                        { label: "50% or less", value: "50% or less" },
                        { label: "None", value: "None" },
                      ]}
                      style={{ width: 250 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          <b>
            7. Have you identified all of the smelters supplying the 3TG to your
            supply chain?
          </b>

          {ConflictformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Have you identified all of the smelters supplying the 3TG to your supply chain?"
            )
            .map((item, index) => (
              <Row
                key={`Have you identified all of the smelters supplying the 3TG to your supply chain?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Have you identified all of the smelters supplying the 3TG to your supply chain?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledtantalum
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledtin
                        ? { required: true, message: "Required" }
                        : index === 2 && !disabledgold
                        ? { required: true, message: "Required" }
                        : index === 3 && !disabledtungsten
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        (index === 0 && disabledtantalum === true) ||
                        (index === 1 && disabledtin === true) ||
                        (index === 2 && disabledgold === true) ||
                        (index === 3 && disabledtungsten === true)
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          <b>
            8. Has all applicable smelter information received by your company
            been reported in this declaration?
          </b>

          {ConflictformGenerate?.declarationScope
            .filter(
              (item) =>
                item.question ===
                "Has all applicable smelter information received by your company been reported in this declaration?"
            )
            .map((item, index) => (
              <Row
                key={`Has all applicable smelter information received by your company been reported in this declaration?-${item.name}`}
                style={{ display: "flex", marginBottom: 8 }}
              >
                <Col span={4}>
                  <Form.Item>{item.name}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`Has all applicable smelter information received by your company been reported in this declaration?-${item.name}-${item.state}-state`}
                    initialValue={item.state}
                    rules={[
                      index === 0 && !disabledtantalum
                        ? { required: true, message: "Required" }
                        : index === 1 && !disabledtin
                        ? { required: true, message: "Required" }
                        : index === 2 && !disabledgold
                        ? { required: true, message: "Required" }
                        : index === 3 && !disabledtungsten
                        ? { required: true, message: "Required" }
                        : {},
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        (index === 0 && disabledtantalum === true) ||
                        (index === 1 && disabledtin === true) ||
                        (index === 2 && disabledgold === true) ||
                        (index === 3 && disabledtungsten === true)
                      }
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Unknown">Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.comments}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
        </Card>
      ),
    },
    {
      label: (
        <>
          POLICY
          {getLocalStorage("conflictMinerals") && (
            <span style={{ marginLeft: "8px" }}>
              {policyIcon ? (
                <WarningFilled style={{ color: "orange", fontSize: "16px" }} />
              ) : (
                <CheckCircleFilled
                  style={{ color: "#237804", fontSize: "16px" }}
                />
              )}
            </span>
          )}
        </>
      ),
      key: "3",
      disabled: currentStep < 3,

      forceRender: true,
      children: (
        <Card
          title="Policy"
          style={{ overflow: "auto", height: "64vh" }}
          actions={actionCards}
        >
          <Card.Meta description="Answer the Following Questions at a Company Level" />
          <br></br>

          {ConflictformGenerate?.policy.map((item, index) => (
            <div key={`${item.question}-${item.name}`}>
              <b>{item.question}</b>
              <Row style={{ display: "flex", marginBottom: 8 }}>
                <Col span={8}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.state}-${index}-state`}
                    initialValue={item.state}
                    rules={[
                      {
                        required: !(
                          disabledgold &&
                          disabledtantalum &&
                          disabledtin &&
                          disabledtungsten
                        ),
                        message: "Required",
                      },
                    ]}
                  >
                    <Radio.Group>
                      {index === 4 && (
                        <Radio value="Yes, using other format (describe)">
                          Yes, using other format (describe)
                        </Radio>
                      )}
                      <br></br>
                      {index === 4 && (
                        <Radio value="Yes, in conformance with ipc1755 (e.g., cmrt)">
                          Yes, in conformance with IPC1755 (e.g., CMRT)
                        </Radio>
                      )}
                      <br></br>
                      {index === 7 && (
                        <Radio value="Yes, with the sec and the eu">
                          Yes, with the SEC and the EU
                        </Radio>
                      )}
                      <br></br>
                      {index === 7 && (
                        <Radio value="Yes, with the eu">Yes, with the EU</Radio>
                      )}
                      <br></br>
                      {index === 7 && (
                        <Radio value="Yes, with the sec">
                          Yes, with the SEC
                        </Radio>
                      )}
                      <br></br>
                      {index !== 7 && index !== 4 && (
                        <Radio value="Yes">Yes</Radio>
                      )}
                      <br></br>
                      <Radio value="No">No</Radio>
                      <br></br>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`${item.question}-${item.name}-${item.comments}-${index}-comments`}
                    initialValue={item.comments}
                  >
                    <Input placeholder="Comments" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
        </Card>
      ),
    },
    {
      label: (
        <>
          SMELTER LIST
          {getLocalStorage("conflictMinerals") && (
            <span style={{ marginLeft: "8px" }}>
              {smelterIcon ? (
                <WarningFilled style={{ color: "orange", fontSize: "16px" }} />
              ) : (
                <CheckCircleFilled
                  style={{ color: "#237804", fontSize: "16px" }}
                />
              )}
            </span>
          )}
        </>
      ),
      key: "4",
      disabled: currentStep < 4,
      forceRender: true,
      children: (
        <Card
          title="Smelter List"
          style={{ height: "64vh" }}
          actions={actionCards}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={openSmelterModal}
              data-testid="add-smelter-button"
              title="Add Smelter"
            >
              <PlusOutlined />
              CREATE
            </Button>,
            <AddSmelter
              key="2"
              onAdd={handleAddToTable}
              open={modalVisible}
              onClose={handleModalClose}
              smelterList={
                getLocalStorage("conflictMinerals")
                  ? ConflictformGenerate?.smelter
                  : smelterData
              }
              type={"ConflictMinerals"}
            />,
          ]}
        >
          <Card.Meta description="Selected Smelters" />
          <br></br>
          <Table
            dataSource={
              getLocalStorage("conflictMinerals")
                ? ConflictformGenerate?.smelter
                : smelterData
            }
            columns={columns}
            rowKey="number"
            scroll={{ y: 200 }}
            pagination={{
              ...tableParams.pagination,
              total: getLocalStorage("conflictMinerals")
                ? ConflictformGenerate?.smelter?.length
                : smelterCount,
              showTotal: (total: number, range: [number, number]) =>
                `${range[0]}-${range[1]} of ${total} items`,
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
        </Card>
      ),
    },
    {
      label: "CMRT",
      key: "6",
      forceRender: true,
      disabled: currentStep < 6,

      children: (
        <Card
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "64vh",
          }}
        >
          {fieldvalidation ? (
            <Card style={{ width: "70vh", textAlign: "center" }}>
              <Result
                status="warning"
                title="There are some problems with your imported data."
                extra={[
                  <Form.Item key="1">
                    <Button onClick={() => fixError()} type="primary">
                      Fix Errors
                    </Button>
                  </Form.Item>,
                ]}
              />
            </Card>
          ) : (
            <Card style={{ width: "70vh", textAlign: "center" }}>
              <Result
                status="success"
                title={`You have successfully ${
                  disabledtab ? "consolidated" : "imported"
                } the CMRT`}
                extra={[
                  <Form.Item key="1">
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => form.submit}
                      loading={loading}
                      data-testid="submit-cmrt-button"
                      title="Submit CMRT"
                    >
                      Save & Close
                    </Button>
                  </Form.Item>,
                ]}
              />
            </Card>
          )}
        </Card>
      ),
    },
  ];
  const productList = {
    label: (
      <>
        PRODUCT LIST
        {getLocalStorage("conflictMinerals") && (
          <span style={{ marginLeft: "8px" }}>
            {productIcon ? (
              <WarningFilled style={{ color: "orange", fontSize: "16px" }} />
            ) : (
              <CheckCircleFilled
                style={{ color: "#237804", fontSize: "16px" }}
              />
            )}
          </span>
        )}
      </>
    ),
    key: "5",
    forceRender: true,
    disabled: currentStep < 6,
    children: (
      <Card
        title="Product List"
        style={{ height: "64vh" }}
        actions={actionCards}
      >
        <Card.Meta description="Selected Products" />
        <br></br>
        <Table
          dataSource={ConflictformGenerate?.products}
          columns={columns1}
          rowKey="manufacturer.itemNumber"
          scroll={{ y: 200 }}
          pagination={{
            total: ConflictformGenerate?.products?.length,
            showTotal: (total: number, range: [number, number]) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
          }}
        />
      </Card>
    ),
  };
  const stepsItems = [
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
  ];
  const productStepItem = {
    title: "",
  };

  if (selectedOption === "B. Product (or List of Products)") {
    items.splice(5, 0, productList);
    stepsItems.splice(5, 0, productStepItem);
  }

  return (
    <ErrorBoundary>
      <Card>
        <Steps
          size="small"
          style={{ maxWidth: 500, margin: "auto", marginBottom: "2rem" }}
          items={stepsItems}
          current={currentStep}
        />
        <Form form={form} onFinish={onFinish}>
          <Tabs
            defaultActiveKey={"0"}
            activeKey={String(currentStep)}
            onChange={handleChangeTab}
            items={items}
            tabPosition="top"
            className="card-tabs"
          ></Tabs>
        </Form>
      </Card>
    </ErrorBoundary>
  );
};

export default ExcelTabs;
