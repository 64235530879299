import React from "react";
import { Layout } from "antd";

import styles from "./footer.module.css";

const { Footer } = Layout;

const FooterLayout: React.FC = () => {
  return (
    <Footer className={styles.footer}>
      Copyright {new Date().getFullYear()} at Acquis Compliance. All rights
      reserved.
    </Footer>
  );
};

export default FooterLayout;
