import React, { useEffect } from "react";
import { Node, Edge, MarkerType } from "reactflow";
import { useCallback } from "react";
import ReactFlow, {
  Controls,
  Background,
  addEdge,
  useNodesState,
  useEdgesState,
  Connection,
} from "reactflow";
import "reactflow/dist/style.css";
import CustomNode from "./CustomNode";
import { Component } from "../../../../utils/types/component";
interface SubstanceType {
  name: string;
  weight: string;
  casNumber: string;
}

const nodeTypes = {
  customNode: CustomNode,
};

const MaterialDisclosureTab: React.FC<{
  component: any;
}> = (props) => {
  useEffect(() => {
    fetchData();
  }, [props.component]);

  const fetchData = () => {
    const data = dataToFlow(props.component && props.component);
    setNodeState(data.nodes || []);
    setEdgeState(data.edges || []);
  };

  const dataToFlow = (data: Component) => {
    const nodes: Node[] = [];
    const edges: Edge[] = [];

    const centerX = 500;
    const centerY = 500;
    const materialSpacing = 180;
    const horizontalSpacing = 400;
    const manufacturerId = "manufacturer";

    nodes.push({
      id: manufacturerId,
      type: "customNode",
      data: { ...data, id: manufacturerId },
      position: { x: centerX, y: centerY },
      draggable: false,
    });

    const halfmaterials = Math.ceil(data?.materials?.length / 2);
    const lastPositions = { left: 50, right: 50 };

    data?.materials?.forEach((material: any, index: number) => {
      const materialId = `material-${index}`;
      const isLeft = index < halfmaterials;
      const positionX = isLeft
        ? centerX - horizontalSpacing
        : centerX + horizontalSpacing;

      const positionY = isLeft
        ? halfmaterials === 1
          ? centerY
          : lastPositions.left + materialSpacing
        : halfmaterials === 1
        ? centerY
        : lastPositions.right + materialSpacing;

      nodes.push({
        id: materialId,
        type: "customNode",
        data: {
          ...material,
          id: materialId,
        },
        position: { x: isLeft ? positionX - 50 : positionX + 50, y: positionY },
        draggable: false,
      });

      edges.push({
        id: `e-${manufacturerId}-${materialId}`,
        source: manufacturerId,
        target: materialId,
        sourceHandle: isLeft ? "left-source" : "right-source",
        targetHandle: isLeft ? "right-target" : "left-target",
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: "black",
        },
        style: {
          strokeWidth: 1.5,
          stroke: "black",
        },
        type: "step",
      });

      let lastsubstancePositionY = positionY;

      material.substances?.forEach(
        (substance: SubstanceType, substanceIndex: number) => {
          const substanceId = `substance-${index}-${substanceIndex}`;
          const substancePositionX = positionX + (isLeft ? -450 : 450);
          const substancePositionY = positionY + substanceIndex * 50;

          nodes.push({
            id: substanceId,
            type: "customNode",
            style: { zIndex: 1 },
            data: {
              ...substance,
              id: substanceId,
              substanceIndex: substanceIndex,
            },
            position: { x: substancePositionX, y: substancePositionY },
            draggable: false,
          });

          if (substanceIndex === 0) {
            edges.push({
              id: `e-${materialId}-${substanceId}`,
              source: materialId,
              target: substanceId,
              sourceHandle: isLeft ? "left-source" : "right-source",
              targetHandle: isLeft ? "right-target" : "left-target",
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: "black",
              },
              style: {
                strokeWidth: 1.5,
                stroke: "black",
              },
              type: "step",
            });
          }

          lastsubstancePositionY = substancePositionY;
        }
      );

      if (isLeft) {
        lastPositions.left = lastsubstancePositionY;
      } else {
        lastPositions.right = lastsubstancePositionY;
      }
    });

    return { nodes, edges };
  };
  const { nodes, edges } = dataToFlow(props.component && props.component);
  const [nodeState, setNodeState, onNodesChange] = useNodesState(nodes || []);
  const [edgeState, setEdgeState, onEdgesChange] = useEdgesState(edges || []);
  const onConnect = (params: Edge | Connection) => {
    setEdgeState((eds) => addEdge(params, eds));
  };
  const handleNodeHover = useCallback((id: string, isHovering: any) => {
    setNodeState((nodeState) =>
      nodeState.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            style: {
              ...node.style,
              zIndex: isHovering && node.id.includes("substance-") ? 20 : 1,
            },
          };
        }
        return node;
      })
    );
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <ReactFlow
        nodes={nodeState}
        edges={edgeState}
        onNodesChange={onNodesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onNodeMouseEnter={(event, node) => handleNodeHover(node.id, true)}
        onNodeMouseLeave={(event, node) => handleNodeHover(node.id, false)}
        fitView
        minZoom={0.2}
        maxZoom={1}
      >
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default MaterialDisclosureTab;
