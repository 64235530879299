import React, { useEffect, useState } from "react";
import { Modal, message, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";

import { baseUrl } from "../../../utils/apiHelper";
import { getLocalStorage } from "../../../utils/localStore";
import axios from "axios";

const ImageCropper: React.FC<{
  postUrl: string;
  tenantNumber: string;
  setFormField: any;
  formField?: string;
}> = (props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleCancel = () => setPreviewOpen(false);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    newFileList.length
      ? props.setFormField(newFileList[0].name)
      : props.setFormField(null);
  };

  useEffect(() => {
    if (props.formField) {
      axios
        .post(
          baseUrl + "/" + props.postUrl.replace("upload", "download"),
          {
            tenantNumber: props.tenantNumber,
            filename: props.formField,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
          }
        )
        .then((response) => {
          const imageBlob = new Blob([response.data], { type: "image/png" });
          const URL = window.URL || window.webkitURL;
          setFileList([
            {
              uid: "-1",
              name: props.formField!,
              status: "done",
              url: URL.createObjectURL(imageBlob),
            },
          ]);
        });
    }
  }, [props.formField]);

  return (
    <>
      <ImgCrop quality={0.5} fillColor="transparent" aspect={4.5 / 1}>
        <Upload
          fileList={fileList}
          listType="picture-card"
          action={baseUrl + "/" + props.postUrl}
          data={{ tenantNumber: props.tenantNumber }}
          headers={{
            Authorization: `Bearer ${getLocalStorage("accessToken")}`,
          }}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          onPreview={handlePreview}
          defaultFileList={[]}
        >
          {fileList.length < 1 && "+ Upload"}
        </Upload>
      </ImgCrop>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default ImageCropper;
