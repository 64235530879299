import React, { FC, useEffect, useState } from "react";
import FusionCharts from "fusioncharts";

// Function to generate a unique ID
const generateUniqueId = () => {
  return 'chart-' + Math.random().toString(36).substr(2, 9);
};

interface ChartProps {
  dataSource: any;
  chartType: string;
}

const Chart: FC<ChartProps> = ({ dataSource, chartType }) => {
  const [uniqueId] = useState(generateUniqueId());

  useEffect(() => {
    FusionCharts.ready(() => {
      new FusionCharts({
        type: chartType,
        renderAt: `chart-container-${uniqueId}`,
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource,
      }).render();
    });
  }, [dataSource, chartType, uniqueId]);

  return <div id={`chart-container-${uniqueId}`} />;
};

export default Chart;
