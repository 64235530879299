import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import {
  authResetPassword,
  authChangePassword,
} from "../../../redux/actions/authAction";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Paragraph } = Typography;

const ResetPassword: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resetToken = query.get("token");
  const emailAddress = query.get("emailAddress");
  const tenantNumber = query.get("tenantNumber");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (values: any) => {
    setLoading(true);
    if (path === true) {
      dispatch(
        authResetPassword({
          ...values,
          resetToken,
          emailAddress,
          tenantNumber,
          currentLocationOrigin: window.location.origin,
        })
      )
        .then(() => {
          navigate(`/login/${tenantNumber}`);
        })
        .catch(() => {
          form.resetFields();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(
        authChangePassword({
          ...values,
          resetToken,
          emailAddress,
          tenantNumber,
        })
      )
        .then(() => {
          navigate(`/login/${tenantNumber}`);
        })
        .catch(() => {
          form.resetFields();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const path = window.location.pathname.includes("setpassword");

  return (
    <ErrorBoundary>
      <Card>
        <Paragraph
          strong
          style={{
            textAlign: "center",
          }}
        >
          {path === true ? "Create Password" : "Reset Password"}
        </Paragraph>
        <Form
          form={form}
          initialValues={{ emailAddress: emailAddress }}
          onFinish={onSubmit}
          style={{
            marginTop: "3rem",
            width: 400,
          }}
        >
          <Form.Item
            name="emailAddress"
            rules={[
              {
                required: true,
                message: "Please input your email address!",
              },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input placeholder="Email Address" readOnly />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^+-_#])[A-Za-z\d@$!%*?&^+-_#]{8,}$/,
                message:
                  "Password must be minimum 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character",
              },
            ]}
          >
            <Input.Password placeholder="Enter Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {path === true ? "Create Password" : "Reset Password"}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </ErrorBoundary>
  );
};

export default ResetPassword;
