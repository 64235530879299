import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ExportOutlined, RollbackOutlined } from "@ant-design/icons";
import { Space, Typography, Button, Table, Input } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Component } from "../../../../../utils/types/component";
import { useAppDispatch } from "../../../../../redux/hooks";
import { readListsComponents } from "../../../../../redux/actions/CampaignManager/listsAction";
import { Regulation } from "../../../../../utils/types/regulation";
import EditListForm from "../../../../../components/forms/EditListForm";
import {
  getRegulationsFromLocalStorage,
  setLocalStorage,
} from "../../../../../utils/localStore";
import ReadSupportingDocument from "../../../../../components/modals/ReadSupportingDocuments";
import ErrorBoundary from "../../../../../utils/errorBoundary";
const { Search } = Input;
const { Text } = Typography;

const SurveyListProfileComponents: React.FC<{
  components: Component[];
  count: number;
  childLoading: boolean;
  type: string;
}> = ({ components, count, childLoading, type }) => {
  const { id } = useParams<{ id: any }>();
  const dispatch = useAppDispatch();

  const [listComponents, setListComponents] = useState<Component[]>([]);
  const [listComponentCount, setListComponentCount] = useState<number>(0);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Component>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const regulations = getRegulationsFromLocalStorage() ?? [];

  const columns: ColumnsType<Component> = [
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
      width: 150,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      render: (manufacturer) => (
        <Text>
          {manufacturer.itemNumber}
          <br></br>
          <small>{manufacturer.name}</small>
        </Text>
      ),
      width: 150,
      // `${manufacturer.name} ${manufacturer.itemNumber}`,
    },
    // ...(parseUser.specifications
    //   ? parseUser.specifications.map((e: any) => ({
    //       title: e.name.charAt(0).toUpperCase() + e.name.slice(1),
    //       dataIndex: `specification.${e.value}`,
    //       key: `specification.${e.value}`,
    //       sorter: true,
    //       width: 150,
    //     }))
    //   : []),
    {
      title: "FMD Document",
      dataIndex: "fmdDocument",
      key: "fmdDocument",
      width: 150,
      render: (text: string) => {
        return text ? (
          text?.includes("http") ? (
            <span>
              <ExportOutlined onClick={() => onClickViewLink(text)} /> Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="materials/document/download"
              filename={text}
              foldername="materials"
            />
          )
        ) : null;
      },
    },
    {
      title: "Part Weight",
      dataIndex: "partWeight",
      key: "partWeight",
      width: 150,
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      width: 150,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ];

  (regulations || []).forEach((e: Regulation) => {
    e.configuration
      .filter((o) => !o.hidden)
      .forEach((k, i) => {
        if (
          k.value !== "internalItemNumber" &&
          k.value !== "manufacturer" &&
          k.value !== "operations"
        ) {
          const newColumn = {
            title: `${e.name} ${k.text}`,
            dataIndex: `${e.regulationNumber}.${k.value}`,
            key: `${e.regulationNumber}.${k.value}`,
            width: k.value === "complianceOutputs.substance" ? 255 : 180,
            sorter: false,
          };
          columns.splice(
            columns.findIndex((c) => c.key === "createdAt"),
            0,
            newColumn
          );
        }
      });
  });

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readListsComponents({
        id: id,
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: "",
      })
    )
      .then((response: any) => {
        setListComponents(response.listComponents);
        setListComponentCount(response.listComponentCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Component>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Component> | SorterResult<Component>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Component>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const onClickBack = () => {
    navigate(-2);
    setLocalStorage("BackTabActivate", "list");
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Table
          loading={type === "emitChild" ? childLoading : loading}
          dataSource={type === "emitChild" ? components : listComponents}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: type === "emitChild" ? count : listComponentCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default SurveyListProfileComponents;
