import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Space, Typography, Button, Table, Input } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { useNavigate } from "react-router-dom";

import { Regulation } from "../../../../utils/types/regulation";
import { useAppDispatch } from "../../../../redux/hooks";
import { listRegulations } from "../../../../redux/actions/regulationsAction";
import RegulationForm from "../../../../components/forms/RegulationForm";
import DeclarationTemplateForm from "../../../../components/forms/DeclarationTemplateForm";
import { getLocalStorage } from "../../../../utils/localStore";
import ErrorBoundary from "../../../../utils/errorBoundary";

const { Search } = Input;
const { Title, Text } = Typography;

const RegulationList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [regulations, setRegulations] = useState<Regulation[]>([]);
  const [regulationCount, setRegulationCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Regulation>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();
  const [search, setSearch] = useState<any>();
  const { workspace } = getLocalStorage("user");

  const columns: ColumnsType<Regulation> = [
    {
      title: "Action",
      key: "_id",
      width: 150,
      render: (_, record: Regulation) => (
        <Space>
          <RegulationForm
            type="update"
            fetchData={fetchData}
            formData={record}
          />
          <DeclarationTemplateForm
            type="update"
            fetchData={fetchData}
            formData={record}
          />
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => navigate(record._id.toString())}
          />
        </Space>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text: string) => (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: any) => (
        <Text>
          {new Date(text).toLocaleString()}
          <br />
          {record.createdBy}
        </Text>
      ),
    },
  ];

  const fetchData = (payload?: any) => {
    setLoading(true);
    dispatch(
      listRegulations({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? true : true,
        filters: payload ? payload : null,
      })
    )
      .then((response) => {
        setRegulations(response.regulations);
        setRegulationCount(response.regulationCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Regulation>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Regulation> | SorterResult<Regulation>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Regulation>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setRegulations([]);
    }
  };

  useEffect(() => {
    fetchData(JSON.stringify(search));
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    JSON.stringify(search),
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Regulations
            </Title>
          </div>
          <Space>
            <Search
              placeholder="Search using name or description"
              allowClear
              onSearch={(text) =>
                setSearch({
                  softDelete: false,
                  $or: [
                    { description: { $regex: text.trim(), $options: "i" } },
                    { name: { $regex: text.trim(), $options: "i" } },
                  ],
                })
              }
              style={{ width: 350 }}
            />
            {workspace === "acquiscompliance" && (
              <RegulationForm type="create" fetchData={fetchData} />
            )}
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={regulations}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: regulationCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default RegulationList;
